import {Box, Typography} from '@mui/material';
import * as React from 'react';
import StyledInjectedHtml from '../styled-injected-html/StyledInjectedHtml';

export interface ResponsiveContentProps {
  disclaimerFooter?: string;
  children?: React.ReactNode;
}

const ResponsiveContent: React.FC<ResponsiveContentProps> = ({
  disclaimerFooter,
  children,
}: ResponsiveContentProps) => {
  let disclaimer;
  // Without the tabIndex property, we can't navigate to the disclaimer footer
  // using assistive technologies. See PHP-3280 for more details.
  if (disclaimerFooter) {
    disclaimer = (
      <footer>
        <Box sx={{mt: 6}}>
          <div data-testid="injectedHTML" role="contentinfo" tabIndex={0}>
            <Typography variant="caption">
              <StyledInjectedHtml content={disclaimerFooter} />
            </Typography>
          </div>
        </Box>
      </footer>
    );
  }
  return (
    <Box
      sx={{
        my: {xs: 3, md: 21},
        marginInline: {xs: 4, md: 'clamp(2rem, 5vw, 6rem)'},
      }}
    >
      <div>{children}</div>
      {disclaimer}
    </Box>
  );
};

export default ResponsiveContent;
