import validZipCodes1 from '../../assets/common/zipcodes_part_1.json';
import validZipCodes2 from '../../assets/common/zipcodes_part_2.json';
import validZipCodes3 from '../../assets/common/zipcodes_part_3.json';
import validZipCodes4 from '../../assets/common/zipcodes_part_4.json';
import validZipCodes5 from '../../assets/common/zipcodes_part_5.json';

/**
 * to be used for age comparison, this function will return a date object (Date.now() - # years)
 * which indicates a user's date of birth if they were to be born exactly `age` years ago
 * Ex. if a user must be over 18, then we expect userDob to be a date before getDobByAge(18)
 * set hours to 00:00:00.000 since dob determined only by date
 * @param age number of years before today's date
 * @returns date of birth of someone born exactly `age` years ago
 */
export const getDobByAge = (age: number): Date => {
  const yearBorn = new Date().getFullYear() - age;
  const dateInYearBorn = new Date().setFullYear(yearBorn);
  const startOfDateInYearBorn = new Date(dateInYearBorn).setHours(0, 0, 0, 0);
  return new Date(startOfDateInYearBorn);
};

type ParticipantAgeConstraints = {
  minAge?: number;
  maxAge?: number;
};

export const isDobWithinConstraints = (
  userDob: Date,
  ageConstraints: ParticipantAgeConstraints // TODO(PHP-6706): support min and max age requirements (set in profile)
): boolean => {
  const todayDate = new Date();
  // note that comparing Date objects compares Date.getTime() values
  // which represent # of miliseconds since January 1, 1970, UTC;
  // hence (olderDate < moreRecentDate)
  const userOldEnough: boolean = ageConstraints.minAge
    ? userDob <= getDobByAge(ageConstraints.minAge)
    : userDob <= todayDate; // default min is today's date
  const userYoungEnough: boolean = ageConstraints.maxAge
    ? userDob >= getDobByAge(ageConstraints.maxAge)
    : true; // no default max age
  return userOldEnough && userYoungEnough;
};

export const isZipCodeValid = (zipCode: string): boolean => {
  const allValidZipCodes = [
    ...validZipCodes1.zipCodes,
    ...validZipCodes2.zipCodes,
    ...validZipCodes3.zipCodes,
    ...validZipCodes4.zipCodes,
    ...validZipCodes5.zipCodes,
  ];
  return allValidZipCodes.includes(zipCode);
};
