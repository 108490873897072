import {
  Date as DatePb,
  Name,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';

export function parseDateStringToProto(dob: string): DatePb {
  const dateParts = String(dob)
    .split('-')
    .map(Number)
    .filter(n => n);
  if (dateParts.length !== 3) {
    throw new Error('invalid date');
  }
  const datePb: DatePb = {
    year: dateParts[0],
    month: dateParts[1],
    day: dateParts[2],
  };
  return datePb;
}
// Returns a string in the format YYYY-MM-dd
// TODO(@mestopinal) add format parameter for extensibility in the future
export function parseProtoToDateString(dob: DatePb): string {
  const YYYY = dob.year;
  const MM = String(dob.month).padStart(2, '0');
  const dd = String(dob.day).padStart(2, '0');
  return `${YYYY}-${MM}-${dd}`;
}

export function toNameProto(firstName: string, lastName: string): Name {
  const name: Name = {given: firstName, family: lastName};
  return name;
}
