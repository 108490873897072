import {RpcError} from '@protobuf-ts/runtime-rpc';
import {
  CheckAuthenticationRequest,
  CheckAuthenticationResponse,
  Step,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callCheckAuthentication} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {
  EnrollmentError,
  EnrollmentErrorType,
  isCiamUnauthenticatedError,
} from './error';
import {getRpcOptions} from './getRpcOptions';

export const checkAuthentication = async (
  domain: string,
  profile: string,
  currentStep: Step
): Promise<CheckAuthenticationResponse> => {
  try {
    const options = await getRpcOptions();
    const request: CheckAuthenticationRequest = {
      profile: toProfileProto(profile, domain),
      currentStep,
    };
    const res = await callCheckAuthentication(request, options);
    return res;
  } catch (e) {
    if (e instanceof RpcError) {
      if (isCiamUnauthenticatedError(e)) {
        throw new EnrollmentError(
          EnrollmentErrorType.UNAUTHENTICATED,
          e.message,
          e
        );
      }
      throw new EnrollmentError(EnrollmentErrorType.UNKNOWN, e.message, e);
    }
    throw e;
  }
};
