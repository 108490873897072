/* eslint-disable node/no-unpublished-import */
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {SingleSelectDropdown, TextField} from '@verily-src/react-design-system';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import type {FieldValues, Path} from 'react-hook-form';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Typography} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {
  GenderIdentity,
  UserType,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {userTypeSpecificI18Namespace} from '../../../i18n_helpers';
import {WithErrorText} from './utils';

type BasicInfoFormProps = {
  collectGenderIdentity?: boolean;
  userType: UserType;
  subheader: string;
};

/**
 * Subform layout for collecting user's basic info (name, dob, optionally gender)
 */
export default function BasicInfoFormLayout<T extends FieldValues>({
  collectGenderIdentity,
  userType,
  subheader,
}: BasicInfoFormProps) {
  const {t} = useTranslation();
  const currentLanguage = t('language');

  const {control, formState} = useFormContext<T>();

  const userTypeSpecificContentNs = userTypeSpecificI18Namespace(userType);
  return (
    <>
      <p>
        <Typography
          variant="body1em"
          component="h2"
          gutterBottom={
            !(
              subheader ||
              t('user-info-form.basic-info-subheader', {
                ns: userTypeSpecificContentNs,
              })
            )
          }
        >
          {t('user-info-form.basic-info-header', 'Basic Information', {
            ns: userTypeSpecificContentNs,
          })}
        </Typography>
      </p>
      {(subheader ||
        t('user-info-form.basic-info-subheader', '', {
          ns: userTypeSpecificContentNs,
        })) && (
        <Typography variant="caption" gutterBottom>
          {subheader ||
            t('user-info-form.basic-info-subheader', {
              ns: userTypeSpecificContentNs,
            })}
        </Typography>
      )}
      <Controller
        control={control}
        name={'firstName' as Path<T>}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          // TODO: replace usage with RDS errorText?
          <>
            <WithErrorText error={error}>
              <TextField
                required
                data-cy="first-name"
                {...field}
                label={t('patientData.first-name')}
                placeholder={t('patientData.first-name')}
                disabled={formState.isSubmitting}
                error={!!error}
                style={{width: '70%'}}
                multiline={false}
              />
            </WithErrorText>
          </>
        )}
      />
      <Controller
        control={control}
        name={'lastName' as Path<T>}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <>
            <WithErrorText error={error}>
              <TextField
                required
                data-cy="last-name"
                {...field}
                label={t('patientData.last-name')}
                aria-labelledby={t('patientData.last-name')}
                placeholder={t('patientData.last-name')}
                disabled={formState.isSubmitting}
                error={!!error}
                style={{width: '70%'}}
                multiline={false}
              />
            </WithErrorText>
          </>
        )}
      />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage}
      >
        <Controller
          control={control}
          name={'dob' as Path<T>}
          render={({field: {ref: _, ...field}, fieldState: {error}}) => (
            <>
              <WithErrorText error={error}>
                <DatePicker
                  {...field}
                  label={t('patientData.date-of-birth')}
                  disabled={formState.isSubmitting}
                  renderInput={params => (
                    <TextField
                      required
                      data-cy="date-picker"
                      aria-labelledby={t('patientData.date-of-birth')}
                      {...params}
                      style={{width: '70%'}}
                      error={!!error}
                      onBlur={() => field.onBlur()}
                      multiline={false}
                      helperText={t('user-info-form.date-of-birth-label', '', {
                        ns: userTypeSpecificContentNs,
                      })}
                    />
                  )}
                />
              </WithErrorText>
            </>
          )}
        />
      </LocalizationProvider>
      {collectGenderIdentity && (
        <Controller
          control={control}
          name={'genderIdentity' as Path<T>}
          render={({field: {ref: _, ...field}}) => {
            const genderIdentityOptions = [
              {
                value: GenderIdentity.MALE.toString(),
                label: t('gender.male'),
              },
              {
                value: GenderIdentity.FEMALE.toString(),
                label: t('gender.female'),
              },
              {
                value: GenderIdentity.NON_BINARY.toString(),
                label: t('gender.non-binary'),
              },
              {
                value: GenderIdentity.TRANSGENDER_MALE.toString(),
                label: t('gender.transgender-male'),
              },
              {
                value: GenderIdentity.TRANSGENDER_FEMALE.toString(),
                label: t('gender.transgender-female'),
              },
              {
                value: GenderIdentity.OTHER.toString(),
                label: t('gender.other'),
              },
              {
                value: GenderIdentity.NON_DISCLOSE.toString(),
                label: t('gender.do-not-wish-to-disclose'),
              },
            ];
            return (
              <>
                <FormControl data-cy="gender" required>
                  <SingleSelectDropdown
                    labelId="gender-identity-label"
                    id="genderIdentity"
                    data-testid="genderIdentity"
                    label={t('gender.gender-identity')}
                    disabled={formState.isSubmitting}
                    placeholder={t('gender.gender-identity')}
                    renderValue={value => {
                      if (!value) {
                        return (
                          <Typography sx={{color: 'text.muted'}}>
                            {t('gender.gender-identity')}
                          </Typography>
                        );
                      }
                      const selectedOption = genderIdentityOptions.find(
                        option => option.value === value.toString()
                      );
                      return selectedOption ? selectedOption.label : '';
                    }}
                    options={genderIdentityOptions}
                    required={true}
                    formControlSx={{width: '100%'}}
                    style={{
                      width: '70%',
                      borderRadius: '16px',
                      marginBottom: '16px', // TODO (PHP-7281) remove (https://verily.atlassian.net/browse/PHP-7281)
                    }}
                    {...field}
                  ></SingleSelectDropdown>
                </FormControl>
              </>
            );
          }}
        />
      )}
    </>
  );
}
