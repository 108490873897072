import {ReportErrorRequest} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callReportError} from '../../utils/clientCaller';

function reportError(error: Error) {
  const reportErrorRequest: ReportErrorRequest = {
    location: window.location.href,
    message: error.message,
    stack: error.stack || '',
  };
  callReportError(reportErrorRequest);
}

export default reportError;
