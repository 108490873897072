import {
  GetAgreementConsentsRequest,
  GetAgreementConsentsResponse,
  Step,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callGetAgreementConsents} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {getRpcOptions} from './getRpcOptions';

const getAgreementConsentsError =
  'There was an error retrieving agreement consents. Please try again later.';
// TODO(https://github.com/verily-src/verily1/pull/6792#discussion_r1362304289):
// it may be worth transforming the GetAgreementConsentsResponse here, rather
// than where this is called, and/or making a hook that is the sole consumer of
// this function.
export const getAgreementConsents = async (
  profileName: string | undefined,
  domainName: string,
  currentStep: Step
): Promise<GetAgreementConsentsResponse> => {
  try {
    const options = await getRpcOptions();
    const request: GetAgreementConsentsRequest = {
      profile: profileName
        ? toProfileProto(profileName as string, domainName)
        : undefined,
      currentStep: currentStep,
    };
    const response = await callGetAgreementConsents(request, options);
    return response;
  } catch (error) {
    throw new Error(getAgreementConsentsError);
  }
};
