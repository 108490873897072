// This script is provided directly by OneTrust for reloading the banner in SPAs.
// See https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046
export function reloadOTBanner() {
  var otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (window.OneTrust != null) {
    OneTrust.Init();

    setTimeout(function () {
      OneTrust.LoadBanner();

      var toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings'
      );

      for (var i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function (event) {
          event.stopImmediatePropagation();
          window.OneTrust.ToggleInfoDisplay();
        };
      }
    }, 1000);
  }
}
