import {Typography} from '@mui/material';
import {TextField} from '@verily-src/react-design-system';
import {UserType} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import type {FieldValues, Path} from 'react-hook-form';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {userTypeSpecificI18Namespace} from '../../../i18n_helpers';

type EligibilityInfoFormProps = {
  memberIdFieldLabel: string;
  userType: UserType;
};

/**
 * Subform layout for collecting user's eligibility key
 */
export default function EligibilityInfoFormLayout<T extends FieldValues>({
  memberIdFieldLabel,
  userType,
}: EligibilityInfoFormProps) {
  const {control, formState} = useFormContext();
  const {t} = useTranslation();

  const userTypeSpecificContentNs = userTypeSpecificI18Namespace(userType);

  // TODO(PHP-24668): eligibility info header can be in common resource once bff i18n serving is deprecated
  return (
    <>
      <p>
        <Typography variant="body1em" component="h2" gutterBottom>
          {t('user-info-form.eligibility-info-header', 'Insurance Coverage', {
            ns: userTypeSpecificContentNs,
          })}
        </Typography>
      </p>
      <Controller
        control={control}
        name={'insurance' as Path<T>}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <>
            <TextField
              type="text"
              data-cy="enrollment-verification-key"
              required
              {...field}
              label={memberIdFieldLabel}
              placeholder={memberIdFieldLabel}
              disabled={formState.isSubmitting}
              error={!!error}
              helperText={error?.message}
              style={{width: '70%'}}
            />
          </>
        )}
      />
    </>
  );
}
