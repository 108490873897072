import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {EnrollmentErrorType} from '../../lib/api/error';
import ResponsiveErrorPage, {
  ResponsiveErrorPageProps,
} from '../responsive/responsive-error-page/ResponsiveErrorPage';
import {toCommonAssetsPath} from '../utils';

export type ErrorElementLayoutProps = {
  errorType: EnrollmentErrorType;
};

export function ErrorElementLayout(props: ErrorElementLayoutProps) {
  const {t} = useTranslation();

  // Important: the build assets paths being generated here must be called from within the component
  // and should not be extracted into a separate function; otherwise webpack does
  // not recognize these as build assets to set the appropriate paths
  const errorToProps = useMemo(
    () =>
      new Map<EnrollmentErrorType, ResponsiveErrorPageProps>([
        [
          EnrollmentErrorType.UNKNOWN,
          {
            header: 'error.generic-error-page-header',
            message: 'error.generic-error-page-message',
            imageUri: toCommonAssetsPath('illy_error.png'),
          },
        ],
        [
          EnrollmentErrorType.PROFILE_NOT_FOUND,
          {
            header: 'error.profile-not-found-header',
            message: 'error.profile-not-found-message',
            imageUri: toCommonAssetsPath('illy_error.png'),
          },
        ],
        [
          EnrollmentErrorType.INTERNAL_SERVER_ERROR,
          {
            header: 'error.internal-error-page-header',
            message: 'error.internal-error-page-message',
            imageUri: toCommonAssetsPath('illy_error.png'),
          },
        ],
        [
          EnrollmentErrorType.PAGE_NOT_FOUND,
          {
            header: 'error.page-not-found-header',
            message: 'error.page-not-found-message',
            imageUri: toCommonAssetsPath('illy_error.png'),
          },
        ],
        [
          EnrollmentErrorType.PASSWORD_RESET_REDIRECT_ERROR,
          {
            header: 'error.password-reset-error-header',
            message: 'error.password-reset-error-message',
            imageUri: toCommonAssetsPath('illy_error.png'),
          },
        ],
      ]),
    []
  );

  // Use default error if specific error is not defined in map
  const enrollmentError = errorToProps.has(props.errorType)
    ? props.errorType
    : EnrollmentErrorType.UNKNOWN;

  return (
    <ResponsiveErrorPage
      header={t(errorToProps.get(enrollmentError).header)}
      message={t(errorToProps.get(enrollmentError).message)}
      imageUri={errorToProps.get(enrollmentError).imageUri}
    />
  );
}
