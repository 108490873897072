import {Box, Typography, useTheme} from '@mui/material';
import {memo} from 'react';

import {useTranslation} from 'react-i18next';
import {AppImageConfig} from '../../lib/types/appConfig';
import ResponsiveContainer from '../responsive/responsive-container/ResponsiveContainer';
import ResponsiveContent from '../responsive/responsive-content/ResponsiveContent';
import ResponsiveSplash from '../responsive/responsive-splash/ResponsiveSplash';
import {toCommonAssetsPath} from '../utils';
import {TerminationReason} from './states';

// Elements that change based on termination reasons
type TerminationTypeSettings = {
  headerMsg: string;
  bodyMsg: string;
  centerImage: string;
};

export type TerminationPageLayoutProps = {
  reason: TerminationReason;
  logo1?: AppImageConfig;
  logo2?: AppImageConfig;
  disclaimerFooter?: string;
  productName?: string;
};

export const TerminationPageLayout = memo(
  ({
    reason,
    logo1,
    logo2,
    disclaimerFooter,
    productName,
  }: TerminationPageLayoutProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    // Important: the build assets paths being generated here must be called from
    // within the component and should not be extracted into a separate
    // function; otherwise webpack does not recognize these as build assets to set
    // the appropriate paths
    const eventTypeToProps = new Map<
      TerminationReason,
      TerminationTypeSettings
    >([
      [
        TerminationReason.INELIGIBLE,
        {
          headerMsg: t('ineligible.were-sorry'),
          bodyMsg: t('ineligible.youre-not-eligible', {
            productName: productName ?? t('ineligible.this-product'),
          }),
          centerImage: toCommonAssetsPath('illy_ineligible.png'),
        },
      ],
      [
        TerminationReason.SCREENED_OUT,
        {
          headerMsg: t('screenedout.were-sorry'),
          bodyMsg: t('screenedout.body'),
          centerImage: toCommonAssetsPath('illy_ineligible.png'),
        },
      ],
      [
        TerminationReason.ELIGIBILITY_USER_ALREADY_ENROLLED,
        {
          headerMsg: t('eligibility-already-enrolled.header'),
          bodyMsg: t('eligibility-already-enrolled.body'),
          centerImage: toCommonAssetsPath('illy_ineligible.png'),
        },
      ],
    ]);

    const splash = (
      <ResponsiveSplash
        logo1={logo1}
        logo2={logo2}
        centerImage={eventTypeToProps.get(reason).centerImage}
      />
    );

    const content = (
      <ResponsiveContent disclaimerFooter={disclaimerFooter}>
        <Box sx={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}>
          <Typography variant="display5" component="h1">
            {eventTypeToProps.get(reason).headerMsg}
          </Typography>
        </Box>
        <Typography variant="body1">
          <strong>{eventTypeToProps.get(reason).bodyMsg}</strong>
        </Typography>
      </ResponsiveContent>
    );

    return <ResponsiveContainer splash={splash} content={content} />;
  }
);
