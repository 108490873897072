import {Box, CardMedia} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import {Checkbox, Link} from '@verily-src/react-design-system';
import {
  AgreementConsentContent,
  AgreementConsentContent_Links_Link as ConsentLink,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {Fragment, ReactNode} from 'react';
import type {Control, FieldValues} from 'react-hook-form';
import {Controller, Path} from 'react-hook-form';
import * as yup from 'yup';
import i18n from '../../i18n';
import StyledInjectedHtml from '../responsive/styled-injected-html/StyledInjectedHtml';
import {toCommonAssetsPath} from '../utils';

const HtmlElement = ({html}: {html: string}) => (
  <StyledInjectedHtml content={html}></StyledInjectedHtml>
);

const LinksElement = ({links}: {links: ConsentLink[]}) => (
  <Box sx={{flexWrap: 'wrap', display: 'flex', marginRight: 1}}>
    {links.map((link, index) => (
      // target="_blank" tells the link to open in a new tab or window, rather
      // than navigating the user away from Enrollment in the current one.
      <Link
        data-cy="consent-link"
        href={link.url}
        key={`link.${index}`}
        target={'_blank'}
        marginRight="5px"
        marginBottom="10px"
        marginTop="10px"
        variant="button2"
      >
        {link.text}
        <CardMedia
          component="img"
          src={toCommonAssetsPath('open_link.png')}
          alt="open link symbol"
          sx={{
            maxWidth: '18px',
          }}
        />
      </Link>
    ))}
  </Box>
);

function SigningReasonElement<T extends FieldValues>(
  reason: string,
  control: Control<T>,
  isDisabled: boolean,
  index: number
): ReactNode {
  const key = `checkboxReasons.${index}`;
  return (
    <Controller
      key={key}
      name={key as Path<T>}
      control={control}
      render={({field: {ref: _, ...field}}) => (
        <Checkbox
          data-cy="policy-checkbox"
          data-testid="policy-checkbox"
          {...field}
          checked={field.value || false}
          disabled={isDisabled}
          label={<Fragment>{reason}</Fragment>}
          sx={{
            paddingInline: 2,
            paddingBlock: 1,
            margin: 0,
            borderRadius: 2,
          }}
        />
      )}
    />
  );
}

const PolicyElement = <T extends FieldValues>({
  control,
  consents,
  disabled = false,
}: {
  control: Control<T>;
  consents: AgreementConsentContent[];
  disabled?: boolean;
}) => {
  // Because MUI FormGroup prefers each element to have a unique key, we need to
  // track the number of elements we create. However, since a CheckboxModule may
  // result in many checkbox elements, we can't just use the index of the
  // module. Additionally, to simplify the schema, we want to track control
  // elements (i.e. checkboxes) and non-control elements (i.e. HTML and links)
  // separately.
  let checkboxCount = 0;
  let nonControlElemCount = 0;
  const elem = (
    <FormGroup sx={{gap: '1rem'}}>
      {consents
        .map(consent =>
          consent.modules
            .map(module => {
              if (module.module.oneofKind === 'checkbox') {
                return module.module['checkbox'].signingReasons.map(reason =>
                  SigningReasonElement(
                    reason,
                    control,
                    disabled,
                    checkboxCount++
                  )
                );
              } else if (module.module.oneofKind === 'html') {
                const htmlModule = module.module['html'];
                return (
                  <HtmlElement
                    html={htmlModule.html}
                    key={`nonControlElem.${nonControlElemCount++}`}
                  />
                );
              } else if (module.module.oneofKind === 'links') {
                const linksModule = module.module['links'];
                return (
                  <LinksElement
                    links={linksModule.links}
                    key={`nonControlElem.${nonControlElemCount++}`}
                  />
                );
              }
            })
            .flat()
        )
        .flat()}
    </FormGroup>
  );
  return elem;
};

// To simplify this schema, we flatten all the checkbox reasons into a single
// array, rather than nesting them and requiring a multidimensional dynamic
// length schema. `checkboxReasons.3`, even if that "3" comes from an offset,
// is easier to build a schema for than `consent.1-module.0-checkboxReason.2`.
function getSchema() {
  return yup.object({
    checkboxReasons: yup
      .array()
      .of(
        yup
          .boolean()
          .oneOf([true], i18n.t('navigation.please-agree-to-the-terms'))
          .required(i18n.t('navigation.please-agree-to-the-terms'))
      ),
  });
}

export {PolicyElement, getSchema};
