/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "proto/google/fhir/proto/r4/core/codes.proto" (package "google.fhir.r4.core", syntax proto3)
// tslint:disable
// @ts-nocheck
//
//    Copyright 2019 Google Inc.
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        https://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * A list of the base types defined by this version of the FHIR specification -
 * types that are defined, but for which only specializations actually are
 * created. See http://hl7.org/fhir/abstract-types
 *
 * @generated from protobuf message google.fhir.r4.core.AbstractTypeCode
 */
export interface AbstractTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AbstractTypeCode.Value
 */
export enum AbstractTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: TYPE = 1;
     */
    TYPE = 1,
    /**
     * @generated from protobuf enum value: ANY = 2;
     */
    ANY = 2
}
/**
 * Indicates whether the account is available to be used.
 * See http://hl7.org/fhir/account-status
 *
 * @generated from protobuf message google.fhir.r4.core.AccountStatusCode
 */
export interface AccountStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AccountStatusCode.Value
 */
export enum AccountStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: INACTIVE = 2;
     */
    INACTIVE = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3,
    /**
     * @generated from protobuf enum value: ON_HOLD = 4;
     */
    ON_HOLD = 4,
    /**
     * @generated from protobuf enum value: UNKNOWN = 5;
     */
    UNKNOWN = 5
}
/**
 * Defines behavior for an action or a group for how many times that item may be
 * repeated. See http://hl7.org/fhir/action-cardinality-behavior
 *
 * @generated from protobuf message google.fhir.r4.core.ActionCardinalityBehaviorCode
 */
export interface ActionCardinalityBehaviorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ActionCardinalityBehaviorCode.Value
 */
export enum ActionCardinalityBehaviorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SINGLE = 1;
     */
    SINGLE = 1,
    /**
     * @generated from protobuf enum value: MULTIPLE = 2;
     */
    MULTIPLE = 2
}
/**
 * Defines the kinds of conditions that can appear on actions.
 * See http://hl7.org/fhir/action-condition-kind
 *
 * @generated from protobuf message google.fhir.r4.core.ActionConditionKindCode
 */
export interface ActionConditionKindCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ActionConditionKindCode.Value
 */
export enum ActionConditionKindCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: APPLICABILITY = 1;
     */
    APPLICABILITY = 1,
    /**
     * @generated from protobuf enum value: START = 2;
     */
    START = 2,
    /**
     * @generated from protobuf enum value: STOP = 3;
     */
    STOP = 3
}
/**
 * Defines organization behavior of a group.
 * See http://hl7.org/fhir/action-grouping-behavior
 *
 * @generated from protobuf message google.fhir.r4.core.ActionGroupingBehaviorCode
 */
export interface ActionGroupingBehaviorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ActionGroupingBehaviorCode.Value
 */
export enum ActionGroupingBehaviorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: VISUAL_GROUP = 1;
     */
    VISUAL_GROUP = 1,
    /**
     * @generated from protobuf enum value: LOGICAL_GROUP = 2;
     */
    LOGICAL_GROUP = 2,
    /**
     * @generated from protobuf enum value: SENTENCE_GROUP = 3;
     */
    SENTENCE_GROUP = 3
}
/**
 * The type of participant for the action.
 * See http://hl7.org/fhir/action-participant-type
 *
 * @generated from protobuf message google.fhir.r4.core.ActionParticipantTypeCode
 */
export interface ActionParticipantTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ActionParticipantTypeCode.Value
 */
export enum ActionParticipantTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PATIENT = 1;
     */
    PATIENT = 1,
    /**
     * @generated from protobuf enum value: PRACTITIONER = 2;
     */
    PRACTITIONER = 2,
    /**
     * @generated from protobuf enum value: RELATED_PERSON = 3;
     */
    RELATED_PERSON = 3,
    /**
     * @generated from protobuf enum value: DEVICE = 4;
     */
    DEVICE = 4
}
/**
 * Defines selection frequency behavior for an action or group.
 * See http://hl7.org/fhir/action-precheck-behavior
 *
 * @generated from protobuf message google.fhir.r4.core.ActionPrecheckBehaviorCode
 */
export interface ActionPrecheckBehaviorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ActionPrecheckBehaviorCode.Value
 */
export enum ActionPrecheckBehaviorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: YES = 1;
     */
    YES = 1,
    /**
     * @generated from protobuf enum value: NO = 2;
     */
    NO = 2
}
/**
 * Defines the types of relationships between actions.
 * See http://hl7.org/fhir/action-relationship-type
 *
 * @generated from protobuf message google.fhir.r4.core.ActionRelationshipTypeCode
 */
export interface ActionRelationshipTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ActionRelationshipTypeCode.Value
 */
export enum ActionRelationshipTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: BEFORE_START = 1;
     */
    BEFORE_START = 1,
    /**
     * @generated from protobuf enum value: BEFORE = 2;
     */
    BEFORE = 2,
    /**
     * @generated from protobuf enum value: BEFORE_END = 3;
     */
    BEFORE_END = 3,
    /**
     * @generated from protobuf enum value: CONCURRENT_WITH_START = 4;
     */
    CONCURRENT_WITH_START = 4,
    /**
     * @generated from protobuf enum value: CONCURRENT = 5;
     */
    CONCURRENT = 5,
    /**
     * @generated from protobuf enum value: CONCURRENT_WITH_END = 6;
     */
    CONCURRENT_WITH_END = 6,
    /**
     * @generated from protobuf enum value: AFTER_START = 7;
     */
    AFTER_START = 7,
    /**
     * @generated from protobuf enum value: AFTER = 8;
     */
    AFTER = 8,
    /**
     * @generated from protobuf enum value: AFTER_END = 9;
     */
    AFTER_END = 9
}
/**
 * Defines expectations around whether an action or action group is required.
 * See http://hl7.org/fhir/action-required-behavior
 *
 * @generated from protobuf message google.fhir.r4.core.ActionRequiredBehaviorCode
 */
export interface ActionRequiredBehaviorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ActionRequiredBehaviorCode.Value
 */
export enum ActionRequiredBehaviorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MUST = 1;
     */
    MUST = 1,
    /**
     * @generated from protobuf enum value: COULD = 2;
     */
    COULD = 2,
    /**
     * @generated from protobuf enum value: MUST_UNLESS_DOCUMENTED = 3;
     */
    MUST_UNLESS_DOCUMENTED = 3
}
/**
 * Defines selection behavior of a group.
 * See http://hl7.org/fhir/action-selection-behavior
 *
 * @generated from protobuf message google.fhir.r4.core.ActionSelectionBehaviorCode
 */
export interface ActionSelectionBehaviorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ActionSelectionBehaviorCode.Value
 */
export enum ActionSelectionBehaviorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ANY = 1;
     */
    ANY = 1,
    /**
     * @generated from protobuf enum value: ALL = 2;
     */
    ALL = 2,
    /**
     * @generated from protobuf enum value: ALL_OR_NONE = 3;
     */
    ALL_OR_NONE = 3,
    /**
     * @generated from protobuf enum value: EXACTLY_ONE = 4;
     */
    EXACTLY_ONE = 4,
    /**
     * @generated from protobuf enum value: AT_MOST_ONE = 5;
     */
    AT_MOST_ONE = 5,
    /**
     * @generated from protobuf enum value: ONE_OR_MORE = 6;
     */
    ONE_OR_MORE = 6
}
/**
 * The type of an address (physical / postal).
 * See http://hl7.org/fhir/address-type
 *
 * @generated from protobuf message google.fhir.r4.core.AddressTypeCode
 */
export interface AddressTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AddressTypeCode.Value
 */
export enum AddressTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: POSTAL = 1;
     */
    POSTAL = 1,
    /**
     * @generated from protobuf enum value: PHYSICAL = 2;
     */
    PHYSICAL = 2,
    /**
     * @generated from protobuf enum value: BOTH = 3;
     */
    BOTH = 3
}
/**
 * The use of an address.
 * See http://hl7.org/fhir/address-use
 *
 * @generated from protobuf message google.fhir.r4.core.AddressUseCode
 */
export interface AddressUseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AddressUseCode.Value
 */
export enum AddressUseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: HOME = 1;
     */
    HOME = 1,
    /**
     * @generated from protobuf enum value: WORK = 2;
     */
    WORK = 2,
    /**
     * @generated from protobuf enum value: TEMP = 3;
     */
    TEMP = 3,
    /**
     * @generated from protobuf enum value: OLD = 4;
     */
    OLD = 4,
    /**
     * @generated from protobuf enum value: BILLING = 5;
     */
    BILLING = 5
}
/**
 * The gender of a person used for administrative purposes.
 * See http://hl7.org/fhir/administrative-gender
 *
 * @generated from protobuf message google.fhir.r4.core.AdministrativeGenderCode
 */
export interface AdministrativeGenderCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AdministrativeGenderCode.Value
 */
export enum AdministrativeGenderCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MALE = 1;
     */
    MALE = 1,
    /**
     * @generated from protobuf enum value: FEMALE = 2;
     */
    FEMALE = 2,
    /**
     * @generated from protobuf enum value: OTHER = 3;
     */
    OTHER = 3,
    /**
     * @generated from protobuf enum value: UNKNOWN = 4;
     */
    UNKNOWN = 4
}
/**
 * Overall nature of the adverse event, e.g. real or potential.
 * See http://hl7.org/fhir/adverse-event-actuality
 *
 * @generated from protobuf message google.fhir.r4.core.AdverseEventActualityCode
 */
export interface AdverseEventActualityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AdverseEventActualityCode.Value
 */
export enum AdverseEventActualityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTUAL = 1;
     */
    ACTUAL = 1,
    /**
     * @generated from protobuf enum value: POTENTIAL = 2;
     */
    POTENTIAL = 2
}
/**
 * TODO (and should this be required?).
 * See http://terminology.hl7.org/CodeSystem/adverse-event-outcome
 *
 * @generated from protobuf message google.fhir.r4.core.AdverseEventOutcomeCode
 */
export interface AdverseEventOutcomeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AdverseEventOutcomeCode.Value
 */
export enum AdverseEventOutcomeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: RESOLVED = 1;
     */
    RESOLVED = 1,
    /**
     * @generated from protobuf enum value: RECOVERING = 2;
     */
    RECOVERING = 2,
    /**
     * @generated from protobuf enum value: ONGOING = 3;
     */
    ONGOING = 3,
    /**
     * @generated from protobuf enum value: RESOLVED_WITH_SEQUELAE = 4;
     */
    RESOLVED_WITH_SEQUELAE = 4,
    /**
     * @generated from protobuf enum value: FATAL = 5;
     */
    FATAL = 5,
    /**
     * @generated from protobuf enum value: UNKNOWN = 6;
     */
    UNKNOWN = 6
}
/**
 * The severity of the adverse event itself, in direct relation to the subject.
 * See http://terminology.hl7.org/CodeSystem/adverse-event-severity
 *
 * @generated from protobuf message google.fhir.r4.core.AdverseEventSeverityCode
 */
export interface AdverseEventSeverityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AdverseEventSeverityCode.Value
 */
export enum AdverseEventSeverityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MILD = 1;
     */
    MILD = 1,
    /**
     * @generated from protobuf enum value: MODERATE = 2;
     */
    MODERATE = 2,
    /**
     * @generated from protobuf enum value: SEVERE = 3;
     */
    SEVERE = 3
}
/**
 * How resource references can be aggregated.
 * See http://hl7.org/fhir/resource-aggregation-mode
 *
 * @generated from protobuf message google.fhir.r4.core.AggregationModeCode
 */
export interface AggregationModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AggregationModeCode.Value
 */
export enum AggregationModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CONTAINED = 1;
     */
    CONTAINED = 1,
    /**
     * @generated from protobuf enum value: REFERENCED = 2;
     */
    REFERENCED = 2,
    /**
     * @generated from protobuf enum value: BUNDLED = 3;
     */
    BUNDLED = 3
}
/**
 * Category of an identified substance associated with allergies or
 * intolerances. See http://hl7.org/fhir/allergy-intolerance-category
 *
 * @generated from protobuf message google.fhir.r4.core.AllergyIntoleranceCategoryCode
 */
export interface AllergyIntoleranceCategoryCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AllergyIntoleranceCategoryCode.Value
 */
export enum AllergyIntoleranceCategoryCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: FOOD = 1;
     */
    FOOD = 1,
    /**
     * @generated from protobuf enum value: MEDICATION = 2;
     */
    MEDICATION = 2,
    /**
     * @generated from protobuf enum value: ENVIRONMENT = 3;
     */
    ENVIRONMENT = 3,
    /**
     * @generated from protobuf enum value: BIOLOGIC = 4;
     */
    BIOLOGIC = 4
}
/**
 * Preferred value set for AllergyIntolerance Clinical Status.
 * See http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical
 *
 * @generated from protobuf message google.fhir.r4.core.AllergyIntoleranceClinicalStatusCode
 */
export interface AllergyIntoleranceClinicalStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AllergyIntoleranceClinicalStatusCode.Value
 */
export enum AllergyIntoleranceClinicalStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: INACTIVE = 2;
     */
    INACTIVE = 2,
    /**
     * @generated from protobuf enum value: RESOLVED = 3;
     */
    RESOLVED = 3
}
/**
 * Estimate of the potential clinical harm, or seriousness, of a reaction to an
 * identified substance. See http://hl7.org/fhir/allergy-intolerance-criticality
 *
 * @generated from protobuf message google.fhir.r4.core.AllergyIntoleranceCriticalityCode
 */
export interface AllergyIntoleranceCriticalityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AllergyIntoleranceCriticalityCode.Value
 */
export enum AllergyIntoleranceCriticalityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: LOW = 1;
     */
    LOW = 1,
    /**
     * @generated from protobuf enum value: HIGH = 2;
     */
    HIGH = 2,
    /**
     * @generated from protobuf enum value: UNABLE_TO_ASSESS = 3;
     */
    UNABLE_TO_ASSESS = 3
}
/**
 * Clinical assessment of the severity of a reaction event as a whole,
 * potentially considering multiple different manifestations. See
 * http://hl7.org/fhir/reaction-event-severity
 *
 * @generated from protobuf message google.fhir.r4.core.AllergyIntoleranceSeverityCode
 */
export interface AllergyIntoleranceSeverityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AllergyIntoleranceSeverityCode.Value
 */
export enum AllergyIntoleranceSeverityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MILD = 1;
     */
    MILD = 1,
    /**
     * @generated from protobuf enum value: MODERATE = 2;
     */
    MODERATE = 2,
    /**
     * @generated from protobuf enum value: SEVERE = 3;
     */
    SEVERE = 3
}
/**
 * The risk of an adverse reaction (allergy or intolerance) for this patient
 * upon exposure to the substance (including pharmaceutical products). See
 * http://terminology.hl7.org/CodeSystem/allerg-intol-substance-exp-risk
 *
 * @generated from protobuf message google.fhir.r4.core.AllergyIntoleranceSubstanceExposureRiskCode
 */
export interface AllergyIntoleranceSubstanceExposureRiskCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AllergyIntoleranceSubstanceExposureRiskCode.Value
 */
export enum AllergyIntoleranceSubstanceExposureRiskCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: KNOWN_REACTION_RISK = 1;
     */
    KNOWN_REACTION_RISK = 1,
    /**
     * @generated from protobuf enum value: NO_KNOWN_REACTION_RISK = 2;
     */
    NO_KNOWN_REACTION_RISK = 2
}
/**
 * Identification of the underlying physiological mechanism for a Reaction Risk.
 * See http://hl7.org/fhir/allergy-intolerance-type
 *
 * @generated from protobuf message google.fhir.r4.core.AllergyIntoleranceTypeCode
 */
export interface AllergyIntoleranceTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AllergyIntoleranceTypeCode.Value
 */
export enum AllergyIntoleranceTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ALLERGY = 1;
     */
    ALLERGY = 1,
    /**
     * @generated from protobuf enum value: INTOLERANCE = 2;
     */
    INTOLERANCE = 2
}
/**
 * Preferred value set for AllergyIntolerance Verification Status.
 * See http://terminology.hl7.org/CodeSystem/allergyintolerance-verification
 *
 * @generated from protobuf message google.fhir.r4.core.AllergyIntoleranceVerificationStatusCode
 */
export interface AllergyIntoleranceVerificationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AllergyIntoleranceVerificationStatusCode.Value
 */
export enum AllergyIntoleranceVerificationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: UNCONFIRMED = 1;
     */
    UNCONFIRMED = 1,
    /**
     * @generated from protobuf enum value: CONFIRMED = 2;
     */
    CONFIRMED = 2,
    /**
     * @generated from protobuf enum value: REFUTED = 3;
     */
    REFUTED = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4
}
/**
 * The free/busy status of an appointment.
 * See http://hl7.org/fhir/appointmentstatus
 *
 * @generated from protobuf message google.fhir.r4.core.AppointmentStatusCode
 */
export interface AppointmentStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AppointmentStatusCode.Value
 */
export enum AppointmentStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PROPOSED = 1;
     */
    PROPOSED = 1,
    /**
     * @generated from protobuf enum value: PENDING = 2;
     */
    PENDING = 2,
    /**
     * @generated from protobuf enum value: BOOKED = 3;
     */
    BOOKED = 3,
    /**
     * @generated from protobuf enum value: ARRIVED = 4;
     */
    ARRIVED = 4,
    /**
     * @generated from protobuf enum value: FULFILLED = 5;
     */
    FULFILLED = 5,
    /**
     * @generated from protobuf enum value: CANCELLED = 6;
     */
    CANCELLED = 6,
    /**
     * @generated from protobuf enum value: NOSHOW = 7;
     */
    NOSHOW = 7,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 8;
     */
    ENTERED_IN_ERROR = 8,
    /**
     * @generated from protobuf enum value: CHECKED_IN = 9;
     */
    CHECKED_IN = 9,
    /**
     * @generated from protobuf enum value: WAITLIST = 10;
     */
    WAITLIST = 10
}
/**
 * The type of direction to use for assertion.
 * See http://hl7.org/fhir/assert-direction-codes
 *
 * @generated from protobuf message google.fhir.r4.core.AssertionDirectionTypeCode
 */
export interface AssertionDirectionTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AssertionDirectionTypeCode.Value
 */
export enum AssertionDirectionTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: RESPONSE = 1;
     */
    RESPONSE = 1,
    /**
     * @generated from protobuf enum value: REQUEST = 2;
     */
    REQUEST = 2
}
/**
 * The type of operator to use for assertion.
 * See http://hl7.org/fhir/assert-operator-codes
 *
 * @generated from protobuf message google.fhir.r4.core.AssertionOperatorTypeCode
 */
export interface AssertionOperatorTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AssertionOperatorTypeCode.Value
 */
export enum AssertionOperatorTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: EQUALS = 1;
     */
    EQUALS = 1,
    /**
     * @generated from protobuf enum value: NOT_EQUALS = 2;
     */
    NOT_EQUALS = 2,
    /**
     * @generated from protobuf enum value: IN = 3;
     */
    IN = 3,
    /**
     * @generated from protobuf enum value: NOT_IN = 4;
     */
    NOT_IN = 4,
    /**
     * @generated from protobuf enum value: GREATER_THAN = 5;
     */
    GREATER_THAN = 5,
    /**
     * @generated from protobuf enum value: LESS_THAN = 6;
     */
    LESS_THAN = 6,
    /**
     * @generated from protobuf enum value: EMPTY = 7;
     */
    EMPTY = 7,
    /**
     * @generated from protobuf enum value: NOT_EMPTY = 8;
     */
    NOT_EMPTY = 8,
    /**
     * @generated from protobuf enum value: CONTAINS = 9;
     */
    CONTAINS = 9,
    /**
     * @generated from protobuf enum value: NOT_CONTAINS = 10;
     */
    NOT_CONTAINS = 10,
    /**
     * @generated from protobuf enum value: EVAL = 11;
     */
    EVAL = 11
}
/**
 * The type of response code to use for assertion.
 * See http://hl7.org/fhir/assert-response-code-types
 *
 * @generated from protobuf message google.fhir.r4.core.AssertionResponseTypesCode
 */
export interface AssertionResponseTypesCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AssertionResponseTypesCode.Value
 */
export enum AssertionResponseTypesCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: OKAY = 1;
     */
    OKAY = 1,
    /**
     * @generated from protobuf enum value: CREATED = 2;
     */
    CREATED = 2,
    /**
     * @generated from protobuf enum value: NO_CONTENT = 3;
     */
    NO_CONTENT = 3,
    /**
     * @generated from protobuf enum value: NOT_MODIFIED = 4;
     */
    NOT_MODIFIED = 4,
    /**
     * @generated from protobuf enum value: BAD = 5;
     */
    BAD = 5,
    /**
     * @generated from protobuf enum value: FORBIDDEN = 6;
     */
    FORBIDDEN = 6,
    /**
     * @generated from protobuf enum value: NOT_FOUND = 7;
     */
    NOT_FOUND = 7,
    /**
     * @generated from protobuf enum value: METHOD_NOT_ALLOWED = 8;
     */
    METHOD_NOT_ALLOWED = 8,
    /**
     * @generated from protobuf enum value: CONFLICT = 9;
     */
    CONFLICT = 9,
    /**
     * @generated from protobuf enum value: GONE = 10;
     */
    GONE = 10,
    /**
     * @generated from protobuf enum value: PRECONDITION_FAILED = 11;
     */
    PRECONDITION_FAILED = 11,
    /**
     * @generated from protobuf enum value: UNPROCESSABLE = 12;
     */
    UNPROCESSABLE = 12
}
/**
 * Indicator for type of action performed during the event that generated the
 * event. See http://hl7.org/fhir/audit-event-action
 *
 * @generated from protobuf message google.fhir.r4.core.AuditEventActionCode
 */
export interface AuditEventActionCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AuditEventActionCode.Value
 */
export enum AuditEventActionCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: C = 1;
     */
    C = 1,
    /**
     * @generated from protobuf enum value: R = 2;
     */
    R = 2,
    /**
     * @generated from protobuf enum value: U = 3;
     */
    U = 3,
    /**
     * @generated from protobuf enum value: D = 4;
     */
    D = 4,
    /**
     * @generated from protobuf enum value: E = 5;
     */
    E = 5
}
/**
 * The type of network access point of this agent in the audit event.
 * See http://hl7.org/fhir/network-type
 *
 * @generated from protobuf message google.fhir.r4.core.AuditEventAgentNetworkTypeCode
 */
export interface AuditEventAgentNetworkTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AuditEventAgentNetworkTypeCode.Value
 */
export enum AuditEventAgentNetworkTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MACHINE_NAME = 1;
     */
    MACHINE_NAME = 1,
    /**
     * @generated from protobuf enum value: IP_ADDRESS = 2;
     */
    IP_ADDRESS = 2,
    /**
     * @generated from protobuf enum value: TELEPHONE_NUMBER = 3;
     */
    TELEPHONE_NUMBER = 3,
    /**
     * @generated from protobuf enum value: EMAIL_ADDRESS = 4;
     */
    EMAIL_ADDRESS = 4,
    /**
     * @generated from protobuf enum value: URI = 5;
     */
    URI = 5
}
/**
 * Indicates whether the event succeeded or failed.
 * See http://hl7.org/fhir/audit-event-outcome
 *
 * @generated from protobuf message google.fhir.r4.core.AuditEventOutcomeCode
 */
export interface AuditEventOutcomeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.AuditEventOutcomeCode.Value
 */
export enum AuditEventOutcomeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SUCCESS = 1;
     */
    SUCCESS = 1,
    /**
     * @generated from protobuf enum value: MINOR_FAILURE = 2;
     */
    MINOR_FAILURE = 2,
    /**
     * @generated from protobuf enum value: SERIOUS_FAILURE = 3;
     */
    SERIOUS_FAILURE = 3,
    /**
     * @generated from protobuf enum value: MAJOR_FAILURE = 4;
     */
    MAJOR_FAILURE = 4
}
/**
 * Whether the cost applies to in-network or out-of-network providers.
 * See http://terminology.hl7.org/CodeSystem/applicability
 *
 * @generated from protobuf message google.fhir.r4.core.BenefitCostApplicabilityCode
 */
export interface BenefitCostApplicabilityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BenefitCostApplicabilityCode.Value
 */
export enum BenefitCostApplicabilityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: IN_NETWORK = 1;
     */
    IN_NETWORK = 1,
    /**
     * @generated from protobuf enum value: OUT_OF_NETWORK = 2;
     */
    OUT_OF_NETWORK = 2,
    /**
     * @generated from protobuf enum value: OTHER = 3;
     */
    OTHER = 3
}
/**
 * Indication of the degree of conformance expectations associated with a
 * binding. See http://hl7.org/fhir/binding-strength
 *
 * @generated from protobuf message google.fhir.r4.core.BindingStrengthCode
 */
export interface BindingStrengthCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BindingStrengthCode.Value
 */
export enum BindingStrengthCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REQUIRED = 1;
     */
    REQUIRED = 1,
    /**
     * @generated from protobuf enum value: EXTENSIBLE = 2;
     */
    EXTENSIBLE = 2,
    /**
     * @generated from protobuf enum value: PREFERRED = 3;
     */
    PREFERRED = 3,
    /**
     * @generated from protobuf enum value: EXAMPLE = 4;
     */
    EXAMPLE = 4
}
/**
 * Biologically Derived Product Category.
 * See http://hl7.org/fhir/product-category
 *
 * @generated from protobuf message google.fhir.r4.core.BiologicallyDerivedProductCategoryCode
 */
export interface BiologicallyDerivedProductCategoryCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BiologicallyDerivedProductCategoryCode.Value
 */
export enum BiologicallyDerivedProductCategoryCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ORGAN = 1;
     */
    ORGAN = 1,
    /**
     * @generated from protobuf enum value: TISSUE = 2;
     */
    TISSUE = 2,
    /**
     * @generated from protobuf enum value: FLUID = 3;
     */
    FLUID = 3,
    /**
     * @generated from protobuf enum value: CELLS = 4;
     */
    CELLS = 4,
    /**
     * @generated from protobuf enum value: BIOLOGICAL_AGENT = 5;
     */
    BIOLOGICAL_AGENT = 5
}
/**
 * Biologically Derived Product Status.
 * See http://hl7.org/fhir/product-status
 *
 * @generated from protobuf message google.fhir.r4.core.BiologicallyDerivedProductStatusCode
 */
export interface BiologicallyDerivedProductStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BiologicallyDerivedProductStatusCode.Value
 */
export enum BiologicallyDerivedProductStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AVAILABLE = 1;
     */
    AVAILABLE = 1,
    /**
     * @generated from protobuf enum value: UNAVAILABLE = 2;
     */
    UNAVAILABLE = 2
}
/**
 * BiologicallyDerived Product Storage Scale.
 * See http://hl7.org/fhir/product-storage-scale
 *
 * @generated from protobuf message google.fhir.r4.core.BiologicallyDerivedProductStorageScaleCode
 */
export interface BiologicallyDerivedProductStorageScaleCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BiologicallyDerivedProductStorageScaleCode.Value
 */
export enum BiologicallyDerivedProductStorageScaleCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: FARENHEIT = 1;
     */
    FARENHEIT = 1,
    /**
     * @generated from protobuf enum value: CELSIUS = 2;
     */
    CELSIUS = 2,
    /**
     * @generated from protobuf enum value: KELVIN = 3;
     */
    KELVIN = 3
}
/**
 * Indicates the purpose of a bundle - how it is intended to be used.
 * See http://hl7.org/fhir/bundle-type
 *
 * @generated from protobuf message google.fhir.r4.core.BundleTypeCode
 */
export interface BundleTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BundleTypeCode.Value
 */
export enum BundleTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DOCUMENT = 1;
     */
    DOCUMENT = 1,
    /**
     * @generated from protobuf enum value: MESSAGE = 2;
     */
    MESSAGE = 2,
    /**
     * @generated from protobuf enum value: TRANSACTION = 3;
     */
    TRANSACTION = 3,
    /**
     * @generated from protobuf enum value: TRANSACTION_RESPONSE = 4;
     */
    TRANSACTION_RESPONSE = 4,
    /**
     * @generated from protobuf enum value: BATCH = 5;
     */
    BATCH = 5,
    /**
     * @generated from protobuf enum value: BATCH_RESPONSE = 6;
     */
    BATCH_RESPONSE = 6,
    /**
     * @generated from protobuf enum value: HISTORY = 7;
     */
    HISTORY = 7,
    /**
     * @generated from protobuf enum value: SEARCHSET = 8;
     */
    SEARCHSET = 8,
    /**
     * @generated from protobuf enum value: COLLECTION = 9;
     */
    COLLECTION = 9
}
/**
 * The master set of status codes used throughout FHIR. All status codes are
 * mapped to one of these codes. See http://hl7.org/fhir/resource-status
 *
 * @generated from protobuf message google.fhir.r4.core.CanonicalStatusCodesForFHIRResourcesCode
 */
export interface CanonicalStatusCodesForFHIRResourcesCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CanonicalStatusCodesForFHIRResourcesCode.Value
 */
export enum CanonicalStatusCodesForFHIRResourcesCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ERROR = 1;
     */
    ERROR = 1,
    /**
     * @generated from protobuf enum value: PROPOSED = 2;
     */
    PROPOSED = 2,
    /**
     * @generated from protobuf enum value: PLANNED = 3;
     */
    PLANNED = 3,
    /**
     * @generated from protobuf enum value: DRAFT = 4;
     */
    DRAFT = 4,
    /**
     * @generated from protobuf enum value: REQUESTED = 5;
     */
    REQUESTED = 5,
    /**
     * @generated from protobuf enum value: RECEIVED = 6;
     */
    RECEIVED = 6,
    /**
     * @generated from protobuf enum value: DECLINED = 7;
     */
    DECLINED = 7,
    /**
     * @generated from protobuf enum value: ACCEPTED = 8;
     */
    ACCEPTED = 8,
    /**
     * @generated from protobuf enum value: ARRIVED = 9;
     */
    ARRIVED = 9,
    /**
     * @generated from protobuf enum value: ACTIVE = 10;
     */
    ACTIVE = 10,
    /**
     * @generated from protobuf enum value: SUSPENDED = 11;
     */
    SUSPENDED = 11,
    /**
     * @generated from protobuf enum value: FAILED = 12;
     */
    FAILED = 12,
    /**
     * @generated from protobuf enum value: REPLACED = 13;
     */
    REPLACED = 13,
    /**
     * @generated from protobuf enum value: COMPLETE = 14;
     */
    COMPLETE = 14,
    /**
     * @generated from protobuf enum value: INACTIVE = 15;
     */
    INACTIVE = 15,
    /**
     * @generated from protobuf enum value: ABANDONED = 16;
     */
    ABANDONED = 16,
    /**
     * @generated from protobuf enum value: UNKNOWN = 17;
     */
    UNKNOWN = 17,
    /**
     * @generated from protobuf enum value: UNCONFIRMED = 18;
     */
    UNCONFIRMED = 18,
    /**
     * @generated from protobuf enum value: CONFIRMED = 19;
     */
    CONFIRMED = 19,
    /**
     * @generated from protobuf enum value: RESOLVED = 20;
     */
    RESOLVED = 20,
    /**
     * @generated from protobuf enum value: REFUTED = 21;
     */
    REFUTED = 21,
    /**
     * @generated from protobuf enum value: DIFFERENTIAL = 22;
     */
    DIFFERENTIAL = 22,
    /**
     * @generated from protobuf enum value: PARTIAL = 23;
     */
    PARTIAL = 23,
    /**
     * @generated from protobuf enum value: BUSY_UNAVAILABLE = 24;
     */
    BUSY_UNAVAILABLE = 24,
    /**
     * @generated from protobuf enum value: FREE = 25;
     */
    FREE = 25,
    /**
     * @generated from protobuf enum value: ON_TARGET = 26;
     */
    ON_TARGET = 26,
    /**
     * @generated from protobuf enum value: AHEAD_OF_TARGET = 27;
     */
    AHEAD_OF_TARGET = 27,
    /**
     * @generated from protobuf enum value: BEHIND_TARGET = 28;
     */
    BEHIND_TARGET = 28,
    /**
     * @generated from protobuf enum value: NOT_READY = 29;
     */
    NOT_READY = 29,
    /**
     * @generated from protobuf enum value: TRANSDUC_DISCON = 30;
     */
    TRANSDUC_DISCON = 30,
    /**
     * @generated from protobuf enum value: HW_DISCON = 31;
     */
    HW_DISCON = 31
}
/**
 * How a capability statement is intended to be used.
 * See http://hl7.org/fhir/capability-statement-kind
 *
 * @generated from protobuf message google.fhir.r4.core.CapabilityStatementKindCode
 */
export interface CapabilityStatementKindCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CapabilityStatementKindCode.Value
 */
export enum CapabilityStatementKindCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: INSTANCE = 1;
     */
    INSTANCE = 1,
    /**
     * @generated from protobuf enum value: CAPABILITY = 2;
     */
    CAPABILITY = 2,
    /**
     * @generated from protobuf enum value: REQUIREMENTS = 3;
     */
    REQUIREMENTS = 3
}
/**
 * Codes that reflect the current state of a care plan activity within its
 * overall life cycle. See http://hl7.org/fhir/care-plan-activity-status
 *
 * @generated from protobuf message google.fhir.r4.core.CarePlanActivityStatusCode
 */
export interface CarePlanActivityStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CarePlanActivityStatusCode.Value
 */
export enum CarePlanActivityStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NOT_STARTED = 1;
     */
    NOT_STARTED = 1,
    /**
     * @generated from protobuf enum value: SCHEDULED = 2;
     */
    SCHEDULED = 2,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 3;
     */
    IN_PROGRESS = 3,
    /**
     * @generated from protobuf enum value: ON_HOLD = 4;
     */
    ON_HOLD = 4,
    /**
     * @generated from protobuf enum value: COMPLETED = 5;
     */
    COMPLETED = 5,
    /**
     * @generated from protobuf enum value: CANCELLED = 6;
     */
    CANCELLED = 6,
    /**
     * @generated from protobuf enum value: STOPPED = 7;
     */
    STOPPED = 7,
    /**
     * @generated from protobuf enum value: UNKNOWN = 8;
     */
    UNKNOWN = 8,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 9;
     */
    ENTERED_IN_ERROR = 9
}
/**
 * Indicates the status of the care team.
 * See http://hl7.org/fhir/care-team-status
 *
 * @generated from protobuf message google.fhir.r4.core.CareTeamStatusCode
 */
export interface CareTeamStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CareTeamStatusCode.Value
 */
export enum CareTeamStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PROPOSED = 1;
     */
    PROPOSED = 1,
    /**
     * @generated from protobuf enum value: ACTIVE = 2;
     */
    ACTIVE = 2,
    /**
     * @generated from protobuf enum value: SUSPENDED = 3;
     */
    SUSPENDED = 3,
    /**
     * @generated from protobuf enum value: INACTIVE = 4;
     */
    INACTIVE = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5
}
/**
 * The type of relations between entries.
 * See http://hl7.org/fhir/relation-type
 *
 * @generated from protobuf message google.fhir.r4.core.CatalogEntryRelationTypeCode
 */
export interface CatalogEntryRelationTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CatalogEntryRelationTypeCode.Value
 */
export enum CatalogEntryRelationTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: TRIGGERS = 1;
     */
    TRIGGERS = 1,
    /**
     * @generated from protobuf enum value: IS_REPLACED_BY = 2;
     */
    IS_REPLACED_BY = 2
}
/**
 * Codes identifying the lifecycle stage of a ChargeItem.
 * See http://hl7.org/fhir/chargeitem-status
 *
 * @generated from protobuf message google.fhir.r4.core.ChargeItemStatusCode
 */
export interface ChargeItemStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ChargeItemStatusCode.Value
 */
export enum ChargeItemStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PLANNED = 1;
     */
    PLANNED = 1,
    /**
     * @generated from protobuf enum value: BILLABLE = 2;
     */
    BILLABLE = 2,
    /**
     * @generated from protobuf enum value: NOT_BILLABLE = 3;
     */
    NOT_BILLABLE = 3,
    /**
     * @generated from protobuf enum value: ABORTED = 4;
     */
    ABORTED = 4,
    /**
     * @generated from protobuf enum value: BILLED = 5;
     */
    BILLED = 5,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 6;
     */
    ENTERED_IN_ERROR = 6,
    /**
     * @generated from protobuf enum value: UNKNOWN = 7;
     */
    UNKNOWN = 7
}
/**
 * Direction in which lists of possible answers should be displayed.
 * See http://terminology.hl7.org/CodeSystem/choice-list-orientation
 *
 * @generated from protobuf message google.fhir.r4.core.ChoiceListOrientationCode
 */
export interface ChoiceListOrientationCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ChoiceListOrientationCode.Value
 */
export enum ChoiceListOrientationCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: HORIZONTAL = 1;
     */
    HORIZONTAL = 1,
    /**
     * @generated from protobuf enum value: VERTICAL = 2;
     */
    VERTICAL = 2
}
/**
 * This value set includes Claim Processing Outcome codes.
 * See http://hl7.org/fhir/remittance-outcome
 *
 * @generated from protobuf message google.fhir.r4.core.ClaimProcessingCode
 */
export interface ClaimProcessingCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ClaimProcessingCode.Value
 */
export enum ClaimProcessingCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: QUEUED = 1;
     */
    QUEUED = 1,
    /**
     * @generated from protobuf enum value: COMPLETE = 2;
     */
    COMPLETE = 2,
    /**
     * @generated from protobuf enum value: ERROR = 3;
     */
    ERROR = 3,
    /**
     * @generated from protobuf enum value: PARTIAL = 4;
     */
    PARTIAL = 4
}
/**
 * The degree to which the server supports the code search parameter on
 * ValueSet, if it is supported. See http://hl7.org/fhir/code-search-support
 *
 * @generated from protobuf message google.fhir.r4.core.CodeSearchSupportCode
 */
export interface CodeSearchSupportCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CodeSearchSupportCode.Value
 */
export enum CodeSearchSupportCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: EXPLICIT = 1;
     */
    EXPLICIT = 1,
    /**
     * @generated from protobuf enum value: ALL = 2;
     */
    ALL = 2
}
/**
 * The extent of the content of the code system (the concepts and codes it
 * defines) are represented in a code system resource. See
 * http://hl7.org/fhir/codesystem-content-mode
 *
 * @generated from protobuf message google.fhir.r4.core.CodeSystemContentModeCode
 */
export interface CodeSystemContentModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CodeSystemContentModeCode.Value
 */
export enum CodeSystemContentModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NOT_PRESENT = 1;
     */
    NOT_PRESENT = 1,
    /**
     * @generated from protobuf enum value: EXAMPLE = 2;
     */
    EXAMPLE = 2,
    /**
     * @generated from protobuf enum value: FRAGMENT = 3;
     */
    FRAGMENT = 3,
    /**
     * @generated from protobuf enum value: COMPLETE = 4;
     */
    COMPLETE = 4,
    /**
     * @generated from protobuf enum value: SUPPLEMENT = 5;
     */
    SUPPLEMENT = 5
}
/**
 * The meaning of the hierarchy of concepts in a code system.
 * See http://hl7.org/fhir/codesystem-hierarchy-meaning
 *
 * @generated from protobuf message google.fhir.r4.core.CodeSystemHierarchyMeaningCode
 */
export interface CodeSystemHierarchyMeaningCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CodeSystemHierarchyMeaningCode.Value
 */
export enum CodeSystemHierarchyMeaningCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: GROUPED_BY = 1;
     */
    GROUPED_BY = 1,
    /**
     * @generated from protobuf enum value: IS_A = 2;
     */
    IS_A = 2,
    /**
     * @generated from protobuf enum value: PART_OF = 3;
     */
    PART_OF = 3,
    /**
     * @generated from protobuf enum value: CLASSIFIED_WITH = 4;
     */
    CLASSIFIED_WITH = 4
}
/**
 * Which type a compartment definition describes.
 * See http://hl7.org/fhir/compartment-type
 *
 * @generated from protobuf message google.fhir.r4.core.CompartmentTypeCode
 */
export interface CompartmentTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CompartmentTypeCode.Value
 */
export enum CompartmentTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PATIENT = 1;
     */
    PATIENT = 1,
    /**
     * @generated from protobuf enum value: ENCOUNTER = 2;
     */
    ENCOUNTER = 2,
    /**
     * @generated from protobuf enum value: RELATED_PERSON = 3;
     */
    RELATED_PERSON = 3,
    /**
     * @generated from protobuf enum value: PRACTITIONER = 4;
     */
    PRACTITIONER = 4,
    /**
     * @generated from protobuf enum value: DEVICE = 5;
     */
    DEVICE = 5
}
/**
 * The way in which a person authenticated a composition.
 * See http://hl7.org/fhir/composition-attestation-mode
 *
 * @generated from protobuf message google.fhir.r4.core.CompositionAttestationModeCode
 */
export interface CompositionAttestationModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CompositionAttestationModeCode.Value
 */
export enum CompositionAttestationModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PERSONAL = 1;
     */
    PERSONAL = 1,
    /**
     * @generated from protobuf enum value: PROFESSIONAL = 2;
     */
    PROFESSIONAL = 2,
    /**
     * @generated from protobuf enum value: LEGAL = 3;
     */
    LEGAL = 3,
    /**
     * @generated from protobuf enum value: OFFICIAL = 4;
     */
    OFFICIAL = 4
}
/**
 * The workflow/clinical status of the composition.
 * See http://hl7.org/fhir/composition-status
 *
 * @generated from protobuf message google.fhir.r4.core.CompositionStatusCode
 */
export interface CompositionStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CompositionStatusCode.Value
 */
export enum CompositionStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PRELIMINARY = 1;
     */
    PRELIMINARY = 1,
    /**
     * @generated from protobuf enum value: FINAL = 2;
     */
    FINAL = 2,
    /**
     * @generated from protobuf enum value: AMENDED = 3;
     */
    AMENDED = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4
}
/**
 * The degree of equivalence between concepts.
 * See http://hl7.org/fhir/concept-map-equivalence
 *
 * @generated from protobuf message google.fhir.r4.core.ConceptMapEquivalenceCode
 */
export interface ConceptMapEquivalenceCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConceptMapEquivalenceCode.Value
 */
export enum ConceptMapEquivalenceCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: RELATEDTO = 1;
     */
    RELATEDTO = 1,
    /**
     * @generated from protobuf enum value: EQUIVALENT = 2;
     */
    EQUIVALENT = 2,
    /**
     * @generated from protobuf enum value: EQUAL = 3;
     */
    EQUAL = 3,
    /**
     * @generated from protobuf enum value: WIDER = 4;
     */
    WIDER = 4,
    /**
     * @generated from protobuf enum value: SUBSUMES = 5;
     */
    SUBSUMES = 5,
    /**
     * @generated from protobuf enum value: NARROWER = 6;
     */
    NARROWER = 6,
    /**
     * @generated from protobuf enum value: SPECIALIZES = 7;
     */
    SPECIALIZES = 7,
    /**
     * @generated from protobuf enum value: INEXACT = 8;
     */
    INEXACT = 8,
    /**
     * @generated from protobuf enum value: UNMATCHED = 9;
     */
    UNMATCHED = 9,
    /**
     * @generated from protobuf enum value: DISJOINT = 10;
     */
    DISJOINT = 10
}
/**
 * Defines which action to take if there is no match in the group.
 * See http://hl7.org/fhir/conceptmap-unmapped-mode
 *
 * @generated from protobuf message google.fhir.r4.core.ConceptMapGroupUnmappedModeCode
 */
export interface ConceptMapGroupUnmappedModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConceptMapGroupUnmappedModeCode.Value
 */
export enum ConceptMapGroupUnmappedModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PROVIDED = 1;
     */
    PROVIDED = 1,
    /**
     * @generated from protobuf enum value: FIXED = 2;
     */
    FIXED = 2,
    /**
     * @generated from protobuf enum value: OTHER_MAP = 3;
     */
    OTHER_MAP = 3
}
/**
 * Preferred value set for Condition Clinical Status.
 * See http://terminology.hl7.org/CodeSystem/condition-clinical
 *
 * @generated from protobuf message google.fhir.r4.core.ConditionClinicalStatusCode
 */
export interface ConditionClinicalStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConditionClinicalStatusCode.Value
 */
export enum ConditionClinicalStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: RECURRENCE = 2;
     */
    RECURRENCE = 2,
    /**
     * @generated from protobuf enum value: RELAPSE = 3;
     */
    RELAPSE = 3,
    /**
     * @generated from protobuf enum value: INACTIVE = 4;
     */
    INACTIVE = 4,
    /**
     * @generated from protobuf enum value: REMISSION = 5;
     */
    REMISSION = 5,
    /**
     * @generated from protobuf enum value: RESOLVED = 6;
     */
    RESOLVED = 6
}
/**
 * The verification status to support or decline the clinical status of the
 * condition or diagnosis. See
 * http://terminology.hl7.org/CodeSystem/condition-ver-status
 *
 * @generated from protobuf message google.fhir.r4.core.ConditionVerificationStatusCode
 */
export interface ConditionVerificationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConditionVerificationStatusCode.Value
 */
export enum ConditionVerificationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: UNCONFIRMED = 1;
     */
    UNCONFIRMED = 1,
    /**
     * @generated from protobuf enum value: PROVISIONAL = 2;
     */
    PROVISIONAL = 2,
    /**
     * @generated from protobuf enum value: DIFFERENTIAL = 3;
     */
    DIFFERENTIAL = 3,
    /**
     * @generated from protobuf enum value: CONFIRMED = 4;
     */
    CONFIRMED = 4,
    /**
     * @generated from protobuf enum value: REFUTED = 5;
     */
    REFUTED = 5,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 6;
     */
    ENTERED_IN_ERROR = 6
}
/**
 * A code that indicates how the server supports conditional delete.
 * See http://hl7.org/fhir/conditional-delete-status
 *
 * @generated from protobuf message google.fhir.r4.core.ConditionalDeleteStatusCode
 */
export interface ConditionalDeleteStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConditionalDeleteStatusCode.Value
 */
export enum ConditionalDeleteStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NOT_SUPPORTED = 1;
     */
    NOT_SUPPORTED = 1,
    /**
     * @generated from protobuf enum value: SINGLE = 2;
     */
    SINGLE = 2,
    /**
     * @generated from protobuf enum value: MULTIPLE = 3;
     */
    MULTIPLE = 3
}
/**
 * A code that indicates how the server supports conditional read.
 * See http://hl7.org/fhir/conditional-read-status
 *
 * @generated from protobuf message google.fhir.r4.core.ConditionalReadStatusCode
 */
export interface ConditionalReadStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConditionalReadStatusCode.Value
 */
export enum ConditionalReadStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NOT_SUPPORTED = 1;
     */
    NOT_SUPPORTED = 1,
    /**
     * @generated from protobuf enum value: MODIFIED_SINCE = 2;
     */
    MODIFIED_SINCE = 2,
    /**
     * @generated from protobuf enum value: NOT_MATCH = 3;
     */
    NOT_MATCH = 3,
    /**
     * @generated from protobuf enum value: FULL_SUPPORT = 4;
     */
    FULL_SUPPORT = 4
}
/**
 * Indicates the degree of adherence to a specified behavior or capability
 * expected for a system to be deemed conformant with a specification. See
 * http://terminology.hl7.org/CodeSystem/conformance-expectation
 *
 * @generated from protobuf message google.fhir.r4.core.ConformanceExpectationCode
 */
export interface ConformanceExpectationCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConformanceExpectationCode.Value
 */
export enum ConformanceExpectationCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SHALL = 1;
     */
    SHALL = 1,
    /**
     * @generated from protobuf enum value: SHOULD = 2;
     */
    SHOULD = 2,
    /**
     * @generated from protobuf enum value: MAY = 3;
     */
    MAY = 3,
    /**
     * @generated from protobuf enum value: SHOULD_NOT = 4;
     */
    SHOULD_NOT = 4
}
/**
 * How a resource reference is interpreted when testing consent restrictions.
 * See http://hl7.org/fhir/consent-data-meaning
 *
 * @generated from protobuf message google.fhir.r4.core.ConsentDataMeaningCode
 */
export interface ConsentDataMeaningCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConsentDataMeaningCode.Value
 */
export enum ConsentDataMeaningCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: INSTANCE = 1;
     */
    INSTANCE = 1,
    /**
     * @generated from protobuf enum value: RELATED = 2;
     */
    RELATED = 2,
    /**
     * @generated from protobuf enum value: DEPENDENTS = 3;
     */
    DEPENDENTS = 3,
    /**
     * @generated from protobuf enum value: AUTHOREDBY = 4;
     */
    AUTHOREDBY = 4
}
/**
 * How a rule statement is applied, such as adding additional consent or
 * removing consent. See http://hl7.org/fhir/consent-provision-type
 *
 * @generated from protobuf message google.fhir.r4.core.ConsentProvisionTypeCode
 */
export interface ConsentProvisionTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConsentProvisionTypeCode.Value
 */
export enum ConsentProvisionTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DENY = 1;
     */
    DENY = 1,
    /**
     * @generated from protobuf enum value: PERMIT = 2;
     */
    PERMIT = 2
}
/**
 * Indicates the state of the consent.
 * See http://hl7.org/fhir/consent-state-codes
 *
 * @generated from protobuf message google.fhir.r4.core.ConsentStateCode
 */
export interface ConsentStateCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConsentStateCode.Value
 */
export enum ConsentStateCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: PROPOSED = 2;
     */
    PROPOSED = 2,
    /**
     * @generated from protobuf enum value: ACTIVE = 3;
     */
    ACTIVE = 3,
    /**
     * @generated from protobuf enum value: REJECTED = 4;
     */
    REJECTED = 4,
    /**
     * @generated from protobuf enum value: INACTIVE = 5;
     */
    INACTIVE = 5,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 6;
     */
    ENTERED_IN_ERROR = 6
}
/**
 * SHALL applications comply with this constraint?
 * See http://hl7.org/fhir/constraint-severity
 *
 * @generated from protobuf message google.fhir.r4.core.ConstraintSeverityCode
 */
export interface ConstraintSeverityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ConstraintSeverityCode.Value
 */
export enum ConstraintSeverityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ERROR = 1;
     */
    ERROR = 1,
    /**
     * @generated from protobuf enum value: WARNING = 2;
     */
    WARNING = 2
}
/**
 * Telecommunications form for contact point.
 * See http://hl7.org/fhir/contact-point-system
 *
 * @generated from protobuf message google.fhir.r4.core.ContactPointSystemCode
 */
export interface ContactPointSystemCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ContactPointSystemCode.Value
 */
export enum ContactPointSystemCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PHONE = 1;
     */
    PHONE = 1,
    /**
     * @generated from protobuf enum value: FAX = 2;
     */
    FAX = 2,
    /**
     * @generated from protobuf enum value: EMAIL = 3;
     */
    EMAIL = 3,
    /**
     * @generated from protobuf enum value: PAGER = 4;
     */
    PAGER = 4,
    /**
     * @generated from protobuf enum value: URL = 5;
     */
    URL = 5,
    /**
     * @generated from protobuf enum value: SMS = 6;
     */
    SMS = 6,
    /**
     * @generated from protobuf enum value: OTHER = 7;
     */
    OTHER = 7
}
/**
 * Use of contact point.
 * See http://hl7.org/fhir/contact-point-use
 *
 * @generated from protobuf message google.fhir.r4.core.ContactPointUseCode
 */
export interface ContactPointUseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ContactPointUseCode.Value
 */
export enum ContactPointUseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: HOME = 1;
     */
    HOME = 1,
    /**
     * @generated from protobuf enum value: WORK = 2;
     */
    WORK = 2,
    /**
     * @generated from protobuf enum value: TEMP = 3;
     */
    TEMP = 3,
    /**
     * @generated from protobuf enum value: OLD = 4;
     */
    OLD = 4,
    /**
     * @generated from protobuf enum value: MOBILE = 5;
     */
    MOBILE = 5
}
/**
 * This value set contract specific codes for status.
 * See http://hl7.org/fhir/contract-publicationstatus
 *
 * @generated from protobuf message google.fhir.r4.core.ContractResourcePublicationStatusCode
 */
export interface ContractResourcePublicationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ContractResourcePublicationStatusCode.Value
 */
export enum ContractResourcePublicationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AMENDED = 1;
     */
    AMENDED = 1,
    /**
     * @generated from protobuf enum value: APPENDED = 2;
     */
    APPENDED = 2,
    /**
     * @generated from protobuf enum value: CANCELLED = 3;
     */
    CANCELLED = 3,
    /**
     * @generated from protobuf enum value: DISPUTED = 4;
     */
    DISPUTED = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5,
    /**
     * @generated from protobuf enum value: EXECUTABLE = 6;
     */
    EXECUTABLE = 6,
    /**
     * @generated from protobuf enum value: EXECUTED = 7;
     */
    EXECUTED = 7,
    /**
     * @generated from protobuf enum value: NEGOTIABLE = 8;
     */
    NEGOTIABLE = 8,
    /**
     * @generated from protobuf enum value: OFFERED = 9;
     */
    OFFERED = 9,
    /**
     * @generated from protobuf enum value: POLICY = 10;
     */
    POLICY = 10,
    /**
     * @generated from protobuf enum value: REJECTED = 11;
     */
    REJECTED = 11,
    /**
     * @generated from protobuf enum value: RENEWED = 12;
     */
    RENEWED = 12,
    /**
     * @generated from protobuf enum value: REVOKED = 13;
     */
    REVOKED = 13,
    /**
     * @generated from protobuf enum value: RESOLVED = 14;
     */
    RESOLVED = 14,
    /**
     * @generated from protobuf enum value: TERMINATED = 15;
     */
    TERMINATED = 15
}
/**
 * This value set contract specific codes for status.
 * See http://hl7.org/fhir/contract-status
 *
 * @generated from protobuf message google.fhir.r4.core.ContractResourceStatusCode
 */
export interface ContractResourceStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ContractResourceStatusCode.Value
 */
export enum ContractResourceStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AMENDED = 1;
     */
    AMENDED = 1,
    /**
     * @generated from protobuf enum value: APPENDED = 2;
     */
    APPENDED = 2,
    /**
     * @generated from protobuf enum value: CANCELLED = 3;
     */
    CANCELLED = 3,
    /**
     * @generated from protobuf enum value: DISPUTED = 4;
     */
    DISPUTED = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5,
    /**
     * @generated from protobuf enum value: EXECUTABLE = 6;
     */
    EXECUTABLE = 6,
    /**
     * @generated from protobuf enum value: EXECUTED = 7;
     */
    EXECUTED = 7,
    /**
     * @generated from protobuf enum value: NEGOTIABLE = 8;
     */
    NEGOTIABLE = 8,
    /**
     * @generated from protobuf enum value: OFFERED = 9;
     */
    OFFERED = 9,
    /**
     * @generated from protobuf enum value: POLICY = 10;
     */
    POLICY = 10,
    /**
     * @generated from protobuf enum value: REJECTED = 11;
     */
    REJECTED = 11,
    /**
     * @generated from protobuf enum value: RENEWED = 12;
     */
    RENEWED = 12,
    /**
     * @generated from protobuf enum value: REVOKED = 13;
     */
    REVOKED = 13,
    /**
     * @generated from protobuf enum value: RESOLVED = 14;
     */
    RESOLVED = 14,
    /**
     * @generated from protobuf enum value: TERMINATED = 15;
     */
    TERMINATED = 15
}
/**
 * The type of contributor.
 * See http://hl7.org/fhir/contributor-type
 *
 * @generated from protobuf message google.fhir.r4.core.ContributorTypeCode
 */
export interface ContributorTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ContributorTypeCode.Value
 */
export enum ContributorTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AUTHOR = 1;
     */
    AUTHOR = 1,
    /**
     * @generated from protobuf enum value: EDITOR = 2;
     */
    EDITOR = 2,
    /**
     * @generated from protobuf enum value: REVIEWER = 3;
     */
    REVIEWER = 3,
    /**
     * @generated from protobuf enum value: ENDORSER = 4;
     */
    ENDORSER = 4
}
/**
 * Used to specify why the normally expected content of the data element is
 * missing. See http://terminology.hl7.org/CodeSystem/data-absent-reason
 *
 * @generated from protobuf message google.fhir.r4.core.DataAbsentReasonCode
 */
export interface DataAbsentReasonCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DataAbsentReasonCode.Value
 */
export enum DataAbsentReasonCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: UNKNOWN = 1;
     */
    UNKNOWN = 1,
    /**
     * @generated from protobuf enum value: ASKED_UNKNOWN = 2;
     */
    ASKED_UNKNOWN = 2,
    /**
     * @generated from protobuf enum value: TEMP_UNKNOWN = 3;
     */
    TEMP_UNKNOWN = 3,
    /**
     * @generated from protobuf enum value: NOT_ASKED = 4;
     */
    NOT_ASKED = 4,
    /**
     * @generated from protobuf enum value: ASKED_DECLINED = 5;
     */
    ASKED_DECLINED = 5,
    /**
     * @generated from protobuf enum value: MASKED = 6;
     */
    MASKED = 6,
    /**
     * @generated from protobuf enum value: NOT_APPLICABLE = 7;
     */
    NOT_APPLICABLE = 7,
    /**
     * @generated from protobuf enum value: UNSUPPORTED = 8;
     */
    UNSUPPORTED = 8,
    /**
     * @generated from protobuf enum value: AS_TEXT = 9;
     */
    AS_TEXT = 9,
    /**
     * @generated from protobuf enum value: ERROR = 10;
     */
    ERROR = 10,
    /**
     * @generated from protobuf enum value: NOT_A_NUMBER = 11;
     */
    NOT_A_NUMBER = 11,
    /**
     * @generated from protobuf enum value: NEGATIVE_INFINITY = 12;
     */
    NEGATIVE_INFINITY = 12,
    /**
     * @generated from protobuf enum value: POSITIVE_INFINITY = 13;
     */
    POSITIVE_INFINITY = 13,
    /**
     * @generated from protobuf enum value: NOT_PERFORMED = 14;
     */
    NOT_PERFORMED = 14,
    /**
     * @generated from protobuf enum value: NOT_PERMITTED = 15;
     */
    NOT_PERMITTED = 15
}
/**
 * A version specific list of the data types defined by the FHIR specification
 * for use as an element  type (any of the FHIR defined data types). See
 * http://hl7.org/fhir/data-types
 *
 * @generated from protobuf message google.fhir.r4.core.DataTypeCode
 */
export interface DataTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DataTypeCode.Value
 */
export enum DataTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ADDRESS = 1;
     */
    ADDRESS = 1,
    /**
     * @generated from protobuf enum value: AGE = 2;
     */
    AGE = 2,
    /**
     * @generated from protobuf enum value: ANNOTATION = 3;
     */
    ANNOTATION = 3,
    /**
     * @generated from protobuf enum value: ATTACHMENT = 4;
     */
    ATTACHMENT = 4,
    /**
     * @generated from protobuf enum value: BACKBONE_ELEMENT = 5;
     */
    BACKBONE_ELEMENT = 5,
    /**
     * @generated from protobuf enum value: CODEABLE_CONCEPT = 6;
     */
    CODEABLE_CONCEPT = 6,
    /**
     * @generated from protobuf enum value: CODING = 7;
     */
    CODING = 7,
    /**
     * @generated from protobuf enum value: CONTACT_DETAIL = 8;
     */
    CONTACT_DETAIL = 8,
    /**
     * @generated from protobuf enum value: CONTACT_POINT = 9;
     */
    CONTACT_POINT = 9,
    /**
     * @generated from protobuf enum value: CONTRIBUTOR = 10;
     */
    CONTRIBUTOR = 10,
    /**
     * @generated from protobuf enum value: COUNT = 11;
     */
    COUNT = 11,
    /**
     * @generated from protobuf enum value: DATA_REQUIREMENT = 12;
     */
    DATA_REQUIREMENT = 12,
    /**
     * @generated from protobuf enum value: DISTANCE = 13;
     */
    DISTANCE = 13,
    /**
     * @generated from protobuf enum value: DOSAGE = 14;
     */
    DOSAGE = 14,
    /**
     * @generated from protobuf enum value: DURATION = 15;
     */
    DURATION = 15,
    /**
     * @generated from protobuf enum value: ELEMENT = 16;
     */
    ELEMENT = 16,
    /**
     * @generated from protobuf enum value: ELEMENT_DEFINITION = 17;
     */
    ELEMENT_DEFINITION = 17,
    /**
     * @generated from protobuf enum value: EXPRESSION = 18;
     */
    EXPRESSION = 18,
    /**
     * @generated from protobuf enum value: EXTENSION = 19;
     */
    EXTENSION = 19,
    /**
     * @generated from protobuf enum value: HUMAN_NAME = 20;
     */
    HUMAN_NAME = 20,
    /**
     * @generated from protobuf enum value: IDENTIFIER = 21;
     */
    IDENTIFIER = 21,
    /**
     * @generated from protobuf enum value: MARKETING_STATUS = 22;
     */
    MARKETING_STATUS = 22,
    /**
     * @generated from protobuf enum value: META = 23;
     */
    META = 23,
    /**
     * @generated from protobuf enum value: MONEY = 24;
     */
    MONEY = 24,
    /**
     * @generated from protobuf enum value: MONEY_QUANTITY = 25;
     */
    MONEY_QUANTITY = 25,
    /**
     * @generated from protobuf enum value: NARRATIVE = 26;
     */
    NARRATIVE = 26,
    /**
     * @generated from protobuf enum value: PARAMETER_DEFINITION = 27;
     */
    PARAMETER_DEFINITION = 27,
    /**
     * @generated from protobuf enum value: PERIOD = 28;
     */
    PERIOD = 28,
    /**
     * @generated from protobuf enum value: POPULATION = 29;
     */
    POPULATION = 29,
    /**
     * @generated from protobuf enum value: PROD_CHARACTERISTIC = 30;
     */
    PROD_CHARACTERISTIC = 30,
    /**
     * @generated from protobuf enum value: PRODUCT_SHELF_LIFE = 31;
     */
    PRODUCT_SHELF_LIFE = 31,
    /**
     * @generated from protobuf enum value: QUANTITY = 32;
     */
    QUANTITY = 32,
    /**
     * @generated from protobuf enum value: RANGE = 33;
     */
    RANGE = 33,
    /**
     * @generated from protobuf enum value: RATIO = 34;
     */
    RATIO = 34,
    /**
     * @generated from protobuf enum value: REFERENCE = 35;
     */
    REFERENCE = 35,
    /**
     * @generated from protobuf enum value: RELATED_ARTIFACT = 36;
     */
    RELATED_ARTIFACT = 36,
    /**
     * @generated from protobuf enum value: SAMPLED_DATA = 37;
     */
    SAMPLED_DATA = 37,
    /**
     * @generated from protobuf enum value: SIGNATURE = 38;
     */
    SIGNATURE = 38,
    /**
     * @generated from protobuf enum value: SIMPLE_QUANTITY = 39;
     */
    SIMPLE_QUANTITY = 39,
    /**
     * @generated from protobuf enum value: SUBSTANCE_AMOUNT = 40;
     */
    SUBSTANCE_AMOUNT = 40,
    /**
     * @generated from protobuf enum value: TIMING = 41;
     */
    TIMING = 41,
    /**
     * @generated from protobuf enum value: TRIGGER_DEFINITION = 42;
     */
    TRIGGER_DEFINITION = 42,
    /**
     * @generated from protobuf enum value: USAGE_CONTEXT = 43;
     */
    USAGE_CONTEXT = 43,
    /**
     * @generated from protobuf enum value: BASE64_BINARY = 44;
     */
    BASE64_BINARY = 44,
    /**
     * @generated from protobuf enum value: BOOLEAN = 45;
     */
    BOOLEAN = 45,
    /**
     * @generated from protobuf enum value: CANONICAL = 46;
     */
    CANONICAL = 46,
    /**
     * @generated from protobuf enum value: CODE = 47;
     */
    CODE = 47,
    /**
     * @generated from protobuf enum value: DATE = 48;
     */
    DATE = 48,
    /**
     * @generated from protobuf enum value: DATE_TIME = 49;
     */
    DATE_TIME = 49,
    /**
     * @generated from protobuf enum value: DECIMAL = 50;
     */
    DECIMAL = 50,
    /**
     * @generated from protobuf enum value: ID = 51;
     */
    ID = 51,
    /**
     * @generated from protobuf enum value: INSTANT = 52;
     */
    INSTANT = 52,
    /**
     * @generated from protobuf enum value: INTEGER = 53;
     */
    INTEGER = 53,
    /**
     * @generated from protobuf enum value: MARKDOWN = 54;
     */
    MARKDOWN = 54,
    /**
     * @generated from protobuf enum value: OID = 55;
     */
    OID = 55,
    /**
     * @generated from protobuf enum value: POSITIVE_INT = 56;
     */
    POSITIVE_INT = 56,
    /**
     * @generated from protobuf enum value: STRING = 57;
     */
    STRING = 57,
    /**
     * @generated from protobuf enum value: TIME = 58;
     */
    TIME = 58,
    /**
     * @generated from protobuf enum value: UNSIGNED_INT = 59;
     */
    UNSIGNED_INT = 59,
    /**
     * @generated from protobuf enum value: URI = 60;
     */
    URI = 60,
    /**
     * @generated from protobuf enum value: URL = 61;
     */
    URL = 61,
    /**
     * @generated from protobuf enum value: UUID = 62;
     */
    UUID = 62,
    /**
     * @generated from protobuf enum value: XHTML = 63;
     */
    XHTML = 63
}
/**
 * The days of the week.
 * See http://hl7.org/fhir/days-of-week
 *
 * @generated from protobuf message google.fhir.r4.core.DaysOfWeekCode
 */
export interface DaysOfWeekCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DaysOfWeekCode.Value
 */
export enum DaysOfWeekCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MON = 1;
     */
    MON = 1,
    /**
     * @generated from protobuf enum value: TUE = 2;
     */
    TUE = 2,
    /**
     * @generated from protobuf enum value: WED = 3;
     */
    WED = 3,
    /**
     * @generated from protobuf enum value: THU = 4;
     */
    THU = 4,
    /**
     * @generated from protobuf enum value: FRI = 5;
     */
    FRI = 5,
    /**
     * @generated from protobuf enum value: SAT = 6;
     */
    SAT = 6,
    /**
     * @generated from protobuf enum value: SUN = 7;
     */
    SUN = 7
}
/**
 * Indicates the potential degree of impact of the identified issue on the
 * patient. See http://hl7.org/fhir/detectedissue-severity
 *
 * @generated from protobuf message google.fhir.r4.core.DetectedIssueSeverityCode
 */
export interface DetectedIssueSeverityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DetectedIssueSeverityCode.Value
 */
export enum DetectedIssueSeverityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: HIGH = 1;
     */
    HIGH = 1,
    /**
     * @generated from protobuf enum value: MODERATE = 2;
     */
    MODERATE = 2,
    /**
     * @generated from protobuf enum value: LOW = 3;
     */
    LOW = 3
}
/**
 * Describes the state of a metric calibration.
 * See http://hl7.org/fhir/metric-calibration-state
 *
 * @generated from protobuf message google.fhir.r4.core.DeviceMetricCalibrationStateCode
 */
export interface DeviceMetricCalibrationStateCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DeviceMetricCalibrationStateCode.Value
 */
export enum DeviceMetricCalibrationStateCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NOT_CALIBRATED = 1;
     */
    NOT_CALIBRATED = 1,
    /**
     * @generated from protobuf enum value: CALIBRATION_REQUIRED = 2;
     */
    CALIBRATION_REQUIRED = 2,
    /**
     * @generated from protobuf enum value: CALIBRATED = 3;
     */
    CALIBRATED = 3,
    /**
     * @generated from protobuf enum value: UNSPECIFIED = 4;
     */
    UNSPECIFIED = 4
}
/**
 * Describes the type of a metric calibration.
 * See http://hl7.org/fhir/metric-calibration-type
 *
 * @generated from protobuf message google.fhir.r4.core.DeviceMetricCalibrationTypeCode
 */
export interface DeviceMetricCalibrationTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DeviceMetricCalibrationTypeCode.Value
 */
export enum DeviceMetricCalibrationTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: UNSPECIFIED = 1;
     */
    UNSPECIFIED = 1,
    /**
     * @generated from protobuf enum value: OFFSET = 2;
     */
    OFFSET = 2,
    /**
     * @generated from protobuf enum value: GAIN = 3;
     */
    GAIN = 3,
    /**
     * @generated from protobuf enum value: TWO_POINT = 4;
     */
    TWO_POINT = 4
}
/**
 * Describes the category of the metric.
 * See http://hl7.org/fhir/metric-category
 *
 * @generated from protobuf message google.fhir.r4.core.DeviceMetricCategoryCode
 */
export interface DeviceMetricCategoryCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DeviceMetricCategoryCode.Value
 */
export enum DeviceMetricCategoryCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MEASUREMENT = 1;
     */
    MEASUREMENT = 1,
    /**
     * @generated from protobuf enum value: SETTING = 2;
     */
    SETTING = 2,
    /**
     * @generated from protobuf enum value: CALCULATION = 3;
     */
    CALCULATION = 3,
    /**
     * @generated from protobuf enum value: UNSPECIFIED = 4;
     */
    UNSPECIFIED = 4
}
/**
 * Describes the typical color of representation.
 * See http://hl7.org/fhir/metric-color
 *
 * @generated from protobuf message google.fhir.r4.core.DeviceMetricColorCode
 */
export interface DeviceMetricColorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DeviceMetricColorCode.Value
 */
export enum DeviceMetricColorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: BLACK = 1;
     */
    BLACK = 1,
    /**
     * @generated from protobuf enum value: RED = 2;
     */
    RED = 2,
    /**
     * @generated from protobuf enum value: GREEN = 3;
     */
    GREEN = 3,
    /**
     * @generated from protobuf enum value: YELLOW = 4;
     */
    YELLOW = 4,
    /**
     * @generated from protobuf enum value: BLUE = 5;
     */
    BLUE = 5,
    /**
     * @generated from protobuf enum value: MAGENTA = 6;
     */
    MAGENTA = 6,
    /**
     * @generated from protobuf enum value: CYAN = 7;
     */
    CYAN = 7,
    /**
     * @generated from protobuf enum value: WHITE = 8;
     */
    WHITE = 8
}
/**
 * Describes the operational status of the DeviceMetric.
 * See http://hl7.org/fhir/metric-operational-status
 *
 * @generated from protobuf message google.fhir.r4.core.DeviceMetricOperationalStatusCode
 */
export interface DeviceMetricOperationalStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DeviceMetricOperationalStatusCode.Value
 */
export enum DeviceMetricOperationalStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ON = 1;
     */
    ON = 1,
    /**
     * @generated from protobuf enum value: OFF = 2;
     */
    OFF = 2,
    /**
     * @generated from protobuf enum value: STANDBY = 3;
     */
    STANDBY = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4
}
/**
 * The type of name the device is referred by.
 * See http://hl7.org/fhir/device-nametype
 *
 * @generated from protobuf message google.fhir.r4.core.DeviceNameTypeCode
 */
export interface DeviceNameTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DeviceNameTypeCode.Value
 */
export enum DeviceNameTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: UDI_LABEL_NAME = 1;
     */
    UDI_LABEL_NAME = 1,
    /**
     * @generated from protobuf enum value: USER_FRIENDLY_NAME = 2;
     */
    USER_FRIENDLY_NAME = 2,
    /**
     * @generated from protobuf enum value: PATIENT_REPORTED_NAME = 3;
     */
    PATIENT_REPORTED_NAME = 3,
    /**
     * @generated from protobuf enum value: MANUFACTURER_NAME = 4;
     */
    MANUFACTURER_NAME = 4,
    /**
     * @generated from protobuf enum value: MODEL_NAME = 5;
     */
    MODEL_NAME = 5,
    /**
     * @generated from protobuf enum value: OTHER = 6;
     */
    OTHER = 6
}
/**
 * A coded concept indicating the current status of the Device Usage.
 * See http://hl7.org/fhir/device-statement-status
 *
 * @generated from protobuf message google.fhir.r4.core.DeviceUseStatementStatusCode
 */
export interface DeviceUseStatementStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DeviceUseStatementStatusCode.Value
 */
export enum DeviceUseStatementStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: COMPLETED = 2;
     */
    COMPLETED = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3,
    /**
     * @generated from protobuf enum value: INTENDED = 4;
     */
    INTENDED = 4,
    /**
     * @generated from protobuf enum value: STOPPED = 5;
     */
    STOPPED = 5,
    /**
     * @generated from protobuf enum value: ON_HOLD = 6;
     */
    ON_HOLD = 6
}
/**
 * The status of the diagnostic report.
 * See http://hl7.org/fhir/diagnostic-report-status
 *
 * @generated from protobuf message google.fhir.r4.core.DiagnosticReportStatusCode
 */
export interface DiagnosticReportStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DiagnosticReportStatusCode.Value
 */
export enum DiagnosticReportStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REGISTERED = 1;
     */
    REGISTERED = 1,
    /**
     * @generated from protobuf enum value: PARTIAL = 2;
     */
    PARTIAL = 2,
    /**
     * @generated from protobuf enum value: PRELIMINARY = 3;
     */
    PRELIMINARY = 3,
    /**
     * @generated from protobuf enum value: FINAL = 4;
     */
    FINAL = 4,
    /**
     * @generated from protobuf enum value: AMENDED = 5;
     */
    AMENDED = 5,
    /**
     * @generated from protobuf enum value: CORRECTED = 6;
     */
    CORRECTED = 6,
    /**
     * @generated from protobuf enum value: APPENDED = 7;
     */
    APPENDED = 7,
    /**
     * @generated from protobuf enum value: CANCELLED = 8;
     */
    CANCELLED = 8,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 9;
     */
    ENTERED_IN_ERROR = 9,
    /**
     * @generated from protobuf enum value: UNKNOWN = 10;
     */
    UNKNOWN = 10
}
/**
 * How an element value is interpreted when discrimination is evaluated.
 * See http://hl7.org/fhir/discriminator-type
 *
 * @generated from protobuf message google.fhir.r4.core.DiscriminatorTypeCode
 */
export interface DiscriminatorTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DiscriminatorTypeCode.Value
 */
export enum DiscriminatorTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: VALUE = 1;
     */
    VALUE = 1,
    /**
     * @generated from protobuf enum value: EXISTS = 2;
     */
    EXISTS = 2,
    /**
     * @generated from protobuf enum value: PATTERN = 3;
     */
    PATTERN = 3,
    /**
     * @generated from protobuf enum value: TYPE = 4;
     */
    TYPE = 4,
    /**
     * @generated from protobuf enum value: PROFILE = 5;
     */
    PROFILE = 5
}
/**
 * Whether the application produces or consumes documents.
 * See http://hl7.org/fhir/document-mode
 *
 * @generated from protobuf message google.fhir.r4.core.DocumentModeCode
 */
export interface DocumentModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DocumentModeCode.Value
 */
export enum DocumentModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PRODUCER = 1;
     */
    PRODUCER = 1,
    /**
     * @generated from protobuf enum value: CONSUMER = 2;
     */
    CONSUMER = 2
}
/**
 * The status of the document reference.
 * See http://hl7.org/fhir/document-reference-status
 *
 * @generated from protobuf message google.fhir.r4.core.DocumentReferenceStatusCode
 */
export interface DocumentReferenceStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DocumentReferenceStatusCode.Value
 */
export enum DocumentReferenceStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CURRENT = 1;
     */
    CURRENT = 1,
    /**
     * @generated from protobuf enum value: SUPERSEDED = 2;
     */
    SUPERSEDED = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3
}
/**
 * The type of relationship between documents.
 * See http://hl7.org/fhir/document-relationship-type
 *
 * @generated from protobuf message google.fhir.r4.core.DocumentRelationshipTypeCode
 */
export interface DocumentRelationshipTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.DocumentRelationshipTypeCode.Value
 */
export enum DocumentRelationshipTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REPLACES = 1;
     */
    REPLACES = 1,
    /**
     * @generated from protobuf enum value: TRANSFORMS = 2;
     */
    TRANSFORMS = 2,
    /**
     * @generated from protobuf enum value: SIGNS = 3;
     */
    SIGNS = 3,
    /**
     * @generated from protobuf enum value: APPENDS = 4;
     */
    APPENDS = 4
}
/**
 * A code specifying the types of information being requested.
 * See http://hl7.org/fhir/eligibilityrequest-purpose
 *
 * @generated from protobuf message google.fhir.r4.core.EligibilityRequestPurposeCode
 */
export interface EligibilityRequestPurposeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EligibilityRequestPurposeCode.Value
 */
export enum EligibilityRequestPurposeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AUTH_REQUIREMENTS = 1;
     */
    AUTH_REQUIREMENTS = 1,
    /**
     * @generated from protobuf enum value: BENEFITS = 2;
     */
    BENEFITS = 2,
    /**
     * @generated from protobuf enum value: DISCOVERY = 3;
     */
    DISCOVERY = 3,
    /**
     * @generated from protobuf enum value: VALIDATION = 4;
     */
    VALIDATION = 4
}
/**
 * A code specifying the types of information being requested.
 * See http://hl7.org/fhir/eligibilityresponse-purpose
 *
 * @generated from protobuf message google.fhir.r4.core.EligibilityResponsePurposeCode
 */
export interface EligibilityResponsePurposeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EligibilityResponsePurposeCode.Value
 */
export enum EligibilityResponsePurposeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AUTH_REQUIREMENTS = 1;
     */
    AUTH_REQUIREMENTS = 1,
    /**
     * @generated from protobuf enum value: BENEFITS = 2;
     */
    BENEFITS = 2,
    /**
     * @generated from protobuf enum value: DISCOVERY = 3;
     */
    DISCOVERY = 3,
    /**
     * @generated from protobuf enum value: VALIDATION = 4;
     */
    VALIDATION = 4
}
/**
 * Controls how multiple enableWhen values are interpreted -  whether all or any
 * must be true. See http://hl7.org/fhir/questionnaire-enable-behavior
 *
 * @generated from protobuf message google.fhir.r4.core.EnableWhenBehaviorCode
 */
export interface EnableWhenBehaviorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EnableWhenBehaviorCode.Value
 */
export enum EnableWhenBehaviorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ALL = 1;
     */
    ALL = 1,
    /**
     * @generated from protobuf enum value: ANY = 2;
     */
    ANY = 2
}
/**
 * The status of the location.
 * See http://hl7.org/fhir/encounter-location-status
 *
 * @generated from protobuf message google.fhir.r4.core.EncounterLocationStatusCode
 */
export interface EncounterLocationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EncounterLocationStatusCode.Value
 */
export enum EncounterLocationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PLANNED = 1;
     */
    PLANNED = 1,
    /**
     * @generated from protobuf enum value: ACTIVE = 2;
     */
    ACTIVE = 2,
    /**
     * @generated from protobuf enum value: RESERVED = 3;
     */
    RESERVED = 3,
    /**
     * @generated from protobuf enum value: COMPLETED = 4;
     */
    COMPLETED = 4
}
/**
 * Current state of the encounter.
 * See http://hl7.org/fhir/encounter-status
 *
 * @generated from protobuf message google.fhir.r4.core.EncounterStatusCode
 */
export interface EncounterStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EncounterStatusCode.Value
 */
export enum EncounterStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PLANNED = 1;
     */
    PLANNED = 1,
    /**
     * @generated from protobuf enum value: ARRIVED = 2;
     */
    ARRIVED = 2,
    /**
     * @generated from protobuf enum value: TRIAGED = 3;
     */
    TRIAGED = 3,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 4;
     */
    IN_PROGRESS = 4,
    /**
     * @generated from protobuf enum value: ONLEAVE = 5;
     */
    ONLEAVE = 5,
    /**
     * @generated from protobuf enum value: FINISHED = 6;
     */
    FINISHED = 6,
    /**
     * @generated from protobuf enum value: CANCELLED = 7;
     */
    CANCELLED = 7,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 8;
     */
    ENTERED_IN_ERROR = 8,
    /**
     * @generated from protobuf enum value: UNKNOWN = 9;
     */
    UNKNOWN = 9
}
/**
 * The status of the endpoint.
 * See http://hl7.org/fhir/endpoint-status
 *
 * @generated from protobuf message google.fhir.r4.core.EndpointStatusCode
 */
export interface EndpointStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EndpointStatusCode.Value
 */
export enum EndpointStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: SUSPENDED = 2;
     */
    SUSPENDED = 2,
    /**
     * @generated from protobuf enum value: ERROR = 3;
     */
    ERROR = 3,
    /**
     * @generated from protobuf enum value: OFF = 4;
     */
    OFF = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5,
    /**
     * @generated from protobuf enum value: TEST = 6;
     */
    TEST = 6
}
/**
 * The status of the episode of care.
 * See http://hl7.org/fhir/episode-of-care-status
 *
 * @generated from protobuf message google.fhir.r4.core.EpisodeOfCareStatusCode
 */
export interface EpisodeOfCareStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EpisodeOfCareStatusCode.Value
 */
export enum EpisodeOfCareStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PLANNED = 1;
     */
    PLANNED = 1,
    /**
     * @generated from protobuf enum value: WAITLIST = 2;
     */
    WAITLIST = 2,
    /**
     * @generated from protobuf enum value: ACTIVE = 3;
     */
    ACTIVE = 3,
    /**
     * @generated from protobuf enum value: ONHOLD = 4;
     */
    ONHOLD = 4,
    /**
     * @generated from protobuf enum value: FINISHED = 5;
     */
    FINISHED = 5,
    /**
     * @generated from protobuf enum value: CANCELLED = 6;
     */
    CANCELLED = 6,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 7;
     */
    ENTERED_IN_ERROR = 7
}
/**
 * The mode of a message capability statement.
 * See http://hl7.org/fhir/event-capability-mode
 *
 * @generated from protobuf message google.fhir.r4.core.EventCapabilityModeCode
 */
export interface EventCapabilityModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EventCapabilityModeCode.Value
 */
export enum EventCapabilityModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SENDER = 1;
     */
    SENDER = 1,
    /**
     * @generated from protobuf enum value: RECEIVER = 2;
     */
    RECEIVER = 2
}
/**
 * Codes identifying the lifecycle stage of an event.
 * See http://hl7.org/fhir/event-status
 *
 * @generated from protobuf message google.fhir.r4.core.EventStatusCode
 */
export interface EventStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EventStatusCode.Value
 */
export enum EventStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PREPARATION = 1;
     */
    PREPARATION = 1,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 2;
     */
    IN_PROGRESS = 2,
    /**
     * @generated from protobuf enum value: NOT_DONE = 3;
     */
    NOT_DONE = 3,
    /**
     * @generated from protobuf enum value: ON_HOLD = 4;
     */
    ON_HOLD = 4,
    /**
     * @generated from protobuf enum value: STOPPED = 5;
     */
    STOPPED = 5,
    /**
     * @generated from protobuf enum value: COMPLETED = 6;
     */
    COMPLETED = 6,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 7;
     */
    ENTERED_IN_ERROR = 7,
    /**
     * @generated from protobuf enum value: UNKNOWN = 8;
     */
    UNKNOWN = 8
}
/**
 * Real world event relating to the schedule.
 * See http://hl7.org/fhir/event-timing
 *
 * @generated from protobuf message google.fhir.r4.core.EventTimingCode
 */
export interface EventTimingCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EventTimingCode.Value
 */
export enum EventTimingCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MORN = 1;
     */
    MORN = 1,
    /**
     * @generated from protobuf enum value: MORN_EARLY = 2;
     */
    MORN_EARLY = 2,
    /**
     * @generated from protobuf enum value: MORN_LATE = 3;
     */
    MORN_LATE = 3,
    /**
     * @generated from protobuf enum value: NOON = 4;
     */
    NOON = 4,
    /**
     * @generated from protobuf enum value: AFT = 5;
     */
    AFT = 5,
    /**
     * @generated from protobuf enum value: AFT_EARLY = 6;
     */
    AFT_EARLY = 6,
    /**
     * @generated from protobuf enum value: AFT_LATE = 7;
     */
    AFT_LATE = 7,
    /**
     * @generated from protobuf enum value: EVE = 8;
     */
    EVE = 8,
    /**
     * @generated from protobuf enum value: EVE_EARLY = 9;
     */
    EVE_EARLY = 9,
    /**
     * @generated from protobuf enum value: EVE_LATE = 10;
     */
    EVE_LATE = 10,
    /**
     * @generated from protobuf enum value: NIGHT = 11;
     */
    NIGHT = 11,
    /**
     * @generated from protobuf enum value: PHS = 12;
     */
    PHS = 12
}
/**
 * The possible types of variables for exposures or outcomes (E.g. Dichotomous,
 * Continuous, Descriptive). See http://hl7.org/fhir/variable-type
 *
 * @generated from protobuf message google.fhir.r4.core.EvidenceVariableTypeCode
 */
export interface EvidenceVariableTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EvidenceVariableTypeCode.Value
 */
export enum EvidenceVariableTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DICHOTOMOUS = 1;
     */
    DICHOTOMOUS = 1,
    /**
     * @generated from protobuf enum value: CONTINUOUS = 2;
     */
    CONTINUOUS = 2,
    /**
     * @generated from protobuf enum value: DESCRIPTIVE = 3;
     */
    DESCRIPTIVE = 3
}
/**
 * The type of actor - system or human.
 * See http://hl7.org/fhir/examplescenario-actor-type
 *
 * @generated from protobuf message google.fhir.r4.core.ExampleScenarioActorTypeCode
 */
export interface ExampleScenarioActorTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ExampleScenarioActorTypeCode.Value
 */
export enum ExampleScenarioActorTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PERSON = 1;
     */
    PERSON = 1,
    /**
     * @generated from protobuf enum value: ENTITY = 2;
     */
    ENTITY = 2
}
/**
 * Declares what the source of a parameter is.
 * See http://terminology.hl7.org/CodeSystem/expansion-parameter-source
 *
 * @generated from protobuf message google.fhir.r4.core.ExpansionParameterSourceCode
 */
export interface ExpansionParameterSourceCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ExpansionParameterSourceCode.Value
 */
export enum ExpansionParameterSourceCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: INPUT = 1;
     */
    INPUT = 1,
    /**
     * @generated from protobuf enum value: SERVER = 2;
     */
    SERVER = 2,
    /**
     * @generated from protobuf enum value: CODESYSTEM = 3;
     */
    CODESYSTEM = 3
}
/**
 * Defines how concepts are processed into the expansion when it's for UI
 * presentation. See
 * http://terminology.hl7.org/CodeSystem/expansion-processing-rule
 *
 * @generated from protobuf message google.fhir.r4.core.ExpansionProcessingRuleCode
 */
export interface ExpansionProcessingRuleCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ExpansionProcessingRuleCode.Value
 */
export enum ExpansionProcessingRuleCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ALL_CODES = 1;
     */
    ALL_CODES = 1,
    /**
     * @generated from protobuf enum value: UNGROUPED = 2;
     */
    UNGROUPED = 2,
    /**
     * @generated from protobuf enum value: GROUPS_ONLY = 3;
     */
    GROUPS_ONLY = 3
}
/**
 * A code specifying the state of the resource instance.
 * See http://hl7.org/fhir/explanationofbenefit-status
 *
 * @generated from protobuf message google.fhir.r4.core.ExplanationOfBenefitStatusCode
 */
export interface ExplanationOfBenefitStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ExplanationOfBenefitStatusCode.Value
 */
export enum ExplanationOfBenefitStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: CANCELLED = 2;
     */
    CANCELLED = 2,
    /**
     * @generated from protobuf enum value: DRAFT = 3;
     */
    DRAFT = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4
}
/**
 * Whether the results by exposure is describing the results for the primary
 * exposure of interest (exposure) or the alternative state
 * (exposureAlternative). See http://hl7.org/fhir/exposure-state
 *
 * @generated from protobuf message google.fhir.r4.core.ExposureStateCode
 */
export interface ExposureStateCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ExposureStateCode.Value
 */
export enum ExposureStateCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: EXPOSURE = 1;
     */
    EXPOSURE = 1,
    /**
     * @generated from protobuf enum value: EXPOSURE_ALTERNATIVE = 2;
     */
    EXPOSURE_ALTERNATIVE = 2
}
/**
 * How an extension context is interpreted.
 * See http://hl7.org/fhir/extension-context-type
 *
 * @generated from protobuf message google.fhir.r4.core.ExtensionContextTypeCode
 */
export interface ExtensionContextTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ExtensionContextTypeCode.Value
 */
export enum ExtensionContextTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: FHIRPATH = 1;
     */
    FHIRPATH = 1,
    /**
     * @generated from protobuf enum value: ELEMENT = 2;
     */
    ELEMENT = 2,
    /**
     * @generated from protobuf enum value: EXTENSION = 3;
     */
    EXTENSION = 3
}
/**
 * The availability status of the device.
 * See http://hl7.org/fhir/device-status
 *
 * @generated from protobuf message google.fhir.r4.core.FHIRDeviceStatusCode
 */
export interface FHIRDeviceStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FHIRDeviceStatusCode.Value
 */
export enum FHIRDeviceStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: INACTIVE = 2;
     */
    INACTIVE = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3,
    /**
     * @generated from protobuf enum value: UNKNOWN = 4;
     */
    UNKNOWN = 4
}
/**
 * The set of interactions defined by the RESTful part of the FHIR
 * specification. See http://hl7.org/fhir/restful-interaction
 *
 * @generated from protobuf message google.fhir.r4.core.FHIRRestfulInteractionsCode
 */
export interface FHIRRestfulInteractionsCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FHIRRestfulInteractionsCode.Value
 */
export enum FHIRRestfulInteractionsCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: READ = 1;
     */
    READ = 1,
    /**
     * @generated from protobuf enum value: VREAD = 2;
     */
    VREAD = 2,
    /**
     * @generated from protobuf enum value: UPDATE = 3;
     */
    UPDATE = 3,
    /**
     * @generated from protobuf enum value: PATCH = 4;
     */
    PATCH = 4,
    /**
     * @generated from protobuf enum value: DELETE = 5;
     */
    DELETE = 5,
    /**
     * @generated from protobuf enum value: HISTORY = 6;
     */
    HISTORY = 6,
    /**
     * @generated from protobuf enum value: HISTORY_INSTANCE = 7;
     */
    HISTORY_INSTANCE = 7,
    /**
     * @generated from protobuf enum value: HISTORY_TYPE = 8;
     */
    HISTORY_TYPE = 8,
    /**
     * @generated from protobuf enum value: HISTORY_SYSTEM = 9;
     */
    HISTORY_SYSTEM = 9,
    /**
     * @generated from protobuf enum value: CREATE = 10;
     */
    CREATE = 10,
    /**
     * @generated from protobuf enum value: SEARCH = 11;
     */
    SEARCH = 11,
    /**
     * @generated from protobuf enum value: SEARCH_TYPE = 12;
     */
    SEARCH_TYPE = 12,
    /**
     * @generated from protobuf enum value: SEARCH_SYSTEM = 13;
     */
    SEARCH_SYSTEM = 13,
    /**
     * @generated from protobuf enum value: CAPABILITIES = 14;
     */
    CAPABILITIES = 14,
    /**
     * @generated from protobuf enum value: TRANSACTION = 15;
     */
    TRANSACTION = 15,
    /**
     * @generated from protobuf enum value: BATCH = 16;
     */
    BATCH = 16,
    /**
     * @generated from protobuf enum value: OPERATION = 17;
     */
    OPERATION = 17
}
/**
 * A code to indicate if the substance is actively used.
 * See http://hl7.org/fhir/substance-status
 *
 * @generated from protobuf message google.fhir.r4.core.FHIRSubstanceStatusCode
 */
export interface FHIRSubstanceStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FHIRSubstanceStatusCode.Value
 */
export enum FHIRSubstanceStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: INACTIVE = 2;
     */
    INACTIVE = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3
}
/**
 * All published FHIR Versions.
 * See http://hl7.org/fhir/FHIR-version
 *
 * @generated from protobuf message google.fhir.r4.core.FHIRVersionCode
 */
export interface FHIRVersionCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FHIRVersionCode.Value
 */
export enum FHIRVersionCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: V_0_01 = 1;
     */
    V_0_01 = 1,
    /**
     * @generated from protobuf enum value: V_0_05 = 2;
     */
    V_0_05 = 2,
    /**
     * @generated from protobuf enum value: V_0_06 = 3;
     */
    V_0_06 = 3,
    /**
     * @generated from protobuf enum value: V_0_11 = 4;
     */
    V_0_11 = 4,
    /**
     * @generated from protobuf enum value: V_0_0_80 = 5;
     */
    V_0_0_80 = 5,
    /**
     * @generated from protobuf enum value: V_0_0_81 = 6;
     */
    V_0_0_81 = 6,
    /**
     * @generated from protobuf enum value: V_0_0_82 = 7;
     */
    V_0_0_82 = 7,
    /**
     * @generated from protobuf enum value: V_0_4_0 = 8;
     */
    V_0_4_0 = 8,
    /**
     * @generated from protobuf enum value: V_0_5_0 = 9;
     */
    V_0_5_0 = 9,
    /**
     * @generated from protobuf enum value: V_1_0_0 = 10;
     */
    V_1_0_0 = 10,
    /**
     * @generated from protobuf enum value: V_1_0_1 = 11;
     */
    V_1_0_1 = 11,
    /**
     * @generated from protobuf enum value: V_1_0_2 = 12;
     */
    V_1_0_2 = 12,
    /**
     * @generated from protobuf enum value: V_1_1_0 = 13;
     */
    V_1_1_0 = 13,
    /**
     * @generated from protobuf enum value: V_1_4_0 = 14;
     */
    V_1_4_0 = 14,
    /**
     * @generated from protobuf enum value: V_1_6_0 = 15;
     */
    V_1_6_0 = 15,
    /**
     * @generated from protobuf enum value: V_1_8_0 = 16;
     */
    V_1_8_0 = 16,
    /**
     * @generated from protobuf enum value: V_3_0_0 = 17;
     */
    V_3_0_0 = 17,
    /**
     * @generated from protobuf enum value: V_3_0_1 = 18;
     */
    V_3_0_1 = 18,
    /**
     * @generated from protobuf enum value: V_3_3_0 = 19;
     */
    V_3_3_0 = 19,
    /**
     * @generated from protobuf enum value: V_3_5_0 = 20;
     */
    V_3_5_0 = 20,
    /**
     * @generated from protobuf enum value: V_4_0_0 = 21;
     */
    V_4_0_0 = 21,
    /**
     * @generated from protobuf enum value: V_4_0_1 = 22;
     */
    V_4_0_1 = 22
}
/**
 * A code that identifies the status of the family history record.
 * See http://hl7.org/fhir/history-status
 *
 * @generated from protobuf message google.fhir.r4.core.FamilyHistoryStatusCode
 */
export interface FamilyHistoryStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FamilyHistoryStatusCode.Value
 */
export enum FamilyHistoryStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PARTIAL = 1;
     */
    PARTIAL = 1,
    /**
     * @generated from protobuf enum value: COMPLETED = 2;
     */
    COMPLETED = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3,
    /**
     * @generated from protobuf enum value: HEALTH_UNKNOWN = 4;
     */
    HEALTH_UNKNOWN = 4
}
/**
 * The kind of operation to perform as a part of a property based filter.
 * See http://hl7.org/fhir/filter-operator
 *
 * @generated from protobuf message google.fhir.r4.core.FilterOperatorCode
 */
export interface FilterOperatorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FilterOperatorCode.Value
 */
export enum FilterOperatorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: EQUALS = 1;
     */
    EQUALS = 1,
    /**
     * @generated from protobuf enum value: IS_A = 2;
     */
    IS_A = 2,
    /**
     * @generated from protobuf enum value: DESCENDENT_OF = 3;
     */
    DESCENDENT_OF = 3,
    /**
     * @generated from protobuf enum value: IS_NOT_A = 4;
     */
    IS_NOT_A = 4,
    /**
     * @generated from protobuf enum value: REGEX = 5;
     */
    REGEX = 5,
    /**
     * @generated from protobuf enum value: IN = 6;
     */
    IN = 6,
    /**
     * @generated from protobuf enum value: NOT_IN = 7;
     */
    NOT_IN = 7,
    /**
     * @generated from protobuf enum value: GENERALIZES = 8;
     */
    GENERALIZES = 8,
    /**
     * @generated from protobuf enum value: EXISTS = 9;
     */
    EXISTS = 9
}
/**
 * This value set includes Status codes.
 * See http://hl7.org/fhir/fm-status
 *
 * @generated from protobuf message google.fhir.r4.core.FinancialResourceStatusCode
 */
export interface FinancialResourceStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FinancialResourceStatusCode.Value
 */
export enum FinancialResourceStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: CANCELLED = 2;
     */
    CANCELLED = 2,
    /**
     * @generated from protobuf enum value: DRAFT = 3;
     */
    DRAFT = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4
}
/**
 * Indicates whether this flag is active and needs to be displayed to a user, or
 * whether it is no longer needed or was entered in error. See
 * http://hl7.org/fhir/flag-status
 *
 * @generated from protobuf message google.fhir.r4.core.FlagStatusCode
 */
export interface FlagStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FlagStatusCode.Value
 */
export enum FlagStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: INACTIVE = 2;
     */
    INACTIVE = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3
}
/**
 * Codes indicating whether the goal has been accepted by a stakeholder.
 * See http://terminology.hl7.org/CodeSystem/goal-acceptance-status
 *
 * @generated from protobuf message google.fhir.r4.core.GoalAcceptanceStatusCode
 */
export interface GoalAcceptanceStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GoalAcceptanceStatusCode.Value
 */
export enum GoalAcceptanceStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AGREE = 1;
     */
    AGREE = 1,
    /**
     * @generated from protobuf enum value: DISAGREE = 2;
     */
    DISAGREE = 2,
    /**
     * @generated from protobuf enum value: PENDING = 3;
     */
    PENDING = 3
}
/**
 * Codes that reflect the current state of a goal and whether the goal is still
 * being targeted. See http://hl7.org/fhir/goal-status
 *
 * @generated from protobuf message google.fhir.r4.core.GoalLifecycleStatusCode
 */
export interface GoalLifecycleStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GoalLifecycleStatusCode.Value
 */
export enum GoalLifecycleStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PROPOSED = 1;
     */
    PROPOSED = 1,
    /**
     * @generated from protobuf enum value: PLANNED = 2;
     */
    PLANNED = 2,
    /**
     * @generated from protobuf enum value: ACCEPTED = 3;
     */
    ACCEPTED = 3,
    /**
     * @generated from protobuf enum value: ACTIVE = 4;
     */
    ACTIVE = 4,
    /**
     * @generated from protobuf enum value: ON_HOLD = 5;
     */
    ON_HOLD = 5,
    /**
     * @generated from protobuf enum value: COMPLETED = 6;
     */
    COMPLETED = 6,
    /**
     * @generated from protobuf enum value: CANCELLED = 7;
     */
    CANCELLED = 7,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 8;
     */
    ENTERED_IN_ERROR = 8,
    /**
     * @generated from protobuf enum value: REJECTED = 9;
     */
    REJECTED = 9
}
/**
 * How a compartment must be linked.
 * See http://hl7.org/fhir/graph-compartment-rule
 *
 * @generated from protobuf message google.fhir.r4.core.GraphCompartmentRuleCode
 */
export interface GraphCompartmentRuleCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GraphCompartmentRuleCode.Value
 */
export enum GraphCompartmentRuleCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: IDENTICAL = 1;
     */
    IDENTICAL = 1,
    /**
     * @generated from protobuf enum value: MATCHING = 2;
     */
    MATCHING = 2,
    /**
     * @generated from protobuf enum value: DIFFERENT = 3;
     */
    DIFFERENT = 3,
    /**
     * @generated from protobuf enum value: CUSTOM = 4;
     */
    CUSTOM = 4
}
/**
 * Defines how a compartment rule is used.
 * See http://hl7.org/fhir/graph-compartment-use
 *
 * @generated from protobuf message google.fhir.r4.core.GraphCompartmentUseCode
 */
export interface GraphCompartmentUseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GraphCompartmentUseCode.Value
 */
export enum GraphCompartmentUseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CONDITION = 1;
     */
    CONDITION = 1,
    /**
     * @generated from protobuf enum value: REQUIREMENT = 2;
     */
    REQUIREMENT = 2
}
/**
 * Possible group measure aggregates (E.g. Mean, Median).
 * See http://hl7.org/fhir/group-measure
 *
 * @generated from protobuf message google.fhir.r4.core.GroupMeasureCode
 */
export interface GroupMeasureCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GroupMeasureCode.Value
 */
export enum GroupMeasureCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MEAN = 1;
     */
    MEAN = 1,
    /**
     * @generated from protobuf enum value: MEDIAN = 2;
     */
    MEDIAN = 2,
    /**
     * @generated from protobuf enum value: MEAN_OF_MEAN = 3;
     */
    MEAN_OF_MEAN = 3,
    /**
     * @generated from protobuf enum value: MEAN_OF_MEDIAN = 4;
     */
    MEAN_OF_MEDIAN = 4,
    /**
     * @generated from protobuf enum value: MEDIAN_OF_MEAN = 5;
     */
    MEDIAN_OF_MEAN = 5,
    /**
     * @generated from protobuf enum value: MEDIAN_OF_MEDIAN = 6;
     */
    MEDIAN_OF_MEDIAN = 6
}
/**
 * Types of resources that are part of group.
 * See http://hl7.org/fhir/group-type
 *
 * @generated from protobuf message google.fhir.r4.core.GroupTypeCode
 */
export interface GroupTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GroupTypeCode.Value
 */
export enum GroupTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PERSON = 1;
     */
    PERSON = 1,
    /**
     * @generated from protobuf enum value: ANIMAL = 2;
     */
    ANIMAL = 2,
    /**
     * @generated from protobuf enum value: PRACTITIONER = 3;
     */
    PRACTITIONER = 3,
    /**
     * @generated from protobuf enum value: DEVICE = 4;
     */
    DEVICE = 4,
    /**
     * @generated from protobuf enum value: MEDICATION = 5;
     */
    MEDICATION = 5,
    /**
     * @generated from protobuf enum value: SUBSTANCE = 6;
     */
    SUBSTANCE = 6
}
/**
 * The status of a guidance response.
 * See http://hl7.org/fhir/guidance-response-status
 *
 * @generated from protobuf message google.fhir.r4.core.GuidanceResponseStatusCode
 */
export interface GuidanceResponseStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GuidanceResponseStatusCode.Value
 */
export enum GuidanceResponseStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SUCCESS = 1;
     */
    SUCCESS = 1,
    /**
     * @generated from protobuf enum value: DATA_REQUESTED = 2;
     */
    DATA_REQUESTED = 2,
    /**
     * @generated from protobuf enum value: DATA_REQUIRED = 3;
     */
    DATA_REQUIRED = 3,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 4;
     */
    IN_PROGRESS = 4,
    /**
     * @generated from protobuf enum value: FAILURE = 5;
     */
    FAILURE = 5,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 6;
     */
    ENTERED_IN_ERROR = 6
}
/**
 * A code that indicates how the page is generated.
 * See http://hl7.org/fhir/guide-page-generation
 *
 * @generated from protobuf message google.fhir.r4.core.GuidePageGenerationCode
 */
export interface GuidePageGenerationCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GuidePageGenerationCode.Value
 */
export enum GuidePageGenerationCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: HTML = 1;
     */
    HTML = 1,
    /**
     * @generated from protobuf enum value: MARKDOWN = 2;
     */
    MARKDOWN = 2,
    /**
     * @generated from protobuf enum value: XML = 3;
     */
    XML = 3,
    /**
     * @generated from protobuf enum value: GENERATED = 4;
     */
    GENERATED = 4
}
/**
 * Code of parameter that is input to the guide.
 * See http://hl7.org/fhir/guide-parameter-code
 *
 * @generated from protobuf message google.fhir.r4.core.GuideParameterCode
 */
export interface GuideParameterCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.GuideParameterCode.Value
 */
export enum GuideParameterCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: APPLY = 1;
     */
    APPLY = 1,
    /**
     * @generated from protobuf enum value: PATH_RESOURCE = 2;
     */
    PATH_RESOURCE = 2,
    /**
     * @generated from protobuf enum value: PATH_PAGES = 3;
     */
    PATH_PAGES = 3,
    /**
     * @generated from protobuf enum value: PATH_TX_CACHE = 4;
     */
    PATH_TX_CACHE = 4,
    /**
     * @generated from protobuf enum value: EXPANSION_PARAMETER = 5;
     */
    EXPANSION_PARAMETER = 5,
    /**
     * @generated from protobuf enum value: RULE_BROKEN_LINKS = 6;
     */
    RULE_BROKEN_LINKS = 6,
    /**
     * @generated from protobuf enum value: GENERATE_XML = 7;
     */
    GENERATE_XML = 7,
    /**
     * @generated from protobuf enum value: GENERATE_JSON = 8;
     */
    GENERATE_JSON = 8,
    /**
     * @generated from protobuf enum value: GENERATE_TURTLE = 9;
     */
    GENERATE_TURTLE = 9,
    /**
     * @generated from protobuf enum value: HTML_TEMPLATE = 10;
     */
    HTML_TEMPLATE = 10
}
/**
 * An HL7 administrative unit that owns artifacts in the FHIR specification.
 * See http://terminology.hl7.org/CodeSystem/hl7-work-group
 *
 * @generated from protobuf message google.fhir.r4.core.HL7WorkgroupCode
 */
export interface HL7WorkgroupCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.HL7WorkgroupCode.Value
 */
export enum HL7WorkgroupCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CBCC = 1;
     */
    CBCC = 1,
    /**
     * @generated from protobuf enum value: CDS = 2;
     */
    CDS = 2,
    /**
     * @generated from protobuf enum value: CQI = 3;
     */
    CQI = 3,
    /**
     * @generated from protobuf enum value: CG = 4;
     */
    CG = 4,
    /**
     * @generated from protobuf enum value: DEV = 5;
     */
    DEV = 5,
    /**
     * @generated from protobuf enum value: EHR = 6;
     */
    EHR = 6,
    /**
     * @generated from protobuf enum value: FHIR = 7;
     */
    FHIR = 7,
    /**
     * @generated from protobuf enum value: FM = 8;
     */
    FM = 8,
    /**
     * @generated from protobuf enum value: HSI = 9;
     */
    HSI = 9,
    /**
     * @generated from protobuf enum value: II = 10;
     */
    II = 10,
    /**
     * @generated from protobuf enum value: INM = 11;
     */
    INM = 11,
    /**
     * @generated from protobuf enum value: ITS = 12;
     */
    ITS = 12,
    /**
     * @generated from protobuf enum value: MNM = 13;
     */
    MNM = 13,
    /**
     * @generated from protobuf enum value: OO = 14;
     */
    OO = 14,
    /**
     * @generated from protobuf enum value: PA = 15;
     */
    PA = 15,
    /**
     * @generated from protobuf enum value: PC = 16;
     */
    PC = 16,
    /**
     * @generated from protobuf enum value: PHER = 17;
     */
    PHER = 17,
    /**
     * @generated from protobuf enum value: PHX = 18;
     */
    PHX = 18,
    /**
     * @generated from protobuf enum value: BRR = 19;
     */
    BRR = 19,
    /**
     * @generated from protobuf enum value: SD = 20;
     */
    SD = 20,
    /**
     * @generated from protobuf enum value: SEC = 21;
     */
    SEC = 21,
    /**
     * @generated from protobuf enum value: US = 22;
     */
    US = 22,
    /**
     * @generated from protobuf enum value: VOCAB = 23;
     */
    VOCAB = 23,
    /**
     * @generated from protobuf enum value: AID = 24;
     */
    AID = 24
}
/**
 * HTTP verbs (in the HTTP command line). See [HTTP
 * rfc](https://tools.ietf.org/html/rfc7231) for details. See
 * http://hl7.org/fhir/http-verb
 *
 * @generated from protobuf message google.fhir.r4.core.HTTPVerbCode
 */
export interface HTTPVerbCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.HTTPVerbCode.Value
 */
export enum HTTPVerbCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: GET = 1;
     */
    GET = 1,
    /**
     * @generated from protobuf enum value: HEAD = 2;
     */
    HEAD = 2,
    /**
     * @generated from protobuf enum value: POST = 3;
     */
    POST = 3,
    /**
     * @generated from protobuf enum value: PUT = 4;
     */
    PUT = 4,
    /**
     * @generated from protobuf enum value: DELETE = 5;
     */
    DELETE = 5,
    /**
     * @generated from protobuf enum value: PATCH = 6;
     */
    PATCH = 6
}
/**
 * A code that represents the preferred display order of the components of a
 * human name. See http://terminology.hl7.org/CodeSystem/name-assembly-order
 *
 * @generated from protobuf message google.fhir.r4.core.HumanNameAssemblyOrderCode
 */
export interface HumanNameAssemblyOrderCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.HumanNameAssemblyOrderCode.Value
 */
export enum HumanNameAssemblyOrderCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NL1 = 1;
     */
    NL1 = 1,
    /**
     * @generated from protobuf enum value: NL2 = 2;
     */
    NL2 = 2,
    /**
     * @generated from protobuf enum value: NL3 = 3;
     */
    NL3 = 3,
    /**
     * @generated from protobuf enum value: NL4 = 4;
     */
    NL4 = 4
}
/**
 * Identifies the purpose for this identifier, if known .
 * See http://hl7.org/fhir/identifier-use
 *
 * @generated from protobuf message google.fhir.r4.core.IdentifierUseCode
 */
export interface IdentifierUseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.IdentifierUseCode.Value
 */
export enum IdentifierUseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: USUAL = 1;
     */
    USUAL = 1,
    /**
     * @generated from protobuf enum value: OFFICIAL = 2;
     */
    OFFICIAL = 2,
    /**
     * @generated from protobuf enum value: TEMP = 3;
     */
    TEMP = 3,
    /**
     * @generated from protobuf enum value: SECONDARY = 4;
     */
    SECONDARY = 4,
    /**
     * @generated from protobuf enum value: OLD = 5;
     */
    OLD = 5
}
/**
 * The level of confidence that this link represents the same actual person,
 * based on NIST Authentication Levels. See
 * http://hl7.org/fhir/identity-assuranceLevel
 *
 * @generated from protobuf message google.fhir.r4.core.IdentityAssuranceLevelCode
 */
export interface IdentityAssuranceLevelCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.IdentityAssuranceLevelCode.Value
 */
export enum IdentityAssuranceLevelCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: LEVEL1 = 1;
     */
    LEVEL1 = 1,
    /**
     * @generated from protobuf enum value: LEVEL2 = 2;
     */
    LEVEL2 = 2,
    /**
     * @generated from protobuf enum value: LEVEL3 = 3;
     */
    LEVEL3 = 3,
    /**
     * @generated from protobuf enum value: LEVEL4 = 4;
     */
    LEVEL4 = 4
}
/**
 * The status of the ImagingStudy.
 * See http://hl7.org/fhir/imagingstudy-status
 *
 * @generated from protobuf message google.fhir.r4.core.ImagingStudyStatusCode
 */
export interface ImagingStudyStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ImagingStudyStatusCode.Value
 */
export enum ImagingStudyStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REGISTERED = 1;
     */
    REGISTERED = 1,
    /**
     * @generated from protobuf enum value: AVAILABLE = 2;
     */
    AVAILABLE = 2,
    /**
     * @generated from protobuf enum value: CANCELLED = 3;
     */
    CANCELLED = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4,
    /**
     * @generated from protobuf enum value: UNKNOWN = 5;
     */
    UNKNOWN = 5
}
/**
 * A set codes that define the functional status of an implanted device.
 * See http://terminology.hl7.org/CodeSystem/implantStatus
 *
 * @generated from protobuf message google.fhir.r4.core.ImplantStatusCode
 */
export interface ImplantStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ImplantStatusCode.Value
 */
export enum ImplantStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: FUNCTIONAL = 1;
     */
    FUNCTIONAL = 1,
    /**
     * @generated from protobuf enum value: NON_FUNCTIONAL = 2;
     */
    NON_FUNCTIONAL = 2,
    /**
     * @generated from protobuf enum value: DISABLED = 3;
     */
    DISABLED = 3,
    /**
     * @generated from protobuf enum value: UNKNOWN = 4;
     */
    UNKNOWN = 4
}
/**
 * Codes indicating the kind of the price component.
 * See http://hl7.org/fhir/invoice-priceComponentType
 *
 * @generated from protobuf message google.fhir.r4.core.InvoicePriceComponentTypeCode
 */
export interface InvoicePriceComponentTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.InvoicePriceComponentTypeCode.Value
 */
export enum InvoicePriceComponentTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: BASE = 1;
     */
    BASE = 1,
    /**
     * @generated from protobuf enum value: SURCHARGE = 2;
     */
    SURCHARGE = 2,
    /**
     * @generated from protobuf enum value: DEDUCTION = 3;
     */
    DEDUCTION = 3,
    /**
     * @generated from protobuf enum value: DISCOUNT = 4;
     */
    DISCOUNT = 4,
    /**
     * @generated from protobuf enum value: TAX = 5;
     */
    TAX = 5,
    /**
     * @generated from protobuf enum value: INFORMATIONAL = 6;
     */
    INFORMATIONAL = 6
}
/**
 * Codes identifying the lifecycle stage of an Invoice.
 * See http://hl7.org/fhir/invoice-status
 *
 * @generated from protobuf message google.fhir.r4.core.InvoiceStatusCode
 */
export interface InvoiceStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.InvoiceStatusCode.Value
 */
export enum InvoiceStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: ISSUED = 2;
     */
    ISSUED = 2,
    /**
     * @generated from protobuf enum value: BALANCED = 3;
     */
    BALANCED = 3,
    /**
     * @generated from protobuf enum value: CANCELLED = 4;
     */
    CANCELLED = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5
}
/**
 * How the issue affects the success of the action.
 * See http://hl7.org/fhir/issue-severity
 *
 * @generated from protobuf message google.fhir.r4.core.IssueSeverityCode
 */
export interface IssueSeverityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.IssueSeverityCode.Value
 */
export enum IssueSeverityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: FATAL = 1;
     */
    FATAL = 1,
    /**
     * @generated from protobuf enum value: ERROR = 2;
     */
    ERROR = 2,
    /**
     * @generated from protobuf enum value: WARNING = 3;
     */
    WARNING = 3,
    /**
     * @generated from protobuf enum value: INFORMATION = 4;
     */
    INFORMATION = 4
}
/**
 * A code that describes the type of issue.
 * See http://hl7.org/fhir/issue-type
 *
 * @generated from protobuf message google.fhir.r4.core.IssueTypeCode
 */
export interface IssueTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.IssueTypeCode.Value
 */
export enum IssueTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: INVALID = 1;
     */
    INVALID = 1,
    /**
     * @generated from protobuf enum value: STRUCTURE = 2;
     */
    STRUCTURE = 2,
    /**
     * @generated from protobuf enum value: REQUIRED = 3;
     */
    REQUIRED = 3,
    /**
     * @generated from protobuf enum value: VALUE = 4;
     */
    VALUE = 4,
    /**
     * @generated from protobuf enum value: INVARIANT = 5;
     */
    INVARIANT = 5,
    /**
     * @generated from protobuf enum value: SECURITY = 6;
     */
    SECURITY = 6,
    /**
     * @generated from protobuf enum value: LOGIN = 7;
     */
    LOGIN = 7,
    /**
     * @generated from protobuf enum value: UNKNOWN = 8;
     */
    UNKNOWN = 8,
    /**
     * @generated from protobuf enum value: EXPIRED = 9;
     */
    EXPIRED = 9,
    /**
     * @generated from protobuf enum value: FORBIDDEN = 10;
     */
    FORBIDDEN = 10,
    /**
     * @generated from protobuf enum value: SUPPRESSED = 11;
     */
    SUPPRESSED = 11,
    /**
     * @generated from protobuf enum value: PROCESSING = 12;
     */
    PROCESSING = 12,
    /**
     * @generated from protobuf enum value: NOT_SUPPORTED = 13;
     */
    NOT_SUPPORTED = 13,
    /**
     * @generated from protobuf enum value: DUPLICATE = 14;
     */
    DUPLICATE = 14,
    /**
     * @generated from protobuf enum value: MULTIPLE_MATCHES = 15;
     */
    MULTIPLE_MATCHES = 15,
    /**
     * @generated from protobuf enum value: NOT_FOUND = 16;
     */
    NOT_FOUND = 16,
    /**
     * @generated from protobuf enum value: DELETED = 17;
     */
    DELETED = 17,
    /**
     * @generated from protobuf enum value: TOO_LONG = 18;
     */
    TOO_LONG = 18,
    /**
     * @generated from protobuf enum value: CODE_INVALID = 19;
     */
    CODE_INVALID = 19,
    /**
     * @generated from protobuf enum value: EXTENSION = 20;
     */
    EXTENSION = 20,
    /**
     * @generated from protobuf enum value: TOO_COSTLY = 21;
     */
    TOO_COSTLY = 21,
    /**
     * @generated from protobuf enum value: BUSINESS_RULE = 22;
     */
    BUSINESS_RULE = 22,
    /**
     * @generated from protobuf enum value: CONFLICT = 23;
     */
    CONFLICT = 23,
    /**
     * @generated from protobuf enum value: TRANSIENT = 24;
     */
    TRANSIENT = 24,
    /**
     * @generated from protobuf enum value: LOCK_ERROR = 25;
     */
    LOCK_ERROR = 25,
    /**
     * @generated from protobuf enum value: NO_STORE = 26;
     */
    NO_STORE = 26,
    /**
     * @generated from protobuf enum value: EXCEPTION = 27;
     */
    EXCEPTION = 27,
    /**
     * @generated from protobuf enum value: TIMEOUT = 28;
     */
    TIMEOUT = 28,
    /**
     * @generated from protobuf enum value: INCOMPLETE = 29;
     */
    INCOMPLETE = 29,
    /**
     * @generated from protobuf enum value: THROTTLED = 30;
     */
    THROTTLED = 30,
    /**
     * @generated from protobuf enum value: INFORMATIONAL = 31;
     */
    INFORMATIONAL = 31
}
/**
 * The type of link between this patient resource and another patient resource.
 * See http://hl7.org/fhir/link-type
 *
 * @generated from protobuf message google.fhir.r4.core.LinkTypeCode
 */
export interface LinkTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.LinkTypeCode.Value
 */
export enum LinkTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REPLACED_BY = 1;
     */
    REPLACED_BY = 1,
    /**
     * @generated from protobuf enum value: REPLACES = 2;
     */
    REPLACES = 2,
    /**
     * @generated from protobuf enum value: REFER = 3;
     */
    REFER = 3,
    /**
     * @generated from protobuf enum value: SEEALSO = 4;
     */
    SEEALSO = 4
}
/**
 * Used to distinguish different roles a resource can play within a set of
 * linked resources. See http://hl7.org/fhir/linkage-type
 *
 * @generated from protobuf message google.fhir.r4.core.LinkageTypeCode
 */
export interface LinkageTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.LinkageTypeCode.Value
 */
export enum LinkageTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SOURCE = 1;
     */
    SOURCE = 1,
    /**
     * @generated from protobuf enum value: ALTERNATE = 2;
     */
    ALTERNATE = 2,
    /**
     * @generated from protobuf enum value: HISTORICAL = 3;
     */
    HISTORICAL = 3
}
/**
 * The processing mode that applies to this list.
 * See http://hl7.org/fhir/list-mode
 *
 * @generated from protobuf message google.fhir.r4.core.ListModeCode
 */
export interface ListModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ListModeCode.Value
 */
export enum ListModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: WORKING = 1;
     */
    WORKING = 1,
    /**
     * @generated from protobuf enum value: SNAPSHOT = 2;
     */
    SNAPSHOT = 2,
    /**
     * @generated from protobuf enum value: CHANGES = 3;
     */
    CHANGES = 3
}
/**
 * The current state of the list.
 * See http://hl7.org/fhir/list-status
 *
 * @generated from protobuf message google.fhir.r4.core.ListStatusCode
 */
export interface ListStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ListStatusCode.Value
 */
export enum ListStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CURRENT = 1;
     */
    CURRENT = 1,
    /**
     * @generated from protobuf enum value: RETIRED = 2;
     */
    RETIRED = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3
}
/**
 * Indicates whether a resource instance represents a specific location or a
 * class of locations. See http://hl7.org/fhir/location-mode
 *
 * @generated from protobuf message google.fhir.r4.core.LocationModeCode
 */
export interface LocationModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.LocationModeCode.Value
 */
export enum LocationModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: INSTANCE = 1;
     */
    INSTANCE = 1,
    /**
     * @generated from protobuf enum value: KIND = 2;
     */
    KIND = 2
}
/**
 * Indicates whether the location is still in use.
 * See http://hl7.org/fhir/location-status
 *
 * @generated from protobuf message google.fhir.r4.core.LocationStatusCode
 */
export interface LocationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.LocationStatusCode.Value
 */
export enum LocationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: SUSPENDED = 2;
     */
    SUSPENDED = 2,
    /**
     * @generated from protobuf enum value: INACTIVE = 3;
     */
    INACTIVE = 3
}
/**
 * A Master Patient Index (MPI) assessment of whether a candidate patient record
 * is a match or not. See http://terminology.hl7.org/CodeSystem/match-grade
 *
 * @generated from protobuf message google.fhir.r4.core.MatchGradeCode
 */
export interface MatchGradeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MatchGradeCode.Value
 */
export enum MatchGradeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CERTAIN = 1;
     */
    CERTAIN = 1,
    /**
     * @generated from protobuf enum value: PROBABLE = 2;
     */
    PROBABLE = 2,
    /**
     * @generated from protobuf enum value: POSSIBLE = 3;
     */
    POSSIBLE = 3,
    /**
     * @generated from protobuf enum value: CERTAINLY_NOT = 4;
     */
    CERTAINLY_NOT = 4
}
/**
 * Observation values that indicate what change in a measurement value or score
 * is indicative of an improvement in the measured item or scored issue. See
 * http://terminology.hl7.org/CodeSystem/measure-improvement-notation
 *
 * @generated from protobuf message google.fhir.r4.core.MeasureImprovementNotationCode
 */
export interface MeasureImprovementNotationCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MeasureImprovementNotationCode.Value
 */
export enum MeasureImprovementNotationCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: INCREASE = 1;
     */
    INCREASE = 1,
    /**
     * @generated from protobuf enum value: DECREASE = 2;
     */
    DECREASE = 2
}
/**
 * The status of the measure report.
 * See http://hl7.org/fhir/measure-report-status
 *
 * @generated from protobuf message google.fhir.r4.core.MeasureReportStatusCode
 */
export interface MeasureReportStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MeasureReportStatusCode.Value
 */
export enum MeasureReportStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: COMPLETE = 1;
     */
    COMPLETE = 1,
    /**
     * @generated from protobuf enum value: PENDING = 2;
     */
    PENDING = 2,
    /**
     * @generated from protobuf enum value: ERROR = 3;
     */
    ERROR = 3
}
/**
 * The type of the measure report.
 * See http://hl7.org/fhir/measure-report-type
 *
 * @generated from protobuf message google.fhir.r4.core.MeasureReportTypeCode
 */
export interface MeasureReportTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MeasureReportTypeCode.Value
 */
export enum MeasureReportTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: INDIVIDUAL = 1;
     */
    INDIVIDUAL = 1,
    /**
     * @generated from protobuf enum value: SUBJECT_LIST = 2;
     */
    SUBJECT_LIST = 2,
    /**
     * @generated from protobuf enum value: SUMMARY = 3;
     */
    SUMMARY = 3,
    /**
     * @generated from protobuf enum value: DATA_COLLECTION = 4;
     */
    DATA_COLLECTION = 4
}
/**
 * MedicationAdministration Status Codes
 * See http://terminology.hl7.org/CodeSystem/medication-admin-status
 *
 * @generated from protobuf message google.fhir.r4.core.MedicationAdministrationStatusCode
 */
export interface MedicationAdministrationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MedicationAdministrationStatusCode.Value
 */
export enum MedicationAdministrationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 1;
     */
    IN_PROGRESS = 1,
    /**
     * @generated from protobuf enum value: NOT_DONE = 2;
     */
    NOT_DONE = 2,
    /**
     * @generated from protobuf enum value: ON_HOLD = 3;
     */
    ON_HOLD = 3,
    /**
     * @generated from protobuf enum value: COMPLETED = 4;
     */
    COMPLETED = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5,
    /**
     * @generated from protobuf enum value: STOPPED = 6;
     */
    STOPPED = 6,
    /**
     * @generated from protobuf enum value: UNKNOWN = 7;
     */
    UNKNOWN = 7
}
/**
 * MedicationDispense Status Codes
 * See http://terminology.hl7.org/CodeSystem/medicationdispense-status
 *
 * @generated from protobuf message google.fhir.r4.core.MedicationDispenseStatusCode
 */
export interface MedicationDispenseStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MedicationDispenseStatusCode.Value
 */
export enum MedicationDispenseStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PREPARATION = 1;
     */
    PREPARATION = 1,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 2;
     */
    IN_PROGRESS = 2,
    /**
     * @generated from protobuf enum value: CANCELLED = 3;
     */
    CANCELLED = 3,
    /**
     * @generated from protobuf enum value: ON_HOLD = 4;
     */
    ON_HOLD = 4,
    /**
     * @generated from protobuf enum value: COMPLETED = 5;
     */
    COMPLETED = 5,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 6;
     */
    ENTERED_IN_ERROR = 6,
    /**
     * @generated from protobuf enum value: STOPPED = 7;
     */
    STOPPED = 7,
    /**
     * @generated from protobuf enum value: DECLINED = 8;
     */
    DECLINED = 8,
    /**
     * @generated from protobuf enum value: UNKNOWN = 9;
     */
    UNKNOWN = 9
}
/**
 * MedicationKnowledge Status Codes
 * See http://terminology.hl7.org/CodeSystem/medicationknowledge-status
 *
 * @generated from protobuf message google.fhir.r4.core.MedicationKnowledgeStatusCode
 */
export interface MedicationKnowledgeStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MedicationKnowledgeStatusCode.Value
 */
export enum MedicationKnowledgeStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: INACTIVE = 2;
     */
    INACTIVE = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3
}
/**
 * MedicationRequest Intent Codes
 * See http://hl7.org/fhir/CodeSystem/medicationrequest-intent
 *
 * @generated from protobuf message google.fhir.r4.core.MedicationRequestIntentCode
 */
export interface MedicationRequestIntentCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MedicationRequestIntentCode.Value
 */
export enum MedicationRequestIntentCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PROPOSAL = 1;
     */
    PROPOSAL = 1,
    /**
     * @generated from protobuf enum value: PLAN = 2;
     */
    PLAN = 2,
    /**
     * @generated from protobuf enum value: ORDER = 3;
     */
    ORDER = 3,
    /**
     * @generated from protobuf enum value: ORIGINAL_ORDER = 4;
     */
    ORIGINAL_ORDER = 4,
    /**
     * @generated from protobuf enum value: REFLEX_ORDER = 5;
     */
    REFLEX_ORDER = 5,
    /**
     * @generated from protobuf enum value: FILLER_ORDER = 6;
     */
    FILLER_ORDER = 6,
    /**
     * @generated from protobuf enum value: INSTANCE_ORDER = 7;
     */
    INSTANCE_ORDER = 7,
    /**
     * @generated from protobuf enum value: OPTION = 8;
     */
    OPTION = 8
}
/**
 * Medication Status Codes
 * See http://hl7.org/fhir/CodeSystem/medication-statement-status
 *
 * @generated from protobuf message google.fhir.r4.core.MedicationStatementStatusCodes
 */
export interface MedicationStatementStatusCodes {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MedicationStatementStatusCodes.Value
 */
export enum MedicationStatementStatusCodes_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: COMPLETED = 2;
     */
    COMPLETED = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3,
    /**
     * @generated from protobuf enum value: INTENDED = 4;
     */
    INTENDED = 4,
    /**
     * @generated from protobuf enum value: STOPPED = 5;
     */
    STOPPED = 5,
    /**
     * @generated from protobuf enum value: ON_HOLD = 6;
     */
    ON_HOLD = 6,
    /**
     * @generated from protobuf enum value: UNKNOWN = 7;
     */
    UNKNOWN = 7,
    /**
     * @generated from protobuf enum value: NOT_TAKEN = 8;
     */
    NOT_TAKEN = 8
}
/**
 * Medication Status Codes
 * See http://hl7.org/fhir/CodeSystem/medication-status
 *
 * @generated from protobuf message google.fhir.r4.core.MedicationStatusCode
 */
export interface MedicationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MedicationStatusCode.Value
 */
export enum MedicationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: INACTIVE = 2;
     */
    INACTIVE = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3
}
/**
 * MedicationRequest Status Codes
 * See http://hl7.org/fhir/CodeSystem/medicationrequest-status
 *
 * @generated from protobuf message google.fhir.r4.core.MedicationrequestStatusCode
 */
export interface MedicationrequestStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MedicationrequestStatusCode.Value
 */
export enum MedicationrequestStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: ON_HOLD = 2;
     */
    ON_HOLD = 2,
    /**
     * @generated from protobuf enum value: CANCELLED = 3;
     */
    CANCELLED = 3,
    /**
     * @generated from protobuf enum value: COMPLETED = 4;
     */
    COMPLETED = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5,
    /**
     * @generated from protobuf enum value: STOPPED = 6;
     */
    STOPPED = 6,
    /**
     * @generated from protobuf enum value: DRAFT = 7;
     */
    DRAFT = 7,
    /**
     * @generated from protobuf enum value: UNKNOWN = 8;
     */
    UNKNOWN = 8
}
/**
 * The impact of the content of a message.
 * See http://hl7.org/fhir/message-significance-category
 *
 * @generated from protobuf message google.fhir.r4.core.MessageSignificanceCategoryCode
 */
export interface MessageSignificanceCategoryCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MessageSignificanceCategoryCode.Value
 */
export enum MessageSignificanceCategoryCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CONSEQUENCE = 1;
     */
    CONSEQUENCE = 1,
    /**
     * @generated from protobuf enum value: CURRENCY = 2;
     */
    CURRENCY = 2,
    /**
     * @generated from protobuf enum value: NOTIFICATION = 3;
     */
    NOTIFICATION = 3
}
/**
 * HL7-defined table of codes which identify conditions under which
 * acknowledgments are required to be returned in response to a message. See
 * http://hl7.org/fhir/messageheader-response-request
 *
 * @generated from protobuf message google.fhir.r4.core.MessageheaderResponseRequestCode
 */
export interface MessageheaderResponseRequestCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.MessageheaderResponseRequestCode.Value
 */
export enum MessageheaderResponseRequestCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ALWAYS = 1;
     */
    ALWAYS = 1,
    /**
     * @generated from protobuf enum value: ON_ERROR = 2;
     */
    ON_ERROR = 2,
    /**
     * @generated from protobuf enum value: NEVER = 3;
     */
    NEVER = 3,
    /**
     * @generated from protobuf enum value: ON_SUCCESS = 4;
     */
    ON_SUCCESS = 4
}
/**
 * The use of a human name.
 * See http://hl7.org/fhir/name-use
 *
 * @generated from protobuf message google.fhir.r4.core.NameUseCode
 */
export interface NameUseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.NameUseCode.Value
 */
export enum NameUseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: USUAL = 1;
     */
    USUAL = 1,
    /**
     * @generated from protobuf enum value: OFFICIAL = 2;
     */
    OFFICIAL = 2,
    /**
     * @generated from protobuf enum value: TEMP = 3;
     */
    TEMP = 3,
    /**
     * @generated from protobuf enum value: NICKNAME = 4;
     */
    NICKNAME = 4,
    /**
     * @generated from protobuf enum value: ANONYMOUS = 5;
     */
    ANONYMOUS = 5,
    /**
     * @generated from protobuf enum value: OLD = 6;
     */
    OLD = 6,
    /**
     * @generated from protobuf enum value: MAIDEN = 7;
     */
    MAIDEN = 7
}
/**
 * Identifies the style of unique identifier used to identify a namespace.
 * See http://hl7.org/fhir/namingsystem-identifier-type
 *
 * @generated from protobuf message google.fhir.r4.core.NamingSystemIdentifierTypeCode
 */
export interface NamingSystemIdentifierTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.NamingSystemIdentifierTypeCode.Value
 */
export enum NamingSystemIdentifierTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: OID = 1;
     */
    OID = 1,
    /**
     * @generated from protobuf enum value: UUID = 2;
     */
    UUID = 2,
    /**
     * @generated from protobuf enum value: URI = 3;
     */
    URI = 3,
    /**
     * @generated from protobuf enum value: OTHER = 4;
     */
    OTHER = 4
}
/**
 * Identifies the purpose of the naming system.
 * See http://hl7.org/fhir/namingsystem-type
 *
 * @generated from protobuf message google.fhir.r4.core.NamingSystemTypeCode
 */
export interface NamingSystemTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.NamingSystemTypeCode.Value
 */
export enum NamingSystemTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CODESYSTEM = 1;
     */
    CODESYSTEM = 1,
    /**
     * @generated from protobuf enum value: IDENTIFIER = 2;
     */
    IDENTIFIER = 2,
    /**
     * @generated from protobuf enum value: ROOT = 3;
     */
    ROOT = 3
}
/**
 * The status of a resource narrative.
 * See http://hl7.org/fhir/narrative-status
 *
 * @generated from protobuf message google.fhir.r4.core.NarrativeStatusCode
 */
export interface NarrativeStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.NarrativeStatusCode.Value
 */
export enum NarrativeStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: GENERATED = 1;
     */
    GENERATED = 1,
    /**
     * @generated from protobuf enum value: EXTENSIONS = 2;
     */
    EXTENSIONS = 2,
    /**
     * @generated from protobuf enum value: ADDITIONAL = 3;
     */
    ADDITIONAL = 3,
    /**
     * @generated from protobuf enum value: EMPTY = 4;
     */
    EMPTY = 4
}
/**
 * The presentation types of notes.
 * See http://hl7.org/fhir/note-type
 *
 * @generated from protobuf message google.fhir.r4.core.NoteTypeCode
 */
export interface NoteTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.NoteTypeCode.Value
 */
export enum NoteTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DISPLAY = 1;
     */
    DISPLAY = 1,
    /**
     * @generated from protobuf enum value: PRINT = 2;
     */
    PRINT = 2,
    /**
     * @generated from protobuf enum value: PRINTOPER = 3;
     */
    PRINTOPER = 3
}
/**
 * Permitted data type for observation value.
 * See http://hl7.org/fhir/permitted-data-type
 *
 * @generated from protobuf message google.fhir.r4.core.ObservationDataTypeCode
 */
export interface ObservationDataTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ObservationDataTypeCode.Value
 */
export enum ObservationDataTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: QUANTITY = 1;
     */
    QUANTITY = 1,
    /**
     * @generated from protobuf enum value: CODEABLE_CONCEPT = 2;
     */
    CODEABLE_CONCEPT = 2,
    /**
     * @generated from protobuf enum value: STRING = 3;
     */
    STRING = 3,
    /**
     * @generated from protobuf enum value: BOOLEAN = 4;
     */
    BOOLEAN = 4,
    /**
     * @generated from protobuf enum value: INTEGER = 5;
     */
    INTEGER = 5,
    /**
     * @generated from protobuf enum value: RANGE = 6;
     */
    RANGE = 6,
    /**
     * @generated from protobuf enum value: RATIO = 7;
     */
    RATIO = 7,
    /**
     * @generated from protobuf enum value: SAMPLED_DATA = 8;
     */
    SAMPLED_DATA = 8,
    /**
     * @generated from protobuf enum value: TIME = 9;
     */
    TIME = 9,
    /**
     * @generated from protobuf enum value: DATE_TIME = 10;
     */
    DATE_TIME = 10,
    /**
     * @generated from protobuf enum value: PERIOD = 11;
     */
    PERIOD = 11
}
/**
 * Codes identifying the category of observation range.
 * See http://hl7.org/fhir/observation-range-category
 *
 * @generated from protobuf message google.fhir.r4.core.ObservationRangeCategoryCode
 */
export interface ObservationRangeCategoryCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ObservationRangeCategoryCode.Value
 */
export enum ObservationRangeCategoryCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REFERENCE = 1;
     */
    REFERENCE = 1,
    /**
     * @generated from protobuf enum value: CRITICAL = 2;
     */
    CRITICAL = 2,
    /**
     * @generated from protobuf enum value: ABSOLUTE = 3;
     */
    ABSOLUTE = 3
}
/**
 * Codes providing the status of an observation.
 * See http://hl7.org/fhir/observation-status
 *
 * @generated from protobuf message google.fhir.r4.core.ObservationStatusCode
 */
export interface ObservationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ObservationStatusCode.Value
 */
export enum ObservationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REGISTERED = 1;
     */
    REGISTERED = 1,
    /**
     * @generated from protobuf enum value: PRELIMINARY = 2;
     */
    PRELIMINARY = 2,
    /**
     * @generated from protobuf enum value: FINAL = 3;
     */
    FINAL = 3,
    /**
     * @generated from protobuf enum value: AMENDED = 4;
     */
    AMENDED = 4,
    /**
     * @generated from protobuf enum value: CORRECTED = 5;
     */
    CORRECTED = 5,
    /**
     * @generated from protobuf enum value: CANCELLED = 6;
     */
    CANCELLED = 6,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 7;
     */
    ENTERED_IN_ERROR = 7,
    /**
     * @generated from protobuf enum value: UNKNOWN = 8;
     */
    UNKNOWN = 8
}
/**
 * Whether an operation is a normal operation or a query.
 * See http://hl7.org/fhir/operation-kind
 *
 * @generated from protobuf message google.fhir.r4.core.OperationKindCode
 */
export interface OperationKindCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.OperationKindCode.Value
 */
export enum OperationKindCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: OPERATION = 1;
     */
    OPERATION = 1,
    /**
     * @generated from protobuf enum value: QUERY = 2;
     */
    QUERY = 2
}
/**
 * Whether an operation parameter is an input or an output parameter.
 * See http://hl7.org/fhir/operation-parameter-use
 *
 * @generated from protobuf message google.fhir.r4.core.OperationParameterUseCode
 */
export interface OperationParameterUseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.OperationParameterUseCode.Value
 */
export enum OperationParameterUseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: IN = 1;
     */
    IN = 1,
    /**
     * @generated from protobuf enum value: OUT = 2;
     */
    OUT = 2
}
/**
 * Type for orientation.
 * See http://hl7.org/fhir/orientation-type
 *
 * @generated from protobuf message google.fhir.r4.core.OrientationTypeCode
 */
export interface OrientationTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.OrientationTypeCode.Value
 */
export enum OrientationTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SENSE = 1;
     */
    SENSE = 1,
    /**
     * @generated from protobuf enum value: ANTISENSE = 2;
     */
    ANTISENSE = 2
}
/**
 * Is the Participant required to attend the appointment.
 * See http://hl7.org/fhir/participantrequired
 *
 * @generated from protobuf message google.fhir.r4.core.ParticipantRequiredCode
 */
export interface ParticipantRequiredCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ParticipantRequiredCode.Value
 */
export enum ParticipantRequiredCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REQUIRED = 1;
     */
    REQUIRED = 1,
    /**
     * @generated from protobuf enum value: OPTIONAL = 2;
     */
    OPTIONAL = 2,
    /**
     * @generated from protobuf enum value: INFORMATION_ONLY = 3;
     */
    INFORMATION_ONLY = 3
}
/**
 * The Participation status of an appointment.
 * See http://hl7.org/fhir/participationstatus
 *
 * @generated from protobuf message google.fhir.r4.core.ParticipationStatusCode
 */
export interface ParticipationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ParticipationStatusCode.Value
 */
export enum ParticipationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACCEPTED = 1;
     */
    ACCEPTED = 1,
    /**
     * @generated from protobuf enum value: DECLINED = 2;
     */
    DECLINED = 2,
    /**
     * @generated from protobuf enum value: TENTATIVE = 3;
     */
    TENTATIVE = 3,
    /**
     * @generated from protobuf enum value: NEEDS_ACTION = 4;
     */
    NEEDS_ACTION = 4
}
/**
 * How a property is represented when serialized.
 * See http://hl7.org/fhir/property-representation
 *
 * @generated from protobuf message google.fhir.r4.core.PropertyRepresentationCode
 */
export interface PropertyRepresentationCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.PropertyRepresentationCode.Value
 */
export enum PropertyRepresentationCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: XML_ATTR = 1;
     */
    XML_ATTR = 1,
    /**
     * @generated from protobuf enum value: XML_TEXT = 2;
     */
    XML_TEXT = 2,
    /**
     * @generated from protobuf enum value: TYPE_ATTR = 3;
     */
    TYPE_ATTR = 3,
    /**
     * @generated from protobuf enum value: CDA_TEXT = 4;
     */
    CDA_TEXT = 4,
    /**
     * @generated from protobuf enum value: XHTML = 5;
     */
    XHTML = 5
}
/**
 * The type of a property value.
 * See http://hl7.org/fhir/concept-property-type
 *
 * @generated from protobuf message google.fhir.r4.core.PropertyTypeCode
 */
export interface PropertyTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.PropertyTypeCode.Value
 */
export enum PropertyTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CODE = 1;
     */
    CODE = 1,
    /**
     * @generated from protobuf enum value: CODING = 2;
     */
    CODING = 2,
    /**
     * @generated from protobuf enum value: STRING = 3;
     */
    STRING = 3,
    /**
     * @generated from protobuf enum value: INTEGER = 4;
     */
    INTEGER = 4,
    /**
     * @generated from protobuf enum value: BOOLEAN = 5;
     */
    BOOLEAN = 5,
    /**
     * @generated from protobuf enum value: DATE_TIME = 6;
     */
    DATE_TIME = 6,
    /**
     * @generated from protobuf enum value: DECIMAL = 7;
     */
    DECIMAL = 7
}
/**
 * How an entity was used in an activity.
 * See http://hl7.org/fhir/provenance-entity-role
 *
 * @generated from protobuf message google.fhir.r4.core.ProvenanceEntityRoleCode
 */
export interface ProvenanceEntityRoleCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ProvenanceEntityRoleCode.Value
 */
export enum ProvenanceEntityRoleCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DERIVATION = 1;
     */
    DERIVATION = 1,
    /**
     * @generated from protobuf enum value: REVISION = 2;
     */
    REVISION = 2,
    /**
     * @generated from protobuf enum value: QUOTATION = 3;
     */
    QUOTATION = 3,
    /**
     * @generated from protobuf enum value: SOURCE = 4;
     */
    SOURCE = 4,
    /**
     * @generated from protobuf enum value: REMOVAL = 5;
     */
    REMOVAL = 5
}
/**
 * The lifecycle status of an artifact.
 * See http://hl7.org/fhir/publication-status
 *
 * @generated from protobuf message google.fhir.r4.core.PublicationStatusCode
 */
export interface PublicationStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.PublicationStatusCode.Value
 */
export enum PublicationStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: ACTIVE = 2;
     */
    ACTIVE = 2,
    /**
     * @generated from protobuf enum value: RETIRED = 3;
     */
    RETIRED = 3,
    /**
     * @generated from protobuf enum value: UNKNOWN = 4;
     */
    UNKNOWN = 4
}
/**
 * Type for quality report.
 * See http://hl7.org/fhir/quality-type
 *
 * @generated from protobuf message google.fhir.r4.core.QualityTypeCode
 */
export interface QualityTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.QualityTypeCode.Value
 */
export enum QualityTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: INDEL = 1;
     */
    INDEL = 1,
    /**
     * @generated from protobuf enum value: SNP = 2;
     */
    SNP = 2,
    /**
     * @generated from protobuf enum value: UNKNOWN = 3;
     */
    UNKNOWN = 3
}
/**
 * How the Quantity should be understood and represented.
 * See http://hl7.org/fhir/quantity-comparator
 *
 * @generated from protobuf message google.fhir.r4.core.QuantityComparatorCode
 */
export interface QuantityComparatorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.QuantityComparatorCode.Value
 */
export enum QuantityComparatorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: LESS_THAN = 1;
     */
    LESS_THAN = 1,
    /**
     * @generated from protobuf enum value: LESS_THAN_OR_EQUAL_TO = 2;
     */
    LESS_THAN_OR_EQUAL_TO = 2,
    /**
     * @generated from protobuf enum value: GREATER_THAN_OR_EQUAL_TO = 3;
     */
    GREATER_THAN_OR_EQUAL_TO = 3,
    /**
     * @generated from protobuf enum value: GREATER_THAN = 4;
     */
    GREATER_THAN = 4
}
/**
 * The criteria by which a question is enabled.
 * See http://hl7.org/fhir/questionnaire-enable-operator
 *
 * @generated from protobuf message google.fhir.r4.core.QuestionnaireItemOperatorCode
 */
export interface QuestionnaireItemOperatorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.QuestionnaireItemOperatorCode.Value
 */
export enum QuestionnaireItemOperatorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: EXISTS = 1;
     */
    EXISTS = 1,
    /**
     * @generated from protobuf enum value: EQUALS = 2;
     */
    EQUALS = 2,
    /**
     * @generated from protobuf enum value: NOT_EQUAL_TO = 3;
     */
    NOT_EQUAL_TO = 3,
    /**
     * @generated from protobuf enum value: GREATER_THAN = 4;
     */
    GREATER_THAN = 4,
    /**
     * @generated from protobuf enum value: LESS_THAN = 5;
     */
    LESS_THAN = 5,
    /**
     * @generated from protobuf enum value: GREATER_THAN_OR_EQUAL_TO = 6;
     */
    GREATER_THAN_OR_EQUAL_TO = 6,
    /**
     * @generated from protobuf enum value: LESS_THAN_OR_EQUAL_TO = 7;
     */
    LESS_THAN_OR_EQUAL_TO = 7
}
/**
 * Distinguishes groups from questions and display text and indicates data type
 * for questions. See http://hl7.org/fhir/item-type
 *
 * @generated from protobuf message google.fhir.r4.core.QuestionnaireItemTypeCode
 */
export interface QuestionnaireItemTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.QuestionnaireItemTypeCode.Value
 */
export enum QuestionnaireItemTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: GROUP = 1;
     */
    GROUP = 1,
    /**
     * @generated from protobuf enum value: DISPLAY = 2;
     */
    DISPLAY = 2,
    /**
     * @generated from protobuf enum value: QUESTION = 3;
     */
    QUESTION = 3,
    /**
     * @generated from protobuf enum value: BOOLEAN = 4;
     */
    BOOLEAN = 4,
    /**
     * @generated from protobuf enum value: DECIMAL = 5;
     */
    DECIMAL = 5,
    /**
     * @generated from protobuf enum value: INTEGER = 6;
     */
    INTEGER = 6,
    /**
     * @generated from protobuf enum value: DATE = 7;
     */
    DATE = 7,
    /**
     * @generated from protobuf enum value: DATE_TIME = 8;
     */
    DATE_TIME = 8,
    /**
     * @generated from protobuf enum value: TIME = 9;
     */
    TIME = 9,
    /**
     * @generated from protobuf enum value: STRING = 10;
     */
    STRING = 10,
    /**
     * @generated from protobuf enum value: TEXT = 11;
     */
    TEXT = 11,
    /**
     * @generated from protobuf enum value: URL = 12;
     */
    URL = 12,
    /**
     * @generated from protobuf enum value: CHOICE = 13;
     */
    CHOICE = 13,
    /**
     * @generated from protobuf enum value: OPEN_CHOICE = 14;
     */
    OPEN_CHOICE = 14,
    /**
     * @generated from protobuf enum value: ATTACHMENT = 15;
     */
    ATTACHMENT = 15,
    /**
     * @generated from protobuf enum value: REFERENCE = 16;
     */
    REFERENCE = 16,
    /**
     * @generated from protobuf enum value: QUANTITY = 17;
     */
    QUANTITY = 17
}
/**
 * Identifies the modes of usage of a questionnaire that should enable a
 * particular questionnaire item. See
 * http://terminology.hl7.org/CodeSystem/questionnaire-usage-mode
 *
 * @generated from protobuf message google.fhir.r4.core.QuestionnaireItemUsageModeCode
 */
export interface QuestionnaireItemUsageModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.QuestionnaireItemUsageModeCode.Value
 */
export enum QuestionnaireItemUsageModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CAPTURE_DISPLAY = 1;
     */
    CAPTURE_DISPLAY = 1,
    /**
     * @generated from protobuf enum value: CAPTURE = 2;
     */
    CAPTURE = 2,
    /**
     * @generated from protobuf enum value: DISPLAY = 3;
     */
    DISPLAY = 3,
    /**
     * @generated from protobuf enum value: DISPLAY_NON_EMPTY = 4;
     */
    DISPLAY_NON_EMPTY = 4,
    /**
     * @generated from protobuf enum value: CAPTURE_DISPLAY_NON_EMPTY = 5;
     */
    CAPTURE_DISPLAY_NON_EMPTY = 5
}
/**
 * Lifecycle status of the questionnaire response.
 * See http://hl7.org/fhir/questionnaire-answers-status
 *
 * @generated from protobuf message google.fhir.r4.core.QuestionnaireResponseStatusCode
 */
export interface QuestionnaireResponseStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.QuestionnaireResponseStatusCode.Value
 */
export enum QuestionnaireResponseStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 1;
     */
    IN_PROGRESS = 1,
    /**
     * @generated from protobuf enum value: COMPLETED = 2;
     */
    COMPLETED = 2,
    /**
     * @generated from protobuf enum value: AMENDED = 3;
     */
    AMENDED = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4,
    /**
     * @generated from protobuf enum value: STOPPED = 5;
     */
    STOPPED = 5
}
/**
 * A set of flags that defines how references are supported.
 * See http://hl7.org/fhir/reference-handling-policy
 *
 * @generated from protobuf message google.fhir.r4.core.ReferenceHandlingPolicyCode
 */
export interface ReferenceHandlingPolicyCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ReferenceHandlingPolicyCode.Value
 */
export enum ReferenceHandlingPolicyCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: LITERAL = 1;
     */
    LITERAL = 1,
    /**
     * @generated from protobuf enum value: LOGICAL = 2;
     */
    LOGICAL = 2,
    /**
     * @generated from protobuf enum value: RESOLVES = 3;
     */
    RESOLVES = 3,
    /**
     * @generated from protobuf enum value: ENFORCED = 4;
     */
    ENFORCED = 4,
    /**
     * @generated from protobuf enum value: LOCAL = 5;
     */
    LOCAL = 5
}
/**
 * Whether a reference needs to be version specific or version independent, or
 * whether either can be used. See http://hl7.org/fhir/reference-version-rules
 *
 * @generated from protobuf message google.fhir.r4.core.ReferenceVersionRulesCode
 */
export interface ReferenceVersionRulesCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ReferenceVersionRulesCode.Value
 */
export enum ReferenceVersionRulesCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: EITHER = 1;
     */
    EITHER = 1,
    /**
     * @generated from protobuf enum value: INDEPENDENT = 2;
     */
    INDEPENDENT = 2,
    /**
     * @generated from protobuf enum value: SPECIFIC = 3;
     */
    SPECIFIC = 3
}
/**
 * The type of relationship to the related artifact.
 * See http://hl7.org/fhir/related-artifact-type
 *
 * @generated from protobuf message google.fhir.r4.core.RelatedArtifactTypeCode
 */
export interface RelatedArtifactTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.RelatedArtifactTypeCode.Value
 */
export enum RelatedArtifactTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DOCUMENTATION = 1;
     */
    DOCUMENTATION = 1,
    /**
     * @generated from protobuf enum value: JUSTIFICATION = 2;
     */
    JUSTIFICATION = 2,
    /**
     * @generated from protobuf enum value: CITATION = 3;
     */
    CITATION = 3,
    /**
     * @generated from protobuf enum value: PREDECESSOR = 4;
     */
    PREDECESSOR = 4,
    /**
     * @generated from protobuf enum value: SUCCESSOR = 5;
     */
    SUCCESSOR = 5,
    /**
     * @generated from protobuf enum value: DERIVED_FROM = 6;
     */
    DERIVED_FROM = 6,
    /**
     * @generated from protobuf enum value: DEPENDS_ON = 7;
     */
    DEPENDS_ON = 7,
    /**
     * @generated from protobuf enum value: COMPOSED_OF = 8;
     */
    COMPOSED_OF = 8
}
/**
 * Type for access of external URI.
 * See http://hl7.org/fhir/repository-type
 *
 * @generated from protobuf message google.fhir.r4.core.RepositoryTypeCode
 */
export interface RepositoryTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.RepositoryTypeCode.Value
 */
export enum RepositoryTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DIRECTLINK = 1;
     */
    DIRECTLINK = 1,
    /**
     * @generated from protobuf enum value: OPENAPI = 2;
     */
    OPENAPI = 2,
    /**
     * @generated from protobuf enum value: LOGIN = 3;
     */
    LOGIN = 3,
    /**
     * @generated from protobuf enum value: OAUTH = 4;
     */
    OAUTH = 4,
    /**
     * @generated from protobuf enum value: OTHER = 5;
     */
    OTHER = 5
}
/**
 * Codes indicating the degree of authority/intentionality associated with a
 * request. See http://hl7.org/fhir/request-intent
 *
 * @generated from protobuf message google.fhir.r4.core.RequestIntentCode
 */
export interface RequestIntentCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.RequestIntentCode.Value
 */
export enum RequestIntentCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PROPOSAL = 1;
     */
    PROPOSAL = 1,
    /**
     * @generated from protobuf enum value: PLAN = 2;
     */
    PLAN = 2,
    /**
     * @generated from protobuf enum value: DIRECTIVE = 3;
     */
    DIRECTIVE = 3,
    /**
     * @generated from protobuf enum value: ORDER = 4;
     */
    ORDER = 4,
    /**
     * @generated from protobuf enum value: ORIGINAL_ORDER = 5;
     */
    ORIGINAL_ORDER = 5,
    /**
     * @generated from protobuf enum value: REFLEX_ORDER = 6;
     */
    REFLEX_ORDER = 6,
    /**
     * @generated from protobuf enum value: FILLER_ORDER = 7;
     */
    FILLER_ORDER = 7,
    /**
     * @generated from protobuf enum value: INSTANCE_ORDER = 8;
     */
    INSTANCE_ORDER = 8,
    /**
     * @generated from protobuf enum value: OPTION = 9;
     */
    OPTION = 9
}
/**
 * Identifies the level of importance to be assigned to actioning the request.
 * See http://hl7.org/fhir/request-priority
 *
 * @generated from protobuf message google.fhir.r4.core.RequestPriorityCode
 */
export interface RequestPriorityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.RequestPriorityCode.Value
 */
export enum RequestPriorityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ROUTINE = 1;
     */
    ROUTINE = 1,
    /**
     * @generated from protobuf enum value: URGENT = 2;
     */
    URGENT = 2,
    /**
     * @generated from protobuf enum value: ASAP = 3;
     */
    ASAP = 3,
    /**
     * @generated from protobuf enum value: STAT = 4;
     */
    STAT = 4
}
/**
 * A list of all the request resource types defined in this version of the FHIR
 * specification. See http://hl7.org/fhir/request-resource-types
 *
 * @generated from protobuf message google.fhir.r4.core.RequestResourceTypeCode
 */
export interface RequestResourceTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.RequestResourceTypeCode.Value
 */
export enum RequestResourceTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: APPOINTMENT = 1;
     */
    APPOINTMENT = 1,
    /**
     * @generated from protobuf enum value: APPOINTMENT_RESPONSE = 2;
     */
    APPOINTMENT_RESPONSE = 2,
    /**
     * @generated from protobuf enum value: CARE_PLAN = 3;
     */
    CARE_PLAN = 3,
    /**
     * @generated from protobuf enum value: CLAIM = 4;
     */
    CLAIM = 4,
    /**
     * @generated from protobuf enum value: COMMUNICATION_REQUEST = 5;
     */
    COMMUNICATION_REQUEST = 5,
    /**
     * @generated from protobuf enum value: CONTRACT = 6;
     */
    CONTRACT = 6,
    /**
     * @generated from protobuf enum value: DEVICE_REQUEST = 7;
     */
    DEVICE_REQUEST = 7,
    /**
     * @generated from protobuf enum value: ENROLLMENT_REQUEST = 8;
     */
    ENROLLMENT_REQUEST = 8,
    /**
     * @generated from protobuf enum value: IMMUNIZATION_RECOMMENDATION = 9;
     */
    IMMUNIZATION_RECOMMENDATION = 9,
    /**
     * @generated from protobuf enum value: MEDICATION_REQUEST = 10;
     */
    MEDICATION_REQUEST = 10,
    /**
     * @generated from protobuf enum value: NUTRITION_ORDER = 11;
     */
    NUTRITION_ORDER = 11,
    /**
     * @generated from protobuf enum value: SERVICE_REQUEST = 12;
     */
    SERVICE_REQUEST = 12,
    /**
     * @generated from protobuf enum value: SUPPLY_REQUEST = 13;
     */
    SUPPLY_REQUEST = 13,
    /**
     * @generated from protobuf enum value: TASK = 14;
     */
    TASK = 14,
    /**
     * @generated from protobuf enum value: VISION_PRESCRIPTION = 15;
     */
    VISION_PRESCRIPTION = 15
}
/**
 * Codes identifying the lifecycle stage of a request.
 * See http://hl7.org/fhir/request-status
 *
 * @generated from protobuf message google.fhir.r4.core.RequestStatusCode
 */
export interface RequestStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.RequestStatusCode.Value
 */
export enum RequestStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: ACTIVE = 2;
     */
    ACTIVE = 2,
    /**
     * @generated from protobuf enum value: ON_HOLD = 3;
     */
    ON_HOLD = 3,
    /**
     * @generated from protobuf enum value: REVOKED = 4;
     */
    REVOKED = 4,
    /**
     * @generated from protobuf enum value: COMPLETED = 5;
     */
    COMPLETED = 5,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 6;
     */
    ENTERED_IN_ERROR = 6,
    /**
     * @generated from protobuf enum value: UNKNOWN = 7;
     */
    UNKNOWN = 7
}
/**
 * The possible types of research elements (E.g. Population, Exposure, Outcome).
 * See http://hl7.org/fhir/research-element-type
 *
 * @generated from protobuf message google.fhir.r4.core.ResearchElementTypeCode
 */
export interface ResearchElementTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ResearchElementTypeCode.Value
 */
export enum ResearchElementTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: POPULATION = 1;
     */
    POPULATION = 1,
    /**
     * @generated from protobuf enum value: EXPOSURE = 2;
     */
    EXPOSURE = 2,
    /**
     * @generated from protobuf enum value: OUTCOME = 3;
     */
    OUTCOME = 3
}
/**
 * Codes that convey the current status of the research study.
 * See http://hl7.org/fhir/research-study-status
 *
 * @generated from protobuf message google.fhir.r4.core.ResearchStudyStatusCode
 */
export interface ResearchStudyStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ResearchStudyStatusCode.Value
 */
export enum ResearchStudyStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACTIVE = 1;
     */
    ACTIVE = 1,
    /**
     * @generated from protobuf enum value: ADMINISTRATIVELY_COMPLETED = 2;
     */
    ADMINISTRATIVELY_COMPLETED = 2,
    /**
     * @generated from protobuf enum value: APPROVED = 3;
     */
    APPROVED = 3,
    /**
     * @generated from protobuf enum value: CLOSED_TO_ACCRUAL = 4;
     */
    CLOSED_TO_ACCRUAL = 4,
    /**
     * @generated from protobuf enum value: CLOSED_TO_ACCRUAL_AND_INTERVENTION = 5;
     */
    CLOSED_TO_ACCRUAL_AND_INTERVENTION = 5,
    /**
     * @generated from protobuf enum value: COMPLETED = 6;
     */
    COMPLETED = 6,
    /**
     * @generated from protobuf enum value: DISAPPROVED = 7;
     */
    DISAPPROVED = 7,
    /**
     * @generated from protobuf enum value: IN_REVIEW = 8;
     */
    IN_REVIEW = 8,
    /**
     * @generated from protobuf enum value: TEMPORARILY_CLOSED_TO_ACCRUAL = 9;
     */
    TEMPORARILY_CLOSED_TO_ACCRUAL = 9,
    /**
     * @generated from protobuf enum value: TEMPORARILY_CLOSED_TO_ACCRUAL_AND_INTERVENTION = 10;
     */
    TEMPORARILY_CLOSED_TO_ACCRUAL_AND_INTERVENTION = 10,
    /**
     * @generated from protobuf enum value: WITHDRAWN = 11;
     */
    WITHDRAWN = 11
}
/**
 * Indicates the progression of a study subject through a study.
 * See http://hl7.org/fhir/research-subject-status
 *
 * @generated from protobuf message google.fhir.r4.core.ResearchSubjectStatusCode
 */
export interface ResearchSubjectStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ResearchSubjectStatusCode.Value
 */
export enum ResearchSubjectStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CANDIDATE = 1;
     */
    CANDIDATE = 1,
    /**
     * @generated from protobuf enum value: ELIGIBLE = 2;
     */
    ELIGIBLE = 2,
    /**
     * @generated from protobuf enum value: FOLLOW_UP = 3;
     */
    FOLLOW_UP = 3,
    /**
     * @generated from protobuf enum value: INELIGIBLE = 4;
     */
    INELIGIBLE = 4,
    /**
     * @generated from protobuf enum value: NOT_REGISTERED = 5;
     */
    NOT_REGISTERED = 5,
    /**
     * @generated from protobuf enum value: OFF_STUDY = 6;
     */
    OFF_STUDY = 6,
    /**
     * @generated from protobuf enum value: ON_STUDY = 7;
     */
    ON_STUDY = 7,
    /**
     * @generated from protobuf enum value: ON_STUDY_INTERVENTION = 8;
     */
    ON_STUDY_INTERVENTION = 8,
    /**
     * @generated from protobuf enum value: ON_STUDY_OBSERVATION = 9;
     */
    ON_STUDY_OBSERVATION = 9,
    /**
     * @generated from protobuf enum value: PENDING_ON_STUDY = 10;
     */
    PENDING_ON_STUDY = 10,
    /**
     * @generated from protobuf enum value: POTENTIAL_CANDIDATE = 11;
     */
    POTENTIAL_CANDIDATE = 11,
    /**
     * @generated from protobuf enum value: SCREENING = 12;
     */
    SCREENING = 12,
    /**
     * @generated from protobuf enum value: WITHDRAWN = 13;
     */
    WITHDRAWN = 13
}
/**
 * Provides general guidance around the kind of access Control to Read, Search,
 * Create, Update, or Delete a resource. See
 * http://terminology.hl7.org/CodeSystem/resource-security-category
 *
 * @generated from protobuf message google.fhir.r4.core.ResourceSecurityCategoryCode
 */
export interface ResourceSecurityCategoryCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ResourceSecurityCategoryCode.Value
 */
export enum ResourceSecurityCategoryCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ANONYMOUS = 1;
     */
    ANONYMOUS = 1,
    /**
     * @generated from protobuf enum value: BUSINESS = 2;
     */
    BUSINESS = 2,
    /**
     * @generated from protobuf enum value: INDIVIDUAL = 3;
     */
    INDIVIDUAL = 3,
    /**
     * @generated from protobuf enum value: PATIENT = 4;
     */
    PATIENT = 4,
    /**
     * @generated from protobuf enum value: NOT_CLASSIFIED = 5;
     */
    NOT_CLASSIFIED = 5
}
/**
 * One of the resource types defined as part of this version of FHIR.
 * See http://hl7.org/fhir/resource-types
 *
 * @generated from protobuf message google.fhir.r4.core.ResourceTypeCode
 */
export interface ResourceTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ResourceTypeCode.Value
 */
export enum ResourceTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ACCOUNT = 1;
     */
    ACCOUNT = 1,
    /**
     * @generated from protobuf enum value: ACTIVITY_DEFINITION = 2;
     */
    ACTIVITY_DEFINITION = 2,
    /**
     * @generated from protobuf enum value: ADVERSE_EVENT = 3;
     */
    ADVERSE_EVENT = 3,
    /**
     * @generated from protobuf enum value: ALLERGY_INTOLERANCE = 4;
     */
    ALLERGY_INTOLERANCE = 4,
    /**
     * @generated from protobuf enum value: APPOINTMENT = 5;
     */
    APPOINTMENT = 5,
    /**
     * @generated from protobuf enum value: APPOINTMENT_RESPONSE = 6;
     */
    APPOINTMENT_RESPONSE = 6,
    /**
     * @generated from protobuf enum value: AUDIT_EVENT = 7;
     */
    AUDIT_EVENT = 7,
    /**
     * @generated from protobuf enum value: BASIC = 8;
     */
    BASIC = 8,
    /**
     * @generated from protobuf enum value: BINARY = 9;
     */
    BINARY = 9,
    /**
     * @generated from protobuf enum value: BIOLOGICALLY_DERIVED_PRODUCT = 10;
     */
    BIOLOGICALLY_DERIVED_PRODUCT = 10,
    /**
     * @generated from protobuf enum value: BODY_STRUCTURE = 11;
     */
    BODY_STRUCTURE = 11,
    /**
     * @generated from protobuf enum value: BUNDLE = 12;
     */
    BUNDLE = 12,
    /**
     * @generated from protobuf enum value: CAPABILITY_STATEMENT = 13;
     */
    CAPABILITY_STATEMENT = 13,
    /**
     * @generated from protobuf enum value: CARE_PLAN = 14;
     */
    CARE_PLAN = 14,
    /**
     * @generated from protobuf enum value: CARE_TEAM = 15;
     */
    CARE_TEAM = 15,
    /**
     * @generated from protobuf enum value: CATALOG_ENTRY = 16;
     */
    CATALOG_ENTRY = 16,
    /**
     * @generated from protobuf enum value: CHARGE_ITEM = 17;
     */
    CHARGE_ITEM = 17,
    /**
     * @generated from protobuf enum value: CHARGE_ITEM_DEFINITION = 18;
     */
    CHARGE_ITEM_DEFINITION = 18,
    /**
     * @generated from protobuf enum value: CLAIM = 19;
     */
    CLAIM = 19,
    /**
     * @generated from protobuf enum value: CLAIM_RESPONSE = 20;
     */
    CLAIM_RESPONSE = 20,
    /**
     * @generated from protobuf enum value: CLINICAL_IMPRESSION = 21;
     */
    CLINICAL_IMPRESSION = 21,
    /**
     * @generated from protobuf enum value: CODE_SYSTEM = 22;
     */
    CODE_SYSTEM = 22,
    /**
     * @generated from protobuf enum value: COMMUNICATION = 23;
     */
    COMMUNICATION = 23,
    /**
     * @generated from protobuf enum value: COMMUNICATION_REQUEST = 24;
     */
    COMMUNICATION_REQUEST = 24,
    /**
     * @generated from protobuf enum value: COMPARTMENT_DEFINITION = 25;
     */
    COMPARTMENT_DEFINITION = 25,
    /**
     * @generated from protobuf enum value: COMPOSITION = 26;
     */
    COMPOSITION = 26,
    /**
     * @generated from protobuf enum value: CONCEPT_MAP = 27;
     */
    CONCEPT_MAP = 27,
    /**
     * @generated from protobuf enum value: CONDITION = 28;
     */
    CONDITION = 28,
    /**
     * @generated from protobuf enum value: CONSENT = 29;
     */
    CONSENT = 29,
    /**
     * @generated from protobuf enum value: CONTRACT = 30;
     */
    CONTRACT = 30,
    /**
     * @generated from protobuf enum value: COVERAGE = 31;
     */
    COVERAGE = 31,
    /**
     * @generated from protobuf enum value: COVERAGE_ELIGIBILITY_REQUEST = 32;
     */
    COVERAGE_ELIGIBILITY_REQUEST = 32,
    /**
     * @generated from protobuf enum value: COVERAGE_ELIGIBILITY_RESPONSE = 33;
     */
    COVERAGE_ELIGIBILITY_RESPONSE = 33,
    /**
     * @generated from protobuf enum value: DETECTED_ISSUE = 34;
     */
    DETECTED_ISSUE = 34,
    /**
     * @generated from protobuf enum value: DEVICE = 35;
     */
    DEVICE = 35,
    /**
     * @generated from protobuf enum value: DEVICE_DEFINITION = 36;
     */
    DEVICE_DEFINITION = 36,
    /**
     * @generated from protobuf enum value: DEVICE_METRIC = 37;
     */
    DEVICE_METRIC = 37,
    /**
     * @generated from protobuf enum value: DEVICE_REQUEST = 38;
     */
    DEVICE_REQUEST = 38,
    /**
     * @generated from protobuf enum value: DEVICE_USE_STATEMENT = 39;
     */
    DEVICE_USE_STATEMENT = 39,
    /**
     * @generated from protobuf enum value: DIAGNOSTIC_REPORT = 40;
     */
    DIAGNOSTIC_REPORT = 40,
    /**
     * @generated from protobuf enum value: DOCUMENT_MANIFEST = 41;
     */
    DOCUMENT_MANIFEST = 41,
    /**
     * @generated from protobuf enum value: DOCUMENT_REFERENCE = 42;
     */
    DOCUMENT_REFERENCE = 42,
    /**
     * @generated from protobuf enum value: DOMAIN_RESOURCE = 43;
     */
    DOMAIN_RESOURCE = 43,
    /**
     * @generated from protobuf enum value: EFFECT_EVIDENCE_SYNTHESIS = 44;
     */
    EFFECT_EVIDENCE_SYNTHESIS = 44,
    /**
     * @generated from protobuf enum value: ENCOUNTER = 45;
     */
    ENCOUNTER = 45,
    /**
     * @generated from protobuf enum value: ENDPOINT = 46;
     */
    ENDPOINT = 46,
    /**
     * @generated from protobuf enum value: ENROLLMENT_REQUEST = 47;
     */
    ENROLLMENT_REQUEST = 47,
    /**
     * @generated from protobuf enum value: ENROLLMENT_RESPONSE = 48;
     */
    ENROLLMENT_RESPONSE = 48,
    /**
     * @generated from protobuf enum value: EPISODE_OF_CARE = 49;
     */
    EPISODE_OF_CARE = 49,
    /**
     * @generated from protobuf enum value: EVENT_DEFINITION = 50;
     */
    EVENT_DEFINITION = 50,
    /**
     * @generated from protobuf enum value: EVIDENCE = 51;
     */
    EVIDENCE = 51,
    /**
     * @generated from protobuf enum value: EVIDENCE_VARIABLE = 52;
     */
    EVIDENCE_VARIABLE = 52,
    /**
     * @generated from protobuf enum value: EXAMPLE_SCENARIO = 53;
     */
    EXAMPLE_SCENARIO = 53,
    /**
     * @generated from protobuf enum value: EXPLANATION_OF_BENEFIT = 54;
     */
    EXPLANATION_OF_BENEFIT = 54,
    /**
     * @generated from protobuf enum value: FAMILY_MEMBER_HISTORY = 55;
     */
    FAMILY_MEMBER_HISTORY = 55,
    /**
     * @generated from protobuf enum value: FLAG = 56;
     */
    FLAG = 56,
    /**
     * @generated from protobuf enum value: GOAL = 57;
     */
    GOAL = 57,
    /**
     * @generated from protobuf enum value: GRAPH_DEFINITION = 58;
     */
    GRAPH_DEFINITION = 58,
    /**
     * @generated from protobuf enum value: GROUP = 59;
     */
    GROUP = 59,
    /**
     * @generated from protobuf enum value: GUIDANCE_RESPONSE = 60;
     */
    GUIDANCE_RESPONSE = 60,
    /**
     * @generated from protobuf enum value: HEALTHCARE_SERVICE = 61;
     */
    HEALTHCARE_SERVICE = 61,
    /**
     * @generated from protobuf enum value: IMAGING_STUDY = 62;
     */
    IMAGING_STUDY = 62,
    /**
     * @generated from protobuf enum value: IMMUNIZATION = 63;
     */
    IMMUNIZATION = 63,
    /**
     * @generated from protobuf enum value: IMMUNIZATION_EVALUATION = 64;
     */
    IMMUNIZATION_EVALUATION = 64,
    /**
     * @generated from protobuf enum value: IMMUNIZATION_RECOMMENDATION = 65;
     */
    IMMUNIZATION_RECOMMENDATION = 65,
    /**
     * @generated from protobuf enum value: IMPLEMENTATION_GUIDE = 66;
     */
    IMPLEMENTATION_GUIDE = 66,
    /**
     * @generated from protobuf enum value: INSURANCE_PLAN = 67;
     */
    INSURANCE_PLAN = 67,
    /**
     * @generated from protobuf enum value: INVOICE = 68;
     */
    INVOICE = 68,
    /**
     * @generated from protobuf enum value: LIBRARY = 69;
     */
    LIBRARY = 69,
    /**
     * @generated from protobuf enum value: LINKAGE = 70;
     */
    LINKAGE = 70,
    /**
     * @generated from protobuf enum value: LIST = 71;
     */
    LIST = 71,
    /**
     * @generated from protobuf enum value: LOCATION = 72;
     */
    LOCATION = 72,
    /**
     * @generated from protobuf enum value: MEASURE = 73;
     */
    MEASURE = 73,
    /**
     * @generated from protobuf enum value: MEASURE_REPORT = 74;
     */
    MEASURE_REPORT = 74,
    /**
     * @generated from protobuf enum value: MEDIA = 75;
     */
    MEDIA = 75,
    /**
     * @generated from protobuf enum value: MEDICATION = 76;
     */
    MEDICATION = 76,
    /**
     * @generated from protobuf enum value: MEDICATION_ADMINISTRATION = 77;
     */
    MEDICATION_ADMINISTRATION = 77,
    /**
     * @generated from protobuf enum value: MEDICATION_DISPENSE = 78;
     */
    MEDICATION_DISPENSE = 78,
    /**
     * @generated from protobuf enum value: MEDICATION_KNOWLEDGE = 79;
     */
    MEDICATION_KNOWLEDGE = 79,
    /**
     * @generated from protobuf enum value: MEDICATION_REQUEST = 80;
     */
    MEDICATION_REQUEST = 80,
    /**
     * @generated from protobuf enum value: MEDICATION_STATEMENT = 81;
     */
    MEDICATION_STATEMENT = 81,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT = 82;
     */
    MEDICINAL_PRODUCT = 82,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_AUTHORIZATION = 83;
     */
    MEDICINAL_PRODUCT_AUTHORIZATION = 83,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_CONTRAINDICATION = 84;
     */
    MEDICINAL_PRODUCT_CONTRAINDICATION = 84,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INDICATION = 85;
     */
    MEDICINAL_PRODUCT_INDICATION = 85,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INGREDIENT = 86;
     */
    MEDICINAL_PRODUCT_INGREDIENT = 86,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INTERACTION = 87;
     */
    MEDICINAL_PRODUCT_INTERACTION = 87,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_MANUFACTURED = 88;
     */
    MEDICINAL_PRODUCT_MANUFACTURED = 88,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_PACKAGED = 89;
     */
    MEDICINAL_PRODUCT_PACKAGED = 89,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_PHARMACEUTICAL = 90;
     */
    MEDICINAL_PRODUCT_PHARMACEUTICAL = 90,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_UNDESIRABLE_EFFECT = 91;
     */
    MEDICINAL_PRODUCT_UNDESIRABLE_EFFECT = 91,
    /**
     * @generated from protobuf enum value: MESSAGE_DEFINITION = 92;
     */
    MESSAGE_DEFINITION = 92,
    /**
     * @generated from protobuf enum value: MESSAGE_HEADER = 93;
     */
    MESSAGE_HEADER = 93,
    /**
     * @generated from protobuf enum value: MOLECULAR_SEQUENCE = 94;
     */
    MOLECULAR_SEQUENCE = 94,
    /**
     * @generated from protobuf enum value: NAMING_SYSTEM = 95;
     */
    NAMING_SYSTEM = 95,
    /**
     * @generated from protobuf enum value: NUTRITION_ORDER = 96;
     */
    NUTRITION_ORDER = 96,
    /**
     * @generated from protobuf enum value: OBSERVATION = 97;
     */
    OBSERVATION = 97,
    /**
     * @generated from protobuf enum value: OBSERVATION_DEFINITION = 98;
     */
    OBSERVATION_DEFINITION = 98,
    /**
     * @generated from protobuf enum value: OPERATION_DEFINITION = 99;
     */
    OPERATION_DEFINITION = 99,
    /**
     * @generated from protobuf enum value: OPERATION_OUTCOME = 100;
     */
    OPERATION_OUTCOME = 100,
    /**
     * @generated from protobuf enum value: ORGANIZATION = 101;
     */
    ORGANIZATION = 101,
    /**
     * @generated from protobuf enum value: ORGANIZATION_AFFILIATION = 102;
     */
    ORGANIZATION_AFFILIATION = 102,
    /**
     * @generated from protobuf enum value: PARAMETERS = 103;
     */
    PARAMETERS = 103,
    /**
     * @generated from protobuf enum value: PATIENT = 104;
     */
    PATIENT = 104,
    /**
     * @generated from protobuf enum value: PAYMENT_NOTICE = 105;
     */
    PAYMENT_NOTICE = 105,
    /**
     * @generated from protobuf enum value: PAYMENT_RECONCILIATION = 106;
     */
    PAYMENT_RECONCILIATION = 106,
    /**
     * @generated from protobuf enum value: PERSON = 107;
     */
    PERSON = 107,
    /**
     * @generated from protobuf enum value: PLAN_DEFINITION = 108;
     */
    PLAN_DEFINITION = 108,
    /**
     * @generated from protobuf enum value: PRACTITIONER = 109;
     */
    PRACTITIONER = 109,
    /**
     * @generated from protobuf enum value: PRACTITIONER_ROLE = 110;
     */
    PRACTITIONER_ROLE = 110,
    /**
     * @generated from protobuf enum value: PROCEDURE = 111;
     */
    PROCEDURE = 111,
    /**
     * @generated from protobuf enum value: PROVENANCE = 112;
     */
    PROVENANCE = 112,
    /**
     * @generated from protobuf enum value: QUESTIONNAIRE = 113;
     */
    QUESTIONNAIRE = 113,
    /**
     * @generated from protobuf enum value: QUESTIONNAIRE_RESPONSE = 114;
     */
    QUESTIONNAIRE_RESPONSE = 114,
    /**
     * @generated from protobuf enum value: RELATED_PERSON = 115;
     */
    RELATED_PERSON = 115,
    /**
     * @generated from protobuf enum value: REQUEST_GROUP = 116;
     */
    REQUEST_GROUP = 116,
    /**
     * @generated from protobuf enum value: RESEARCH_DEFINITION = 117;
     */
    RESEARCH_DEFINITION = 117,
    /**
     * @generated from protobuf enum value: RESEARCH_ELEMENT_DEFINITION = 118;
     */
    RESEARCH_ELEMENT_DEFINITION = 118,
    /**
     * @generated from protobuf enum value: RESEARCH_STUDY = 119;
     */
    RESEARCH_STUDY = 119,
    /**
     * @generated from protobuf enum value: RESEARCH_SUBJECT = 120;
     */
    RESEARCH_SUBJECT = 120,
    /**
     * @generated from protobuf enum value: RESOURCE = 121;
     */
    RESOURCE = 121,
    /**
     * @generated from protobuf enum value: RISK_ASSESSMENT = 122;
     */
    RISK_ASSESSMENT = 122,
    /**
     * @generated from protobuf enum value: RISK_EVIDENCE_SYNTHESIS = 123;
     */
    RISK_EVIDENCE_SYNTHESIS = 123,
    /**
     * @generated from protobuf enum value: SCHEDULE = 124;
     */
    SCHEDULE = 124,
    /**
     * @generated from protobuf enum value: SEARCH_PARAMETER = 125;
     */
    SEARCH_PARAMETER = 125,
    /**
     * @generated from protobuf enum value: SERVICE_REQUEST = 126;
     */
    SERVICE_REQUEST = 126,
    /**
     * @generated from protobuf enum value: SLOT = 127;
     */
    SLOT = 127,
    /**
     * @generated from protobuf enum value: SPECIMEN = 128;
     */
    SPECIMEN = 128,
    /**
     * @generated from protobuf enum value: SPECIMEN_DEFINITION = 129;
     */
    SPECIMEN_DEFINITION = 129,
    /**
     * @generated from protobuf enum value: STRUCTURE_DEFINITION = 130;
     */
    STRUCTURE_DEFINITION = 130,
    /**
     * @generated from protobuf enum value: STRUCTURE_MAP = 131;
     */
    STRUCTURE_MAP = 131,
    /**
     * @generated from protobuf enum value: SUBSCRIPTION = 132;
     */
    SUBSCRIPTION = 132,
    /**
     * @generated from protobuf enum value: SUBSTANCE = 133;
     */
    SUBSTANCE = 133,
    /**
     * @generated from protobuf enum value: SUBSTANCE_NUCLEIC_ACID = 134;
     */
    SUBSTANCE_NUCLEIC_ACID = 134,
    /**
     * @generated from protobuf enum value: SUBSTANCE_POLYMER = 135;
     */
    SUBSTANCE_POLYMER = 135,
    /**
     * @generated from protobuf enum value: SUBSTANCE_PROTEIN = 136;
     */
    SUBSTANCE_PROTEIN = 136,
    /**
     * @generated from protobuf enum value: SUBSTANCE_REFERENCE_INFORMATION = 137;
     */
    SUBSTANCE_REFERENCE_INFORMATION = 137,
    /**
     * @generated from protobuf enum value: SUBSTANCE_SOURCE_MATERIAL = 138;
     */
    SUBSTANCE_SOURCE_MATERIAL = 138,
    /**
     * @generated from protobuf enum value: SUBSTANCE_SPECIFICATION = 139;
     */
    SUBSTANCE_SPECIFICATION = 139,
    /**
     * @generated from protobuf enum value: SUPPLY_DELIVERY = 140;
     */
    SUPPLY_DELIVERY = 140,
    /**
     * @generated from protobuf enum value: SUPPLY_REQUEST = 141;
     */
    SUPPLY_REQUEST = 141,
    /**
     * @generated from protobuf enum value: TASK = 142;
     */
    TASK = 142,
    /**
     * @generated from protobuf enum value: TERMINOLOGY_CAPABILITIES = 143;
     */
    TERMINOLOGY_CAPABILITIES = 143,
    /**
     * @generated from protobuf enum value: TEST_REPORT = 144;
     */
    TEST_REPORT = 144,
    /**
     * @generated from protobuf enum value: TEST_SCRIPT = 145;
     */
    TEST_SCRIPT = 145,
    /**
     * @generated from protobuf enum value: VALUE_SET = 146;
     */
    VALUE_SET = 146,
    /**
     * @generated from protobuf enum value: VERIFICATION_RESULT = 147;
     */
    VERIFICATION_RESULT = 147,
    /**
     * @generated from protobuf enum value: VISION_PRESCRIPTION = 148;
     */
    VISION_PRESCRIPTION = 148
}
/**
 * How the system supports versioning for a resource.
 * See http://hl7.org/fhir/versioning-policy
 *
 * @generated from protobuf message google.fhir.r4.core.ResourceVersionPolicyCode
 */
export interface ResourceVersionPolicyCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ResourceVersionPolicyCode.Value
 */
export enum ResourceVersionPolicyCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NO_VERSION = 1;
     */
    NO_VERSION = 1,
    /**
     * @generated from protobuf enum value: VERSIONED = 2;
     */
    VERSIONED = 2,
    /**
     * @generated from protobuf enum value: VERSIONED_UPDATE = 3;
     */
    VERSIONED_UPDATE = 3
}
/**
 * The kind of response to a message.
 * See http://hl7.org/fhir/response-code
 *
 * @generated from protobuf message google.fhir.r4.core.ResponseTypeCode
 */
export interface ResponseTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ResponseTypeCode.Value
 */
export enum ResponseTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: OK = 1;
     */
    OK = 1,
    /**
     * @generated from protobuf enum value: TRANSIENT_ERROR = 2;
     */
    TRANSIENT_ERROR = 2,
    /**
     * @generated from protobuf enum value: FATAL_ERROR = 3;
     */
    FATAL_ERROR = 3
}
/**
 * The mode of a RESTful capability statement.
 * See http://hl7.org/fhir/restful-capability-mode
 *
 * @generated from protobuf message google.fhir.r4.core.RestfulCapabilityModeCode
 */
export interface RestfulCapabilityModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.RestfulCapabilityModeCode.Value
 */
export enum RestfulCapabilityModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CLIENT = 1;
     */
    CLIENT = 1,
    /**
     * @generated from protobuf enum value: SERVER = 2;
     */
    SERVER = 2
}
/**
 * The license that applies to an Implementation Guide (using an SPDX license
 * Identifiers, or 'not-open-source'). The binding is required but new SPDX
 * license Identifiers are allowed to be used (https://spdx.org/licenses/). See
 * http://hl7.org/fhir/spdx-license
 *
 * @generated from protobuf message google.fhir.r4.core.SPDXLicenseCode
 */
export interface SPDXLicenseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SPDXLicenseCode.Value
 */
export enum SPDXLicenseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NOT_OPEN_SOURCE = 1;
     */
    NOT_OPEN_SOURCE = 1,
    /**
     * @generated from protobuf enum value: BSD_ZERO_CLAUSE_LICENSE = 2;
     */
    BSD_ZERO_CLAUSE_LICENSE = 2,
    /**
     * @generated from protobuf enum value: AAL = 3;
     */
    AAL = 3,
    /**
     * @generated from protobuf enum value: ABSTYLES = 4;
     */
    ABSTYLES = 4,
    /**
     * @generated from protobuf enum value: ADOBE_2006 = 5;
     */
    ADOBE_2006 = 5,
    /**
     * @generated from protobuf enum value: ADOBE_GLYPH = 6;
     */
    ADOBE_GLYPH = 6,
    /**
     * @generated from protobuf enum value: ADSL = 7;
     */
    ADSL = 7,
    /**
     * @generated from protobuf enum value: AFL_1_1 = 8;
     */
    AFL_1_1 = 8,
    /**
     * @generated from protobuf enum value: AFL_1_2 = 9;
     */
    AFL_1_2 = 9,
    /**
     * @generated from protobuf enum value: AFL_2_0 = 10;
     */
    AFL_2_0 = 10,
    /**
     * @generated from protobuf enum value: AFL_2_1 = 11;
     */
    AFL_2_1 = 11,
    /**
     * @generated from protobuf enum value: AFL_3_0 = 12;
     */
    AFL_3_0 = 12,
    /**
     * @generated from protobuf enum value: AFMPARSE = 13;
     */
    AFMPARSE = 13,
    /**
     * @generated from protobuf enum value: AGPL_1_0_ONLY = 14;
     */
    AGPL_1_0_ONLY = 14,
    /**
     * @generated from protobuf enum value: AGPL_1_0_OR_LATER = 15;
     */
    AGPL_1_0_OR_LATER = 15,
    /**
     * @generated from protobuf enum value: AGPL_3_0_ONLY = 16;
     */
    AGPL_3_0_ONLY = 16,
    /**
     * @generated from protobuf enum value: AGPL_3_0_OR_LATER = 17;
     */
    AGPL_3_0_OR_LATER = 17,
    /**
     * @generated from protobuf enum value: ALADDIN = 18;
     */
    ALADDIN = 18,
    /**
     * @generated from protobuf enum value: AMDPLPA = 19;
     */
    AMDPLPA = 19,
    /**
     * @generated from protobuf enum value: AML = 20;
     */
    AML = 20,
    /**
     * @generated from protobuf enum value: AMPAS = 21;
     */
    AMPAS = 21,
    /**
     * @generated from protobuf enum value: ANTLR_PD = 22;
     */
    ANTLR_PD = 22,
    /**
     * @generated from protobuf enum value: APACHE_1_0 = 23;
     */
    APACHE_1_0 = 23,
    /**
     * @generated from protobuf enum value: APACHE_1_1 = 24;
     */
    APACHE_1_1 = 24,
    /**
     * @generated from protobuf enum value: APACHE_2_0 = 25;
     */
    APACHE_2_0 = 25,
    /**
     * @generated from protobuf enum value: APAFML = 26;
     */
    APAFML = 26,
    /**
     * @generated from protobuf enum value: APL_1_0 = 27;
     */
    APL_1_0 = 27,
    /**
     * @generated from protobuf enum value: APSL_1_0 = 28;
     */
    APSL_1_0 = 28,
    /**
     * @generated from protobuf enum value: APSL_1_1 = 29;
     */
    APSL_1_1 = 29,
    /**
     * @generated from protobuf enum value: APSL_1_2 = 30;
     */
    APSL_1_2 = 30,
    /**
     * @generated from protobuf enum value: APSL_2_0 = 31;
     */
    APSL_2_0 = 31,
    /**
     * @generated from protobuf enum value: ARTISTIC_1_0_CL8 = 32;
     */
    ARTISTIC_1_0_CL8 = 32,
    /**
     * @generated from protobuf enum value: ARTISTIC_1_0_PERL = 33;
     */
    ARTISTIC_1_0_PERL = 33,
    /**
     * @generated from protobuf enum value: ARTISTIC_1_0 = 34;
     */
    ARTISTIC_1_0 = 34,
    /**
     * @generated from protobuf enum value: ARTISTIC_2_0 = 35;
     */
    ARTISTIC_2_0 = 35,
    /**
     * @generated from protobuf enum value: BAHYPH = 36;
     */
    BAHYPH = 36,
    /**
     * @generated from protobuf enum value: BARR = 37;
     */
    BARR = 37,
    /**
     * @generated from protobuf enum value: BEERWARE = 38;
     */
    BEERWARE = 38,
    /**
     * @generated from protobuf enum value: BIT_TORRENT_1_0 = 39;
     */
    BIT_TORRENT_1_0 = 39,
    /**
     * @generated from protobuf enum value: BIT_TORRENT_1_1 = 40;
     */
    BIT_TORRENT_1_1 = 40,
    /**
     * @generated from protobuf enum value: BORCEUX = 41;
     */
    BORCEUX = 41,
    /**
     * @generated from protobuf enum value: BSD_1_CLAUSE = 42;
     */
    BSD_1_CLAUSE = 42,
    /**
     * @generated from protobuf enum value: BSD_2_CLAUSE_FREE_BSD = 43;
     */
    BSD_2_CLAUSE_FREE_BSD = 43,
    /**
     * @generated from protobuf enum value: BSD_2_CLAUSE_NET_BSD = 44;
     */
    BSD_2_CLAUSE_NET_BSD = 44,
    /**
     * @generated from protobuf enum value: BSD_2_CLAUSE_PATENT = 45;
     */
    BSD_2_CLAUSE_PATENT = 45,
    /**
     * @generated from protobuf enum value: BSD_2_CLAUSE = 46;
     */
    BSD_2_CLAUSE = 46,
    /**
     * @generated from protobuf enum value: BSD_3_CLAUSE_ATTRIBUTION = 47;
     */
    BSD_3_CLAUSE_ATTRIBUTION = 47,
    /**
     * @generated from protobuf enum value: BSD_3_CLAUSE_CLEAR = 48;
     */
    BSD_3_CLAUSE_CLEAR = 48,
    /**
     * @generated from protobuf enum value: BSD_3_CLAUSE_LBNL = 49;
     */
    BSD_3_CLAUSE_LBNL = 49,
    /**
     * @generated from protobuf enum value: BSD_3_CLAUSE_NO_NUCLEAR_LICENSE_2014 = 50;
     */
    BSD_3_CLAUSE_NO_NUCLEAR_LICENSE_2014 = 50,
    /**
     * @generated from protobuf enum value: BSD_3_CLAUSE_NO_NUCLEAR_LICENSE = 51;
     */
    BSD_3_CLAUSE_NO_NUCLEAR_LICENSE = 51,
    /**
     * @generated from protobuf enum value: BSD_3_CLAUSE_NO_NUCLEAR_WARRANTY = 52;
     */
    BSD_3_CLAUSE_NO_NUCLEAR_WARRANTY = 52,
    /**
     * @generated from protobuf enum value: BSD_3_CLAUSE = 53;
     */
    BSD_3_CLAUSE = 53,
    /**
     * @generated from protobuf enum value: BSD_4_CLAUSE_UC = 54;
     */
    BSD_4_CLAUSE_UC = 54,
    /**
     * @generated from protobuf enum value: BSD_4_CLAUSE = 55;
     */
    BSD_4_CLAUSE = 55,
    /**
     * @generated from protobuf enum value: BSD_PROTECTION = 56;
     */
    BSD_PROTECTION = 56,
    /**
     * @generated from protobuf enum value: BSD_SOURCE_CODE = 57;
     */
    BSD_SOURCE_CODE = 57,
    /**
     * @generated from protobuf enum value: BSL_1_0 = 58;
     */
    BSL_1_0 = 58,
    /**
     * @generated from protobuf enum value: BZIP2_1_0_5 = 59;
     */
    BZIP2_1_0_5 = 59,
    /**
     * @generated from protobuf enum value: BZIP2_1_0_6 = 60;
     */
    BZIP2_1_0_6 = 60,
    /**
     * @generated from protobuf enum value: CALDERA = 61;
     */
    CALDERA = 61,
    /**
     * @generated from protobuf enum value: CATOSL_1_1 = 62;
     */
    CATOSL_1_1 = 62,
    /**
     * @generated from protobuf enum value: CC_BY_1_0 = 63;
     */
    CC_BY_1_0 = 63,
    /**
     * @generated from protobuf enum value: CC_BY_2_0 = 64;
     */
    CC_BY_2_0 = 64,
    /**
     * @generated from protobuf enum value: CC_BY_2_5 = 65;
     */
    CC_BY_2_5 = 65,
    /**
     * @generated from protobuf enum value: CC_BY_3_0 = 66;
     */
    CC_BY_3_0 = 66,
    /**
     * @generated from protobuf enum value: CC_BY_4_0 = 67;
     */
    CC_BY_4_0 = 67,
    /**
     * @generated from protobuf enum value: CC_BY_NC_1_0 = 68;
     */
    CC_BY_NC_1_0 = 68,
    /**
     * @generated from protobuf enum value: CC_BY_NC_2_0 = 69;
     */
    CC_BY_NC_2_0 = 69,
    /**
     * @generated from protobuf enum value: CC_BY_NC_2_5 = 70;
     */
    CC_BY_NC_2_5 = 70,
    /**
     * @generated from protobuf enum value: CC_BY_NC_3_0 = 71;
     */
    CC_BY_NC_3_0 = 71,
    /**
     * @generated from protobuf enum value: CC_BY_NC_4_0 = 72;
     */
    CC_BY_NC_4_0 = 72,
    /**
     * @generated from protobuf enum value: CC_BY_NC_ND_1_0 = 73;
     */
    CC_BY_NC_ND_1_0 = 73,
    /**
     * @generated from protobuf enum value: CC_BY_NC_ND_2_0 = 74;
     */
    CC_BY_NC_ND_2_0 = 74,
    /**
     * @generated from protobuf enum value: CC_BY_NC_ND_2_5 = 75;
     */
    CC_BY_NC_ND_2_5 = 75,
    /**
     * @generated from protobuf enum value: CC_BY_NC_ND_3_0 = 76;
     */
    CC_BY_NC_ND_3_0 = 76,
    /**
     * @generated from protobuf enum value: CC_BY_NC_ND_4_0 = 77;
     */
    CC_BY_NC_ND_4_0 = 77,
    /**
     * @generated from protobuf enum value: CC_BY_NC_SA_1_0 = 78;
     */
    CC_BY_NC_SA_1_0 = 78,
    /**
     * @generated from protobuf enum value: CC_BY_NC_SA_2_0 = 79;
     */
    CC_BY_NC_SA_2_0 = 79,
    /**
     * @generated from protobuf enum value: CC_BY_NC_SA_2_5 = 80;
     */
    CC_BY_NC_SA_2_5 = 80,
    /**
     * @generated from protobuf enum value: CC_BY_NC_SA_3_0 = 81;
     */
    CC_BY_NC_SA_3_0 = 81,
    /**
     * @generated from protobuf enum value: CC_BY_NC_SA_4_0 = 82;
     */
    CC_BY_NC_SA_4_0 = 82,
    /**
     * @generated from protobuf enum value: CC_BY_ND_1_0 = 83;
     */
    CC_BY_ND_1_0 = 83,
    /**
     * @generated from protobuf enum value: CC_BY_ND_2_0 = 84;
     */
    CC_BY_ND_2_0 = 84,
    /**
     * @generated from protobuf enum value: CC_BY_ND_2_5 = 85;
     */
    CC_BY_ND_2_5 = 85,
    /**
     * @generated from protobuf enum value: CC_BY_ND_3_0 = 86;
     */
    CC_BY_ND_3_0 = 86,
    /**
     * @generated from protobuf enum value: CC_BY_ND_4_0 = 87;
     */
    CC_BY_ND_4_0 = 87,
    /**
     * @generated from protobuf enum value: CC_BY_SA_1_0 = 88;
     */
    CC_BY_SA_1_0 = 88,
    /**
     * @generated from protobuf enum value: CC_BY_SA_2_0 = 89;
     */
    CC_BY_SA_2_0 = 89,
    /**
     * @generated from protobuf enum value: CC_BY_SA_2_5 = 90;
     */
    CC_BY_SA_2_5 = 90,
    /**
     * @generated from protobuf enum value: CC_BY_SA_3_0 = 91;
     */
    CC_BY_SA_3_0 = 91,
    /**
     * @generated from protobuf enum value: CC_BY_SA_4_0 = 92;
     */
    CC_BY_SA_4_0 = 92,
    /**
     * @generated from protobuf enum value: CC0_1_0 = 93;
     */
    CC0_1_0 = 93,
    /**
     * @generated from protobuf enum value: CDDL_1_0 = 94;
     */
    CDDL_1_0 = 94,
    /**
     * @generated from protobuf enum value: CDDL_1_1 = 95;
     */
    CDDL_1_1 = 95,
    /**
     * @generated from protobuf enum value: CDLA_PERMISSIVE_1_0 = 96;
     */
    CDLA_PERMISSIVE_1_0 = 96,
    /**
     * @generated from protobuf enum value: CDLA_SHARING_1_0 = 97;
     */
    CDLA_SHARING_1_0 = 97,
    /**
     * @generated from protobuf enum value: CECILL_1_0 = 98;
     */
    CECILL_1_0 = 98,
    /**
     * @generated from protobuf enum value: CECILL_1_1 = 99;
     */
    CECILL_1_1 = 99,
    /**
     * @generated from protobuf enum value: CECILL_2_0 = 100;
     */
    CECILL_2_0 = 100,
    /**
     * @generated from protobuf enum value: CECILL_2_1 = 101;
     */
    CECILL_2_1 = 101,
    /**
     * @generated from protobuf enum value: CECILL_B = 102;
     */
    CECILL_B = 102,
    /**
     * @generated from protobuf enum value: CECILL_C = 103;
     */
    CECILL_C = 103,
    /**
     * @generated from protobuf enum value: CL_ARTISTIC = 104;
     */
    CL_ARTISTIC = 104,
    /**
     * @generated from protobuf enum value: CNRI_JYTHON = 105;
     */
    CNRI_JYTHON = 105,
    /**
     * @generated from protobuf enum value: CNRI_PYTHON_GPL_COMPATIBLE = 106;
     */
    CNRI_PYTHON_GPL_COMPATIBLE = 106,
    /**
     * @generated from protobuf enum value: CNRI_PYTHON = 107;
     */
    CNRI_PYTHON = 107,
    /**
     * @generated from protobuf enum value: CONDOR_1_1 = 108;
     */
    CONDOR_1_1 = 108,
    /**
     * @generated from protobuf enum value: CPAL_1_0 = 109;
     */
    CPAL_1_0 = 109,
    /**
     * @generated from protobuf enum value: CPL_1_0 = 110;
     */
    CPL_1_0 = 110,
    /**
     * @generated from protobuf enum value: CPOL_1_02 = 111;
     */
    CPOL_1_02 = 111,
    /**
     * @generated from protobuf enum value: CROSSWORD = 112;
     */
    CROSSWORD = 112,
    /**
     * @generated from protobuf enum value: CRYSTAL_STACKER = 113;
     */
    CRYSTAL_STACKER = 113,
    /**
     * @generated from protobuf enum value: CUA_OPL_1_0 = 114;
     */
    CUA_OPL_1_0 = 114,
    /**
     * @generated from protobuf enum value: CUBE = 115;
     */
    CUBE = 115,
    /**
     * @generated from protobuf enum value: CURL = 116;
     */
    CURL = 116,
    /**
     * @generated from protobuf enum value: D_FSL_1_0 = 117;
     */
    D_FSL_1_0 = 117,
    /**
     * @generated from protobuf enum value: DIFFMARK = 118;
     */
    DIFFMARK = 118,
    /**
     * @generated from protobuf enum value: DOC = 119;
     */
    DOC = 119,
    /**
     * @generated from protobuf enum value: DOTSEQN = 120;
     */
    DOTSEQN = 120,
    /**
     * @generated from protobuf enum value: DSDP = 121;
     */
    DSDP = 121,
    /**
     * @generated from protobuf enum value: DVIPDFM = 122;
     */
    DVIPDFM = 122,
    /**
     * @generated from protobuf enum value: ECL_1_0 = 123;
     */
    ECL_1_0 = 123,
    /**
     * @generated from protobuf enum value: ECL_2_0 = 124;
     */
    ECL_2_0 = 124,
    /**
     * @generated from protobuf enum value: EFL_1_0 = 125;
     */
    EFL_1_0 = 125,
    /**
     * @generated from protobuf enum value: EFL_2_0 = 126;
     */
    EFL_2_0 = 126,
    /**
     * @generated from protobuf enum value: E_GENIX = 127;
     */
    E_GENIX = 127,
    /**
     * @generated from protobuf enum value: ENTESSA = 128;
     */
    ENTESSA = 128,
    /**
     * @generated from protobuf enum value: EPL_1_0 = 129;
     */
    EPL_1_0 = 129,
    /**
     * @generated from protobuf enum value: EPL_2_0 = 130;
     */
    EPL_2_0 = 130,
    /**
     * @generated from protobuf enum value: ERL_PL_1_1 = 131;
     */
    ERL_PL_1_1 = 131,
    /**
     * @generated from protobuf enum value: EU_DATAGRID = 132;
     */
    EU_DATAGRID = 132,
    /**
     * @generated from protobuf enum value: EUPL_1_0 = 133;
     */
    EUPL_1_0 = 133,
    /**
     * @generated from protobuf enum value: EUPL_1_1 = 134;
     */
    EUPL_1_1 = 134,
    /**
     * @generated from protobuf enum value: EUPL_1_2 = 135;
     */
    EUPL_1_2 = 135,
    /**
     * @generated from protobuf enum value: EUROSYM = 136;
     */
    EUROSYM = 136,
    /**
     * @generated from protobuf enum value: FAIR = 137;
     */
    FAIR = 137,
    /**
     * @generated from protobuf enum value: FRAMEWORX_1_0 = 138;
     */
    FRAMEWORX_1_0 = 138,
    /**
     * @generated from protobuf enum value: FREE_IMAGE = 139;
     */
    FREE_IMAGE = 139,
    /**
     * @generated from protobuf enum value: FSFAP = 140;
     */
    FSFAP = 140,
    /**
     * @generated from protobuf enum value: FSFUL = 141;
     */
    FSFUL = 141,
    /**
     * @generated from protobuf enum value: FSFULLR = 142;
     */
    FSFULLR = 142,
    /**
     * @generated from protobuf enum value: FTL = 143;
     */
    FTL = 143,
    /**
     * @generated from protobuf enum value: GFDL_1_1_ONLY = 144;
     */
    GFDL_1_1_ONLY = 144,
    /**
     * @generated from protobuf enum value: GFDL_1_1_OR_LATER = 145;
     */
    GFDL_1_1_OR_LATER = 145,
    /**
     * @generated from protobuf enum value: GFDL_1_2_ONLY = 146;
     */
    GFDL_1_2_ONLY = 146,
    /**
     * @generated from protobuf enum value: GFDL_1_2_OR_LATER = 147;
     */
    GFDL_1_2_OR_LATER = 147,
    /**
     * @generated from protobuf enum value: GFDL_1_3_ONLY = 148;
     */
    GFDL_1_3_ONLY = 148,
    /**
     * @generated from protobuf enum value: GFDL_1_3_OR_LATER = 149;
     */
    GFDL_1_3_OR_LATER = 149,
    /**
     * @generated from protobuf enum value: GIFTWARE = 150;
     */
    GIFTWARE = 150,
    /**
     * @generated from protobuf enum value: GL2PS = 151;
     */
    GL2PS = 151,
    /**
     * @generated from protobuf enum value: GLIDE = 152;
     */
    GLIDE = 152,
    /**
     * @generated from protobuf enum value: GLULXE = 153;
     */
    GLULXE = 153,
    /**
     * @generated from protobuf enum value: GNUPLOT = 154;
     */
    GNUPLOT = 154,
    /**
     * @generated from protobuf enum value: GPL_1_0_ONLY = 155;
     */
    GPL_1_0_ONLY = 155,
    /**
     * @generated from protobuf enum value: GPL_1_0_OR_LATER = 156;
     */
    GPL_1_0_OR_LATER = 156,
    /**
     * @generated from protobuf enum value: GPL_2_0_ONLY = 157;
     */
    GPL_2_0_ONLY = 157,
    /**
     * @generated from protobuf enum value: GPL_2_0_OR_LATER = 158;
     */
    GPL_2_0_OR_LATER = 158,
    /**
     * @generated from protobuf enum value: GPL_3_0_ONLY = 159;
     */
    GPL_3_0_ONLY = 159,
    /**
     * @generated from protobuf enum value: GPL_3_0_OR_LATER = 160;
     */
    GPL_3_0_OR_LATER = 160,
    /**
     * @generated from protobuf enum value: G_SOAP_1_3B = 161;
     */
    G_SOAP_1_3B = 161,
    /**
     * @generated from protobuf enum value: HASKELL_REPORT = 162;
     */
    HASKELL_REPORT = 162,
    /**
     * @generated from protobuf enum value: HPND = 163;
     */
    HPND = 163,
    /**
     * @generated from protobuf enum value: IBM_PIBS = 164;
     */
    IBM_PIBS = 164,
    /**
     * @generated from protobuf enum value: ICU = 165;
     */
    ICU = 165,
    /**
     * @generated from protobuf enum value: IJG = 166;
     */
    IJG = 166,
    /**
     * @generated from protobuf enum value: IMAGE_MAGICK = 167;
     */
    IMAGE_MAGICK = 167,
    /**
     * @generated from protobuf enum value: I_MATIX = 168;
     */
    I_MATIX = 168,
    /**
     * @generated from protobuf enum value: IMLIB2 = 169;
     */
    IMLIB2 = 169,
    /**
     * @generated from protobuf enum value: INFO_ZIP = 170;
     */
    INFO_ZIP = 170,
    /**
     * @generated from protobuf enum value: INTEL_ACPI = 171;
     */
    INTEL_ACPI = 171,
    /**
     * @generated from protobuf enum value: INTEL = 172;
     */
    INTEL = 172,
    /**
     * @generated from protobuf enum value: INTERBASE_1_0 = 173;
     */
    INTERBASE_1_0 = 173,
    /**
     * @generated from protobuf enum value: IPA = 174;
     */
    IPA = 174,
    /**
     * @generated from protobuf enum value: IPL_1_0 = 175;
     */
    IPL_1_0 = 175,
    /**
     * @generated from protobuf enum value: ISC = 176;
     */
    ISC = 176,
    /**
     * @generated from protobuf enum value: JAS_PER_2_0 = 177;
     */
    JAS_PER_2_0 = 177,
    /**
     * @generated from protobuf enum value: JSON = 178;
     */
    JSON = 178,
    /**
     * @generated from protobuf enum value: LAL_1_2 = 179;
     */
    LAL_1_2 = 179,
    /**
     * @generated from protobuf enum value: LAL_1_3 = 180;
     */
    LAL_1_3 = 180,
    /**
     * @generated from protobuf enum value: LATEX2E = 181;
     */
    LATEX2E = 181,
    /**
     * @generated from protobuf enum value: LEPTONICA = 182;
     */
    LEPTONICA = 182,
    /**
     * @generated from protobuf enum value: LGPL_2_0_ONLY = 183;
     */
    LGPL_2_0_ONLY = 183,
    /**
     * @generated from protobuf enum value: LGPL_2_0_OR_LATER = 184;
     */
    LGPL_2_0_OR_LATER = 184,
    /**
     * @generated from protobuf enum value: LGPL_2_1_ONLY = 185;
     */
    LGPL_2_1_ONLY = 185,
    /**
     * @generated from protobuf enum value: LGPL_2_1_OR_LATER = 186;
     */
    LGPL_2_1_OR_LATER = 186,
    /**
     * @generated from protobuf enum value: LGPL_3_0_ONLY = 187;
     */
    LGPL_3_0_ONLY = 187,
    /**
     * @generated from protobuf enum value: LGPL_3_0_OR_LATER = 188;
     */
    LGPL_3_0_OR_LATER = 188,
    /**
     * @generated from protobuf enum value: LGPLLR = 189;
     */
    LGPLLR = 189,
    /**
     * @generated from protobuf enum value: LIBPNG = 190;
     */
    LIBPNG = 190,
    /**
     * @generated from protobuf enum value: LIBTIFF = 191;
     */
    LIBTIFF = 191,
    /**
     * @generated from protobuf enum value: LI_LI_Q_P_1_1 = 192;
     */
    LI_LI_Q_P_1_1 = 192,
    /**
     * @generated from protobuf enum value: LI_LI_Q_R_1_1 = 193;
     */
    LI_LI_Q_R_1_1 = 193,
    /**
     * @generated from protobuf enum value: LI_LI_Q_RPLUS_1_1 = 194;
     */
    LI_LI_Q_RPLUS_1_1 = 194,
    /**
     * @generated from protobuf enum value: LINUX_OPEN_IB = 195;
     */
    LINUX_OPEN_IB = 195,
    /**
     * @generated from protobuf enum value: LPL_1_0 = 196;
     */
    LPL_1_0 = 196,
    /**
     * @generated from protobuf enum value: LPL_1_02 = 197;
     */
    LPL_1_02 = 197,
    /**
     * @generated from protobuf enum value: LPPL_1_0 = 198;
     */
    LPPL_1_0 = 198,
    /**
     * @generated from protobuf enum value: LPPL_1_1 = 199;
     */
    LPPL_1_1 = 199,
    /**
     * @generated from protobuf enum value: LPPL_1_2 = 200;
     */
    LPPL_1_2 = 200,
    /**
     * @generated from protobuf enum value: LPPL_1_3A = 201;
     */
    LPPL_1_3A = 201,
    /**
     * @generated from protobuf enum value: LPPL_1_3C = 202;
     */
    LPPL_1_3C = 202,
    /**
     * @generated from protobuf enum value: MAKE_INDEX = 203;
     */
    MAKE_INDEX = 203,
    /**
     * @generated from protobuf enum value: MIR_OS = 204;
     */
    MIR_OS = 204,
    /**
     * @generated from protobuf enum value: MIT_0 = 205;
     */
    MIT_0 = 205,
    /**
     * @generated from protobuf enum value: MIT_ADVERTISING = 206;
     */
    MIT_ADVERTISING = 206,
    /**
     * @generated from protobuf enum value: MIT_CMU = 207;
     */
    MIT_CMU = 207,
    /**
     * @generated from protobuf enum value: MIT_ENNA = 208;
     */
    MIT_ENNA = 208,
    /**
     * @generated from protobuf enum value: MIT_FEH = 209;
     */
    MIT_FEH = 209,
    /**
     * @generated from protobuf enum value: MIT = 210;
     */
    MIT = 210,
    /**
     * @generated from protobuf enum value: MITNFA = 211;
     */
    MITNFA = 211,
    /**
     * @generated from protobuf enum value: MOTOSOTO = 212;
     */
    MOTOSOTO = 212,
    /**
     * @generated from protobuf enum value: MPICH2 = 213;
     */
    MPICH2 = 213,
    /**
     * @generated from protobuf enum value: MPL_1_0 = 214;
     */
    MPL_1_0 = 214,
    /**
     * @generated from protobuf enum value: MPL_1_1 = 215;
     */
    MPL_1_1 = 215,
    /**
     * @generated from protobuf enum value: MPL_2_0_NO_COPYLEFT_EXCEPTION = 216;
     */
    MPL_2_0_NO_COPYLEFT_EXCEPTION = 216,
    /**
     * @generated from protobuf enum value: MPL_2_0 = 217;
     */
    MPL_2_0 = 217,
    /**
     * @generated from protobuf enum value: MS_PL = 218;
     */
    MS_PL = 218,
    /**
     * @generated from protobuf enum value: MS_RL = 219;
     */
    MS_RL = 219,
    /**
     * @generated from protobuf enum value: MTLL = 220;
     */
    MTLL = 220,
    /**
     * @generated from protobuf enum value: MULTICS = 221;
     */
    MULTICS = 221,
    /**
     * @generated from protobuf enum value: MUP = 222;
     */
    MUP = 222,
    /**
     * @generated from protobuf enum value: NASA_1_3 = 223;
     */
    NASA_1_3 = 223,
    /**
     * @generated from protobuf enum value: NAUMEN = 224;
     */
    NAUMEN = 224,
    /**
     * @generated from protobuf enum value: NBPL_1_0 = 225;
     */
    NBPL_1_0 = 225,
    /**
     * @generated from protobuf enum value: NCSA = 226;
     */
    NCSA = 226,
    /**
     * @generated from protobuf enum value: NET_SNMP = 227;
     */
    NET_SNMP = 227,
    /**
     * @generated from protobuf enum value: NET_CDF = 228;
     */
    NET_CDF = 228,
    /**
     * @generated from protobuf enum value: NEWSLETR = 229;
     */
    NEWSLETR = 229,
    /**
     * @generated from protobuf enum value: NGPL = 230;
     */
    NGPL = 230,
    /**
     * @generated from protobuf enum value: NLOD_1_0 = 231;
     */
    NLOD_1_0 = 231,
    /**
     * @generated from protobuf enum value: NLPL = 232;
     */
    NLPL = 232,
    /**
     * @generated from protobuf enum value: NOKIA = 233;
     */
    NOKIA = 233,
    /**
     * @generated from protobuf enum value: NOSL = 234;
     */
    NOSL = 234,
    /**
     * @generated from protobuf enum value: NOWEB = 235;
     */
    NOWEB = 235,
    /**
     * @generated from protobuf enum value: NPL_1_0 = 236;
     */
    NPL_1_0 = 236,
    /**
     * @generated from protobuf enum value: NPL_1_1 = 237;
     */
    NPL_1_1 = 237,
    /**
     * @generated from protobuf enum value: NPOSL_3_0 = 238;
     */
    NPOSL_3_0 = 238,
    /**
     * @generated from protobuf enum value: NRL = 239;
     */
    NRL = 239,
    /**
     * @generated from protobuf enum value: NTP = 240;
     */
    NTP = 240,
    /**
     * @generated from protobuf enum value: OCCT_PL = 241;
     */
    OCCT_PL = 241,
    /**
     * @generated from protobuf enum value: OCLC_2_0 = 242;
     */
    OCLC_2_0 = 242,
    /**
     * @generated from protobuf enum value: O_DB_L_1_0 = 243;
     */
    O_DB_L_1_0 = 243,
    /**
     * @generated from protobuf enum value: OFL_1_0 = 244;
     */
    OFL_1_0 = 244,
    /**
     * @generated from protobuf enum value: OFL_1_1 = 245;
     */
    OFL_1_1 = 245,
    /**
     * @generated from protobuf enum value: OGTSL = 246;
     */
    OGTSL = 246,
    /**
     * @generated from protobuf enum value: OLDAP_1_1 = 247;
     */
    OLDAP_1_1 = 247,
    /**
     * @generated from protobuf enum value: OLDAP_1_2 = 248;
     */
    OLDAP_1_2 = 248,
    /**
     * @generated from protobuf enum value: OLDAP_1_3 = 249;
     */
    OLDAP_1_3 = 249,
    /**
     * @generated from protobuf enum value: OLDAP_1_4 = 250;
     */
    OLDAP_1_4 = 250,
    /**
     * @generated from protobuf enum value: OLDAP_2_0_1 = 251;
     */
    OLDAP_2_0_1 = 251,
    /**
     * @generated from protobuf enum value: OLDAP_2_0 = 252;
     */
    OLDAP_2_0 = 252,
    /**
     * @generated from protobuf enum value: OLDAP_2_1 = 253;
     */
    OLDAP_2_1 = 253,
    /**
     * @generated from protobuf enum value: OLDAP_2_2_1 = 254;
     */
    OLDAP_2_2_1 = 254,
    /**
     * @generated from protobuf enum value: OLDAP_2_2_2 = 255;
     */
    OLDAP_2_2_2 = 255,
    /**
     * @generated from protobuf enum value: OLDAP_2_2 = 256;
     */
    OLDAP_2_2 = 256,
    /**
     * @generated from protobuf enum value: OLDAP_2_3 = 257;
     */
    OLDAP_2_3 = 257,
    /**
     * @generated from protobuf enum value: OLDAP_2_4 = 258;
     */
    OLDAP_2_4 = 258,
    /**
     * @generated from protobuf enum value: OLDAP_2_5 = 259;
     */
    OLDAP_2_5 = 259,
    /**
     * @generated from protobuf enum value: OLDAP_2_6 = 260;
     */
    OLDAP_2_6 = 260,
    /**
     * @generated from protobuf enum value: OLDAP_2_7 = 261;
     */
    OLDAP_2_7 = 261,
    /**
     * @generated from protobuf enum value: OLDAP_2_8 = 262;
     */
    OLDAP_2_8 = 262,
    /**
     * @generated from protobuf enum value: OML = 263;
     */
    OML = 263,
    /**
     * @generated from protobuf enum value: OPEN_SSL = 264;
     */
    OPEN_SSL = 264,
    /**
     * @generated from protobuf enum value: OPL_1_0 = 265;
     */
    OPL_1_0 = 265,
    /**
     * @generated from protobuf enum value: OSET_PL_2_1 = 266;
     */
    OSET_PL_2_1 = 266,
    /**
     * @generated from protobuf enum value: OSL_1_0 = 267;
     */
    OSL_1_0 = 267,
    /**
     * @generated from protobuf enum value: OSL_1_1 = 268;
     */
    OSL_1_1 = 268,
    /**
     * @generated from protobuf enum value: OSL_2_0 = 269;
     */
    OSL_2_0 = 269,
    /**
     * @generated from protobuf enum value: OSL_2_1 = 270;
     */
    OSL_2_1 = 270,
    /**
     * @generated from protobuf enum value: OSL_3_0 = 271;
     */
    OSL_3_0 = 271,
    /**
     * @generated from protobuf enum value: PDDL_1_0 = 272;
     */
    PDDL_1_0 = 272,
    /**
     * @generated from protobuf enum value: PHP_3_0 = 273;
     */
    PHP_3_0 = 273,
    /**
     * @generated from protobuf enum value: PHP_3_01 = 274;
     */
    PHP_3_01 = 274,
    /**
     * @generated from protobuf enum value: PLEXUS = 275;
     */
    PLEXUS = 275,
    /**
     * @generated from protobuf enum value: POSTGRE_SQL = 276;
     */
    POSTGRE_SQL = 276,
    /**
     * @generated from protobuf enum value: PSFRAG = 277;
     */
    PSFRAG = 277,
    /**
     * @generated from protobuf enum value: PSUTILS = 278;
     */
    PSUTILS = 278,
    /**
     * @generated from protobuf enum value: PYTHON_2_0 = 279;
     */
    PYTHON_2_0 = 279,
    /**
     * @generated from protobuf enum value: QHULL = 280;
     */
    QHULL = 280,
    /**
     * @generated from protobuf enum value: QPL_1_0 = 281;
     */
    QPL_1_0 = 281,
    /**
     * @generated from protobuf enum value: RDISC = 282;
     */
    RDISC = 282,
    /**
     * @generated from protobuf enum value: R_HE_COS_1_1 = 283;
     */
    R_HE_COS_1_1 = 283,
    /**
     * @generated from protobuf enum value: RPL_1_1 = 284;
     */
    RPL_1_1 = 284,
    /**
     * @generated from protobuf enum value: RPL_1_5 = 285;
     */
    RPL_1_5 = 285,
    /**
     * @generated from protobuf enum value: RPSL_1_0 = 286;
     */
    RPSL_1_0 = 286,
    /**
     * @generated from protobuf enum value: RSA_MD = 287;
     */
    RSA_MD = 287,
    /**
     * @generated from protobuf enum value: RSCPL = 288;
     */
    RSCPL = 288,
    /**
     * @generated from protobuf enum value: RUBY = 289;
     */
    RUBY = 289,
    /**
     * @generated from protobuf enum value: SAX_PD = 290;
     */
    SAX_PD = 290,
    /**
     * @generated from protobuf enum value: SAXPATH = 291;
     */
    SAXPATH = 291,
    /**
     * @generated from protobuf enum value: SCEA = 292;
     */
    SCEA = 292,
    /**
     * @generated from protobuf enum value: SENDMAIL = 293;
     */
    SENDMAIL = 293,
    /**
     * @generated from protobuf enum value: SGI_B_1_0 = 294;
     */
    SGI_B_1_0 = 294,
    /**
     * @generated from protobuf enum value: SGI_B_1_1 = 295;
     */
    SGI_B_1_1 = 295,
    /**
     * @generated from protobuf enum value: SGI_B_2_0 = 296;
     */
    SGI_B_2_0 = 296,
    /**
     * @generated from protobuf enum value: SIM_PL_2_0 = 297;
     */
    SIM_PL_2_0 = 297,
    /**
     * @generated from protobuf enum value: SISSL_1_2 = 298;
     */
    SISSL_1_2 = 298,
    /**
     * @generated from protobuf enum value: SISSL = 299;
     */
    SISSL = 299,
    /**
     * @generated from protobuf enum value: SLEEPYCAT = 300;
     */
    SLEEPYCAT = 300,
    /**
     * @generated from protobuf enum value: SMLNJ = 301;
     */
    SMLNJ = 301,
    /**
     * @generated from protobuf enum value: SMPPL = 302;
     */
    SMPPL = 302,
    /**
     * @generated from protobuf enum value: SNIA = 303;
     */
    SNIA = 303,
    /**
     * @generated from protobuf enum value: SPENCER_86 = 304;
     */
    SPENCER_86 = 304,
    /**
     * @generated from protobuf enum value: SPENCER_94 = 305;
     */
    SPENCER_94 = 305,
    /**
     * @generated from protobuf enum value: SPENCER_99 = 306;
     */
    SPENCER_99 = 306,
    /**
     * @generated from protobuf enum value: SPL_1_0 = 307;
     */
    SPL_1_0 = 307,
    /**
     * @generated from protobuf enum value: SUGAR_CRM_1_1_3 = 308;
     */
    SUGAR_CRM_1_1_3 = 308,
    /**
     * @generated from protobuf enum value: SWL = 309;
     */
    SWL = 309,
    /**
     * @generated from protobuf enum value: TCL = 310;
     */
    TCL = 310,
    /**
     * @generated from protobuf enum value: TCP_WRAPPERS = 311;
     */
    TCP_WRAPPERS = 311,
    /**
     * @generated from protobuf enum value: T_MATE = 312;
     */
    T_MATE = 312,
    /**
     * @generated from protobuf enum value: TORQUE_1_1 = 313;
     */
    TORQUE_1_1 = 313,
    /**
     * @generated from protobuf enum value: TOSL = 314;
     */
    TOSL = 314,
    /**
     * @generated from protobuf enum value: UNICODE_DFS_2015 = 315;
     */
    UNICODE_DFS_2015 = 315,
    /**
     * @generated from protobuf enum value: UNICODE_DFS_2016 = 316;
     */
    UNICODE_DFS_2016 = 316,
    /**
     * @generated from protobuf enum value: UNICODE_TOU = 317;
     */
    UNICODE_TOU = 317,
    /**
     * @generated from protobuf enum value: UNLICENSE = 318;
     */
    UNLICENSE = 318,
    /**
     * @generated from protobuf enum value: UPL_1_0 = 319;
     */
    UPL_1_0 = 319,
    /**
     * @generated from protobuf enum value: VIM = 320;
     */
    VIM = 320,
    /**
     * @generated from protobuf enum value: VOSTROM = 321;
     */
    VOSTROM = 321,
    /**
     * @generated from protobuf enum value: VSL_1_0 = 322;
     */
    VSL_1_0 = 322,
    /**
     * @generated from protobuf enum value: W3C_19980720 = 323;
     */
    W3C_19980720 = 323,
    /**
     * @generated from protobuf enum value: W3C_20150513 = 324;
     */
    W3C_20150513 = 324,
    /**
     * @generated from protobuf enum value: W3C = 325;
     */
    W3C = 325,
    /**
     * @generated from protobuf enum value: WATCOM_1_0 = 326;
     */
    WATCOM_1_0 = 326,
    /**
     * @generated from protobuf enum value: WSUIPA = 327;
     */
    WSUIPA = 327,
    /**
     * @generated from protobuf enum value: WTFPL = 328;
     */
    WTFPL = 328,
    /**
     * @generated from protobuf enum value: X11 = 329;
     */
    X11 = 329,
    /**
     * @generated from protobuf enum value: XEROX = 330;
     */
    XEROX = 330,
    /**
     * @generated from protobuf enum value: X_FREE86_1_1 = 331;
     */
    X_FREE86_1_1 = 331,
    /**
     * @generated from protobuf enum value: XINETD = 332;
     */
    XINETD = 332,
    /**
     * @generated from protobuf enum value: XNET = 333;
     */
    XNET = 333,
    /**
     * @generated from protobuf enum value: XPP = 334;
     */
    XPP = 334,
    /**
     * @generated from protobuf enum value: X_SKAT = 335;
     */
    X_SKAT = 335,
    /**
     * @generated from protobuf enum value: YPL_1_0 = 336;
     */
    YPL_1_0 = 336,
    /**
     * @generated from protobuf enum value: YPL_1_1 = 337;
     */
    YPL_1_1 = 337,
    /**
     * @generated from protobuf enum value: ZED = 338;
     */
    ZED = 338,
    /**
     * @generated from protobuf enum value: ZEND_2_0 = 339;
     */
    ZEND_2_0 = 339,
    /**
     * @generated from protobuf enum value: ZIMBRA_1_3 = 340;
     */
    ZIMBRA_1_3 = 340,
    /**
     * @generated from protobuf enum value: ZIMBRA_1_4 = 341;
     */
    ZIMBRA_1_4 = 341,
    /**
     * @generated from protobuf enum value: ZLIB_ACKNOWLEDGEMENT = 342;
     */
    ZLIB_ACKNOWLEDGEMENT = 342,
    /**
     * @generated from protobuf enum value: ZLIB = 343;
     */
    ZLIB = 343,
    /**
     * @generated from protobuf enum value: ZPL_1_1 = 344;
     */
    ZPL_1_1 = 344,
    /**
     * @generated from protobuf enum value: ZPL_2_0 = 345;
     */
    ZPL_2_0 = 345,
    /**
     * @generated from protobuf enum value: ZPL_2_1 = 346;
     */
    ZPL_2_1 = 346
}
/**
 * What Search Comparator Codes are supported in search.
 * See http://hl7.org/fhir/search-comparator
 *
 * @generated from protobuf message google.fhir.r4.core.SearchComparatorCode
 */
export interface SearchComparatorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SearchComparatorCode.Value
 */
export enum SearchComparatorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: EQ = 1;
     */
    EQ = 1,
    /**
     * @generated from protobuf enum value: NE = 2;
     */
    NE = 2,
    /**
     * @generated from protobuf enum value: GT = 3;
     */
    GT = 3,
    /**
     * @generated from protobuf enum value: LT = 4;
     */
    LT = 4,
    /**
     * @generated from protobuf enum value: GE = 5;
     */
    GE = 5,
    /**
     * @generated from protobuf enum value: LE = 6;
     */
    LE = 6,
    /**
     * @generated from protobuf enum value: SA = 7;
     */
    SA = 7,
    /**
     * @generated from protobuf enum value: EB = 8;
     */
    EB = 8,
    /**
     * @generated from protobuf enum value: AP = 9;
     */
    AP = 9
}
/**
 * Why an entry is in the result set - whether it's included as a match or
 * because of an _include requirement, or to convey information or warning
 * information about the search process. See
 * http://hl7.org/fhir/search-entry-mode
 *
 * @generated from protobuf message google.fhir.r4.core.SearchEntryModeCode
 */
export interface SearchEntryModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SearchEntryModeCode.Value
 */
export enum SearchEntryModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MATCH = 1;
     */
    MATCH = 1,
    /**
     * @generated from protobuf enum value: INCLUDE = 2;
     */
    INCLUDE = 2,
    /**
     * @generated from protobuf enum value: OUTCOME = 3;
     */
    OUTCOME = 3
}
/**
 * A supported modifier for a search parameter.
 * See http://hl7.org/fhir/search-modifier-code
 *
 * @generated from protobuf message google.fhir.r4.core.SearchModifierCode
 */
export interface SearchModifierCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SearchModifierCode.Value
 */
export enum SearchModifierCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MISSING = 1;
     */
    MISSING = 1,
    /**
     * @generated from protobuf enum value: EXACT = 2;
     */
    EXACT = 2,
    /**
     * @generated from protobuf enum value: CONTAINS = 3;
     */
    CONTAINS = 3,
    /**
     * @generated from protobuf enum value: NOT = 4;
     */
    NOT = 4,
    /**
     * @generated from protobuf enum value: TEXT = 5;
     */
    TEXT = 5,
    /**
     * @generated from protobuf enum value: IN = 6;
     */
    IN = 6,
    /**
     * @generated from protobuf enum value: NOT_IN = 7;
     */
    NOT_IN = 7,
    /**
     * @generated from protobuf enum value: BELOW = 8;
     */
    BELOW = 8,
    /**
     * @generated from protobuf enum value: ABOVE = 9;
     */
    ABOVE = 9,
    /**
     * @generated from protobuf enum value: TYPE = 10;
     */
    TYPE = 10,
    /**
     * @generated from protobuf enum value: IDENTIFIER = 11;
     */
    IDENTIFIER = 11,
    /**
     * @generated from protobuf enum value: OF_TYPE = 12;
     */
    OF_TYPE = 12
}
/**
 * Data types allowed to be used for search parameters.
 * See http://hl7.org/fhir/search-param-type
 *
 * @generated from protobuf message google.fhir.r4.core.SearchParamTypeCode
 */
export interface SearchParamTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SearchParamTypeCode.Value
 */
export enum SearchParamTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NUMBER = 1;
     */
    NUMBER = 1,
    /**
     * @generated from protobuf enum value: DATE = 2;
     */
    DATE = 2,
    /**
     * @generated from protobuf enum value: STRING = 3;
     */
    STRING = 3,
    /**
     * @generated from protobuf enum value: TOKEN = 4;
     */
    TOKEN = 4,
    /**
     * @generated from protobuf enum value: REFERENCE = 5;
     */
    REFERENCE = 5,
    /**
     * @generated from protobuf enum value: COMPOSITE = 6;
     */
    COMPOSITE = 6,
    /**
     * @generated from protobuf enum value: QUANTITY = 7;
     */
    QUANTITY = 7,
    /**
     * @generated from protobuf enum value: URI = 8;
     */
    URI = 8,
    /**
     * @generated from protobuf enum value: SPECIAL = 9;
     */
    SPECIAL = 9
}
/**
 * Type if a sequence -- DNA, RNA, or amino acid sequence.
 * See http://hl7.org/fhir/sequence-type
 *
 * @generated from protobuf message google.fhir.r4.core.SequenceTypeCode
 */
export interface SequenceTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SequenceTypeCode.Value
 */
export enum SequenceTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AA = 1;
     */
    AA = 1,
    /**
     * @generated from protobuf enum value: DNA = 2;
     */
    DNA = 2,
    /**
     * @generated from protobuf enum value: RNA = 3;
     */
    RNA = 3
}
/**
 * How slices are interpreted when evaluating an instance.
 * See http://hl7.org/fhir/resource-slicing-rules
 *
 * @generated from protobuf message google.fhir.r4.core.SlicingRulesCode
 */
export interface SlicingRulesCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SlicingRulesCode.Value
 */
export enum SlicingRulesCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CLOSED = 1;
     */
    CLOSED = 1,
    /**
     * @generated from protobuf enum value: OPEN = 2;
     */
    OPEN = 2,
    /**
     * @generated from protobuf enum value: OPEN_AT_END = 3;
     */
    OPEN_AT_END = 3
}
/**
 * The free/busy status of the slot.
 * See http://hl7.org/fhir/slotstatus
 *
 * @generated from protobuf message google.fhir.r4.core.SlotStatusCode
 */
export interface SlotStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SlotStatusCode.Value
 */
export enum SlotStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: BUSY = 1;
     */
    BUSY = 1,
    /**
     * @generated from protobuf enum value: FREE = 2;
     */
    FREE = 2,
    /**
     * @generated from protobuf enum value: BUSY_UNAVAILABLE = 3;
     */
    BUSY_UNAVAILABLE = 3,
    /**
     * @generated from protobuf enum value: BUSY_TENTATIVE = 4;
     */
    BUSY_TENTATIVE = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5
}
/**
 * Codes that define what the server is capable of.
 * See http://terminology.hl7.org/CodeSystem/smart-capabilities
 *
 * @generated from protobuf message google.fhir.r4.core.SmartCapabilitiesCode
 */
export interface SmartCapabilitiesCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SmartCapabilitiesCode.Value
 */
export enum SmartCapabilitiesCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: LAUNCH_EHR = 1;
     */
    LAUNCH_EHR = 1,
    /**
     * @generated from protobuf enum value: LAUNCH_STANDALONE = 2;
     */
    LAUNCH_STANDALONE = 2,
    /**
     * @generated from protobuf enum value: CLIENT_PUBLIC = 3;
     */
    CLIENT_PUBLIC = 3,
    /**
     * @generated from protobuf enum value: CLIENT_CONFIDENTIAL_SYMMETRIC = 4;
     */
    CLIENT_CONFIDENTIAL_SYMMETRIC = 4,
    /**
     * @generated from protobuf enum value: SSO_OPENID_CONNECT = 5;
     */
    SSO_OPENID_CONNECT = 5,
    /**
     * @generated from protobuf enum value: CONTEXT_PASSTHROUGH_BANNER = 6;
     */
    CONTEXT_PASSTHROUGH_BANNER = 6,
    /**
     * @generated from protobuf enum value: CONTEXT_PASSTHROUGH_STYLE = 7;
     */
    CONTEXT_PASSTHROUGH_STYLE = 7,
    /**
     * @generated from protobuf enum value: CONTEXT_EHR_PATIENT = 8;
     */
    CONTEXT_EHR_PATIENT = 8,
    /**
     * @generated from protobuf enum value: CONTEXT_EHR_ENCOUNTER = 9;
     */
    CONTEXT_EHR_ENCOUNTER = 9,
    /**
     * @generated from protobuf enum value: CONTEXT_STANDALONE_PATIENT = 10;
     */
    CONTEXT_STANDALONE_PATIENT = 10,
    /**
     * @generated from protobuf enum value: CONTEXT_STANDALONE_ENCOUNTER = 11;
     */
    CONTEXT_STANDALONE_ENCOUNTER = 11,
    /**
     * @generated from protobuf enum value: PERMISSION_OFFLINE = 12;
     */
    PERMISSION_OFFLINE = 12,
    /**
     * @generated from protobuf enum value: PERMISSION_PATIENT = 13;
     */
    PERMISSION_PATIENT = 13,
    /**
     * @generated from protobuf enum value: PERMISSION_USER = 14;
     */
    PERMISSION_USER = 14
}
/**
 * The possible sort directions, ascending or descending.
 * See http://hl7.org/fhir/sort-direction
 *
 * @generated from protobuf message google.fhir.r4.core.SortDirectionCode
 */
export interface SortDirectionCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SortDirectionCode.Value
 */
export enum SortDirectionCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ASCENDING = 1;
     */
    ASCENDING = 1,
    /**
     * @generated from protobuf enum value: DESCENDING = 2;
     */
    DESCENDING = 2
}
/**
 * Degree of preference of a type of conditioned specimen.
 * See http://hl7.org/fhir/specimen-contained-preference
 *
 * @generated from protobuf message google.fhir.r4.core.SpecimenContainedPreferenceCode
 */
export interface SpecimenContainedPreferenceCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SpecimenContainedPreferenceCode.Value
 */
export enum SpecimenContainedPreferenceCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PREFERRED = 1;
     */
    PREFERRED = 1,
    /**
     * @generated from protobuf enum value: ALTERNATE = 2;
     */
    ALTERNATE = 2
}
/**
 * Codes providing the status/availability of a specimen.
 * See http://hl7.org/fhir/specimen-status
 *
 * @generated from protobuf message google.fhir.r4.core.SpecimenStatusCode
 */
export interface SpecimenStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SpecimenStatusCode.Value
 */
export enum SpecimenStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AVAILABLE = 1;
     */
    AVAILABLE = 1,
    /**
     * @generated from protobuf enum value: UNAVAILABLE = 2;
     */
    UNAVAILABLE = 2,
    /**
     * @generated from protobuf enum value: UNSATISFACTORY = 3;
     */
    UNSATISFACTORY = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4
}
/**
 * HL7 Ballot/Standards status of artifact.
 * See http://terminology.hl7.org/CodeSystem/standards-status
 *
 * @generated from protobuf message google.fhir.r4.core.StandardsStatusCode
 */
export interface StandardsStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StandardsStatusCode.Value
 */
export enum StandardsStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: NORMATIVE = 2;
     */
    NORMATIVE = 2,
    /**
     * @generated from protobuf enum value: TRIAL_USE = 3;
     */
    TRIAL_USE = 3,
    /**
     * @generated from protobuf enum value: INFORMATIVE = 4;
     */
    INFORMATIVE = 4,
    /**
     * @generated from protobuf enum value: DEPRECATED = 5;
     */
    DEPRECATED = 5,
    /**
     * @generated from protobuf enum value: EXTERNAL = 6;
     */
    EXTERNAL = 6
}
/**
 * The validation status of the target
 * See http://hl7.org/fhir/CodeSystem/status
 *
 * @generated from protobuf message google.fhir.r4.core.StatusCode
 */
export interface StatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StatusCode.Value
 */
export enum StatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ATTESTED = 1;
     */
    ATTESTED = 1,
    /**
     * @generated from protobuf enum value: VALIDATED = 2;
     */
    VALIDATED = 2,
    /**
     * @generated from protobuf enum value: IN_PROCESS = 3;
     */
    IN_PROCESS = 3,
    /**
     * @generated from protobuf enum value: REQ_REVALID = 4;
     */
    REQ_REVALID = 4,
    /**
     * @generated from protobuf enum value: VAL_FAIL = 5;
     */
    VAL_FAIL = 5,
    /**
     * @generated from protobuf enum value: REVAL_FAIL = 6;
     */
    REVAL_FAIL = 6
}
/**
 * Type for strand.
 * See http://hl7.org/fhir/strand-type
 *
 * @generated from protobuf message google.fhir.r4.core.StrandTypeCode
 */
export interface StrandTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StrandTypeCode.Value
 */
export enum StrandTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: WATSON = 1;
     */
    WATSON = 1,
    /**
     * @generated from protobuf enum value: CRICK = 2;
     */
    CRICK = 2
}
/**
 * Defines the type of structure that a definition is describing.
 * See http://hl7.org/fhir/structure-definition-kind
 *
 * @generated from protobuf message google.fhir.r4.core.StructureDefinitionKindCode
 */
export interface StructureDefinitionKindCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StructureDefinitionKindCode.Value
 */
export enum StructureDefinitionKindCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PRIMITIVE_TYPE = 1;
     */
    PRIMITIVE_TYPE = 1,
    /**
     * @generated from protobuf enum value: COMPLEX_TYPE = 2;
     */
    COMPLEX_TYPE = 2,
    /**
     * @generated from protobuf enum value: RESOURCE = 3;
     */
    RESOURCE = 3,
    /**
     * @generated from protobuf enum value: LOGICAL = 4;
     */
    LOGICAL = 4
}
/**
 * How to interpret the context.
 * See http://hl7.org/fhir/map-context-type
 *
 * @generated from protobuf message google.fhir.r4.core.StructureMapContextTypeCode
 */
export interface StructureMapContextTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StructureMapContextTypeCode.Value
 */
export enum StructureMapContextTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: TYPE = 1;
     */
    TYPE = 1,
    /**
     * @generated from protobuf enum value: VARIABLE = 2;
     */
    VARIABLE = 2
}
/**
 * If this is the default rule set to apply for the source type, or this
 * combination of types. See http://hl7.org/fhir/map-group-type-mode
 *
 * @generated from protobuf message google.fhir.r4.core.StructureMapGroupTypeModeCode
 */
export interface StructureMapGroupTypeModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StructureMapGroupTypeModeCode.Value
 */
export enum StructureMapGroupTypeModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NONE = 1;
     */
    NONE = 1,
    /**
     * @generated from protobuf enum value: TYPES = 2;
     */
    TYPES = 2,
    /**
     * @generated from protobuf enum value: TYPE_AND_TYPES = 3;
     */
    TYPE_AND_TYPES = 3
}
/**
 * Mode for this instance of data.
 * See http://hl7.org/fhir/map-input-mode
 *
 * @generated from protobuf message google.fhir.r4.core.StructureMapInputModeCode
 */
export interface StructureMapInputModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StructureMapInputModeCode.Value
 */
export enum StructureMapInputModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SOURCE = 1;
     */
    SOURCE = 1,
    /**
     * @generated from protobuf enum value: TARGET = 2;
     */
    TARGET = 2
}
/**
 * How the referenced structure is used in this mapping.
 * See http://hl7.org/fhir/map-model-mode
 *
 * @generated from protobuf message google.fhir.r4.core.StructureMapModelModeCode
 */
export interface StructureMapModelModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StructureMapModelModeCode.Value
 */
export enum StructureMapModelModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SOURCE = 1;
     */
    SOURCE = 1,
    /**
     * @generated from protobuf enum value: QUERIED = 2;
     */
    QUERIED = 2,
    /**
     * @generated from protobuf enum value: TARGET = 3;
     */
    TARGET = 3,
    /**
     * @generated from protobuf enum value: PRODUCED = 4;
     */
    PRODUCED = 4
}
/**
 * If field is a list, how to manage the source.
 * See http://hl7.org/fhir/map-source-list-mode
 *
 * @generated from protobuf message google.fhir.r4.core.StructureMapSourceListModeCode
 */
export interface StructureMapSourceListModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StructureMapSourceListModeCode.Value
 */
export enum StructureMapSourceListModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: FIRST = 1;
     */
    FIRST = 1,
    /**
     * @generated from protobuf enum value: NOT_FIRST = 2;
     */
    NOT_FIRST = 2,
    /**
     * @generated from protobuf enum value: LAST = 3;
     */
    LAST = 3,
    /**
     * @generated from protobuf enum value: NOT_LAST = 4;
     */
    NOT_LAST = 4,
    /**
     * @generated from protobuf enum value: ONLY_ONE = 5;
     */
    ONLY_ONE = 5
}
/**
 * If field is a list, how to manage the production.
 * See http://hl7.org/fhir/map-target-list-mode
 *
 * @generated from protobuf message google.fhir.r4.core.StructureMapTargetListModeCode
 */
export interface StructureMapTargetListModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StructureMapTargetListModeCode.Value
 */
export enum StructureMapTargetListModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: FIRST = 1;
     */
    FIRST = 1,
    /**
     * @generated from protobuf enum value: SHARE = 2;
     */
    SHARE = 2,
    /**
     * @generated from protobuf enum value: LAST = 3;
     */
    LAST = 3,
    /**
     * @generated from protobuf enum value: COLLATE = 4;
     */
    COLLATE = 4
}
/**
 * How data is copied/created.
 * See http://hl7.org/fhir/map-transform
 *
 * @generated from protobuf message google.fhir.r4.core.StructureMapTransformCode
 */
export interface StructureMapTransformCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.StructureMapTransformCode.Value
 */
export enum StructureMapTransformCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CREATE = 1;
     */
    CREATE = 1,
    /**
     * @generated from protobuf enum value: COPY = 2;
     */
    COPY = 2,
    /**
     * @generated from protobuf enum value: TRUNCATE = 3;
     */
    TRUNCATE = 3,
    /**
     * @generated from protobuf enum value: ESCAPE = 4;
     */
    ESCAPE = 4,
    /**
     * @generated from protobuf enum value: CAST = 5;
     */
    CAST = 5,
    /**
     * @generated from protobuf enum value: APPEND = 6;
     */
    APPEND = 6,
    /**
     * @generated from protobuf enum value: TRANSLATE = 7;
     */
    TRANSLATE = 7,
    /**
     * @generated from protobuf enum value: REFERENCE = 8;
     */
    REFERENCE = 8,
    /**
     * @generated from protobuf enum value: DATE_OP = 9;
     */
    DATE_OP = 9,
    /**
     * @generated from protobuf enum value: UUID = 10;
     */
    UUID = 10,
    /**
     * @generated from protobuf enum value: POINTER = 11;
     */
    POINTER = 11,
    /**
     * @generated from protobuf enum value: EVALUATE = 12;
     */
    EVALUATE = 12,
    /**
     * @generated from protobuf enum value: CC = 13;
     */
    CC = 13,
    /**
     * @generated from protobuf enum value: C = 14;
     */
    C = 14,
    /**
     * @generated from protobuf enum value: QTY = 15;
     */
    QTY = 15,
    /**
     * @generated from protobuf enum value: ID = 16;
     */
    ID = 16,
    /**
     * @generated from protobuf enum value: CP = 17;
     */
    CP = 17
}
/**
 * The type of method used to execute a subscription.
 * See http://hl7.org/fhir/subscription-channel-type
 *
 * @generated from protobuf message google.fhir.r4.core.SubscriptionChannelTypeCode
 */
export interface SubscriptionChannelTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SubscriptionChannelTypeCode.Value
 */
export enum SubscriptionChannelTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REST_HOOK = 1;
     */
    REST_HOOK = 1,
    /**
     * @generated from protobuf enum value: WEBSOCKET = 2;
     */
    WEBSOCKET = 2,
    /**
     * @generated from protobuf enum value: EMAIL = 3;
     */
    EMAIL = 3,
    /**
     * @generated from protobuf enum value: SMS = 4;
     */
    SMS = 4,
    /**
     * @generated from protobuf enum value: MESSAGE = 5;
     */
    MESSAGE = 5
}
/**
 * The status of a subscription.
 * See http://hl7.org/fhir/subscription-status
 *
 * @generated from protobuf message google.fhir.r4.core.SubscriptionStatusCode
 */
export interface SubscriptionStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SubscriptionStatusCode.Value
 */
export enum SubscriptionStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: REQUESTED = 1;
     */
    REQUESTED = 1,
    /**
     * @generated from protobuf enum value: ACTIVE = 2;
     */
    ACTIVE = 2,
    /**
     * @generated from protobuf enum value: ERROR = 3;
     */
    ERROR = 3,
    /**
     * @generated from protobuf enum value: OFF = 4;
     */
    OFF = 4
}
/**
 * Status of the supply delivery.
 * See http://hl7.org/fhir/supplydelivery-status
 *
 * @generated from protobuf message google.fhir.r4.core.SupplyDeliveryStatusCode
 */
export interface SupplyDeliveryStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SupplyDeliveryStatusCode.Value
 */
export enum SupplyDeliveryStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 1;
     */
    IN_PROGRESS = 1,
    /**
     * @generated from protobuf enum value: COMPLETED = 2;
     */
    COMPLETED = 2,
    /**
     * @generated from protobuf enum value: ABANDONED = 3;
     */
    ABANDONED = 3,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 4;
     */
    ENTERED_IN_ERROR = 4
}
/**
 * This value sets refers to a specific supply item.
 * See http://terminology.hl7.org/CodeSystem/supply-item-type
 *
 * @generated from protobuf message google.fhir.r4.core.SupplyItemTypeCode
 */
export interface SupplyItemTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SupplyItemTypeCode.Value
 */
export enum SupplyItemTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MEDICATION = 1;
     */
    MEDICATION = 1,
    /**
     * @generated from protobuf enum value: DEVICE = 2;
     */
    DEVICE = 2
}
/**
 * Status of the supply request.
 * See http://hl7.org/fhir/supplyrequest-status
 *
 * @generated from protobuf message google.fhir.r4.core.SupplyRequestStatusCode
 */
export interface SupplyRequestStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SupplyRequestStatusCode.Value
 */
export enum SupplyRequestStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: ACTIVE = 2;
     */
    ACTIVE = 2,
    /**
     * @generated from protobuf enum value: SUSPENDED = 3;
     */
    SUSPENDED = 3,
    /**
     * @generated from protobuf enum value: CANCELLED = 4;
     */
    CANCELLED = 4,
    /**
     * @generated from protobuf enum value: COMPLETED = 5;
     */
    COMPLETED = 5,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 6;
     */
    ENTERED_IN_ERROR = 6,
    /**
     * @generated from protobuf enum value: UNKNOWN = 7;
     */
    UNKNOWN = 7
}
/**
 * Distinguishes whether the task is a proposal, plan or full order.
 * See http://hl7.org/fhir/task-intent
 *
 * @generated from protobuf message google.fhir.r4.core.TaskIntentCode
 */
export interface TaskIntentCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TaskIntentCode.Value
 */
export enum TaskIntentCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: UNKNOWN = 1;
     */
    UNKNOWN = 1
}
/**
 * The current status of the task.
 * See http://hl7.org/fhir/task-status
 *
 * @generated from protobuf message google.fhir.r4.core.TaskStatusCode
 */
export interface TaskStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TaskStatusCode.Value
 */
export enum TaskStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: REQUESTED = 2;
     */
    REQUESTED = 2,
    /**
     * @generated from protobuf enum value: RECEIVED = 3;
     */
    RECEIVED = 3,
    /**
     * @generated from protobuf enum value: ACCEPTED = 4;
     */
    ACCEPTED = 4,
    /**
     * @generated from protobuf enum value: REJECTED = 5;
     */
    REJECTED = 5,
    /**
     * @generated from protobuf enum value: READY = 6;
     */
    READY = 6,
    /**
     * @generated from protobuf enum value: CANCELLED = 7;
     */
    CANCELLED = 7,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 8;
     */
    IN_PROGRESS = 8,
    /**
     * @generated from protobuf enum value: ON_HOLD = 9;
     */
    ON_HOLD = 9,
    /**
     * @generated from protobuf enum value: FAILED = 10;
     */
    FAILED = 10,
    /**
     * @generated from protobuf enum value: COMPLETED = 11;
     */
    COMPLETED = 11,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 12;
     */
    ENTERED_IN_ERROR = 12
}
/**
 * Life cycle of the Status Code of a Template Design (Version)
 * See urn:oid:2.16.840.1.113883.3.1937.98.5.8
 *
 * @generated from protobuf message google.fhir.r4.core.TemplateStatusCodeLifeCycleCode
 */
export interface TemplateStatusCodeLifeCycleCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TemplateStatusCodeLifeCycleCode.Value
 */
export enum TemplateStatusCodeLifeCycleCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: PENDING = 2;
     */
    PENDING = 2,
    /**
     * @generated from protobuf enum value: ACTIVE = 3;
     */
    ACTIVE = 3,
    /**
     * @generated from protobuf enum value: REVIEW = 4;
     */
    REVIEW = 4,
    /**
     * @generated from protobuf enum value: CANCELLED = 5;
     */
    CANCELLED = 5,
    /**
     * @generated from protobuf enum value: REJECTED = 6;
     */
    REJECTED = 6,
    /**
     * @generated from protobuf enum value: RETIRED = 7;
     */
    RETIRED = 7,
    /**
     * @generated from protobuf enum value: TERMINATED = 8;
     */
    TERMINATED = 8
}
/**
 * The results of executing an action.
 * See http://hl7.org/fhir/report-action-result-codes
 *
 * @generated from protobuf message google.fhir.r4.core.TestReportActionResultCode
 */
export interface TestReportActionResultCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TestReportActionResultCode.Value
 */
export enum TestReportActionResultCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PASS = 1;
     */
    PASS = 1,
    /**
     * @generated from protobuf enum value: SKIP = 2;
     */
    SKIP = 2,
    /**
     * @generated from protobuf enum value: FAIL = 3;
     */
    FAIL = 3,
    /**
     * @generated from protobuf enum value: WARNING = 4;
     */
    WARNING = 4,
    /**
     * @generated from protobuf enum value: ERROR = 5;
     */
    ERROR = 5
}
/**
 * The type of participant.
 * See http://hl7.org/fhir/report-participant-type
 *
 * @generated from protobuf message google.fhir.r4.core.TestReportParticipantTypeCode
 */
export interface TestReportParticipantTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TestReportParticipantTypeCode.Value
 */
export enum TestReportParticipantTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: TEST_ENGINE = 1;
     */
    TEST_ENGINE = 1,
    /**
     * @generated from protobuf enum value: CLIENT = 2;
     */
    CLIENT = 2,
    /**
     * @generated from protobuf enum value: SERVER = 3;
     */
    SERVER = 3
}
/**
 * The reported execution result.
 * See http://hl7.org/fhir/report-result-codes
 *
 * @generated from protobuf message google.fhir.r4.core.TestReportResultCode
 */
export interface TestReportResultCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TestReportResultCode.Value
 */
export enum TestReportResultCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PASS = 1;
     */
    PASS = 1,
    /**
     * @generated from protobuf enum value: FAIL = 2;
     */
    FAIL = 2,
    /**
     * @generated from protobuf enum value: PENDING = 3;
     */
    PENDING = 3
}
/**
 * The current status of the test report.
 * See http://hl7.org/fhir/report-status-codes
 *
 * @generated from protobuf message google.fhir.r4.core.TestReportStatusCode
 */
export interface TestReportStatusCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TestReportStatusCode.Value
 */
export enum TestReportStatusCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: COMPLETED = 1;
     */
    COMPLETED = 1,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 2;
     */
    IN_PROGRESS = 2,
    /**
     * @generated from protobuf enum value: WAITING = 3;
     */
    WAITING = 3,
    /**
     * @generated from protobuf enum value: STOPPED = 4;
     */
    STOPPED = 4,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 5;
     */
    ENTERED_IN_ERROR = 5
}
/**
 * The allowable request method or HTTP operation codes.
 * See http://hl7.org/fhir/http-operations
 *
 * @generated from protobuf message google.fhir.r4.core.TestScriptRequestMethodCode
 */
export interface TestScriptRequestMethodCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TestScriptRequestMethodCode.Value
 */
export enum TestScriptRequestMethodCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DELETE = 1;
     */
    DELETE = 1,
    /**
     * @generated from protobuf enum value: GET = 2;
     */
    GET = 2,
    /**
     * @generated from protobuf enum value: OPTIONS = 3;
     */
    OPTIONS = 3,
    /**
     * @generated from protobuf enum value: PATCH = 4;
     */
    PATCH = 4,
    /**
     * @generated from protobuf enum value: POST = 5;
     */
    POST = 5,
    /**
     * @generated from protobuf enum value: PUT = 6;
     */
    PUT = 6,
    /**
     * @generated from protobuf enum value: HEAD = 7;
     */
    HEAD = 7
}
/**
 * The type of trigger.
 * See http://hl7.org/fhir/trigger-type
 *
 * @generated from protobuf message google.fhir.r4.core.TriggerTypeCode
 */
export interface TriggerTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TriggerTypeCode.Value
 */
export enum TriggerTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NAMED_EVENT = 1;
     */
    NAMED_EVENT = 1,
    /**
     * @generated from protobuf enum value: PERIODIC = 2;
     */
    PERIODIC = 2,
    /**
     * @generated from protobuf enum value: DATA_CHANGED = 3;
     */
    DATA_CHANGED = 3,
    /**
     * @generated from protobuf enum value: DATA_ADDED = 4;
     */
    DATA_ADDED = 4,
    /**
     * @generated from protobuf enum value: DATA_MODIFIED = 5;
     */
    DATA_MODIFIED = 5,
    /**
     * @generated from protobuf enum value: DATA_REMOVED = 6;
     */
    DATA_REMOVED = 6,
    /**
     * @generated from protobuf enum value: DATA_ACCESSED = 7;
     */
    DATA_ACCESSED = 7,
    /**
     * @generated from protobuf enum value: DATA_ACCESS_ENDED = 8;
     */
    DATA_ACCESS_ENDED = 8
}
/**
 * How a type relates to its baseDefinition.
 * See http://hl7.org/fhir/type-derivation-rule
 *
 * @generated from protobuf message google.fhir.r4.core.TypeDerivationRuleCode
 */
export interface TypeDerivationRuleCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TypeDerivationRuleCode.Value
 */
export enum TypeDerivationRuleCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SPECIALIZATION = 1;
     */
    SPECIALIZATION = 1,
    /**
     * @generated from protobuf enum value: CONSTRAINT = 2;
     */
    CONSTRAINT = 2
}
/**
 * Codes to identify how UDI data was entered.
 * See http://hl7.org/fhir/udi-entry-type
 *
 * @generated from protobuf message google.fhir.r4.core.UDIEntryTypeCode
 */
export interface UDIEntryTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.UDIEntryTypeCode.Value
 */
export enum UDIEntryTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: BARCODE = 1;
     */
    BARCODE = 1,
    /**
     * @generated from protobuf enum value: RFID = 2;
     */
    RFID = 2,
    /**
     * @generated from protobuf enum value: MANUAL = 3;
     */
    MANUAL = 3,
    /**
     * @generated from protobuf enum value: CARD = 4;
     */
    CARD = 4,
    /**
     * @generated from protobuf enum value: SELF_REPORTED = 5;
     */
    SELF_REPORTED = 5,
    /**
     * @generated from protobuf enum value: UNKNOWN = 6;
     */
    UNKNOWN = 6
}
/**
 * The purpose of the Claim: predetermination, preauthorization, claim.
 * See http://hl7.org/fhir/claim-use
 *
 * @generated from protobuf message google.fhir.r4.core.UseCode
 */
export interface UseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.UseCode.Value
 */
export enum UseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CLAIM = 1;
     */
    CLAIM = 1,
    /**
     * @generated from protobuf enum value: PREAUTHORIZATION = 2;
     */
    PREAUTHORIZATION = 2,
    /**
     * @generated from protobuf enum value: PREDETERMINATION = 3;
     */
    PREDETERMINATION = 3
}
/**
 * FHIR Value set/code system definition for HL7 v2 table 0444 ( Name assembly
 * order) See http://terminology.hl7.org/CodeSystem/v2-0444
 *
 * @generated from protobuf message google.fhir.r4.core.V20444Code
 */
export interface V20444Code {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V20444Code.Value
 */
export enum V20444Code_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: F = 1;
     */
    F = 1,
    /**
     * @generated from protobuf enum value: G = 2;
     */
    G = 2
}
/**
 *  Codes that provide guidance around the circumstances in which a given
 *  address should be used.
 * See http://terminology.hl7.org/CodeSystem/v3-AddressUse
 *
 * @generated from protobuf message google.fhir.r4.core.V3AddressUseCode
 */
export interface V3AddressUseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3AddressUseCode.Value
 */
export enum V3AddressUseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: GENERAL_ADDRESS_USE = 1;
     */
    GENERAL_ADDRESS_USE = 1,
    /**
     * @generated from protobuf enum value: BAD = 2;
     */
    BAD = 2,
    /**
     * @generated from protobuf enum value: CONF = 3;
     */
    CONF = 3,
    /**
     * @generated from protobuf enum value: H = 4;
     */
    H = 4,
    /**
     * @generated from protobuf enum value: HP = 5;
     */
    HP = 5,
    /**
     * @generated from protobuf enum value: HV = 6;
     */
    HV = 6,
    /**
     * @generated from protobuf enum value: OLD = 7;
     */
    OLD = 7,
    /**
     * @generated from protobuf enum value: TMP = 8;
     */
    TMP = 8,
    /**
     * @generated from protobuf enum value: WP = 9;
     */
    WP = 9,
    /**
     * @generated from protobuf enum value: DIR = 10;
     */
    DIR = 10,
    /**
     * @generated from protobuf enum value: PUB = 11;
     */
    PUB = 11,
    /**
     * @generated from protobuf enum value: POSTAL_ADDRESS_USE = 12;
     */
    POSTAL_ADDRESS_USE = 12,
    /**
     * @generated from protobuf enum value: PHYS = 13;
     */
    PHYS = 13,
    /**
     * @generated from protobuf enum value: PST = 14;
     */
    PST = 14,
    /**
     * @generated from protobuf enum value: TELECOMMUNICATION_ADDRESS_USE = 15;
     */
    TELECOMMUNICATION_ADDRESS_USE = 15,
    /**
     * @generated from protobuf enum value: AS = 16;
     */
    AS = 16,
    /**
     * @generated from protobuf enum value: EC = 17;
     */
    EC = 17,
    /**
     * @generated from protobuf enum value: MC = 18;
     */
    MC = 18,
    /**
     * @generated from protobuf enum value: PG = 19;
     */
    PG = 19
}
/**
 *  A set of codes specifying the security classification of acts and roles in
 *  accordance with the definition for concept domain "Confidentiality".
 * See http://terminology.hl7.org/CodeSystem/v3-Confidentiality
 *
 * @generated from protobuf message google.fhir.r4.core.V3ConfidentialityCode
 */
export interface V3ConfidentialityCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3ConfidentialityCode.Value
 */
export enum V3ConfidentialityCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CONFIDENTIALITY = 1;
     */
    CONFIDENTIALITY = 1,
    /**
     * @generated from protobuf enum value: L = 2;
     */
    L = 2,
    /**
     * @generated from protobuf enum value: M = 3;
     */
    M = 3,
    /**
     * @generated from protobuf enum value: N = 4;
     */
    N = 4,
    /**
     * @generated from protobuf enum value: R = 5;
     */
    R = 5,
    /**
     * @generated from protobuf enum value: U = 6;
     */
    U = 6,
    /**
     * @generated from protobuf enum value: V = 7;
     */
    V = 7,
    /**
     * @generated from protobuf enum value: CONFIDENTIALITY_BY_ACCESS_KIND = 8;
     */
    CONFIDENTIALITY_BY_ACCESS_KIND = 8,
    /**
     * @generated from protobuf enum value: B = 9;
     */
    B = 9,
    /**
     * @generated from protobuf enum value: D = 10;
     */
    D = 10,
    /**
     * @generated from protobuf enum value: I = 11;
     */
    I = 11,
    /**
     * @generated from protobuf enum value: CONFIDENTIALITY_BY_INFO_TYPE = 12;
     */
    CONFIDENTIALITY_BY_INFO_TYPE = 12,
    /**
     * @generated from protobuf enum value: ETH = 13;
     */
    ETH = 13,
    /**
     * @generated from protobuf enum value: HIV = 14;
     */
    HIV = 14,
    /**
     * @generated from protobuf enum value: PSY = 15;
     */
    PSY = 15,
    /**
     * @generated from protobuf enum value: SDV = 16;
     */
    SDV = 16,
    /**
     * @generated from protobuf enum value: CONFIDENTIALITY_MODIFIERS = 17;
     */
    CONFIDENTIALITY_MODIFIERS = 17,
    /**
     * @generated from protobuf enum value: C = 18;
     */
    C = 18,
    /**
     * @generated from protobuf enum value: S = 19;
     */
    S = 19,
    /**
     * @generated from protobuf enum value: T = 20;
     */
    T = 20
}
/**
 *   OpenIssue:
 *
 * Needs description
 * See http://terminology.hl7.org/CodeSystem/v3-EntityNamePartQualifier
 *
 * @generated from protobuf message google.fhir.r4.core.V3EntityNamePartQualifierCode
 */
export interface V3EntityNamePartQualifierCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3EntityNamePartQualifierCode.Value
 */
export enum V3EntityNamePartQualifierCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ORGANIZATION_NAME_PART_QUALIFIER = 1;
     */
    ORGANIZATION_NAME_PART_QUALIFIER = 1,
    /**
     * @generated from protobuf enum value: AC = 2;
     */
    AC = 2,
    /**
     * @generated from protobuf enum value: AD = 3;
     */
    AD = 3,
    /**
     * @generated from protobuf enum value: BR = 4;
     */
    BR = 4,
    /**
     * @generated from protobuf enum value: CL = 5;
     */
    CL = 5,
    /**
     * @generated from protobuf enum value: IN = 6;
     */
    IN = 6,
    /**
     * @generated from protobuf enum value: LS = 7;
     */
    LS = 7,
    /**
     * @generated from protobuf enum value: NB = 8;
     */
    NB = 8,
    /**
     * @generated from protobuf enum value: PR = 9;
     */
    PR = 9,
    /**
     * @generated from protobuf enum value: SP = 10;
     */
    SP = 10,
    /**
     * @generated from protobuf enum value: TITLE = 11;
     */
    TITLE = 11,
    /**
     * @generated from protobuf enum value: VV = 12;
     */
    VV = 12,
    /**
     * @generated from protobuf enum value: PHARMACEUTICAL_ENTITY_NAME_PART_QUALIFIERS = 13;
     */
    PHARMACEUTICAL_ENTITY_NAME_PART_QUALIFIERS = 13,
    /**
     * @generated from protobuf enum value: CON = 14;
     */
    CON = 14,
    /**
     * @generated from protobuf enum value: DEV = 15;
     */
    DEV = 15,
    /**
     * @generated from protobuf enum value: FLAV = 16;
     */
    FLAV = 16,
    /**
     * @generated from protobuf enum value: FORMUL = 17;
     */
    FORMUL = 17,
    /**
     * @generated from protobuf enum value: FRM = 18;
     */
    FRM = 18,
    /**
     * @generated from protobuf enum value: INV = 19;
     */
    INV = 19,
    /**
     * @generated from protobuf enum value: POPUL = 20;
     */
    POPUL = 20,
    /**
     * @generated from protobuf enum value: SCI = 21;
     */
    SCI = 21,
    /**
     * @generated from protobuf enum value: STR = 22;
     */
    STR = 22,
    /**
     * @generated from protobuf enum value: TIME = 23;
     */
    TIME = 23,
    /**
     * @generated from protobuf enum value: TMK = 24;
     */
    TMK = 24,
    /**
     * @generated from protobuf enum value: USE = 25;
     */
    USE = 25,
    /**
     * @generated from protobuf enum value: PERSON_NAME_PART_QUALIFIER = 26;
     */
    PERSON_NAME_PART_QUALIFIER = 26,
    /**
     * @generated from protobuf enum value: PERSON_NAME_PART_AFFIX_TYPES = 27;
     */
    PERSON_NAME_PART_AFFIX_TYPES = 27,
    /**
     * @generated from protobuf enum value: PERSON_NAME_PART_CHANGE_QUALIFIER = 28;
     */
    PERSON_NAME_PART_CHANGE_QUALIFIER = 28,
    /**
     * @generated from protobuf enum value: PERSON_NAME_PART_MISC_QUALIFIER = 29;
     */
    PERSON_NAME_PART_MISC_QUALIFIER = 29
}
/**
 *   Description:
 *
 * The qualifier is a set of codes each of which specifies a certain subcategory
 * of the name part in addition to the main name part type. For example, a given
 * name may be flagged as a nickname, a family name may be a pseudonym or a name
 * of public records. See
 * http://terminology.hl7.org/CodeSystem/v3-EntityNamePartQualifierR2
 *
 * @generated from protobuf message google.fhir.r4.core.V3EntityNamePartQualifierR2Code
 */
export interface V3EntityNamePartQualifierR2Code {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3EntityNamePartQualifierR2Code.Value
 */
export enum V3EntityNamePartQualifierR2Code_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AD = 1;
     */
    AD = 1,
    /**
     * @generated from protobuf enum value: SP = 2;
     */
    SP = 2,
    /**
     * @generated from protobuf enum value: BR = 3;
     */
    BR = 3,
    /**
     * @generated from protobuf enum value: CL = 4;
     */
    CL = 4,
    /**
     * @generated from protobuf enum value: IN = 5;
     */
    IN = 5,
    /**
     * @generated from protobuf enum value: LS = 6;
     */
    LS = 6,
    /**
     * @generated from protobuf enum value: MID = 7;
     */
    MID = 7,
    /**
     * @generated from protobuf enum value: PFX = 8;
     */
    PFX = 8,
    /**
     * @generated from protobuf enum value: PHARMACEUTICAL_ENTITY_NAME_PART_QUALIFIERS = 9;
     */
    PHARMACEUTICAL_ENTITY_NAME_PART_QUALIFIERS = 9,
    /**
     * @generated from protobuf enum value: CON = 10;
     */
    CON = 10,
    /**
     * @generated from protobuf enum value: DEV = 11;
     */
    DEV = 11,
    /**
     * @generated from protobuf enum value: FLAV = 12;
     */
    FLAV = 12,
    /**
     * @generated from protobuf enum value: FORMUL = 13;
     */
    FORMUL = 13,
    /**
     * @generated from protobuf enum value: FRM = 14;
     */
    FRM = 14,
    /**
     * @generated from protobuf enum value: INV = 15;
     */
    INV = 15,
    /**
     * @generated from protobuf enum value: POPUL = 16;
     */
    POPUL = 16,
    /**
     * @generated from protobuf enum value: SCI = 17;
     */
    SCI = 17,
    /**
     * @generated from protobuf enum value: STR = 18;
     */
    STR = 18,
    /**
     * @generated from protobuf enum value: TIME = 19;
     */
    TIME = 19,
    /**
     * @generated from protobuf enum value: TMK = 20;
     */
    TMK = 20,
    /**
     * @generated from protobuf enum value: USE = 21;
     */
    USE = 21,
    /**
     * @generated from protobuf enum value: SFX = 22;
     */
    SFX = 22,
    /**
     * @generated from protobuf enum value: TITLE_STYLES = 23;
     */
    TITLE_STYLES = 23,
    /**
     * @generated from protobuf enum value: AC = 24;
     */
    AC = 24,
    /**
     * @generated from protobuf enum value: HON = 25;
     */
    HON = 25,
    /**
     * @generated from protobuf enum value: NB = 26;
     */
    NB = 26,
    /**
     * @generated from protobuf enum value: PR = 27;
     */
    PR = 27
}
/**
 * **** MISSING DEFINITIONS ****
 * See http://terminology.hl7.org/CodeSystem/v3-EntityNameUse
 *
 * @generated from protobuf message google.fhir.r4.core.V3EntityNameUseCode
 */
export interface V3EntityNameUseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3EntityNameUseCode.Value
 */
export enum V3EntityNameUseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NAME_REPRESENTATION_USE = 1;
     */
    NAME_REPRESENTATION_USE = 1,
    /**
     * @generated from protobuf enum value: ABC = 2;
     */
    ABC = 2,
    /**
     * @generated from protobuf enum value: IDE = 3;
     */
    IDE = 3,
    /**
     * @generated from protobuf enum value: SYL = 4;
     */
    SYL = 4,
    /**
     * @generated from protobuf enum value: ASGN = 5;
     */
    ASGN = 5,
    /**
     * @generated from protobuf enum value: C = 6;
     */
    C = 6,
    /**
     * @generated from protobuf enum value: I = 7;
     */
    I = 7,
    /**
     * @generated from protobuf enum value: L = 8;
     */
    L = 8,
    /**
     * @generated from protobuf enum value: OR = 9;
     */
    OR = 9,
    /**
     * @generated from protobuf enum value: P = 10;
     */
    P = 10,
    /**
     * @generated from protobuf enum value: A = 11;
     */
    A = 11,
    /**
     * @generated from protobuf enum value: R = 12;
     */
    R = 12,
    /**
     * @generated from protobuf enum value: SRCH = 13;
     */
    SRCH = 13,
    /**
     * @generated from protobuf enum value: PHON = 14;
     */
    PHON = 14,
    /**
     * @generated from protobuf enum value: SNDX = 15;
     */
    SNDX = 15
}
/**
 *   Description:
 *
 * A set of codes advising a system or user which name in a set of names to
 * select for a given purpose. See
 * http://terminology.hl7.org/CodeSystem/v3-EntityNameUseR2
 *
 * @generated from protobuf message google.fhir.r4.core.V3EntityNameUseR2Code
 */
export interface V3EntityNameUseR2Code {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3EntityNameUseR2Code.Value
 */
export enum V3EntityNameUseR2Code_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ASSUMED = 1;
     */
    ASSUMED = 1,
    /**
     * @generated from protobuf enum value: A = 2;
     */
    A = 2,
    /**
     * @generated from protobuf enum value: ANON = 3;
     */
    ANON = 3,
    /**
     * @generated from protobuf enum value: I = 4;
     */
    I = 4,
    /**
     * @generated from protobuf enum value: P = 5;
     */
    P = 5,
    /**
     * @generated from protobuf enum value: R = 6;
     */
    R = 6,
    /**
     * @generated from protobuf enum value: C = 7;
     */
    C = 7,
    /**
     * @generated from protobuf enum value: M = 8;
     */
    M = 8,
    /**
     * @generated from protobuf enum value: NAME_REPRESENTATION_USE = 9;
     */
    NAME_REPRESENTATION_USE = 9,
    /**
     * @generated from protobuf enum value: ABC = 10;
     */
    ABC = 10,
    /**
     * @generated from protobuf enum value: IDE = 11;
     */
    IDE = 11,
    /**
     * @generated from protobuf enum value: SYL = 12;
     */
    SYL = 12,
    /**
     * @generated from protobuf enum value: OLD = 13;
     */
    OLD = 13,
    /**
     * @generated from protobuf enum value: DN = 14;
     */
    DN = 14,
    /**
     * @generated from protobuf enum value: OR = 15;
     */
    OR = 15,
    /**
     * @generated from protobuf enum value: PHON = 16;
     */
    PHON = 16,
    /**
     * @generated from protobuf enum value: SRCH = 17;
     */
    SRCH = 17,
    /**
     * @generated from protobuf enum value: T = 18;
     */
    T = 18
}
/**
 *  A collection of codes specifying why a valid value is not present.
 * See http://terminology.hl7.org/CodeSystem/v3-NullFlavor
 *
 * @generated from protobuf message google.fhir.r4.core.V3NullFlavorCode
 */
export interface V3NullFlavorCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3NullFlavorCode.Value
 */
export enum V3NullFlavorCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NI = 1;
     */
    NI = 1,
    /**
     * @generated from protobuf enum value: INV = 2;
     */
    INV = 2,
    /**
     * @generated from protobuf enum value: DER = 3;
     */
    DER = 3,
    /**
     * @generated from protobuf enum value: OTH = 4;
     */
    OTH = 4,
    /**
     * @generated from protobuf enum value: NINF = 5;
     */
    NINF = 5,
    /**
     * @generated from protobuf enum value: PINF = 6;
     */
    PINF = 6,
    /**
     * @generated from protobuf enum value: UNC = 7;
     */
    UNC = 7,
    /**
     * @generated from protobuf enum value: MSK = 8;
     */
    MSK = 8,
    /**
     * @generated from protobuf enum value: NA = 9;
     */
    NA = 9,
    /**
     * @generated from protobuf enum value: UNK = 10;
     */
    UNK = 10,
    /**
     * @generated from protobuf enum value: ASKU = 11;
     */
    ASKU = 11,
    /**
     * @generated from protobuf enum value: NAV = 12;
     */
    NAV = 12,
    /**
     * @generated from protobuf enum value: NASK = 13;
     */
    NASK = 13,
    /**
     * @generated from protobuf enum value: NAVU = 14;
     */
    NAVU = 14,
    /**
     * @generated from protobuf enum value: QS = 15;
     */
    QS = 15,
    /**
     * @generated from protobuf enum value: TRC = 16;
     */
    TRC = 16,
    /**
     * @generated from protobuf enum value: NP = 17;
     */
    NP = 17
}
/**
 *  A set of codes specifying the modality by which the Entity playing the Role
 *  is participating in the Act.  Examples:
 *
 * Physically present, over the telephone, written communication.  Rationale:
 *
 * Particularly for author (originator) participants this is used to specify
 * whether the information represented by the act was initially provided
 * verbally, (hand-)written, or electronically.  Open Issue:
 *
 * There needs to be a reexamination of the hierarchies as there seems to be
 * some muddling between ELECTRONIC and other concepts that involve electronic
 * communication that are in other hierarchies. See
 * http://terminology.hl7.org/CodeSystem/v3-ParticipationMode
 *
 * @generated from protobuf message google.fhir.r4.core.V3ParticipationModeCode
 */
export interface V3ParticipationModeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3ParticipationModeCode.Value
 */
export enum V3ParticipationModeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ELECTRONIC = 1;
     */
    ELECTRONIC = 1,
    /**
     * @generated from protobuf enum value: PHYSICAL = 2;
     */
    PHYSICAL = 2,
    /**
     * @generated from protobuf enum value: REMOTE = 3;
     */
    REMOTE = 3,
    /**
     * @generated from protobuf enum value: VERBAL = 4;
     */
    VERBAL = 4,
    /**
     * @generated from protobuf enum value: DICTATE = 5;
     */
    DICTATE = 5,
    /**
     * @generated from protobuf enum value: FACE = 6;
     */
    FACE = 6,
    /**
     * @generated from protobuf enum value: PHONE = 7;
     */
    PHONE = 7,
    /**
     * @generated from protobuf enum value: VIDEOCONF = 8;
     */
    VIDEOCONF = 8,
    /**
     * @generated from protobuf enum value: WRITTEN = 9;
     */
    WRITTEN = 9,
    /**
     * @generated from protobuf enum value: FAXWRIT = 10;
     */
    FAXWRIT = 10,
    /**
     * @generated from protobuf enum value: HANDWRIT = 11;
     */
    HANDWRIT = 11,
    /**
     * @generated from protobuf enum value: MAILWRIT = 12;
     */
    MAILWRIT = 12,
    /**
     * @generated from protobuf enum value: ONLINEWRIT = 13;
     */
    ONLINEWRIT = 13,
    /**
     * @generated from protobuf enum value: EMAILWRIT = 14;
     */
    EMAILWRIT = 14,
    /**
     * @generated from protobuf enum value: TYPEWRIT = 15;
     */
    TYPEWRIT = 15
}
/**
 * **** MISSING DEFINITIONS ****
 * See http://terminology.hl7.org/CodeSystem/v3-ProbabilityDistributionType
 *
 * @generated from protobuf message google.fhir.r4.core.V3ProbabilityDistributionTypeCode
 */
export interface V3ProbabilityDistributionTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3ProbabilityDistributionTypeCode.Value
 */
export enum V3ProbabilityDistributionTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: B = 1;
     */
    B = 1,
    /**
     * @generated from protobuf enum value: E = 2;
     */
    E = 2,
    /**
     * @generated from protobuf enum value: F = 3;
     */
    F = 3,
    /**
     * @generated from protobuf enum value: G = 4;
     */
    G = 4,
    /**
     * @generated from protobuf enum value: LN = 5;
     */
    LN = 5,
    /**
     * @generated from protobuf enum value: N = 6;
     */
    N = 6,
    /**
     * @generated from protobuf enum value: T = 7;
     */
    T = 7,
    /**
     * @generated from protobuf enum value: U = 8;
     */
    U = 8,
    /**
     * @generated from protobuf enum value: X2 = 9;
     */
    X2 = 9
}
/**
 *  A set of codes further specifying the kind of Role; specific classification
 *  codes for further qualifying RoleClass codes.
 * See http://terminology.hl7.org/CodeSystem/v3-RoleCode
 *
 * @generated from protobuf message google.fhir.r4.core.V3RoleCode
 */
export interface V3RoleCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3RoleCode.Value
 */
export enum V3RoleCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AFFILIATION_ROLE_TYPE = 1;
     */
    AFFILIATION_ROLE_TYPE = 1,
    /**
     * @generated from protobuf enum value: AGENT_ROLE_TYPE = 2;
     */
    AGENT_ROLE_TYPE = 2,
    /**
     * @generated from protobuf enum value: AMENDER = 3;
     */
    AMENDER = 3,
    /**
     * @generated from protobuf enum value: CLASSIFIER = 4;
     */
    CLASSIFIER = 4,
    /**
     * @generated from protobuf enum value: CONSENTER = 5;
     */
    CONSENTER = 5,
    /**
     * @generated from protobuf enum value: CONSWIT = 6;
     */
    CONSWIT = 6,
    /**
     * @generated from protobuf enum value: COPART = 7;
     */
    COPART = 7,
    /**
     * @generated from protobuf enum value: DECLASSIFIER = 8;
     */
    DECLASSIFIER = 8,
    /**
     * @generated from protobuf enum value: DELEGATEE = 9;
     */
    DELEGATEE = 9,
    /**
     * @generated from protobuf enum value: DELEGATOR = 10;
     */
    DELEGATOR = 10,
    /**
     * @generated from protobuf enum value: DOWNGRDER = 11;
     */
    DOWNGRDER = 11,
    /**
     * @generated from protobuf enum value: DRIVCLASSIFIER = 12;
     */
    DRIVCLASSIFIER = 12,
    /**
     * @generated from protobuf enum value: GRANTEE = 13;
     */
    GRANTEE = 13,
    /**
     * @generated from protobuf enum value: GRANTOR = 14;
     */
    GRANTOR = 14,
    /**
     * @generated from protobuf enum value: INTPRTER = 15;
     */
    INTPRTER = 15,
    /**
     * @generated from protobuf enum value: REVIEWER = 16;
     */
    REVIEWER = 16,
    /**
     * @generated from protobuf enum value: VALIDATOR = 17;
     */
    VALIDATOR = 17,
    /**
     * @generated from protobuf enum value: COVERAGE_SPONSOR_ROLE_TYPE = 18;
     */
    COVERAGE_SPONSOR_ROLE_TYPE = 18,
    /**
     * @generated from protobuf enum value: FULLINS = 19;
     */
    FULLINS = 19,
    /**
     * @generated from protobuf enum value: SELFINS = 20;
     */
    SELFINS = 20,
    /**
     * @generated from protobuf enum value: PAYOR_ROLE_TYPE = 21;
     */
    PAYOR_ROLE_TYPE = 21,
    /**
     * @generated from protobuf enum value: ENROLBKR = 22;
     */
    ENROLBKR = 22,
    /**
     * @generated from protobuf enum value: TPA = 23;
     */
    TPA = 23,
    /**
     * @generated from protobuf enum value: UMO = 24;
     */
    UMO = 24,
    /**
     * @generated from protobuf enum value: RESPRSN = 25;
     */
    RESPRSN = 25,
    /**
     * @generated from protobuf enum value: EXCEST = 26;
     */
    EXCEST = 26,
    /**
     * @generated from protobuf enum value: GUADLTM = 27;
     */
    GUADLTM = 27,
    /**
     * @generated from protobuf enum value: GUARD = 28;
     */
    GUARD = 28,
    /**
     * @generated from protobuf enum value: POWATT = 29;
     */
    POWATT = 29,
    /**
     * @generated from protobuf enum value: DPOWATT = 30;
     */
    DPOWATT = 30,
    /**
     * @generated from protobuf enum value: HPOWATT = 31;
     */
    HPOWATT = 31,
    /**
     * @generated from protobuf enum value: SPOWATT = 32;
     */
    SPOWATT = 32,
    /**
     * @generated from protobuf enum value: ASSIGNED_ROLE_TYPE = 33;
     */
    ASSIGNED_ROLE_TYPE = 33,
    /**
     * @generated from protobuf enum value: ASSIGNED_NON_PERSON_LIVING_SUBJECT_ROLE_TYPE = 34;
     */
    ASSIGNED_NON_PERSON_LIVING_SUBJECT_ROLE_TYPE = 34,
    /**
     * @generated from protobuf enum value: ASSIST = 35;
     */
    ASSIST = 35,
    /**
     * @generated from protobuf enum value: BIOTH = 36;
     */
    BIOTH = 36,
    /**
     * @generated from protobuf enum value: ANTIBIOT = 37;
     */
    ANTIBIOT = 37,
    /**
     * @generated from protobuf enum value: DEBR = 38;
     */
    DEBR = 38,
    /**
     * @generated from protobuf enum value: CCO = 39;
     */
    CCO = 39,
    /**
     * @generated from protobuf enum value: SEE = 40;
     */
    SEE = 40,
    /**
     * @generated from protobuf enum value: SNIFF = 41;
     */
    SNIFF = 41,
    /**
     * @generated from protobuf enum value: CERTIFIED_ENTITY_TYPE = 42;
     */
    CERTIFIED_ENTITY_TYPE = 42,
    /**
     * @generated from protobuf enum value: CITIZEN_ROLE_TYPE = 43;
     */
    CITIZEN_ROLE_TYPE = 43,
    /**
     * @generated from protobuf enum value: CAS = 44;
     */
    CAS = 44,
    /**
     * @generated from protobuf enum value: CASM = 45;
     */
    CASM = 45,
    /**
     * @generated from protobuf enum value: CN = 46;
     */
    CN = 46,
    /**
     * @generated from protobuf enum value: CNRP = 47;
     */
    CNRP = 47,
    /**
     * @generated from protobuf enum value: CNRPM = 48;
     */
    CNRPM = 48,
    /**
     * @generated from protobuf enum value: CPCA = 49;
     */
    CPCA = 49,
    /**
     * @generated from protobuf enum value: CRP = 50;
     */
    CRP = 50,
    /**
     * @generated from protobuf enum value: CRPM = 51;
     */
    CRPM = 51,
    /**
     * @generated from protobuf enum value: CONTACT_ROLE_TYPE = 52;
     */
    CONTACT_ROLE_TYPE = 52,
    /**
     * @generated from protobuf enum value: ADMINISTRATIVE_CONTACT_ROLE_TYPE = 53;
     */
    ADMINISTRATIVE_CONTACT_ROLE_TYPE = 53,
    /**
     * @generated from protobuf enum value: BILL = 54;
     */
    BILL = 54,
    /**
     * @generated from protobuf enum value: ORG = 55;
     */
    ORG = 55,
    /**
     * @generated from protobuf enum value: PAYOR = 56;
     */
    PAYOR = 56,
    /**
     * @generated from protobuf enum value: ECON = 57;
     */
    ECON = 57,
    /**
     * @generated from protobuf enum value: NOK = 58;
     */
    NOK = 58,
    /**
     * @generated from protobuf enum value: IDENTIFIED_ENTITY_TYPE = 59;
     */
    IDENTIFIED_ENTITY_TYPE = 59,
    /**
     * @generated from protobuf enum value: LOCATION_IDENTIFIED_ENTITY_ROLE_CODE = 60;
     */
    LOCATION_IDENTIFIED_ENTITY_ROLE_CODE = 60,
    /**
     * @generated from protobuf enum value: ACHFID = 61;
     */
    ACHFID = 61,
    /**
     * @generated from protobuf enum value: JURID = 62;
     */
    JURID = 62,
    /**
     * @generated from protobuf enum value: LOCHFID = 63;
     */
    LOCHFID = 63,
    /**
     * @generated from protobuf enum value: LIVING_SUBJECT_PRODUCTION_CLASS = 64;
     */
    LIVING_SUBJECT_PRODUCTION_CLASS = 64,
    /**
     * @generated from protobuf enum value: BF = 65;
     */
    BF = 65,
    /**
     * @generated from protobuf enum value: BL = 66;
     */
    BL = 66,
    /**
     * @generated from protobuf enum value: BR = 67;
     */
    BR = 67,
    /**
     * @generated from protobuf enum value: CO = 68;
     */
    CO = 68,
    /**
     * @generated from protobuf enum value: DA = 69;
     */
    DA = 69,
    /**
     * @generated from protobuf enum value: DR = 70;
     */
    DR = 70,
    /**
     * @generated from protobuf enum value: DU = 71;
     */
    DU = 71,
    /**
     * @generated from protobuf enum value: FI = 72;
     */
    FI = 72,
    /**
     * @generated from protobuf enum value: LY = 73;
     */
    LY = 73,
    /**
     * @generated from protobuf enum value: MT = 74;
     */
    MT = 74,
    /**
     * @generated from protobuf enum value: MU = 75;
     */
    MU = 75,
    /**
     * @generated from protobuf enum value: PL = 76;
     */
    PL = 76,
    /**
     * @generated from protobuf enum value: RC = 77;
     */
    RC = 77,
    /**
     * @generated from protobuf enum value: SH = 78;
     */
    SH = 78,
    /**
     * @generated from protobuf enum value: VL = 79;
     */
    VL = 79,
    /**
     * @generated from protobuf enum value: WL = 80;
     */
    WL = 80,
    /**
     * @generated from protobuf enum value: WO = 81;
     */
    WO = 81,
    /**
     * @generated from protobuf enum value: MEDICATION_GENERALIZATION_ROLE_TYPE = 82;
     */
    MEDICATION_GENERALIZATION_ROLE_TYPE = 82,
    /**
     * @generated from protobuf enum value: DC = 83;
     */
    DC = 83,
    /**
     * @generated from protobuf enum value: GD = 84;
     */
    GD = 84,
    /**
     * @generated from protobuf enum value: GDF = 85;
     */
    GDF = 85,
    /**
     * @generated from protobuf enum value: GDS = 86;
     */
    GDS = 86,
    /**
     * @generated from protobuf enum value: GDSF = 87;
     */
    GDSF = 87,
    /**
     * @generated from protobuf enum value: MGDSF = 88;
     */
    MGDSF = 88,
    /**
     * @generated from protobuf enum value: MEMBER_ROLE_TYPE = 89;
     */
    MEMBER_ROLE_TYPE = 89,
    /**
     * @generated from protobuf enum value: TRB = 90;
     */
    TRB = 90,
    /**
     * @generated from protobuf enum value: PERSONAL_RELATIONSHIP_ROLE_TYPE = 91;
     */
    PERSONAL_RELATIONSHIP_ROLE_TYPE = 91,
    /**
     * @generated from protobuf enum value: FAMMEMB = 92;
     */
    FAMMEMB = 92,
    /**
     * @generated from protobuf enum value: CHILD = 93;
     */
    CHILD = 93,
    /**
     * @generated from protobuf enum value: CHLDADOPT = 94;
     */
    CHLDADOPT = 94,
    /**
     * @generated from protobuf enum value: DAUADOPT = 95;
     */
    DAUADOPT = 95,
    /**
     * @generated from protobuf enum value: SONADOPT = 96;
     */
    SONADOPT = 96,
    /**
     * @generated from protobuf enum value: CHLDFOST = 97;
     */
    CHLDFOST = 97,
    /**
     * @generated from protobuf enum value: DAUFOST = 98;
     */
    DAUFOST = 98,
    /**
     * @generated from protobuf enum value: SONFOST = 99;
     */
    SONFOST = 99,
    /**
     * @generated from protobuf enum value: DAUC = 100;
     */
    DAUC = 100,
    /**
     * @generated from protobuf enum value: DAU = 101;
     */
    DAU = 101,
    /**
     * @generated from protobuf enum value: STPDAU = 102;
     */
    STPDAU = 102,
    /**
     * @generated from protobuf enum value: NCHILD = 103;
     */
    NCHILD = 103,
    /**
     * @generated from protobuf enum value: SON = 104;
     */
    SON = 104,
    /**
     * @generated from protobuf enum value: SONC = 105;
     */
    SONC = 105,
    /**
     * @generated from protobuf enum value: STPSON = 106;
     */
    STPSON = 106,
    /**
     * @generated from protobuf enum value: STPCHLD = 107;
     */
    STPCHLD = 107,
    /**
     * @generated from protobuf enum value: EXT = 108;
     */
    EXT = 108,
    /**
     * @generated from protobuf enum value: AUNT = 109;
     */
    AUNT = 109,
    /**
     * @generated from protobuf enum value: MAUNT = 110;
     */
    MAUNT = 110,
    /**
     * @generated from protobuf enum value: PAUNT = 111;
     */
    PAUNT = 111,
    /**
     * @generated from protobuf enum value: COUSN = 112;
     */
    COUSN = 112,
    /**
     * @generated from protobuf enum value: MCOUSN = 113;
     */
    MCOUSN = 113,
    /**
     * @generated from protobuf enum value: PCOUSN = 114;
     */
    PCOUSN = 114,
    /**
     * @generated from protobuf enum value: GGRPRN = 115;
     */
    GGRPRN = 115,
    /**
     * @generated from protobuf enum value: GGRFTH = 116;
     */
    GGRFTH = 116,
    /**
     * @generated from protobuf enum value: MGGRFTH = 117;
     */
    MGGRFTH = 117,
    /**
     * @generated from protobuf enum value: PGGRFTH = 118;
     */
    PGGRFTH = 118,
    /**
     * @generated from protobuf enum value: GGRMTH = 119;
     */
    GGRMTH = 119,
    /**
     * @generated from protobuf enum value: MGGRMTH = 120;
     */
    MGGRMTH = 120,
    /**
     * @generated from protobuf enum value: PGGRMTH = 121;
     */
    PGGRMTH = 121,
    /**
     * @generated from protobuf enum value: MGGRPRN = 122;
     */
    MGGRPRN = 122,
    /**
     * @generated from protobuf enum value: PGGRPRN = 123;
     */
    PGGRPRN = 123,
    /**
     * @generated from protobuf enum value: GRNDCHILD = 124;
     */
    GRNDCHILD = 124,
    /**
     * @generated from protobuf enum value: GRNDDAU = 125;
     */
    GRNDDAU = 125,
    /**
     * @generated from protobuf enum value: GRNDSON = 126;
     */
    GRNDSON = 126,
    /**
     * @generated from protobuf enum value: GRPRN = 127;
     */
    GRPRN = 127,
    /**
     * @generated from protobuf enum value: GRFTH = 128;
     */
    GRFTH = 128,
    /**
     * @generated from protobuf enum value: MGRFTH = 129;
     */
    MGRFTH = 129,
    /**
     * @generated from protobuf enum value: PGRFTH = 130;
     */
    PGRFTH = 130,
    /**
     * @generated from protobuf enum value: GRMTH = 131;
     */
    GRMTH = 131,
    /**
     * @generated from protobuf enum value: MGRMTH = 132;
     */
    MGRMTH = 132,
    /**
     * @generated from protobuf enum value: PGRMTH = 133;
     */
    PGRMTH = 133,
    /**
     * @generated from protobuf enum value: MGRPRN = 134;
     */
    MGRPRN = 134,
    /**
     * @generated from protobuf enum value: PGRPRN = 135;
     */
    PGRPRN = 135,
    /**
     * @generated from protobuf enum value: INLAW = 136;
     */
    INLAW = 136,
    /**
     * @generated from protobuf enum value: CHLDINLAW = 137;
     */
    CHLDINLAW = 137,
    /**
     * @generated from protobuf enum value: DAUINLAW = 138;
     */
    DAUINLAW = 138,
    /**
     * @generated from protobuf enum value: SONINLAW = 139;
     */
    SONINLAW = 139,
    /**
     * @generated from protobuf enum value: PRNINLAW = 140;
     */
    PRNINLAW = 140,
    /**
     * @generated from protobuf enum value: FTHINLAW = 141;
     */
    FTHINLAW = 141,
    /**
     * @generated from protobuf enum value: MTHINLAW = 142;
     */
    MTHINLAW = 142,
    /**
     * @generated from protobuf enum value: SIBINLAW = 143;
     */
    SIBINLAW = 143,
    /**
     * @generated from protobuf enum value: BROINLAW = 144;
     */
    BROINLAW = 144,
    /**
     * @generated from protobuf enum value: SISINLAW = 145;
     */
    SISINLAW = 145,
    /**
     * @generated from protobuf enum value: NIENEPH = 146;
     */
    NIENEPH = 146,
    /**
     * @generated from protobuf enum value: NEPHEW = 147;
     */
    NEPHEW = 147,
    /**
     * @generated from protobuf enum value: NIECE = 148;
     */
    NIECE = 148,
    /**
     * @generated from protobuf enum value: UNCLE = 149;
     */
    UNCLE = 149,
    /**
     * @generated from protobuf enum value: MUNCLE = 150;
     */
    MUNCLE = 150,
    /**
     * @generated from protobuf enum value: PUNCLE = 151;
     */
    PUNCLE = 151,
    /**
     * @generated from protobuf enum value: PRN = 152;
     */
    PRN = 152,
    /**
     * @generated from protobuf enum value: ADOPTP = 153;
     */
    ADOPTP = 153,
    /**
     * @generated from protobuf enum value: ADOPTF = 154;
     */
    ADOPTF = 154,
    /**
     * @generated from protobuf enum value: ADOPTM = 155;
     */
    ADOPTM = 155,
    /**
     * @generated from protobuf enum value: FTH = 156;
     */
    FTH = 156,
    /**
     * @generated from protobuf enum value: FTHFOST = 157;
     */
    FTHFOST = 157,
    /**
     * @generated from protobuf enum value: NFTH = 158;
     */
    NFTH = 158,
    /**
     * @generated from protobuf enum value: NFTHF = 159;
     */
    NFTHF = 159,
    /**
     * @generated from protobuf enum value: STPFTH = 160;
     */
    STPFTH = 160,
    /**
     * @generated from protobuf enum value: MTH = 161;
     */
    MTH = 161,
    /**
     * @generated from protobuf enum value: GESTM = 162;
     */
    GESTM = 162,
    /**
     * @generated from protobuf enum value: MTHFOST = 163;
     */
    MTHFOST = 163,
    /**
     * @generated from protobuf enum value: NMTH = 164;
     */
    NMTH = 164,
    /**
     * @generated from protobuf enum value: NMTHF = 165;
     */
    NMTHF = 165,
    /**
     * @generated from protobuf enum value: STPMTH = 166;
     */
    STPMTH = 166,
    /**
     * @generated from protobuf enum value: NPRN = 167;
     */
    NPRN = 167,
    /**
     * @generated from protobuf enum value: PRNFOST = 168;
     */
    PRNFOST = 168,
    /**
     * @generated from protobuf enum value: STPPRN = 169;
     */
    STPPRN = 169,
    /**
     * @generated from protobuf enum value: SIB = 170;
     */
    SIB = 170,
    /**
     * @generated from protobuf enum value: BRO = 171;
     */
    BRO = 171,
    /**
     * @generated from protobuf enum value: HBRO = 172;
     */
    HBRO = 172,
    /**
     * @generated from protobuf enum value: NBRO = 173;
     */
    NBRO = 173,
    /**
     * @generated from protobuf enum value: TWINBRO = 174;
     */
    TWINBRO = 174,
    /**
     * @generated from protobuf enum value: FTWINBRO = 175;
     */
    FTWINBRO = 175,
    /**
     * @generated from protobuf enum value: ITWINBRO = 176;
     */
    ITWINBRO = 176,
    /**
     * @generated from protobuf enum value: STPBRO = 177;
     */
    STPBRO = 177,
    /**
     * @generated from protobuf enum value: HSIB = 178;
     */
    HSIB = 178,
    /**
     * @generated from protobuf enum value: HSIS = 179;
     */
    HSIS = 179,
    /**
     * @generated from protobuf enum value: NSIB = 180;
     */
    NSIB = 180,
    /**
     * @generated from protobuf enum value: NSIS = 181;
     */
    NSIS = 181,
    /**
     * @generated from protobuf enum value: TWINSIS = 182;
     */
    TWINSIS = 182,
    /**
     * @generated from protobuf enum value: FTWINSIS = 183;
     */
    FTWINSIS = 183,
    /**
     * @generated from protobuf enum value: ITWINSIS = 184;
     */
    ITWINSIS = 184,
    /**
     * @generated from protobuf enum value: TWIN = 185;
     */
    TWIN = 185,
    /**
     * @generated from protobuf enum value: FTWIN = 186;
     */
    FTWIN = 186,
    /**
     * @generated from protobuf enum value: ITWIN = 187;
     */
    ITWIN = 187,
    /**
     * @generated from protobuf enum value: SIS = 188;
     */
    SIS = 188,
    /**
     * @generated from protobuf enum value: STPSIS = 189;
     */
    STPSIS = 189,
    /**
     * @generated from protobuf enum value: STPSIB = 190;
     */
    STPSIB = 190,
    /**
     * @generated from protobuf enum value: SIGOTHR = 191;
     */
    SIGOTHR = 191,
    /**
     * @generated from protobuf enum value: DOMPART = 192;
     */
    DOMPART = 192,
    /**
     * @generated from protobuf enum value: FMRSPS = 193;
     */
    FMRSPS = 193,
    /**
     * @generated from protobuf enum value: SPS = 194;
     */
    SPS = 194,
    /**
     * @generated from protobuf enum value: HUSB = 195;
     */
    HUSB = 195,
    /**
     * @generated from protobuf enum value: WIFE = 196;
     */
    WIFE = 196,
    /**
     * @generated from protobuf enum value: FRND = 197;
     */
    FRND = 197,
    /**
     * @generated from protobuf enum value: NBOR = 198;
     */
    NBOR = 198,
    /**
     * @generated from protobuf enum value: ONESELF = 199;
     */
    ONESELF = 199,
    /**
     * @generated from protobuf enum value: ROOM = 200;
     */
    ROOM = 200,
    /**
     * @generated from protobuf enum value: POLICY_OR_PROGRAM_COVERAGE_ROLE_TYPE = 201;
     */
    POLICY_OR_PROGRAM_COVERAGE_ROLE_TYPE = 201,
    /**
     * @generated from protobuf enum value: COVERAGE_ROLE_TYPE = 202;
     */
    COVERAGE_ROLE_TYPE = 202,
    /**
     * @generated from protobuf enum value: FAMDEP = 203;
     */
    FAMDEP = 203,
    /**
     * @generated from protobuf enum value: HANDIC = 204;
     */
    HANDIC = 204,
    /**
     * @generated from protobuf enum value: INJ = 205;
     */
    INJ = 205,
    /**
     * @generated from protobuf enum value: SELF = 206;
     */
    SELF = 206,
    /**
     * @generated from protobuf enum value: SPON = 207;
     */
    SPON = 207,
    /**
     * @generated from protobuf enum value: STUD = 208;
     */
    STUD = 208,
    /**
     * @generated from protobuf enum value: FSTUD = 209;
     */
    FSTUD = 209,
    /**
     * @generated from protobuf enum value: PSTUD = 210;
     */
    PSTUD = 210,
    /**
     * @generated from protobuf enum value: ADOPT = 211;
     */
    ADOPT = 211,
    /**
     * @generated from protobuf enum value: GCHILD = 212;
     */
    GCHILD = 212,
    /**
     * @generated from protobuf enum value: GPARNT = 213;
     */
    GPARNT = 213,
    /**
     * @generated from protobuf enum value: NAT = 214;
     */
    NAT = 214,
    /**
     * @generated from protobuf enum value: NIENE = 215;
     */
    NIENE = 215,
    /**
     * @generated from protobuf enum value: PARNT = 216;
     */
    PARNT = 216,
    /**
     * @generated from protobuf enum value: SPSE = 217;
     */
    SPSE = 217,
    /**
     * @generated from protobuf enum value: STEP = 218;
     */
    STEP = 218,
    /**
     * @generated from protobuf enum value: COVERED_PARTY_ROLE_TYPE = 219;
     */
    COVERED_PARTY_ROLE_TYPE = 219,
    /**
     * @generated from protobuf enum value: CLAIMANT_COVERED_PARTY_ROLE_TYPE = 220;
     */
    CLAIMANT_COVERED_PARTY_ROLE_TYPE = 220,
    /**
     * @generated from protobuf enum value: CRIMEVIC = 221;
     */
    CRIMEVIC = 221,
    /**
     * @generated from protobuf enum value: INJWKR = 222;
     */
    INJWKR = 222,
    /**
     * @generated from protobuf enum value: DEPENDENT_COVERED_PARTY_ROLE_TYPE = 223;
     */
    DEPENDENT_COVERED_PARTY_ROLE_TYPE = 223,
    /**
     * @generated from protobuf enum value: COCBEN = 224;
     */
    COCBEN = 224,
    /**
     * @generated from protobuf enum value: DIFFABL = 225;
     */
    DIFFABL = 225,
    /**
     * @generated from protobuf enum value: WARD = 226;
     */
    WARD = 226,
    /**
     * @generated from protobuf enum value: INDIVIDUAL_INSURED_PARTY_ROLE_TYPE = 227;
     */
    INDIVIDUAL_INSURED_PARTY_ROLE_TYPE = 227,
    /**
     * @generated from protobuf enum value: RETIREE = 228;
     */
    RETIREE = 228,
    /**
     * @generated from protobuf enum value: PROGRAM_ELIGIBLE_PARTY_ROLE_TYPE = 229;
     */
    PROGRAM_ELIGIBLE_PARTY_ROLE_TYPE = 229,
    /**
     * @generated from protobuf enum value: INDIG = 230;
     */
    INDIG = 230,
    /**
     * @generated from protobuf enum value: MIL = 231;
     */
    MIL = 231,
    /**
     * @generated from protobuf enum value: ACTMIL = 232;
     */
    ACTMIL = 232,
    /**
     * @generated from protobuf enum value: RETMIL = 233;
     */
    RETMIL = 233,
    /**
     * @generated from protobuf enum value: VET = 234;
     */
    VET = 234,
    /**
     * @generated from protobuf enum value: SUBSCRIBER_COVERED_PARTY_ROLE_TYPE = 235;
     */
    SUBSCRIBER_COVERED_PARTY_ROLE_TYPE = 235,
    /**
     * @generated from protobuf enum value: RESEARCH_SUBJECT_ROLE_BASIS = 236;
     */
    RESEARCH_SUBJECT_ROLE_BASIS = 236,
    /**
     * @generated from protobuf enum value: ERL = 237;
     */
    ERL = 237,
    /**
     * @generated from protobuf enum value: SCN = 238;
     */
    SCN = 238,
    /**
     * @generated from protobuf enum value: SERVICE_DELIVERY_LOCATION_ROLE_TYPE = 239;
     */
    SERVICE_DELIVERY_LOCATION_ROLE_TYPE = 239,
    /**
     * @generated from protobuf enum value: DEDICATED_SERVICE_DELIVERY_LOCATION_ROLE_TYPE = 240;
     */
    DEDICATED_SERVICE_DELIVERY_LOCATION_ROLE_TYPE = 240,
    /**
     * @generated from protobuf enum value: DEDICATED_CLINICAL_LOCATION_ROLE_TYPE = 241;
     */
    DEDICATED_CLINICAL_LOCATION_ROLE_TYPE = 241,
    /**
     * @generated from protobuf enum value: DX = 242;
     */
    DX = 242,
    /**
     * @generated from protobuf enum value: CVDX = 243;
     */
    CVDX = 243,
    /**
     * @generated from protobuf enum value: CATH = 244;
     */
    CATH = 244,
    /**
     * @generated from protobuf enum value: ECHO = 245;
     */
    ECHO = 245,
    /**
     * @generated from protobuf enum value: GIDX = 246;
     */
    GIDX = 246,
    /**
     * @generated from protobuf enum value: ENDOS = 247;
     */
    ENDOS = 247,
    /**
     * @generated from protobuf enum value: RADDX = 248;
     */
    RADDX = 248,
    /**
     * @generated from protobuf enum value: RADO = 249;
     */
    RADO = 249,
    /**
     * @generated from protobuf enum value: RNEU = 250;
     */
    RNEU = 250,
    /**
     * @generated from protobuf enum value: HOSP = 251;
     */
    HOSP = 251,
    /**
     * @generated from protobuf enum value: CHR = 252;
     */
    CHR = 252,
    /**
     * @generated from protobuf enum value: GACH = 253;
     */
    GACH = 253,
    /**
     * @generated from protobuf enum value: MHSP = 254;
     */
    MHSP = 254,
    /**
     * @generated from protobuf enum value: PSYCHF = 255;
     */
    PSYCHF = 255,
    /**
     * @generated from protobuf enum value: RH = 256;
     */
    RH = 256,
    /**
     * @generated from protobuf enum value: RHAT = 257;
     */
    RHAT = 257,
    /**
     * @generated from protobuf enum value: RHII = 258;
     */
    RHII = 258,
    /**
     * @generated from protobuf enum value: RHMAD = 259;
     */
    RHMAD = 259,
    /**
     * @generated from protobuf enum value: RHPI = 260;
     */
    RHPI = 260,
    /**
     * @generated from protobuf enum value: RHPIH = 261;
     */
    RHPIH = 261,
    /**
     * @generated from protobuf enum value: RHPIMS = 262;
     */
    RHPIMS = 262,
    /**
     * @generated from protobuf enum value: RHPIVS = 263;
     */
    RHPIVS = 263,
    /**
     * @generated from protobuf enum value: RHYAD = 264;
     */
    RHYAD = 264,
    /**
     * @generated from protobuf enum value: HU = 265;
     */
    HU = 265,
    /**
     * @generated from protobuf enum value: BMTU = 266;
     */
    BMTU = 266,
    /**
     * @generated from protobuf enum value: CCU = 267;
     */
    CCU = 267,
    /**
     * @generated from protobuf enum value: CHEST = 268;
     */
    CHEST = 268,
    /**
     * @generated from protobuf enum value: EPIL = 269;
     */
    EPIL = 269,
    /**
     * @generated from protobuf enum value: ER = 270;
     */
    ER = 270,
    /**
     * @generated from protobuf enum value: ETU = 271;
     */
    ETU = 271,
    /**
     * @generated from protobuf enum value: HD = 272;
     */
    HD = 272,
    /**
     * @generated from protobuf enum value: HLAB = 273;
     */
    HLAB = 273,
    /**
     * @generated from protobuf enum value: INLAB = 274;
     */
    INLAB = 274,
    /**
     * @generated from protobuf enum value: OUTLAB = 275;
     */
    OUTLAB = 275,
    /**
     * @generated from protobuf enum value: HRAD = 276;
     */
    HRAD = 276,
    /**
     * @generated from protobuf enum value: HUSCS = 277;
     */
    HUSCS = 277,
    /**
     * @generated from protobuf enum value: ICU = 278;
     */
    ICU = 278,
    /**
     * @generated from protobuf enum value: PEDICU = 279;
     */
    PEDICU = 279,
    /**
     * @generated from protobuf enum value: PEDNICU = 280;
     */
    PEDNICU = 280,
    /**
     * @generated from protobuf enum value: INPHARM = 281;
     */
    INPHARM = 281,
    /**
     * @generated from protobuf enum value: MBL = 282;
     */
    MBL = 282,
    /**
     * @generated from protobuf enum value: NCCS = 283;
     */
    NCCS = 283,
    /**
     * @generated from protobuf enum value: NS = 284;
     */
    NS = 284,
    /**
     * @generated from protobuf enum value: OUTPHARM = 285;
     */
    OUTPHARM = 285,
    /**
     * @generated from protobuf enum value: PEDU = 286;
     */
    PEDU = 286,
    /**
     * @generated from protobuf enum value: PHU = 287;
     */
    PHU = 287,
    /**
     * @generated from protobuf enum value: RHU = 288;
     */
    RHU = 288,
    /**
     * @generated from protobuf enum value: SLEEP = 289;
     */
    SLEEP = 289,
    /**
     * @generated from protobuf enum value: NCCF = 290;
     */
    NCCF = 290,
    /**
     * @generated from protobuf enum value: SNF = 291;
     */
    SNF = 291,
    /**
     * @generated from protobuf enum value: OF = 292;
     */
    OF = 292,
    /**
     * @generated from protobuf enum value: ALL = 293;
     */
    ALL = 293,
    /**
     * @generated from protobuf enum value: AMPUT = 294;
     */
    AMPUT = 294,
    /**
     * @generated from protobuf enum value: BMTC = 295;
     */
    BMTC = 295,
    /**
     * @generated from protobuf enum value: BREAST = 296;
     */
    BREAST = 296,
    /**
     * @generated from protobuf enum value: CANC = 297;
     */
    CANC = 297,
    /**
     * @generated from protobuf enum value: CAPC = 298;
     */
    CAPC = 298,
    /**
     * @generated from protobuf enum value: CARD = 299;
     */
    CARD = 299,
    /**
     * @generated from protobuf enum value: PEDCARD = 300;
     */
    PEDCARD = 300,
    /**
     * @generated from protobuf enum value: COAG = 301;
     */
    COAG = 301,
    /**
     * @generated from protobuf enum value: CRS = 302;
     */
    CRS = 302,
    /**
     * @generated from protobuf enum value: DERM = 303;
     */
    DERM = 303,
    /**
     * @generated from protobuf enum value: ENDO = 304;
     */
    ENDO = 304,
    /**
     * @generated from protobuf enum value: PEDE = 305;
     */
    PEDE = 305,
    /**
     * @generated from protobuf enum value: ENT = 306;
     */
    ENT = 306,
    /**
     * @generated from protobuf enum value: FMC = 307;
     */
    FMC = 307,
    /**
     * @generated from protobuf enum value: GI = 308;
     */
    GI = 308,
    /**
     * @generated from protobuf enum value: PEDGI = 309;
     */
    PEDGI = 309,
    /**
     * @generated from protobuf enum value: GIM = 310;
     */
    GIM = 310,
    /**
     * @generated from protobuf enum value: GYN = 311;
     */
    GYN = 311,
    /**
     * @generated from protobuf enum value: HEM = 312;
     */
    HEM = 312,
    /**
     * @generated from protobuf enum value: PEDHEM = 313;
     */
    PEDHEM = 313,
    /**
     * @generated from protobuf enum value: HTN = 314;
     */
    HTN = 314,
    /**
     * @generated from protobuf enum value: IEC = 315;
     */
    IEC = 315,
    /**
     * @generated from protobuf enum value: INFD = 316;
     */
    INFD = 316,
    /**
     * @generated from protobuf enum value: PEDID = 317;
     */
    PEDID = 317,
    /**
     * @generated from protobuf enum value: INV = 318;
     */
    INV = 318,
    /**
     * @generated from protobuf enum value: LYMPH = 319;
     */
    LYMPH = 319,
    /**
     * @generated from protobuf enum value: MGEN = 320;
     */
    MGEN = 320,
    /**
     * @generated from protobuf enum value: NEPH = 321;
     */
    NEPH = 321,
    /**
     * @generated from protobuf enum value: PEDNEPH = 322;
     */
    PEDNEPH = 322,
    /**
     * @generated from protobuf enum value: NEUR = 323;
     */
    NEUR = 323,
    /**
     * @generated from protobuf enum value: OB = 324;
     */
    OB = 324,
    /**
     * @generated from protobuf enum value: OMS = 325;
     */
    OMS = 325,
    /**
     * @generated from protobuf enum value: ONCL = 326;
     */
    ONCL = 326,
    /**
     * @generated from protobuf enum value: PEDHO = 327;
     */
    PEDHO = 327,
    /**
     * @generated from protobuf enum value: OPH = 328;
     */
    OPH = 328,
    /**
     * @generated from protobuf enum value: OPTC = 329;
     */
    OPTC = 329,
    /**
     * @generated from protobuf enum value: ORTHO = 330;
     */
    ORTHO = 330,
    /**
     * @generated from protobuf enum value: HAND = 331;
     */
    HAND = 331,
    /**
     * @generated from protobuf enum value: PAINCL = 332;
     */
    PAINCL = 332,
    /**
     * @generated from protobuf enum value: PC = 333;
     */
    PC = 333,
    /**
     * @generated from protobuf enum value: PEDC = 334;
     */
    PEDC = 334,
    /**
     * @generated from protobuf enum value: PEDRHEUM = 335;
     */
    PEDRHEUM = 335,
    /**
     * @generated from protobuf enum value: POD = 336;
     */
    POD = 336,
    /**
     * @generated from protobuf enum value: PREV = 337;
     */
    PREV = 337,
    /**
     * @generated from protobuf enum value: PROCTO = 338;
     */
    PROCTO = 338,
    /**
     * @generated from protobuf enum value: PROFF = 339;
     */
    PROFF = 339,
    /**
     * @generated from protobuf enum value: PROS = 340;
     */
    PROS = 340,
    /**
     * @generated from protobuf enum value: PSI = 341;
     */
    PSI = 341,
    /**
     * @generated from protobuf enum value: PSY = 342;
     */
    PSY = 342,
    /**
     * @generated from protobuf enum value: RHEUM = 343;
     */
    RHEUM = 343,
    /**
     * @generated from protobuf enum value: SPMED = 344;
     */
    SPMED = 344,
    /**
     * @generated from protobuf enum value: SU = 345;
     */
    SU = 345,
    /**
     * @generated from protobuf enum value: PLS = 346;
     */
    PLS = 346,
    /**
     * @generated from protobuf enum value: URO = 347;
     */
    URO = 347,
    /**
     * @generated from protobuf enum value: TR = 348;
     */
    TR = 348,
    /**
     * @generated from protobuf enum value: TRAVEL = 349;
     */
    TRAVEL = 349,
    /**
     * @generated from protobuf enum value: WND = 350;
     */
    WND = 350,
    /**
     * @generated from protobuf enum value: RTF = 351;
     */
    RTF = 351,
    /**
     * @generated from protobuf enum value: PRC = 352;
     */
    PRC = 352,
    /**
     * @generated from protobuf enum value: SURF = 353;
     */
    SURF = 353,
    /**
     * @generated from protobuf enum value: DEDICATED_NON_CLINICAL_LOCATION_ROLE_TYPE = 354;
     */
    DEDICATED_NON_CLINICAL_LOCATION_ROLE_TYPE = 354,
    /**
     * @generated from protobuf enum value: DADDR = 355;
     */
    DADDR = 355,
    /**
     * @generated from protobuf enum value: MOBL = 356;
     */
    MOBL = 356,
    /**
     * @generated from protobuf enum value: AMB = 357;
     */
    AMB = 357,
    /**
     * @generated from protobuf enum value: PHARM = 358;
     */
    PHARM = 358,
    /**
     * @generated from protobuf enum value: INCIDENTAL_SERVICE_DELIVERY_LOCATION_ROLE_TYPE = 359;
     */
    INCIDENTAL_SERVICE_DELIVERY_LOCATION_ROLE_TYPE = 359,
    /**
     * @generated from protobuf enum value: ACC = 360;
     */
    ACC = 360,
    /**
     * @generated from protobuf enum value: COMM = 361;
     */
    COMM = 361,
    /**
     * @generated from protobuf enum value: CSC = 362;
     */
    CSC = 362,
    /**
     * @generated from protobuf enum value: PTRES = 363;
     */
    PTRES = 363,
    /**
     * @generated from protobuf enum value: SCHOOL = 364;
     */
    SCHOOL = 364,
    /**
     * @generated from protobuf enum value: UPC = 365;
     */
    UPC = 365,
    /**
     * @generated from protobuf enum value: WORK = 366;
     */
    WORK = 366,
    /**
     * @generated from protobuf enum value: SPECIMEN_ROLE_TYPE = 367;
     */
    SPECIMEN_ROLE_TYPE = 367,
    /**
     * @generated from protobuf enum value: C = 368;
     */
    C = 368,
    /**
     * @generated from protobuf enum value: G = 369;
     */
    G = 369,
    /**
     * @generated from protobuf enum value: L = 370;
     */
    L = 370,
    /**
     * @generated from protobuf enum value: P = 371;
     */
    P = 371,
    /**
     * @generated from protobuf enum value: Q = 372;
     */
    Q = 372,
    /**
     * @generated from protobuf enum value: B = 373;
     */
    B = 373,
    /**
     * @generated from protobuf enum value: E = 374;
     */
    E = 374,
    /**
     * @generated from protobuf enum value: F = 375;
     */
    F = 375,
    /**
     * @generated from protobuf enum value: O = 376;
     */
    O = 376,
    /**
     * @generated from protobuf enum value: V = 377;
     */
    V = 377,
    /**
     * @generated from protobuf enum value: R = 378;
     */
    R = 378,
    /**
     * @generated from protobuf enum value: CLAIM = 379;
     */
    CLAIM = 379,
    /**
     * @generated from protobuf enum value: COMMUNITY_LABORATORY = 380;
     */
    COMMUNITY_LABORATORY = 380,
    /**
     * @generated from protobuf enum value: GT = 381;
     */
    GT = 381,
    /**
     * @generated from protobuf enum value: HOME_HEALTH = 382;
     */
    HOME_HEALTH = 382,
    /**
     * @generated from protobuf enum value: LABORATORY = 383;
     */
    LABORATORY = 383,
    /**
     * @generated from protobuf enum value: PATHOLOGIST = 384;
     */
    PATHOLOGIST = 384,
    /**
     * @generated from protobuf enum value: PH = 385;
     */
    PH = 385,
    /**
     * @generated from protobuf enum value: PHLEBOTOMIST = 386;
     */
    PHLEBOTOMIST = 386,
    /**
     * @generated from protobuf enum value: PROG = 387;
     */
    PROG = 387,
    /**
     * @generated from protobuf enum value: PT = 388;
     */
    PT = 388,
    /**
     * @generated from protobuf enum value: SUBJECT = 389;
     */
    SUBJECT = 389,
    /**
     * @generated from protobuf enum value: THIRD_PARTY = 390;
     */
    THIRD_PARTY = 390,
    /**
     * @generated from protobuf enum value: DEP = 391;
     */
    DEP = 391,
    /**
     * @generated from protobuf enum value: DEPEN = 392;
     */
    DEPEN = 392,
    /**
     * @generated from protobuf enum value: FM = 393;
     */
    FM = 393,
    /**
     * @generated from protobuf enum value: INDIV = 394;
     */
    INDIV = 394,
    /**
     * @generated from protobuf enum value: NAMED = 395;
     */
    NAMED = 395,
    /**
     * @generated from protobuf enum value: PSYCHCF = 396;
     */
    PSYCHCF = 396,
    /**
     * @generated from protobuf enum value: SUBSCR = 397;
     */
    SUBSCR = 397
}
/**
 * **** MISSING DEFINITIONS ****
 * See http://terminology.hl7.org/CodeSystem/v3-TimingEvent
 *
 * @generated from protobuf message google.fhir.r4.core.V3TimingEventCode
 */
export interface V3TimingEventCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3TimingEventCode.Value
 */
export enum V3TimingEventCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: AC = 1;
     */
    AC = 1,
    /**
     * @generated from protobuf enum value: ACD = 2;
     */
    ACD = 2,
    /**
     * @generated from protobuf enum value: ACM = 3;
     */
    ACM = 3,
    /**
     * @generated from protobuf enum value: ACV = 4;
     */
    ACV = 4,
    /**
     * @generated from protobuf enum value: C = 5;
     */
    C = 5,
    /**
     * @generated from protobuf enum value: CD = 6;
     */
    CD = 6,
    /**
     * @generated from protobuf enum value: CM = 7;
     */
    CM = 7,
    /**
     * @generated from protobuf enum value: CV = 8;
     */
    CV = 8,
    /**
     * @generated from protobuf enum value: HS = 9;
     */
    HS = 9,
    /**
     * @generated from protobuf enum value: IC = 10;
     */
    IC = 10,
    /**
     * @generated from protobuf enum value: ICD = 11;
     */
    ICD = 11,
    /**
     * @generated from protobuf enum value: ICM = 12;
     */
    ICM = 12,
    /**
     * @generated from protobuf enum value: ICV = 13;
     */
    ICV = 13,
    /**
     * @generated from protobuf enum value: PC = 14;
     */
    PC = 14,
    /**
     * @generated from protobuf enum value: PCD = 15;
     */
    PCD = 15,
    /**
     * @generated from protobuf enum value: PCM = 16;
     */
    PCM = 16,
    /**
     * @generated from protobuf enum value: PCV = 17;
     */
    PCV = 17,
    /**
     * @generated from protobuf enum value: WAKE = 18;
     */
    WAKE = 18
}
/**
 * A coded concept listing the base codes.
 * See http://hl7.org/fhir/vision-base-codes
 *
 * @generated from protobuf message google.fhir.r4.core.VisionBaseCode
 */
export interface VisionBaseCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.VisionBaseCode.Value
 */
export enum VisionBaseCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: UP = 1;
     */
    UP = 1,
    /**
     * @generated from protobuf enum value: DOWN = 2;
     */
    DOWN = 2,
    /**
     * @generated from protobuf enum value: IN = 3;
     */
    IN = 3,
    /**
     * @generated from protobuf enum value: OUT = 4;
     */
    OUT = 4
}
/**
 * A coded concept listing the eye codes.
 * See http://hl7.org/fhir/vision-eye-codes
 *
 * @generated from protobuf message google.fhir.r4.core.VisionEyesCode
 */
export interface VisionEyesCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.VisionEyesCode.Value
 */
export enum VisionEyesCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: RIGHT = 1;
     */
    RIGHT = 1,
    /**
     * @generated from protobuf enum value: LEFT = 2;
     */
    LEFT = 2
}
/**
 * How a search parameter relates to the set of elements returned by evaluating
 * its xpath query. See http://hl7.org/fhir/search-xpath-usage
 *
 * @generated from protobuf message google.fhir.r4.core.XPathUsageTypeCode
 */
export interface XPathUsageTypeCode {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.XPathUsageTypeCode.Value
 */
export enum XPathUsageTypeCode_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NORMAL = 1;
     */
    NORMAL = 1,
    /**
     * @generated from protobuf enum value: PHONETIC = 2;
     */
    PHONETIC = 2,
    /**
     * @generated from protobuf enum value: NEARBY = 3;
     */
    NEARBY = 3,
    /**
     * @generated from protobuf enum value: DISTANCE = 4;
     */
    DISTANCE = 4,
    /**
     * @generated from protobuf enum value: OTHER = 5;
     */
    OTHER = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class AbstractTypeCode$Type extends MessageType<AbstractTypeCode> {
    constructor() {
        super("google.fhir.r4.core.AbstractTypeCode", []);
    }
    create(value?: PartialMessage<AbstractTypeCode>): AbstractTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AbstractTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AbstractTypeCode): AbstractTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AbstractTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AbstractTypeCode
 */
export const AbstractTypeCode = new AbstractTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountStatusCode$Type extends MessageType<AccountStatusCode> {
    constructor() {
        super("google.fhir.r4.core.AccountStatusCode", []);
    }
    create(value?: PartialMessage<AccountStatusCode>): AccountStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccountStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountStatusCode): AccountStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AccountStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AccountStatusCode
 */
export const AccountStatusCode = new AccountStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionCardinalityBehaviorCode$Type extends MessageType<ActionCardinalityBehaviorCode> {
    constructor() {
        super("google.fhir.r4.core.ActionCardinalityBehaviorCode", []);
    }
    create(value?: PartialMessage<ActionCardinalityBehaviorCode>): ActionCardinalityBehaviorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionCardinalityBehaviorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionCardinalityBehaviorCode): ActionCardinalityBehaviorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActionCardinalityBehaviorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ActionCardinalityBehaviorCode
 */
export const ActionCardinalityBehaviorCode = new ActionCardinalityBehaviorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionConditionKindCode$Type extends MessageType<ActionConditionKindCode> {
    constructor() {
        super("google.fhir.r4.core.ActionConditionKindCode", []);
    }
    create(value?: PartialMessage<ActionConditionKindCode>): ActionConditionKindCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionConditionKindCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionConditionKindCode): ActionConditionKindCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActionConditionKindCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ActionConditionKindCode
 */
export const ActionConditionKindCode = new ActionConditionKindCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionGroupingBehaviorCode$Type extends MessageType<ActionGroupingBehaviorCode> {
    constructor() {
        super("google.fhir.r4.core.ActionGroupingBehaviorCode", []);
    }
    create(value?: PartialMessage<ActionGroupingBehaviorCode>): ActionGroupingBehaviorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionGroupingBehaviorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionGroupingBehaviorCode): ActionGroupingBehaviorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActionGroupingBehaviorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ActionGroupingBehaviorCode
 */
export const ActionGroupingBehaviorCode = new ActionGroupingBehaviorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionParticipantTypeCode$Type extends MessageType<ActionParticipantTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ActionParticipantTypeCode", []);
    }
    create(value?: PartialMessage<ActionParticipantTypeCode>): ActionParticipantTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionParticipantTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionParticipantTypeCode): ActionParticipantTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActionParticipantTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ActionParticipantTypeCode
 */
export const ActionParticipantTypeCode = new ActionParticipantTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionPrecheckBehaviorCode$Type extends MessageType<ActionPrecheckBehaviorCode> {
    constructor() {
        super("google.fhir.r4.core.ActionPrecheckBehaviorCode", []);
    }
    create(value?: PartialMessage<ActionPrecheckBehaviorCode>): ActionPrecheckBehaviorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionPrecheckBehaviorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionPrecheckBehaviorCode): ActionPrecheckBehaviorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActionPrecheckBehaviorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ActionPrecheckBehaviorCode
 */
export const ActionPrecheckBehaviorCode = new ActionPrecheckBehaviorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionRelationshipTypeCode$Type extends MessageType<ActionRelationshipTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ActionRelationshipTypeCode", []);
    }
    create(value?: PartialMessage<ActionRelationshipTypeCode>): ActionRelationshipTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionRelationshipTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionRelationshipTypeCode): ActionRelationshipTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActionRelationshipTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ActionRelationshipTypeCode
 */
export const ActionRelationshipTypeCode = new ActionRelationshipTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionRequiredBehaviorCode$Type extends MessageType<ActionRequiredBehaviorCode> {
    constructor() {
        super("google.fhir.r4.core.ActionRequiredBehaviorCode", []);
    }
    create(value?: PartialMessage<ActionRequiredBehaviorCode>): ActionRequiredBehaviorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionRequiredBehaviorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionRequiredBehaviorCode): ActionRequiredBehaviorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActionRequiredBehaviorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ActionRequiredBehaviorCode
 */
export const ActionRequiredBehaviorCode = new ActionRequiredBehaviorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionSelectionBehaviorCode$Type extends MessageType<ActionSelectionBehaviorCode> {
    constructor() {
        super("google.fhir.r4.core.ActionSelectionBehaviorCode", []);
    }
    create(value?: PartialMessage<ActionSelectionBehaviorCode>): ActionSelectionBehaviorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionSelectionBehaviorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionSelectionBehaviorCode): ActionSelectionBehaviorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActionSelectionBehaviorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ActionSelectionBehaviorCode
 */
export const ActionSelectionBehaviorCode = new ActionSelectionBehaviorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddressTypeCode$Type extends MessageType<AddressTypeCode> {
    constructor() {
        super("google.fhir.r4.core.AddressTypeCode", []);
    }
    create(value?: PartialMessage<AddressTypeCode>): AddressTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddressTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddressTypeCode): AddressTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddressTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AddressTypeCode
 */
export const AddressTypeCode = new AddressTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddressUseCode$Type extends MessageType<AddressUseCode> {
    constructor() {
        super("google.fhir.r4.core.AddressUseCode", []);
    }
    create(value?: PartialMessage<AddressUseCode>): AddressUseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddressUseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddressUseCode): AddressUseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddressUseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AddressUseCode
 */
export const AddressUseCode = new AddressUseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdministrativeGenderCode$Type extends MessageType<AdministrativeGenderCode> {
    constructor() {
        super("google.fhir.r4.core.AdministrativeGenderCode", []);
    }
    create(value?: PartialMessage<AdministrativeGenderCode>): AdministrativeGenderCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdministrativeGenderCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdministrativeGenderCode): AdministrativeGenderCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AdministrativeGenderCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AdministrativeGenderCode
 */
export const AdministrativeGenderCode = new AdministrativeGenderCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdverseEventActualityCode$Type extends MessageType<AdverseEventActualityCode> {
    constructor() {
        super("google.fhir.r4.core.AdverseEventActualityCode", []);
    }
    create(value?: PartialMessage<AdverseEventActualityCode>): AdverseEventActualityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdverseEventActualityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdverseEventActualityCode): AdverseEventActualityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AdverseEventActualityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AdverseEventActualityCode
 */
export const AdverseEventActualityCode = new AdverseEventActualityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdverseEventOutcomeCode$Type extends MessageType<AdverseEventOutcomeCode> {
    constructor() {
        super("google.fhir.r4.core.AdverseEventOutcomeCode", []);
    }
    create(value?: PartialMessage<AdverseEventOutcomeCode>): AdverseEventOutcomeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdverseEventOutcomeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdverseEventOutcomeCode): AdverseEventOutcomeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AdverseEventOutcomeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AdverseEventOutcomeCode
 */
export const AdverseEventOutcomeCode = new AdverseEventOutcomeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdverseEventSeverityCode$Type extends MessageType<AdverseEventSeverityCode> {
    constructor() {
        super("google.fhir.r4.core.AdverseEventSeverityCode", []);
    }
    create(value?: PartialMessage<AdverseEventSeverityCode>): AdverseEventSeverityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdverseEventSeverityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdverseEventSeverityCode): AdverseEventSeverityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AdverseEventSeverityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AdverseEventSeverityCode
 */
export const AdverseEventSeverityCode = new AdverseEventSeverityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AggregationModeCode$Type extends MessageType<AggregationModeCode> {
    constructor() {
        super("google.fhir.r4.core.AggregationModeCode", []);
    }
    create(value?: PartialMessage<AggregationModeCode>): AggregationModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AggregationModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AggregationModeCode): AggregationModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AggregationModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AggregationModeCode
 */
export const AggregationModeCode = new AggregationModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllergyIntoleranceCategoryCode$Type extends MessageType<AllergyIntoleranceCategoryCode> {
    constructor() {
        super("google.fhir.r4.core.AllergyIntoleranceCategoryCode", []);
    }
    create(value?: PartialMessage<AllergyIntoleranceCategoryCode>): AllergyIntoleranceCategoryCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllergyIntoleranceCategoryCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllergyIntoleranceCategoryCode): AllergyIntoleranceCategoryCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AllergyIntoleranceCategoryCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AllergyIntoleranceCategoryCode
 */
export const AllergyIntoleranceCategoryCode = new AllergyIntoleranceCategoryCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllergyIntoleranceClinicalStatusCode$Type extends MessageType<AllergyIntoleranceClinicalStatusCode> {
    constructor() {
        super("google.fhir.r4.core.AllergyIntoleranceClinicalStatusCode", []);
    }
    create(value?: PartialMessage<AllergyIntoleranceClinicalStatusCode>): AllergyIntoleranceClinicalStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllergyIntoleranceClinicalStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllergyIntoleranceClinicalStatusCode): AllergyIntoleranceClinicalStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AllergyIntoleranceClinicalStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AllergyIntoleranceClinicalStatusCode
 */
export const AllergyIntoleranceClinicalStatusCode = new AllergyIntoleranceClinicalStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllergyIntoleranceCriticalityCode$Type extends MessageType<AllergyIntoleranceCriticalityCode> {
    constructor() {
        super("google.fhir.r4.core.AllergyIntoleranceCriticalityCode", []);
    }
    create(value?: PartialMessage<AllergyIntoleranceCriticalityCode>): AllergyIntoleranceCriticalityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllergyIntoleranceCriticalityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllergyIntoleranceCriticalityCode): AllergyIntoleranceCriticalityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AllergyIntoleranceCriticalityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AllergyIntoleranceCriticalityCode
 */
export const AllergyIntoleranceCriticalityCode = new AllergyIntoleranceCriticalityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllergyIntoleranceSeverityCode$Type extends MessageType<AllergyIntoleranceSeverityCode> {
    constructor() {
        super("google.fhir.r4.core.AllergyIntoleranceSeverityCode", []);
    }
    create(value?: PartialMessage<AllergyIntoleranceSeverityCode>): AllergyIntoleranceSeverityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllergyIntoleranceSeverityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllergyIntoleranceSeverityCode): AllergyIntoleranceSeverityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AllergyIntoleranceSeverityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AllergyIntoleranceSeverityCode
 */
export const AllergyIntoleranceSeverityCode = new AllergyIntoleranceSeverityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllergyIntoleranceSubstanceExposureRiskCode$Type extends MessageType<AllergyIntoleranceSubstanceExposureRiskCode> {
    constructor() {
        super("google.fhir.r4.core.AllergyIntoleranceSubstanceExposureRiskCode", []);
    }
    create(value?: PartialMessage<AllergyIntoleranceSubstanceExposureRiskCode>): AllergyIntoleranceSubstanceExposureRiskCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllergyIntoleranceSubstanceExposureRiskCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllergyIntoleranceSubstanceExposureRiskCode): AllergyIntoleranceSubstanceExposureRiskCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AllergyIntoleranceSubstanceExposureRiskCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AllergyIntoleranceSubstanceExposureRiskCode
 */
export const AllergyIntoleranceSubstanceExposureRiskCode = new AllergyIntoleranceSubstanceExposureRiskCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllergyIntoleranceTypeCode$Type extends MessageType<AllergyIntoleranceTypeCode> {
    constructor() {
        super("google.fhir.r4.core.AllergyIntoleranceTypeCode", []);
    }
    create(value?: PartialMessage<AllergyIntoleranceTypeCode>): AllergyIntoleranceTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllergyIntoleranceTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllergyIntoleranceTypeCode): AllergyIntoleranceTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AllergyIntoleranceTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AllergyIntoleranceTypeCode
 */
export const AllergyIntoleranceTypeCode = new AllergyIntoleranceTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllergyIntoleranceVerificationStatusCode$Type extends MessageType<AllergyIntoleranceVerificationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.AllergyIntoleranceVerificationStatusCode", []);
    }
    create(value?: PartialMessage<AllergyIntoleranceVerificationStatusCode>): AllergyIntoleranceVerificationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllergyIntoleranceVerificationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllergyIntoleranceVerificationStatusCode): AllergyIntoleranceVerificationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AllergyIntoleranceVerificationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AllergyIntoleranceVerificationStatusCode
 */
export const AllergyIntoleranceVerificationStatusCode = new AllergyIntoleranceVerificationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppointmentStatusCode$Type extends MessageType<AppointmentStatusCode> {
    constructor() {
        super("google.fhir.r4.core.AppointmentStatusCode", []);
    }
    create(value?: PartialMessage<AppointmentStatusCode>): AppointmentStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppointmentStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppointmentStatusCode): AppointmentStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AppointmentStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AppointmentStatusCode
 */
export const AppointmentStatusCode = new AppointmentStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssertionDirectionTypeCode$Type extends MessageType<AssertionDirectionTypeCode> {
    constructor() {
        super("google.fhir.r4.core.AssertionDirectionTypeCode", []);
    }
    create(value?: PartialMessage<AssertionDirectionTypeCode>): AssertionDirectionTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssertionDirectionTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssertionDirectionTypeCode): AssertionDirectionTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AssertionDirectionTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AssertionDirectionTypeCode
 */
export const AssertionDirectionTypeCode = new AssertionDirectionTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssertionOperatorTypeCode$Type extends MessageType<AssertionOperatorTypeCode> {
    constructor() {
        super("google.fhir.r4.core.AssertionOperatorTypeCode", []);
    }
    create(value?: PartialMessage<AssertionOperatorTypeCode>): AssertionOperatorTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssertionOperatorTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssertionOperatorTypeCode): AssertionOperatorTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AssertionOperatorTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AssertionOperatorTypeCode
 */
export const AssertionOperatorTypeCode = new AssertionOperatorTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssertionResponseTypesCode$Type extends MessageType<AssertionResponseTypesCode> {
    constructor() {
        super("google.fhir.r4.core.AssertionResponseTypesCode", []);
    }
    create(value?: PartialMessage<AssertionResponseTypesCode>): AssertionResponseTypesCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssertionResponseTypesCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssertionResponseTypesCode): AssertionResponseTypesCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AssertionResponseTypesCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AssertionResponseTypesCode
 */
export const AssertionResponseTypesCode = new AssertionResponseTypesCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuditEventActionCode$Type extends MessageType<AuditEventActionCode> {
    constructor() {
        super("google.fhir.r4.core.AuditEventActionCode", []);
    }
    create(value?: PartialMessage<AuditEventActionCode>): AuditEventActionCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuditEventActionCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuditEventActionCode): AuditEventActionCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AuditEventActionCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AuditEventActionCode
 */
export const AuditEventActionCode = new AuditEventActionCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuditEventAgentNetworkTypeCode$Type extends MessageType<AuditEventAgentNetworkTypeCode> {
    constructor() {
        super("google.fhir.r4.core.AuditEventAgentNetworkTypeCode", []);
    }
    create(value?: PartialMessage<AuditEventAgentNetworkTypeCode>): AuditEventAgentNetworkTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuditEventAgentNetworkTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuditEventAgentNetworkTypeCode): AuditEventAgentNetworkTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AuditEventAgentNetworkTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AuditEventAgentNetworkTypeCode
 */
export const AuditEventAgentNetworkTypeCode = new AuditEventAgentNetworkTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuditEventOutcomeCode$Type extends MessageType<AuditEventOutcomeCode> {
    constructor() {
        super("google.fhir.r4.core.AuditEventOutcomeCode", []);
    }
    create(value?: PartialMessage<AuditEventOutcomeCode>): AuditEventOutcomeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuditEventOutcomeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuditEventOutcomeCode): AuditEventOutcomeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AuditEventOutcomeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.AuditEventOutcomeCode
 */
export const AuditEventOutcomeCode = new AuditEventOutcomeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BenefitCostApplicabilityCode$Type extends MessageType<BenefitCostApplicabilityCode> {
    constructor() {
        super("google.fhir.r4.core.BenefitCostApplicabilityCode", []);
    }
    create(value?: PartialMessage<BenefitCostApplicabilityCode>): BenefitCostApplicabilityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BenefitCostApplicabilityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BenefitCostApplicabilityCode): BenefitCostApplicabilityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BenefitCostApplicabilityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BenefitCostApplicabilityCode
 */
export const BenefitCostApplicabilityCode = new BenefitCostApplicabilityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BindingStrengthCode$Type extends MessageType<BindingStrengthCode> {
    constructor() {
        super("google.fhir.r4.core.BindingStrengthCode", []);
    }
    create(value?: PartialMessage<BindingStrengthCode>): BindingStrengthCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BindingStrengthCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BindingStrengthCode): BindingStrengthCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BindingStrengthCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BindingStrengthCode
 */
export const BindingStrengthCode = new BindingStrengthCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BiologicallyDerivedProductCategoryCode$Type extends MessageType<BiologicallyDerivedProductCategoryCode> {
    constructor() {
        super("google.fhir.r4.core.BiologicallyDerivedProductCategoryCode", []);
    }
    create(value?: PartialMessage<BiologicallyDerivedProductCategoryCode>): BiologicallyDerivedProductCategoryCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BiologicallyDerivedProductCategoryCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BiologicallyDerivedProductCategoryCode): BiologicallyDerivedProductCategoryCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BiologicallyDerivedProductCategoryCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BiologicallyDerivedProductCategoryCode
 */
export const BiologicallyDerivedProductCategoryCode = new BiologicallyDerivedProductCategoryCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BiologicallyDerivedProductStatusCode$Type extends MessageType<BiologicallyDerivedProductStatusCode> {
    constructor() {
        super("google.fhir.r4.core.BiologicallyDerivedProductStatusCode", []);
    }
    create(value?: PartialMessage<BiologicallyDerivedProductStatusCode>): BiologicallyDerivedProductStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BiologicallyDerivedProductStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BiologicallyDerivedProductStatusCode): BiologicallyDerivedProductStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BiologicallyDerivedProductStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BiologicallyDerivedProductStatusCode
 */
export const BiologicallyDerivedProductStatusCode = new BiologicallyDerivedProductStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BiologicallyDerivedProductStorageScaleCode$Type extends MessageType<BiologicallyDerivedProductStorageScaleCode> {
    constructor() {
        super("google.fhir.r4.core.BiologicallyDerivedProductStorageScaleCode", []);
    }
    create(value?: PartialMessage<BiologicallyDerivedProductStorageScaleCode>): BiologicallyDerivedProductStorageScaleCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BiologicallyDerivedProductStorageScaleCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BiologicallyDerivedProductStorageScaleCode): BiologicallyDerivedProductStorageScaleCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BiologicallyDerivedProductStorageScaleCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BiologicallyDerivedProductStorageScaleCode
 */
export const BiologicallyDerivedProductStorageScaleCode = new BiologicallyDerivedProductStorageScaleCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BundleTypeCode$Type extends MessageType<BundleTypeCode> {
    constructor() {
        super("google.fhir.r4.core.BundleTypeCode", []);
    }
    create(value?: PartialMessage<BundleTypeCode>): BundleTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BundleTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BundleTypeCode): BundleTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BundleTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BundleTypeCode
 */
export const BundleTypeCode = new BundleTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CanonicalStatusCodesForFHIRResourcesCode$Type extends MessageType<CanonicalStatusCodesForFHIRResourcesCode> {
    constructor() {
        super("google.fhir.r4.core.CanonicalStatusCodesForFHIRResourcesCode", []);
    }
    create(value?: PartialMessage<CanonicalStatusCodesForFHIRResourcesCode>): CanonicalStatusCodesForFHIRResourcesCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CanonicalStatusCodesForFHIRResourcesCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CanonicalStatusCodesForFHIRResourcesCode): CanonicalStatusCodesForFHIRResourcesCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CanonicalStatusCodesForFHIRResourcesCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CanonicalStatusCodesForFHIRResourcesCode
 */
export const CanonicalStatusCodesForFHIRResourcesCode = new CanonicalStatusCodesForFHIRResourcesCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CapabilityStatementKindCode$Type extends MessageType<CapabilityStatementKindCode> {
    constructor() {
        super("google.fhir.r4.core.CapabilityStatementKindCode", []);
    }
    create(value?: PartialMessage<CapabilityStatementKindCode>): CapabilityStatementKindCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CapabilityStatementKindCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CapabilityStatementKindCode): CapabilityStatementKindCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CapabilityStatementKindCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CapabilityStatementKindCode
 */
export const CapabilityStatementKindCode = new CapabilityStatementKindCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CarePlanActivityStatusCode$Type extends MessageType<CarePlanActivityStatusCode> {
    constructor() {
        super("google.fhir.r4.core.CarePlanActivityStatusCode", []);
    }
    create(value?: PartialMessage<CarePlanActivityStatusCode>): CarePlanActivityStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CarePlanActivityStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CarePlanActivityStatusCode): CarePlanActivityStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CarePlanActivityStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CarePlanActivityStatusCode
 */
export const CarePlanActivityStatusCode = new CarePlanActivityStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CareTeamStatusCode$Type extends MessageType<CareTeamStatusCode> {
    constructor() {
        super("google.fhir.r4.core.CareTeamStatusCode", []);
    }
    create(value?: PartialMessage<CareTeamStatusCode>): CareTeamStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CareTeamStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CareTeamStatusCode): CareTeamStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CareTeamStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CareTeamStatusCode
 */
export const CareTeamStatusCode = new CareTeamStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CatalogEntryRelationTypeCode$Type extends MessageType<CatalogEntryRelationTypeCode> {
    constructor() {
        super("google.fhir.r4.core.CatalogEntryRelationTypeCode", []);
    }
    create(value?: PartialMessage<CatalogEntryRelationTypeCode>): CatalogEntryRelationTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CatalogEntryRelationTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CatalogEntryRelationTypeCode): CatalogEntryRelationTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CatalogEntryRelationTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CatalogEntryRelationTypeCode
 */
export const CatalogEntryRelationTypeCode = new CatalogEntryRelationTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChargeItemStatusCode$Type extends MessageType<ChargeItemStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ChargeItemStatusCode", []);
    }
    create(value?: PartialMessage<ChargeItemStatusCode>): ChargeItemStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChargeItemStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChargeItemStatusCode): ChargeItemStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ChargeItemStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ChargeItemStatusCode
 */
export const ChargeItemStatusCode = new ChargeItemStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChoiceListOrientationCode$Type extends MessageType<ChoiceListOrientationCode> {
    constructor() {
        super("google.fhir.r4.core.ChoiceListOrientationCode", []);
    }
    create(value?: PartialMessage<ChoiceListOrientationCode>): ChoiceListOrientationCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChoiceListOrientationCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChoiceListOrientationCode): ChoiceListOrientationCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ChoiceListOrientationCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ChoiceListOrientationCode
 */
export const ChoiceListOrientationCode = new ChoiceListOrientationCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimProcessingCode$Type extends MessageType<ClaimProcessingCode> {
    constructor() {
        super("google.fhir.r4.core.ClaimProcessingCode", []);
    }
    create(value?: PartialMessage<ClaimProcessingCode>): ClaimProcessingCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClaimProcessingCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimProcessingCode): ClaimProcessingCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ClaimProcessingCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ClaimProcessingCode
 */
export const ClaimProcessingCode = new ClaimProcessingCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CodeSearchSupportCode$Type extends MessageType<CodeSearchSupportCode> {
    constructor() {
        super("google.fhir.r4.core.CodeSearchSupportCode", []);
    }
    create(value?: PartialMessage<CodeSearchSupportCode>): CodeSearchSupportCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CodeSearchSupportCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CodeSearchSupportCode): CodeSearchSupportCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CodeSearchSupportCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CodeSearchSupportCode
 */
export const CodeSearchSupportCode = new CodeSearchSupportCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CodeSystemContentModeCode$Type extends MessageType<CodeSystemContentModeCode> {
    constructor() {
        super("google.fhir.r4.core.CodeSystemContentModeCode", []);
    }
    create(value?: PartialMessage<CodeSystemContentModeCode>): CodeSystemContentModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CodeSystemContentModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CodeSystemContentModeCode): CodeSystemContentModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CodeSystemContentModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CodeSystemContentModeCode
 */
export const CodeSystemContentModeCode = new CodeSystemContentModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CodeSystemHierarchyMeaningCode$Type extends MessageType<CodeSystemHierarchyMeaningCode> {
    constructor() {
        super("google.fhir.r4.core.CodeSystemHierarchyMeaningCode", []);
    }
    create(value?: PartialMessage<CodeSystemHierarchyMeaningCode>): CodeSystemHierarchyMeaningCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CodeSystemHierarchyMeaningCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CodeSystemHierarchyMeaningCode): CodeSystemHierarchyMeaningCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CodeSystemHierarchyMeaningCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CodeSystemHierarchyMeaningCode
 */
export const CodeSystemHierarchyMeaningCode = new CodeSystemHierarchyMeaningCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompartmentTypeCode$Type extends MessageType<CompartmentTypeCode> {
    constructor() {
        super("google.fhir.r4.core.CompartmentTypeCode", []);
    }
    create(value?: PartialMessage<CompartmentTypeCode>): CompartmentTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompartmentTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompartmentTypeCode): CompartmentTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CompartmentTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CompartmentTypeCode
 */
export const CompartmentTypeCode = new CompartmentTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompositionAttestationModeCode$Type extends MessageType<CompositionAttestationModeCode> {
    constructor() {
        super("google.fhir.r4.core.CompositionAttestationModeCode", []);
    }
    create(value?: PartialMessage<CompositionAttestationModeCode>): CompositionAttestationModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompositionAttestationModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompositionAttestationModeCode): CompositionAttestationModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CompositionAttestationModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CompositionAttestationModeCode
 */
export const CompositionAttestationModeCode = new CompositionAttestationModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompositionStatusCode$Type extends MessageType<CompositionStatusCode> {
    constructor() {
        super("google.fhir.r4.core.CompositionStatusCode", []);
    }
    create(value?: PartialMessage<CompositionStatusCode>): CompositionStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompositionStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompositionStatusCode): CompositionStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CompositionStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CompositionStatusCode
 */
export const CompositionStatusCode = new CompositionStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptMapEquivalenceCode$Type extends MessageType<ConceptMapEquivalenceCode> {
    constructor() {
        super("google.fhir.r4.core.ConceptMapEquivalenceCode", []);
    }
    create(value?: PartialMessage<ConceptMapEquivalenceCode>): ConceptMapEquivalenceCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptMapEquivalenceCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptMapEquivalenceCode): ConceptMapEquivalenceCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConceptMapEquivalenceCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConceptMapEquivalenceCode
 */
export const ConceptMapEquivalenceCode = new ConceptMapEquivalenceCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptMapGroupUnmappedModeCode$Type extends MessageType<ConceptMapGroupUnmappedModeCode> {
    constructor() {
        super("google.fhir.r4.core.ConceptMapGroupUnmappedModeCode", []);
    }
    create(value?: PartialMessage<ConceptMapGroupUnmappedModeCode>): ConceptMapGroupUnmappedModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptMapGroupUnmappedModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptMapGroupUnmappedModeCode): ConceptMapGroupUnmappedModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConceptMapGroupUnmappedModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConceptMapGroupUnmappedModeCode
 */
export const ConceptMapGroupUnmappedModeCode = new ConceptMapGroupUnmappedModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConditionClinicalStatusCode$Type extends MessageType<ConditionClinicalStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ConditionClinicalStatusCode", []);
    }
    create(value?: PartialMessage<ConditionClinicalStatusCode>): ConditionClinicalStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConditionClinicalStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConditionClinicalStatusCode): ConditionClinicalStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConditionClinicalStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConditionClinicalStatusCode
 */
export const ConditionClinicalStatusCode = new ConditionClinicalStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConditionVerificationStatusCode$Type extends MessageType<ConditionVerificationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ConditionVerificationStatusCode", []);
    }
    create(value?: PartialMessage<ConditionVerificationStatusCode>): ConditionVerificationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConditionVerificationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConditionVerificationStatusCode): ConditionVerificationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConditionVerificationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConditionVerificationStatusCode
 */
export const ConditionVerificationStatusCode = new ConditionVerificationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConditionalDeleteStatusCode$Type extends MessageType<ConditionalDeleteStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ConditionalDeleteStatusCode", []);
    }
    create(value?: PartialMessage<ConditionalDeleteStatusCode>): ConditionalDeleteStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConditionalDeleteStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConditionalDeleteStatusCode): ConditionalDeleteStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConditionalDeleteStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConditionalDeleteStatusCode
 */
export const ConditionalDeleteStatusCode = new ConditionalDeleteStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConditionalReadStatusCode$Type extends MessageType<ConditionalReadStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ConditionalReadStatusCode", []);
    }
    create(value?: PartialMessage<ConditionalReadStatusCode>): ConditionalReadStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConditionalReadStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConditionalReadStatusCode): ConditionalReadStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConditionalReadStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConditionalReadStatusCode
 */
export const ConditionalReadStatusCode = new ConditionalReadStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConformanceExpectationCode$Type extends MessageType<ConformanceExpectationCode> {
    constructor() {
        super("google.fhir.r4.core.ConformanceExpectationCode", []);
    }
    create(value?: PartialMessage<ConformanceExpectationCode>): ConformanceExpectationCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConformanceExpectationCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConformanceExpectationCode): ConformanceExpectationCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConformanceExpectationCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConformanceExpectationCode
 */
export const ConformanceExpectationCode = new ConformanceExpectationCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentDataMeaningCode$Type extends MessageType<ConsentDataMeaningCode> {
    constructor() {
        super("google.fhir.r4.core.ConsentDataMeaningCode", []);
    }
    create(value?: PartialMessage<ConsentDataMeaningCode>): ConsentDataMeaningCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentDataMeaningCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentDataMeaningCode): ConsentDataMeaningCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConsentDataMeaningCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConsentDataMeaningCode
 */
export const ConsentDataMeaningCode = new ConsentDataMeaningCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentProvisionTypeCode$Type extends MessageType<ConsentProvisionTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ConsentProvisionTypeCode", []);
    }
    create(value?: PartialMessage<ConsentProvisionTypeCode>): ConsentProvisionTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentProvisionTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentProvisionTypeCode): ConsentProvisionTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConsentProvisionTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConsentProvisionTypeCode
 */
export const ConsentProvisionTypeCode = new ConsentProvisionTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentStateCode$Type extends MessageType<ConsentStateCode> {
    constructor() {
        super("google.fhir.r4.core.ConsentStateCode", []);
    }
    create(value?: PartialMessage<ConsentStateCode>): ConsentStateCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentStateCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentStateCode): ConsentStateCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConsentStateCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConsentStateCode
 */
export const ConsentStateCode = new ConsentStateCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConstraintSeverityCode$Type extends MessageType<ConstraintSeverityCode> {
    constructor() {
        super("google.fhir.r4.core.ConstraintSeverityCode", []);
    }
    create(value?: PartialMessage<ConstraintSeverityCode>): ConstraintSeverityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConstraintSeverityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConstraintSeverityCode): ConstraintSeverityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConstraintSeverityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ConstraintSeverityCode
 */
export const ConstraintSeverityCode = new ConstraintSeverityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactPointSystemCode$Type extends MessageType<ContactPointSystemCode> {
    constructor() {
        super("google.fhir.r4.core.ContactPointSystemCode", []);
    }
    create(value?: PartialMessage<ContactPointSystemCode>): ContactPointSystemCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactPointSystemCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactPointSystemCode): ContactPointSystemCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ContactPointSystemCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ContactPointSystemCode
 */
export const ContactPointSystemCode = new ContactPointSystemCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactPointUseCode$Type extends MessageType<ContactPointUseCode> {
    constructor() {
        super("google.fhir.r4.core.ContactPointUseCode", []);
    }
    create(value?: PartialMessage<ContactPointUseCode>): ContactPointUseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactPointUseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactPointUseCode): ContactPointUseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ContactPointUseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ContactPointUseCode
 */
export const ContactPointUseCode = new ContactPointUseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContractResourcePublicationStatusCode$Type extends MessageType<ContractResourcePublicationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ContractResourcePublicationStatusCode", []);
    }
    create(value?: PartialMessage<ContractResourcePublicationStatusCode>): ContractResourcePublicationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContractResourcePublicationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContractResourcePublicationStatusCode): ContractResourcePublicationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ContractResourcePublicationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ContractResourcePublicationStatusCode
 */
export const ContractResourcePublicationStatusCode = new ContractResourcePublicationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContractResourceStatusCode$Type extends MessageType<ContractResourceStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ContractResourceStatusCode", []);
    }
    create(value?: PartialMessage<ContractResourceStatusCode>): ContractResourceStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContractResourceStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContractResourceStatusCode): ContractResourceStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ContractResourceStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ContractResourceStatusCode
 */
export const ContractResourceStatusCode = new ContractResourceStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContributorTypeCode$Type extends MessageType<ContributorTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ContributorTypeCode", []);
    }
    create(value?: PartialMessage<ContributorTypeCode>): ContributorTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContributorTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContributorTypeCode): ContributorTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ContributorTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ContributorTypeCode
 */
export const ContributorTypeCode = new ContributorTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataAbsentReasonCode$Type extends MessageType<DataAbsentReasonCode> {
    constructor() {
        super("google.fhir.r4.core.DataAbsentReasonCode", []);
    }
    create(value?: PartialMessage<DataAbsentReasonCode>): DataAbsentReasonCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataAbsentReasonCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataAbsentReasonCode): DataAbsentReasonCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DataAbsentReasonCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DataAbsentReasonCode
 */
export const DataAbsentReasonCode = new DataAbsentReasonCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataTypeCode$Type extends MessageType<DataTypeCode> {
    constructor() {
        super("google.fhir.r4.core.DataTypeCode", []);
    }
    create(value?: PartialMessage<DataTypeCode>): DataTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataTypeCode): DataTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DataTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DataTypeCode
 */
export const DataTypeCode = new DataTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DaysOfWeekCode$Type extends MessageType<DaysOfWeekCode> {
    constructor() {
        super("google.fhir.r4.core.DaysOfWeekCode", []);
    }
    create(value?: PartialMessage<DaysOfWeekCode>): DaysOfWeekCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DaysOfWeekCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DaysOfWeekCode): DaysOfWeekCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DaysOfWeekCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DaysOfWeekCode
 */
export const DaysOfWeekCode = new DaysOfWeekCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DetectedIssueSeverityCode$Type extends MessageType<DetectedIssueSeverityCode> {
    constructor() {
        super("google.fhir.r4.core.DetectedIssueSeverityCode", []);
    }
    create(value?: PartialMessage<DetectedIssueSeverityCode>): DetectedIssueSeverityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DetectedIssueSeverityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DetectedIssueSeverityCode): DetectedIssueSeverityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DetectedIssueSeverityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DetectedIssueSeverityCode
 */
export const DetectedIssueSeverityCode = new DetectedIssueSeverityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceMetricCalibrationStateCode$Type extends MessageType<DeviceMetricCalibrationStateCode> {
    constructor() {
        super("google.fhir.r4.core.DeviceMetricCalibrationStateCode", []);
    }
    create(value?: PartialMessage<DeviceMetricCalibrationStateCode>): DeviceMetricCalibrationStateCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceMetricCalibrationStateCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceMetricCalibrationStateCode): DeviceMetricCalibrationStateCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeviceMetricCalibrationStateCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DeviceMetricCalibrationStateCode
 */
export const DeviceMetricCalibrationStateCode = new DeviceMetricCalibrationStateCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceMetricCalibrationTypeCode$Type extends MessageType<DeviceMetricCalibrationTypeCode> {
    constructor() {
        super("google.fhir.r4.core.DeviceMetricCalibrationTypeCode", []);
    }
    create(value?: PartialMessage<DeviceMetricCalibrationTypeCode>): DeviceMetricCalibrationTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceMetricCalibrationTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceMetricCalibrationTypeCode): DeviceMetricCalibrationTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeviceMetricCalibrationTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DeviceMetricCalibrationTypeCode
 */
export const DeviceMetricCalibrationTypeCode = new DeviceMetricCalibrationTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceMetricCategoryCode$Type extends MessageType<DeviceMetricCategoryCode> {
    constructor() {
        super("google.fhir.r4.core.DeviceMetricCategoryCode", []);
    }
    create(value?: PartialMessage<DeviceMetricCategoryCode>): DeviceMetricCategoryCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceMetricCategoryCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceMetricCategoryCode): DeviceMetricCategoryCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeviceMetricCategoryCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DeviceMetricCategoryCode
 */
export const DeviceMetricCategoryCode = new DeviceMetricCategoryCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceMetricColorCode$Type extends MessageType<DeviceMetricColorCode> {
    constructor() {
        super("google.fhir.r4.core.DeviceMetricColorCode", []);
    }
    create(value?: PartialMessage<DeviceMetricColorCode>): DeviceMetricColorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceMetricColorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceMetricColorCode): DeviceMetricColorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeviceMetricColorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DeviceMetricColorCode
 */
export const DeviceMetricColorCode = new DeviceMetricColorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceMetricOperationalStatusCode$Type extends MessageType<DeviceMetricOperationalStatusCode> {
    constructor() {
        super("google.fhir.r4.core.DeviceMetricOperationalStatusCode", []);
    }
    create(value?: PartialMessage<DeviceMetricOperationalStatusCode>): DeviceMetricOperationalStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceMetricOperationalStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceMetricOperationalStatusCode): DeviceMetricOperationalStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeviceMetricOperationalStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DeviceMetricOperationalStatusCode
 */
export const DeviceMetricOperationalStatusCode = new DeviceMetricOperationalStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceNameTypeCode$Type extends MessageType<DeviceNameTypeCode> {
    constructor() {
        super("google.fhir.r4.core.DeviceNameTypeCode", []);
    }
    create(value?: PartialMessage<DeviceNameTypeCode>): DeviceNameTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceNameTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceNameTypeCode): DeviceNameTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeviceNameTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DeviceNameTypeCode
 */
export const DeviceNameTypeCode = new DeviceNameTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceUseStatementStatusCode$Type extends MessageType<DeviceUseStatementStatusCode> {
    constructor() {
        super("google.fhir.r4.core.DeviceUseStatementStatusCode", []);
    }
    create(value?: PartialMessage<DeviceUseStatementStatusCode>): DeviceUseStatementStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceUseStatementStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceUseStatementStatusCode): DeviceUseStatementStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeviceUseStatementStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DeviceUseStatementStatusCode
 */
export const DeviceUseStatementStatusCode = new DeviceUseStatementStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiagnosticReportStatusCode$Type extends MessageType<DiagnosticReportStatusCode> {
    constructor() {
        super("google.fhir.r4.core.DiagnosticReportStatusCode", []);
    }
    create(value?: PartialMessage<DiagnosticReportStatusCode>): DiagnosticReportStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DiagnosticReportStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiagnosticReportStatusCode): DiagnosticReportStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DiagnosticReportStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DiagnosticReportStatusCode
 */
export const DiagnosticReportStatusCode = new DiagnosticReportStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscriminatorTypeCode$Type extends MessageType<DiscriminatorTypeCode> {
    constructor() {
        super("google.fhir.r4.core.DiscriminatorTypeCode", []);
    }
    create(value?: PartialMessage<DiscriminatorTypeCode>): DiscriminatorTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DiscriminatorTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscriminatorTypeCode): DiscriminatorTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DiscriminatorTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DiscriminatorTypeCode
 */
export const DiscriminatorTypeCode = new DiscriminatorTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DocumentModeCode$Type extends MessageType<DocumentModeCode> {
    constructor() {
        super("google.fhir.r4.core.DocumentModeCode", []);
    }
    create(value?: PartialMessage<DocumentModeCode>): DocumentModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DocumentModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DocumentModeCode): DocumentModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DocumentModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DocumentModeCode
 */
export const DocumentModeCode = new DocumentModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DocumentReferenceStatusCode$Type extends MessageType<DocumentReferenceStatusCode> {
    constructor() {
        super("google.fhir.r4.core.DocumentReferenceStatusCode", []);
    }
    create(value?: PartialMessage<DocumentReferenceStatusCode>): DocumentReferenceStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DocumentReferenceStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DocumentReferenceStatusCode): DocumentReferenceStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DocumentReferenceStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DocumentReferenceStatusCode
 */
export const DocumentReferenceStatusCode = new DocumentReferenceStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DocumentRelationshipTypeCode$Type extends MessageType<DocumentRelationshipTypeCode> {
    constructor() {
        super("google.fhir.r4.core.DocumentRelationshipTypeCode", []);
    }
    create(value?: PartialMessage<DocumentRelationshipTypeCode>): DocumentRelationshipTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DocumentRelationshipTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DocumentRelationshipTypeCode): DocumentRelationshipTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DocumentRelationshipTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.DocumentRelationshipTypeCode
 */
export const DocumentRelationshipTypeCode = new DocumentRelationshipTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EligibilityRequestPurposeCode$Type extends MessageType<EligibilityRequestPurposeCode> {
    constructor() {
        super("google.fhir.r4.core.EligibilityRequestPurposeCode", []);
    }
    create(value?: PartialMessage<EligibilityRequestPurposeCode>): EligibilityRequestPurposeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EligibilityRequestPurposeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EligibilityRequestPurposeCode): EligibilityRequestPurposeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EligibilityRequestPurposeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EligibilityRequestPurposeCode
 */
export const EligibilityRequestPurposeCode = new EligibilityRequestPurposeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EligibilityResponsePurposeCode$Type extends MessageType<EligibilityResponsePurposeCode> {
    constructor() {
        super("google.fhir.r4.core.EligibilityResponsePurposeCode", []);
    }
    create(value?: PartialMessage<EligibilityResponsePurposeCode>): EligibilityResponsePurposeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EligibilityResponsePurposeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EligibilityResponsePurposeCode): EligibilityResponsePurposeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EligibilityResponsePurposeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EligibilityResponsePurposeCode
 */
export const EligibilityResponsePurposeCode = new EligibilityResponsePurposeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnableWhenBehaviorCode$Type extends MessageType<EnableWhenBehaviorCode> {
    constructor() {
        super("google.fhir.r4.core.EnableWhenBehaviorCode", []);
    }
    create(value?: PartialMessage<EnableWhenBehaviorCode>): EnableWhenBehaviorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnableWhenBehaviorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnableWhenBehaviorCode): EnableWhenBehaviorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EnableWhenBehaviorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EnableWhenBehaviorCode
 */
export const EnableWhenBehaviorCode = new EnableWhenBehaviorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EncounterLocationStatusCode$Type extends MessageType<EncounterLocationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.EncounterLocationStatusCode", []);
    }
    create(value?: PartialMessage<EncounterLocationStatusCode>): EncounterLocationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EncounterLocationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EncounterLocationStatusCode): EncounterLocationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EncounterLocationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EncounterLocationStatusCode
 */
export const EncounterLocationStatusCode = new EncounterLocationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EncounterStatusCode$Type extends MessageType<EncounterStatusCode> {
    constructor() {
        super("google.fhir.r4.core.EncounterStatusCode", []);
    }
    create(value?: PartialMessage<EncounterStatusCode>): EncounterStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EncounterStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EncounterStatusCode): EncounterStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EncounterStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EncounterStatusCode
 */
export const EncounterStatusCode = new EncounterStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndpointStatusCode$Type extends MessageType<EndpointStatusCode> {
    constructor() {
        super("google.fhir.r4.core.EndpointStatusCode", []);
    }
    create(value?: PartialMessage<EndpointStatusCode>): EndpointStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EndpointStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EndpointStatusCode): EndpointStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EndpointStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EndpointStatusCode
 */
export const EndpointStatusCode = new EndpointStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EpisodeOfCareStatusCode$Type extends MessageType<EpisodeOfCareStatusCode> {
    constructor() {
        super("google.fhir.r4.core.EpisodeOfCareStatusCode", []);
    }
    create(value?: PartialMessage<EpisodeOfCareStatusCode>): EpisodeOfCareStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EpisodeOfCareStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EpisodeOfCareStatusCode): EpisodeOfCareStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EpisodeOfCareStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EpisodeOfCareStatusCode
 */
export const EpisodeOfCareStatusCode = new EpisodeOfCareStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventCapabilityModeCode$Type extends MessageType<EventCapabilityModeCode> {
    constructor() {
        super("google.fhir.r4.core.EventCapabilityModeCode", []);
    }
    create(value?: PartialMessage<EventCapabilityModeCode>): EventCapabilityModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventCapabilityModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventCapabilityModeCode): EventCapabilityModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EventCapabilityModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EventCapabilityModeCode
 */
export const EventCapabilityModeCode = new EventCapabilityModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventStatusCode$Type extends MessageType<EventStatusCode> {
    constructor() {
        super("google.fhir.r4.core.EventStatusCode", []);
    }
    create(value?: PartialMessage<EventStatusCode>): EventStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventStatusCode): EventStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EventStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EventStatusCode
 */
export const EventStatusCode = new EventStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventTimingCode$Type extends MessageType<EventTimingCode> {
    constructor() {
        super("google.fhir.r4.core.EventTimingCode", []);
    }
    create(value?: PartialMessage<EventTimingCode>): EventTimingCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventTimingCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventTimingCode): EventTimingCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EventTimingCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EventTimingCode
 */
export const EventTimingCode = new EventTimingCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EvidenceVariableTypeCode$Type extends MessageType<EvidenceVariableTypeCode> {
    constructor() {
        super("google.fhir.r4.core.EvidenceVariableTypeCode", []);
    }
    create(value?: PartialMessage<EvidenceVariableTypeCode>): EvidenceVariableTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EvidenceVariableTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EvidenceVariableTypeCode): EvidenceVariableTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EvidenceVariableTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EvidenceVariableTypeCode
 */
export const EvidenceVariableTypeCode = new EvidenceVariableTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExampleScenarioActorTypeCode$Type extends MessageType<ExampleScenarioActorTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ExampleScenarioActorTypeCode", []);
    }
    create(value?: PartialMessage<ExampleScenarioActorTypeCode>): ExampleScenarioActorTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExampleScenarioActorTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExampleScenarioActorTypeCode): ExampleScenarioActorTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ExampleScenarioActorTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ExampleScenarioActorTypeCode
 */
export const ExampleScenarioActorTypeCode = new ExampleScenarioActorTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpansionParameterSourceCode$Type extends MessageType<ExpansionParameterSourceCode> {
    constructor() {
        super("google.fhir.r4.core.ExpansionParameterSourceCode", []);
    }
    create(value?: PartialMessage<ExpansionParameterSourceCode>): ExpansionParameterSourceCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExpansionParameterSourceCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExpansionParameterSourceCode): ExpansionParameterSourceCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ExpansionParameterSourceCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ExpansionParameterSourceCode
 */
export const ExpansionParameterSourceCode = new ExpansionParameterSourceCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpansionProcessingRuleCode$Type extends MessageType<ExpansionProcessingRuleCode> {
    constructor() {
        super("google.fhir.r4.core.ExpansionProcessingRuleCode", []);
    }
    create(value?: PartialMessage<ExpansionProcessingRuleCode>): ExpansionProcessingRuleCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExpansionProcessingRuleCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExpansionProcessingRuleCode): ExpansionProcessingRuleCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ExpansionProcessingRuleCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ExpansionProcessingRuleCode
 */
export const ExpansionProcessingRuleCode = new ExpansionProcessingRuleCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExplanationOfBenefitStatusCode$Type extends MessageType<ExplanationOfBenefitStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ExplanationOfBenefitStatusCode", []);
    }
    create(value?: PartialMessage<ExplanationOfBenefitStatusCode>): ExplanationOfBenefitStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExplanationOfBenefitStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExplanationOfBenefitStatusCode): ExplanationOfBenefitStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ExplanationOfBenefitStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ExplanationOfBenefitStatusCode
 */
export const ExplanationOfBenefitStatusCode = new ExplanationOfBenefitStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExposureStateCode$Type extends MessageType<ExposureStateCode> {
    constructor() {
        super("google.fhir.r4.core.ExposureStateCode", []);
    }
    create(value?: PartialMessage<ExposureStateCode>): ExposureStateCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExposureStateCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExposureStateCode): ExposureStateCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ExposureStateCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ExposureStateCode
 */
export const ExposureStateCode = new ExposureStateCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtensionContextTypeCode$Type extends MessageType<ExtensionContextTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ExtensionContextTypeCode", []);
    }
    create(value?: PartialMessage<ExtensionContextTypeCode>): ExtensionContextTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExtensionContextTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExtensionContextTypeCode): ExtensionContextTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ExtensionContextTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ExtensionContextTypeCode
 */
export const ExtensionContextTypeCode = new ExtensionContextTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FHIRDeviceStatusCode$Type extends MessageType<FHIRDeviceStatusCode> {
    constructor() {
        super("google.fhir.r4.core.FHIRDeviceStatusCode", []);
    }
    create(value?: PartialMessage<FHIRDeviceStatusCode>): FHIRDeviceStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FHIRDeviceStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FHIRDeviceStatusCode): FHIRDeviceStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FHIRDeviceStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FHIRDeviceStatusCode
 */
export const FHIRDeviceStatusCode = new FHIRDeviceStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FHIRRestfulInteractionsCode$Type extends MessageType<FHIRRestfulInteractionsCode> {
    constructor() {
        super("google.fhir.r4.core.FHIRRestfulInteractionsCode", []);
    }
    create(value?: PartialMessage<FHIRRestfulInteractionsCode>): FHIRRestfulInteractionsCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FHIRRestfulInteractionsCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FHIRRestfulInteractionsCode): FHIRRestfulInteractionsCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FHIRRestfulInteractionsCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FHIRRestfulInteractionsCode
 */
export const FHIRRestfulInteractionsCode = new FHIRRestfulInteractionsCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FHIRSubstanceStatusCode$Type extends MessageType<FHIRSubstanceStatusCode> {
    constructor() {
        super("google.fhir.r4.core.FHIRSubstanceStatusCode", []);
    }
    create(value?: PartialMessage<FHIRSubstanceStatusCode>): FHIRSubstanceStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FHIRSubstanceStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FHIRSubstanceStatusCode): FHIRSubstanceStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FHIRSubstanceStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FHIRSubstanceStatusCode
 */
export const FHIRSubstanceStatusCode = new FHIRSubstanceStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FHIRVersionCode$Type extends MessageType<FHIRVersionCode> {
    constructor() {
        super("google.fhir.r4.core.FHIRVersionCode", []);
    }
    create(value?: PartialMessage<FHIRVersionCode>): FHIRVersionCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FHIRVersionCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FHIRVersionCode): FHIRVersionCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FHIRVersionCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FHIRVersionCode
 */
export const FHIRVersionCode = new FHIRVersionCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FamilyHistoryStatusCode$Type extends MessageType<FamilyHistoryStatusCode> {
    constructor() {
        super("google.fhir.r4.core.FamilyHistoryStatusCode", []);
    }
    create(value?: PartialMessage<FamilyHistoryStatusCode>): FamilyHistoryStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FamilyHistoryStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FamilyHistoryStatusCode): FamilyHistoryStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FamilyHistoryStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FamilyHistoryStatusCode
 */
export const FamilyHistoryStatusCode = new FamilyHistoryStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterOperatorCode$Type extends MessageType<FilterOperatorCode> {
    constructor() {
        super("google.fhir.r4.core.FilterOperatorCode", []);
    }
    create(value?: PartialMessage<FilterOperatorCode>): FilterOperatorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FilterOperatorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterOperatorCode): FilterOperatorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FilterOperatorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FilterOperatorCode
 */
export const FilterOperatorCode = new FilterOperatorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialResourceStatusCode$Type extends MessageType<FinancialResourceStatusCode> {
    constructor() {
        super("google.fhir.r4.core.FinancialResourceStatusCode", []);
    }
    create(value?: PartialMessage<FinancialResourceStatusCode>): FinancialResourceStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialResourceStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialResourceStatusCode): FinancialResourceStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FinancialResourceStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FinancialResourceStatusCode
 */
export const FinancialResourceStatusCode = new FinancialResourceStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlagStatusCode$Type extends MessageType<FlagStatusCode> {
    constructor() {
        super("google.fhir.r4.core.FlagStatusCode", []);
    }
    create(value?: PartialMessage<FlagStatusCode>): FlagStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlagStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlagStatusCode): FlagStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FlagStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FlagStatusCode
 */
export const FlagStatusCode = new FlagStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoalAcceptanceStatusCode$Type extends MessageType<GoalAcceptanceStatusCode> {
    constructor() {
        super("google.fhir.r4.core.GoalAcceptanceStatusCode", []);
    }
    create(value?: PartialMessage<GoalAcceptanceStatusCode>): GoalAcceptanceStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GoalAcceptanceStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GoalAcceptanceStatusCode): GoalAcceptanceStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GoalAcceptanceStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GoalAcceptanceStatusCode
 */
export const GoalAcceptanceStatusCode = new GoalAcceptanceStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoalLifecycleStatusCode$Type extends MessageType<GoalLifecycleStatusCode> {
    constructor() {
        super("google.fhir.r4.core.GoalLifecycleStatusCode", []);
    }
    create(value?: PartialMessage<GoalLifecycleStatusCode>): GoalLifecycleStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GoalLifecycleStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GoalLifecycleStatusCode): GoalLifecycleStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GoalLifecycleStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GoalLifecycleStatusCode
 */
export const GoalLifecycleStatusCode = new GoalLifecycleStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphCompartmentRuleCode$Type extends MessageType<GraphCompartmentRuleCode> {
    constructor() {
        super("google.fhir.r4.core.GraphCompartmentRuleCode", []);
    }
    create(value?: PartialMessage<GraphCompartmentRuleCode>): GraphCompartmentRuleCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GraphCompartmentRuleCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GraphCompartmentRuleCode): GraphCompartmentRuleCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GraphCompartmentRuleCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GraphCompartmentRuleCode
 */
export const GraphCompartmentRuleCode = new GraphCompartmentRuleCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphCompartmentUseCode$Type extends MessageType<GraphCompartmentUseCode> {
    constructor() {
        super("google.fhir.r4.core.GraphCompartmentUseCode", []);
    }
    create(value?: PartialMessage<GraphCompartmentUseCode>): GraphCompartmentUseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GraphCompartmentUseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GraphCompartmentUseCode): GraphCompartmentUseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GraphCompartmentUseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GraphCompartmentUseCode
 */
export const GraphCompartmentUseCode = new GraphCompartmentUseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupMeasureCode$Type extends MessageType<GroupMeasureCode> {
    constructor() {
        super("google.fhir.r4.core.GroupMeasureCode", []);
    }
    create(value?: PartialMessage<GroupMeasureCode>): GroupMeasureCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GroupMeasureCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GroupMeasureCode): GroupMeasureCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GroupMeasureCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GroupMeasureCode
 */
export const GroupMeasureCode = new GroupMeasureCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupTypeCode$Type extends MessageType<GroupTypeCode> {
    constructor() {
        super("google.fhir.r4.core.GroupTypeCode", []);
    }
    create(value?: PartialMessage<GroupTypeCode>): GroupTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GroupTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GroupTypeCode): GroupTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GroupTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GroupTypeCode
 */
export const GroupTypeCode = new GroupTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuidanceResponseStatusCode$Type extends MessageType<GuidanceResponseStatusCode> {
    constructor() {
        super("google.fhir.r4.core.GuidanceResponseStatusCode", []);
    }
    create(value?: PartialMessage<GuidanceResponseStatusCode>): GuidanceResponseStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuidanceResponseStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuidanceResponseStatusCode): GuidanceResponseStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GuidanceResponseStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GuidanceResponseStatusCode
 */
export const GuidanceResponseStatusCode = new GuidanceResponseStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuidePageGenerationCode$Type extends MessageType<GuidePageGenerationCode> {
    constructor() {
        super("google.fhir.r4.core.GuidePageGenerationCode", []);
    }
    create(value?: PartialMessage<GuidePageGenerationCode>): GuidePageGenerationCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuidePageGenerationCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuidePageGenerationCode): GuidePageGenerationCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GuidePageGenerationCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GuidePageGenerationCode
 */
export const GuidePageGenerationCode = new GuidePageGenerationCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideParameterCode$Type extends MessageType<GuideParameterCode> {
    constructor() {
        super("google.fhir.r4.core.GuideParameterCode", []);
    }
    create(value?: PartialMessage<GuideParameterCode>): GuideParameterCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideParameterCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideParameterCode): GuideParameterCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GuideParameterCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.GuideParameterCode
 */
export const GuideParameterCode = new GuideParameterCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HL7WorkgroupCode$Type extends MessageType<HL7WorkgroupCode> {
    constructor() {
        super("google.fhir.r4.core.HL7WorkgroupCode", []);
    }
    create(value?: PartialMessage<HL7WorkgroupCode>): HL7WorkgroupCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HL7WorkgroupCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HL7WorkgroupCode): HL7WorkgroupCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: HL7WorkgroupCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.HL7WorkgroupCode
 */
export const HL7WorkgroupCode = new HL7WorkgroupCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HTTPVerbCode$Type extends MessageType<HTTPVerbCode> {
    constructor() {
        super("google.fhir.r4.core.HTTPVerbCode", []);
    }
    create(value?: PartialMessage<HTTPVerbCode>): HTTPVerbCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HTTPVerbCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HTTPVerbCode): HTTPVerbCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: HTTPVerbCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.HTTPVerbCode
 */
export const HTTPVerbCode = new HTTPVerbCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HumanNameAssemblyOrderCode$Type extends MessageType<HumanNameAssemblyOrderCode> {
    constructor() {
        super("google.fhir.r4.core.HumanNameAssemblyOrderCode", []);
    }
    create(value?: PartialMessage<HumanNameAssemblyOrderCode>): HumanNameAssemblyOrderCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HumanNameAssemblyOrderCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HumanNameAssemblyOrderCode): HumanNameAssemblyOrderCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: HumanNameAssemblyOrderCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.HumanNameAssemblyOrderCode
 */
export const HumanNameAssemblyOrderCode = new HumanNameAssemblyOrderCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdentifierUseCode$Type extends MessageType<IdentifierUseCode> {
    constructor() {
        super("google.fhir.r4.core.IdentifierUseCode", []);
    }
    create(value?: PartialMessage<IdentifierUseCode>): IdentifierUseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IdentifierUseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IdentifierUseCode): IdentifierUseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: IdentifierUseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.IdentifierUseCode
 */
export const IdentifierUseCode = new IdentifierUseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdentityAssuranceLevelCode$Type extends MessageType<IdentityAssuranceLevelCode> {
    constructor() {
        super("google.fhir.r4.core.IdentityAssuranceLevelCode", []);
    }
    create(value?: PartialMessage<IdentityAssuranceLevelCode>): IdentityAssuranceLevelCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IdentityAssuranceLevelCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IdentityAssuranceLevelCode): IdentityAssuranceLevelCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: IdentityAssuranceLevelCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.IdentityAssuranceLevelCode
 */
export const IdentityAssuranceLevelCode = new IdentityAssuranceLevelCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImagingStudyStatusCode$Type extends MessageType<ImagingStudyStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ImagingStudyStatusCode", []);
    }
    create(value?: PartialMessage<ImagingStudyStatusCode>): ImagingStudyStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImagingStudyStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImagingStudyStatusCode): ImagingStudyStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImagingStudyStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ImagingStudyStatusCode
 */
export const ImagingStudyStatusCode = new ImagingStudyStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImplantStatusCode$Type extends MessageType<ImplantStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ImplantStatusCode", []);
    }
    create(value?: PartialMessage<ImplantStatusCode>): ImplantStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImplantStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImplantStatusCode): ImplantStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImplantStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ImplantStatusCode
 */
export const ImplantStatusCode = new ImplantStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoicePriceComponentTypeCode$Type extends MessageType<InvoicePriceComponentTypeCode> {
    constructor() {
        super("google.fhir.r4.core.InvoicePriceComponentTypeCode", []);
    }
    create(value?: PartialMessage<InvoicePriceComponentTypeCode>): InvoicePriceComponentTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InvoicePriceComponentTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvoicePriceComponentTypeCode): InvoicePriceComponentTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: InvoicePriceComponentTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.InvoicePriceComponentTypeCode
 */
export const InvoicePriceComponentTypeCode = new InvoicePriceComponentTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceStatusCode$Type extends MessageType<InvoiceStatusCode> {
    constructor() {
        super("google.fhir.r4.core.InvoiceStatusCode", []);
    }
    create(value?: PartialMessage<InvoiceStatusCode>): InvoiceStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InvoiceStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvoiceStatusCode): InvoiceStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: InvoiceStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.InvoiceStatusCode
 */
export const InvoiceStatusCode = new InvoiceStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IssueSeverityCode$Type extends MessageType<IssueSeverityCode> {
    constructor() {
        super("google.fhir.r4.core.IssueSeverityCode", []);
    }
    create(value?: PartialMessage<IssueSeverityCode>): IssueSeverityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IssueSeverityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IssueSeverityCode): IssueSeverityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: IssueSeverityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.IssueSeverityCode
 */
export const IssueSeverityCode = new IssueSeverityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IssueTypeCode$Type extends MessageType<IssueTypeCode> {
    constructor() {
        super("google.fhir.r4.core.IssueTypeCode", []);
    }
    create(value?: PartialMessage<IssueTypeCode>): IssueTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IssueTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IssueTypeCode): IssueTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: IssueTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.IssueTypeCode
 */
export const IssueTypeCode = new IssueTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkTypeCode$Type extends MessageType<LinkTypeCode> {
    constructor() {
        super("google.fhir.r4.core.LinkTypeCode", []);
    }
    create(value?: PartialMessage<LinkTypeCode>): LinkTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LinkTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LinkTypeCode): LinkTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LinkTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.LinkTypeCode
 */
export const LinkTypeCode = new LinkTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkageTypeCode$Type extends MessageType<LinkageTypeCode> {
    constructor() {
        super("google.fhir.r4.core.LinkageTypeCode", []);
    }
    create(value?: PartialMessage<LinkageTypeCode>): LinkageTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LinkageTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LinkageTypeCode): LinkageTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LinkageTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.LinkageTypeCode
 */
export const LinkageTypeCode = new LinkageTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListModeCode$Type extends MessageType<ListModeCode> {
    constructor() {
        super("google.fhir.r4.core.ListModeCode", []);
    }
    create(value?: PartialMessage<ListModeCode>): ListModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListModeCode): ListModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ListModeCode
 */
export const ListModeCode = new ListModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStatusCode$Type extends MessageType<ListStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ListStatusCode", []);
    }
    create(value?: PartialMessage<ListStatusCode>): ListStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStatusCode): ListStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ListStatusCode
 */
export const ListStatusCode = new ListStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationModeCode$Type extends MessageType<LocationModeCode> {
    constructor() {
        super("google.fhir.r4.core.LocationModeCode", []);
    }
    create(value?: PartialMessage<LocationModeCode>): LocationModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationModeCode): LocationModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LocationModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.LocationModeCode
 */
export const LocationModeCode = new LocationModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationStatusCode$Type extends MessageType<LocationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.LocationStatusCode", []);
    }
    create(value?: PartialMessage<LocationStatusCode>): LocationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationStatusCode): LocationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LocationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.LocationStatusCode
 */
export const LocationStatusCode = new LocationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchGradeCode$Type extends MessageType<MatchGradeCode> {
    constructor() {
        super("google.fhir.r4.core.MatchGradeCode", []);
    }
    create(value?: PartialMessage<MatchGradeCode>): MatchGradeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MatchGradeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchGradeCode): MatchGradeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MatchGradeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MatchGradeCode
 */
export const MatchGradeCode = new MatchGradeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeasureImprovementNotationCode$Type extends MessageType<MeasureImprovementNotationCode> {
    constructor() {
        super("google.fhir.r4.core.MeasureImprovementNotationCode", []);
    }
    create(value?: PartialMessage<MeasureImprovementNotationCode>): MeasureImprovementNotationCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeasureImprovementNotationCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeasureImprovementNotationCode): MeasureImprovementNotationCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MeasureImprovementNotationCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MeasureImprovementNotationCode
 */
export const MeasureImprovementNotationCode = new MeasureImprovementNotationCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeasureReportStatusCode$Type extends MessageType<MeasureReportStatusCode> {
    constructor() {
        super("google.fhir.r4.core.MeasureReportStatusCode", []);
    }
    create(value?: PartialMessage<MeasureReportStatusCode>): MeasureReportStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeasureReportStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeasureReportStatusCode): MeasureReportStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MeasureReportStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MeasureReportStatusCode
 */
export const MeasureReportStatusCode = new MeasureReportStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeasureReportTypeCode$Type extends MessageType<MeasureReportTypeCode> {
    constructor() {
        super("google.fhir.r4.core.MeasureReportTypeCode", []);
    }
    create(value?: PartialMessage<MeasureReportTypeCode>): MeasureReportTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeasureReportTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeasureReportTypeCode): MeasureReportTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MeasureReportTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MeasureReportTypeCode
 */
export const MeasureReportTypeCode = new MeasureReportTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MedicationAdministrationStatusCode$Type extends MessageType<MedicationAdministrationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.MedicationAdministrationStatusCode", []);
    }
    create(value?: PartialMessage<MedicationAdministrationStatusCode>): MedicationAdministrationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MedicationAdministrationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MedicationAdministrationStatusCode): MedicationAdministrationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MedicationAdministrationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MedicationAdministrationStatusCode
 */
export const MedicationAdministrationStatusCode = new MedicationAdministrationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MedicationDispenseStatusCode$Type extends MessageType<MedicationDispenseStatusCode> {
    constructor() {
        super("google.fhir.r4.core.MedicationDispenseStatusCode", []);
    }
    create(value?: PartialMessage<MedicationDispenseStatusCode>): MedicationDispenseStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MedicationDispenseStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MedicationDispenseStatusCode): MedicationDispenseStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MedicationDispenseStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MedicationDispenseStatusCode
 */
export const MedicationDispenseStatusCode = new MedicationDispenseStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MedicationKnowledgeStatusCode$Type extends MessageType<MedicationKnowledgeStatusCode> {
    constructor() {
        super("google.fhir.r4.core.MedicationKnowledgeStatusCode", []);
    }
    create(value?: PartialMessage<MedicationKnowledgeStatusCode>): MedicationKnowledgeStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MedicationKnowledgeStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MedicationKnowledgeStatusCode): MedicationKnowledgeStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MedicationKnowledgeStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MedicationKnowledgeStatusCode
 */
export const MedicationKnowledgeStatusCode = new MedicationKnowledgeStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MedicationRequestIntentCode$Type extends MessageType<MedicationRequestIntentCode> {
    constructor() {
        super("google.fhir.r4.core.MedicationRequestIntentCode", []);
    }
    create(value?: PartialMessage<MedicationRequestIntentCode>): MedicationRequestIntentCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MedicationRequestIntentCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MedicationRequestIntentCode): MedicationRequestIntentCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MedicationRequestIntentCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MedicationRequestIntentCode
 */
export const MedicationRequestIntentCode = new MedicationRequestIntentCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MedicationStatementStatusCodes$Type extends MessageType<MedicationStatementStatusCodes> {
    constructor() {
        super("google.fhir.r4.core.MedicationStatementStatusCodes", []);
    }
    create(value?: PartialMessage<MedicationStatementStatusCodes>): MedicationStatementStatusCodes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MedicationStatementStatusCodes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MedicationStatementStatusCodes): MedicationStatementStatusCodes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MedicationStatementStatusCodes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MedicationStatementStatusCodes
 */
export const MedicationStatementStatusCodes = new MedicationStatementStatusCodes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MedicationStatusCode$Type extends MessageType<MedicationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.MedicationStatusCode", []);
    }
    create(value?: PartialMessage<MedicationStatusCode>): MedicationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MedicationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MedicationStatusCode): MedicationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MedicationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MedicationStatusCode
 */
export const MedicationStatusCode = new MedicationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MedicationrequestStatusCode$Type extends MessageType<MedicationrequestStatusCode> {
    constructor() {
        super("google.fhir.r4.core.MedicationrequestStatusCode", []);
    }
    create(value?: PartialMessage<MedicationrequestStatusCode>): MedicationrequestStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MedicationrequestStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MedicationrequestStatusCode): MedicationrequestStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MedicationrequestStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MedicationrequestStatusCode
 */
export const MedicationrequestStatusCode = new MedicationrequestStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageSignificanceCategoryCode$Type extends MessageType<MessageSignificanceCategoryCode> {
    constructor() {
        super("google.fhir.r4.core.MessageSignificanceCategoryCode", []);
    }
    create(value?: PartialMessage<MessageSignificanceCategoryCode>): MessageSignificanceCategoryCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageSignificanceCategoryCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageSignificanceCategoryCode): MessageSignificanceCategoryCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MessageSignificanceCategoryCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MessageSignificanceCategoryCode
 */
export const MessageSignificanceCategoryCode = new MessageSignificanceCategoryCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageheaderResponseRequestCode$Type extends MessageType<MessageheaderResponseRequestCode> {
    constructor() {
        super("google.fhir.r4.core.MessageheaderResponseRequestCode", []);
    }
    create(value?: PartialMessage<MessageheaderResponseRequestCode>): MessageheaderResponseRequestCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageheaderResponseRequestCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageheaderResponseRequestCode): MessageheaderResponseRequestCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MessageheaderResponseRequestCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.MessageheaderResponseRequestCode
 */
export const MessageheaderResponseRequestCode = new MessageheaderResponseRequestCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NameUseCode$Type extends MessageType<NameUseCode> {
    constructor() {
        super("google.fhir.r4.core.NameUseCode", []);
    }
    create(value?: PartialMessage<NameUseCode>): NameUseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NameUseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NameUseCode): NameUseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: NameUseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.NameUseCode
 */
export const NameUseCode = new NameUseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NamingSystemIdentifierTypeCode$Type extends MessageType<NamingSystemIdentifierTypeCode> {
    constructor() {
        super("google.fhir.r4.core.NamingSystemIdentifierTypeCode", []);
    }
    create(value?: PartialMessage<NamingSystemIdentifierTypeCode>): NamingSystemIdentifierTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NamingSystemIdentifierTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NamingSystemIdentifierTypeCode): NamingSystemIdentifierTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: NamingSystemIdentifierTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.NamingSystemIdentifierTypeCode
 */
export const NamingSystemIdentifierTypeCode = new NamingSystemIdentifierTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NamingSystemTypeCode$Type extends MessageType<NamingSystemTypeCode> {
    constructor() {
        super("google.fhir.r4.core.NamingSystemTypeCode", []);
    }
    create(value?: PartialMessage<NamingSystemTypeCode>): NamingSystemTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NamingSystemTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NamingSystemTypeCode): NamingSystemTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: NamingSystemTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.NamingSystemTypeCode
 */
export const NamingSystemTypeCode = new NamingSystemTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NarrativeStatusCode$Type extends MessageType<NarrativeStatusCode> {
    constructor() {
        super("google.fhir.r4.core.NarrativeStatusCode", []);
    }
    create(value?: PartialMessage<NarrativeStatusCode>): NarrativeStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NarrativeStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NarrativeStatusCode): NarrativeStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: NarrativeStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.NarrativeStatusCode
 */
export const NarrativeStatusCode = new NarrativeStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NoteTypeCode$Type extends MessageType<NoteTypeCode> {
    constructor() {
        super("google.fhir.r4.core.NoteTypeCode", []);
    }
    create(value?: PartialMessage<NoteTypeCode>): NoteTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NoteTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NoteTypeCode): NoteTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: NoteTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.NoteTypeCode
 */
export const NoteTypeCode = new NoteTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObservationDataTypeCode$Type extends MessageType<ObservationDataTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ObservationDataTypeCode", []);
    }
    create(value?: PartialMessage<ObservationDataTypeCode>): ObservationDataTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ObservationDataTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObservationDataTypeCode): ObservationDataTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ObservationDataTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ObservationDataTypeCode
 */
export const ObservationDataTypeCode = new ObservationDataTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObservationRangeCategoryCode$Type extends MessageType<ObservationRangeCategoryCode> {
    constructor() {
        super("google.fhir.r4.core.ObservationRangeCategoryCode", []);
    }
    create(value?: PartialMessage<ObservationRangeCategoryCode>): ObservationRangeCategoryCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ObservationRangeCategoryCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObservationRangeCategoryCode): ObservationRangeCategoryCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ObservationRangeCategoryCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ObservationRangeCategoryCode
 */
export const ObservationRangeCategoryCode = new ObservationRangeCategoryCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObservationStatusCode$Type extends MessageType<ObservationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ObservationStatusCode", []);
    }
    create(value?: PartialMessage<ObservationStatusCode>): ObservationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ObservationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObservationStatusCode): ObservationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ObservationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ObservationStatusCode
 */
export const ObservationStatusCode = new ObservationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OperationKindCode$Type extends MessageType<OperationKindCode> {
    constructor() {
        super("google.fhir.r4.core.OperationKindCode", []);
    }
    create(value?: PartialMessage<OperationKindCode>): OperationKindCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OperationKindCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OperationKindCode): OperationKindCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: OperationKindCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.OperationKindCode
 */
export const OperationKindCode = new OperationKindCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OperationParameterUseCode$Type extends MessageType<OperationParameterUseCode> {
    constructor() {
        super("google.fhir.r4.core.OperationParameterUseCode", []);
    }
    create(value?: PartialMessage<OperationParameterUseCode>): OperationParameterUseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OperationParameterUseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OperationParameterUseCode): OperationParameterUseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: OperationParameterUseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.OperationParameterUseCode
 */
export const OperationParameterUseCode = new OperationParameterUseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrientationTypeCode$Type extends MessageType<OrientationTypeCode> {
    constructor() {
        super("google.fhir.r4.core.OrientationTypeCode", []);
    }
    create(value?: PartialMessage<OrientationTypeCode>): OrientationTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrientationTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrientationTypeCode): OrientationTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: OrientationTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.OrientationTypeCode
 */
export const OrientationTypeCode = new OrientationTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantRequiredCode$Type extends MessageType<ParticipantRequiredCode> {
    constructor() {
        super("google.fhir.r4.core.ParticipantRequiredCode", []);
    }
    create(value?: PartialMessage<ParticipantRequiredCode>): ParticipantRequiredCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParticipantRequiredCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipantRequiredCode): ParticipantRequiredCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ParticipantRequiredCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ParticipantRequiredCode
 */
export const ParticipantRequiredCode = new ParticipantRequiredCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipationStatusCode$Type extends MessageType<ParticipationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ParticipationStatusCode", []);
    }
    create(value?: PartialMessage<ParticipationStatusCode>): ParticipationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParticipationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipationStatusCode): ParticipationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ParticipationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ParticipationStatusCode
 */
export const ParticipationStatusCode = new ParticipationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PropertyRepresentationCode$Type extends MessageType<PropertyRepresentationCode> {
    constructor() {
        super("google.fhir.r4.core.PropertyRepresentationCode", []);
    }
    create(value?: PartialMessage<PropertyRepresentationCode>): PropertyRepresentationCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PropertyRepresentationCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PropertyRepresentationCode): PropertyRepresentationCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PropertyRepresentationCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.PropertyRepresentationCode
 */
export const PropertyRepresentationCode = new PropertyRepresentationCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PropertyTypeCode$Type extends MessageType<PropertyTypeCode> {
    constructor() {
        super("google.fhir.r4.core.PropertyTypeCode", []);
    }
    create(value?: PartialMessage<PropertyTypeCode>): PropertyTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PropertyTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PropertyTypeCode): PropertyTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PropertyTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.PropertyTypeCode
 */
export const PropertyTypeCode = new PropertyTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProvenanceEntityRoleCode$Type extends MessageType<ProvenanceEntityRoleCode> {
    constructor() {
        super("google.fhir.r4.core.ProvenanceEntityRoleCode", []);
    }
    create(value?: PartialMessage<ProvenanceEntityRoleCode>): ProvenanceEntityRoleCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProvenanceEntityRoleCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProvenanceEntityRoleCode): ProvenanceEntityRoleCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ProvenanceEntityRoleCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ProvenanceEntityRoleCode
 */
export const ProvenanceEntityRoleCode = new ProvenanceEntityRoleCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublicationStatusCode$Type extends MessageType<PublicationStatusCode> {
    constructor() {
        super("google.fhir.r4.core.PublicationStatusCode", []);
    }
    create(value?: PartialMessage<PublicationStatusCode>): PublicationStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PublicationStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PublicationStatusCode): PublicationStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PublicationStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.PublicationStatusCode
 */
export const PublicationStatusCode = new PublicationStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QualityTypeCode$Type extends MessageType<QualityTypeCode> {
    constructor() {
        super("google.fhir.r4.core.QualityTypeCode", []);
    }
    create(value?: PartialMessage<QualityTypeCode>): QualityTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QualityTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QualityTypeCode): QualityTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: QualityTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.QualityTypeCode
 */
export const QualityTypeCode = new QualityTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuantityComparatorCode$Type extends MessageType<QuantityComparatorCode> {
    constructor() {
        super("google.fhir.r4.core.QuantityComparatorCode", []);
    }
    create(value?: PartialMessage<QuantityComparatorCode>): QuantityComparatorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QuantityComparatorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QuantityComparatorCode): QuantityComparatorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: QuantityComparatorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.QuantityComparatorCode
 */
export const QuantityComparatorCode = new QuantityComparatorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionnaireItemOperatorCode$Type extends MessageType<QuestionnaireItemOperatorCode> {
    constructor() {
        super("google.fhir.r4.core.QuestionnaireItemOperatorCode", []);
    }
    create(value?: PartialMessage<QuestionnaireItemOperatorCode>): QuestionnaireItemOperatorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QuestionnaireItemOperatorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QuestionnaireItemOperatorCode): QuestionnaireItemOperatorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: QuestionnaireItemOperatorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.QuestionnaireItemOperatorCode
 */
export const QuestionnaireItemOperatorCode = new QuestionnaireItemOperatorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionnaireItemTypeCode$Type extends MessageType<QuestionnaireItemTypeCode> {
    constructor() {
        super("google.fhir.r4.core.QuestionnaireItemTypeCode", []);
    }
    create(value?: PartialMessage<QuestionnaireItemTypeCode>): QuestionnaireItemTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QuestionnaireItemTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QuestionnaireItemTypeCode): QuestionnaireItemTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: QuestionnaireItemTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.QuestionnaireItemTypeCode
 */
export const QuestionnaireItemTypeCode = new QuestionnaireItemTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionnaireItemUsageModeCode$Type extends MessageType<QuestionnaireItemUsageModeCode> {
    constructor() {
        super("google.fhir.r4.core.QuestionnaireItemUsageModeCode", []);
    }
    create(value?: PartialMessage<QuestionnaireItemUsageModeCode>): QuestionnaireItemUsageModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QuestionnaireItemUsageModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QuestionnaireItemUsageModeCode): QuestionnaireItemUsageModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: QuestionnaireItemUsageModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.QuestionnaireItemUsageModeCode
 */
export const QuestionnaireItemUsageModeCode = new QuestionnaireItemUsageModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionnaireResponseStatusCode$Type extends MessageType<QuestionnaireResponseStatusCode> {
    constructor() {
        super("google.fhir.r4.core.QuestionnaireResponseStatusCode", []);
    }
    create(value?: PartialMessage<QuestionnaireResponseStatusCode>): QuestionnaireResponseStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QuestionnaireResponseStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QuestionnaireResponseStatusCode): QuestionnaireResponseStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: QuestionnaireResponseStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.QuestionnaireResponseStatusCode
 */
export const QuestionnaireResponseStatusCode = new QuestionnaireResponseStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReferenceHandlingPolicyCode$Type extends MessageType<ReferenceHandlingPolicyCode> {
    constructor() {
        super("google.fhir.r4.core.ReferenceHandlingPolicyCode", []);
    }
    create(value?: PartialMessage<ReferenceHandlingPolicyCode>): ReferenceHandlingPolicyCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReferenceHandlingPolicyCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReferenceHandlingPolicyCode): ReferenceHandlingPolicyCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReferenceHandlingPolicyCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ReferenceHandlingPolicyCode
 */
export const ReferenceHandlingPolicyCode = new ReferenceHandlingPolicyCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReferenceVersionRulesCode$Type extends MessageType<ReferenceVersionRulesCode> {
    constructor() {
        super("google.fhir.r4.core.ReferenceVersionRulesCode", []);
    }
    create(value?: PartialMessage<ReferenceVersionRulesCode>): ReferenceVersionRulesCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReferenceVersionRulesCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReferenceVersionRulesCode): ReferenceVersionRulesCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReferenceVersionRulesCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ReferenceVersionRulesCode
 */
export const ReferenceVersionRulesCode = new ReferenceVersionRulesCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelatedArtifactTypeCode$Type extends MessageType<RelatedArtifactTypeCode> {
    constructor() {
        super("google.fhir.r4.core.RelatedArtifactTypeCode", []);
    }
    create(value?: PartialMessage<RelatedArtifactTypeCode>): RelatedArtifactTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelatedArtifactTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelatedArtifactTypeCode): RelatedArtifactTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RelatedArtifactTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.RelatedArtifactTypeCode
 */
export const RelatedArtifactTypeCode = new RelatedArtifactTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RepositoryTypeCode$Type extends MessageType<RepositoryTypeCode> {
    constructor() {
        super("google.fhir.r4.core.RepositoryTypeCode", []);
    }
    create(value?: PartialMessage<RepositoryTypeCode>): RepositoryTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RepositoryTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RepositoryTypeCode): RepositoryTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RepositoryTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.RepositoryTypeCode
 */
export const RepositoryTypeCode = new RepositoryTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestIntentCode$Type extends MessageType<RequestIntentCode> {
    constructor() {
        super("google.fhir.r4.core.RequestIntentCode", []);
    }
    create(value?: PartialMessage<RequestIntentCode>): RequestIntentCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestIntentCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestIntentCode): RequestIntentCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RequestIntentCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.RequestIntentCode
 */
export const RequestIntentCode = new RequestIntentCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestPriorityCode$Type extends MessageType<RequestPriorityCode> {
    constructor() {
        super("google.fhir.r4.core.RequestPriorityCode", []);
    }
    create(value?: PartialMessage<RequestPriorityCode>): RequestPriorityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestPriorityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestPriorityCode): RequestPriorityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RequestPriorityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.RequestPriorityCode
 */
export const RequestPriorityCode = new RequestPriorityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestResourceTypeCode$Type extends MessageType<RequestResourceTypeCode> {
    constructor() {
        super("google.fhir.r4.core.RequestResourceTypeCode", []);
    }
    create(value?: PartialMessage<RequestResourceTypeCode>): RequestResourceTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestResourceTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestResourceTypeCode): RequestResourceTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RequestResourceTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.RequestResourceTypeCode
 */
export const RequestResourceTypeCode = new RequestResourceTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestStatusCode$Type extends MessageType<RequestStatusCode> {
    constructor() {
        super("google.fhir.r4.core.RequestStatusCode", []);
    }
    create(value?: PartialMessage<RequestStatusCode>): RequestStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestStatusCode): RequestStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RequestStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.RequestStatusCode
 */
export const RequestStatusCode = new RequestStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResearchElementTypeCode$Type extends MessageType<ResearchElementTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ResearchElementTypeCode", []);
    }
    create(value?: PartialMessage<ResearchElementTypeCode>): ResearchElementTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResearchElementTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResearchElementTypeCode): ResearchElementTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResearchElementTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ResearchElementTypeCode
 */
export const ResearchElementTypeCode = new ResearchElementTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResearchStudyStatusCode$Type extends MessageType<ResearchStudyStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ResearchStudyStatusCode", []);
    }
    create(value?: PartialMessage<ResearchStudyStatusCode>): ResearchStudyStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResearchStudyStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResearchStudyStatusCode): ResearchStudyStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResearchStudyStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ResearchStudyStatusCode
 */
export const ResearchStudyStatusCode = new ResearchStudyStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResearchSubjectStatusCode$Type extends MessageType<ResearchSubjectStatusCode> {
    constructor() {
        super("google.fhir.r4.core.ResearchSubjectStatusCode", []);
    }
    create(value?: PartialMessage<ResearchSubjectStatusCode>): ResearchSubjectStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResearchSubjectStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResearchSubjectStatusCode): ResearchSubjectStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResearchSubjectStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ResearchSubjectStatusCode
 */
export const ResearchSubjectStatusCode = new ResearchSubjectStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceSecurityCategoryCode$Type extends MessageType<ResourceSecurityCategoryCode> {
    constructor() {
        super("google.fhir.r4.core.ResourceSecurityCategoryCode", []);
    }
    create(value?: PartialMessage<ResourceSecurityCategoryCode>): ResourceSecurityCategoryCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResourceSecurityCategoryCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResourceSecurityCategoryCode): ResourceSecurityCategoryCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResourceSecurityCategoryCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ResourceSecurityCategoryCode
 */
export const ResourceSecurityCategoryCode = new ResourceSecurityCategoryCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceTypeCode$Type extends MessageType<ResourceTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ResourceTypeCode", []);
    }
    create(value?: PartialMessage<ResourceTypeCode>): ResourceTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResourceTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResourceTypeCode): ResourceTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResourceTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ResourceTypeCode
 */
export const ResourceTypeCode = new ResourceTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceVersionPolicyCode$Type extends MessageType<ResourceVersionPolicyCode> {
    constructor() {
        super("google.fhir.r4.core.ResourceVersionPolicyCode", []);
    }
    create(value?: PartialMessage<ResourceVersionPolicyCode>): ResourceVersionPolicyCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResourceVersionPolicyCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResourceVersionPolicyCode): ResourceVersionPolicyCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResourceVersionPolicyCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ResourceVersionPolicyCode
 */
export const ResourceVersionPolicyCode = new ResourceVersionPolicyCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResponseTypeCode$Type extends MessageType<ResponseTypeCode> {
    constructor() {
        super("google.fhir.r4.core.ResponseTypeCode", []);
    }
    create(value?: PartialMessage<ResponseTypeCode>): ResponseTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResponseTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResponseTypeCode): ResponseTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResponseTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ResponseTypeCode
 */
export const ResponseTypeCode = new ResponseTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestfulCapabilityModeCode$Type extends MessageType<RestfulCapabilityModeCode> {
    constructor() {
        super("google.fhir.r4.core.RestfulCapabilityModeCode", []);
    }
    create(value?: PartialMessage<RestfulCapabilityModeCode>): RestfulCapabilityModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RestfulCapabilityModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestfulCapabilityModeCode): RestfulCapabilityModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RestfulCapabilityModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.RestfulCapabilityModeCode
 */
export const RestfulCapabilityModeCode = new RestfulCapabilityModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SPDXLicenseCode$Type extends MessageType<SPDXLicenseCode> {
    constructor() {
        super("google.fhir.r4.core.SPDXLicenseCode", []);
    }
    create(value?: PartialMessage<SPDXLicenseCode>): SPDXLicenseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SPDXLicenseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SPDXLicenseCode): SPDXLicenseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SPDXLicenseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SPDXLicenseCode
 */
export const SPDXLicenseCode = new SPDXLicenseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchComparatorCode$Type extends MessageType<SearchComparatorCode> {
    constructor() {
        super("google.fhir.r4.core.SearchComparatorCode", []);
    }
    create(value?: PartialMessage<SearchComparatorCode>): SearchComparatorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchComparatorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchComparatorCode): SearchComparatorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SearchComparatorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SearchComparatorCode
 */
export const SearchComparatorCode = new SearchComparatorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchEntryModeCode$Type extends MessageType<SearchEntryModeCode> {
    constructor() {
        super("google.fhir.r4.core.SearchEntryModeCode", []);
    }
    create(value?: PartialMessage<SearchEntryModeCode>): SearchEntryModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchEntryModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchEntryModeCode): SearchEntryModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SearchEntryModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SearchEntryModeCode
 */
export const SearchEntryModeCode = new SearchEntryModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchModifierCode$Type extends MessageType<SearchModifierCode> {
    constructor() {
        super("google.fhir.r4.core.SearchModifierCode", []);
    }
    create(value?: PartialMessage<SearchModifierCode>): SearchModifierCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchModifierCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchModifierCode): SearchModifierCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SearchModifierCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SearchModifierCode
 */
export const SearchModifierCode = new SearchModifierCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchParamTypeCode$Type extends MessageType<SearchParamTypeCode> {
    constructor() {
        super("google.fhir.r4.core.SearchParamTypeCode", []);
    }
    create(value?: PartialMessage<SearchParamTypeCode>): SearchParamTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchParamTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchParamTypeCode): SearchParamTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SearchParamTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SearchParamTypeCode
 */
export const SearchParamTypeCode = new SearchParamTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SequenceTypeCode$Type extends MessageType<SequenceTypeCode> {
    constructor() {
        super("google.fhir.r4.core.SequenceTypeCode", []);
    }
    create(value?: PartialMessage<SequenceTypeCode>): SequenceTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SequenceTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SequenceTypeCode): SequenceTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SequenceTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SequenceTypeCode
 */
export const SequenceTypeCode = new SequenceTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SlicingRulesCode$Type extends MessageType<SlicingRulesCode> {
    constructor() {
        super("google.fhir.r4.core.SlicingRulesCode", []);
    }
    create(value?: PartialMessage<SlicingRulesCode>): SlicingRulesCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SlicingRulesCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SlicingRulesCode): SlicingRulesCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SlicingRulesCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SlicingRulesCode
 */
export const SlicingRulesCode = new SlicingRulesCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SlotStatusCode$Type extends MessageType<SlotStatusCode> {
    constructor() {
        super("google.fhir.r4.core.SlotStatusCode", []);
    }
    create(value?: PartialMessage<SlotStatusCode>): SlotStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SlotStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SlotStatusCode): SlotStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SlotStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SlotStatusCode
 */
export const SlotStatusCode = new SlotStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SmartCapabilitiesCode$Type extends MessageType<SmartCapabilitiesCode> {
    constructor() {
        super("google.fhir.r4.core.SmartCapabilitiesCode", []);
    }
    create(value?: PartialMessage<SmartCapabilitiesCode>): SmartCapabilitiesCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SmartCapabilitiesCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SmartCapabilitiesCode): SmartCapabilitiesCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SmartCapabilitiesCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SmartCapabilitiesCode
 */
export const SmartCapabilitiesCode = new SmartCapabilitiesCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SortDirectionCode$Type extends MessageType<SortDirectionCode> {
    constructor() {
        super("google.fhir.r4.core.SortDirectionCode", []);
    }
    create(value?: PartialMessage<SortDirectionCode>): SortDirectionCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SortDirectionCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SortDirectionCode): SortDirectionCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SortDirectionCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SortDirectionCode
 */
export const SortDirectionCode = new SortDirectionCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpecimenContainedPreferenceCode$Type extends MessageType<SpecimenContainedPreferenceCode> {
    constructor() {
        super("google.fhir.r4.core.SpecimenContainedPreferenceCode", []);
    }
    create(value?: PartialMessage<SpecimenContainedPreferenceCode>): SpecimenContainedPreferenceCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SpecimenContainedPreferenceCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpecimenContainedPreferenceCode): SpecimenContainedPreferenceCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SpecimenContainedPreferenceCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SpecimenContainedPreferenceCode
 */
export const SpecimenContainedPreferenceCode = new SpecimenContainedPreferenceCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpecimenStatusCode$Type extends MessageType<SpecimenStatusCode> {
    constructor() {
        super("google.fhir.r4.core.SpecimenStatusCode", []);
    }
    create(value?: PartialMessage<SpecimenStatusCode>): SpecimenStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SpecimenStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpecimenStatusCode): SpecimenStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SpecimenStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SpecimenStatusCode
 */
export const SpecimenStatusCode = new SpecimenStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StandardsStatusCode$Type extends MessageType<StandardsStatusCode> {
    constructor() {
        super("google.fhir.r4.core.StandardsStatusCode", []);
    }
    create(value?: PartialMessage<StandardsStatusCode>): StandardsStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StandardsStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StandardsStatusCode): StandardsStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StandardsStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StandardsStatusCode
 */
export const StandardsStatusCode = new StandardsStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusCode$Type extends MessageType<StatusCode> {
    constructor() {
        super("google.fhir.r4.core.StatusCode", []);
    }
    create(value?: PartialMessage<StatusCode>): StatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StatusCode): StatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StatusCode
 */
export const StatusCode = new StatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StrandTypeCode$Type extends MessageType<StrandTypeCode> {
    constructor() {
        super("google.fhir.r4.core.StrandTypeCode", []);
    }
    create(value?: PartialMessage<StrandTypeCode>): StrandTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StrandTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StrandTypeCode): StrandTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StrandTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StrandTypeCode
 */
export const StrandTypeCode = new StrandTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StructureDefinitionKindCode$Type extends MessageType<StructureDefinitionKindCode> {
    constructor() {
        super("google.fhir.r4.core.StructureDefinitionKindCode", []);
    }
    create(value?: PartialMessage<StructureDefinitionKindCode>): StructureDefinitionKindCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StructureDefinitionKindCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StructureDefinitionKindCode): StructureDefinitionKindCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StructureDefinitionKindCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StructureDefinitionKindCode
 */
export const StructureDefinitionKindCode = new StructureDefinitionKindCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StructureMapContextTypeCode$Type extends MessageType<StructureMapContextTypeCode> {
    constructor() {
        super("google.fhir.r4.core.StructureMapContextTypeCode", []);
    }
    create(value?: PartialMessage<StructureMapContextTypeCode>): StructureMapContextTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StructureMapContextTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StructureMapContextTypeCode): StructureMapContextTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StructureMapContextTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StructureMapContextTypeCode
 */
export const StructureMapContextTypeCode = new StructureMapContextTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StructureMapGroupTypeModeCode$Type extends MessageType<StructureMapGroupTypeModeCode> {
    constructor() {
        super("google.fhir.r4.core.StructureMapGroupTypeModeCode", []);
    }
    create(value?: PartialMessage<StructureMapGroupTypeModeCode>): StructureMapGroupTypeModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StructureMapGroupTypeModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StructureMapGroupTypeModeCode): StructureMapGroupTypeModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StructureMapGroupTypeModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StructureMapGroupTypeModeCode
 */
export const StructureMapGroupTypeModeCode = new StructureMapGroupTypeModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StructureMapInputModeCode$Type extends MessageType<StructureMapInputModeCode> {
    constructor() {
        super("google.fhir.r4.core.StructureMapInputModeCode", []);
    }
    create(value?: PartialMessage<StructureMapInputModeCode>): StructureMapInputModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StructureMapInputModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StructureMapInputModeCode): StructureMapInputModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StructureMapInputModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StructureMapInputModeCode
 */
export const StructureMapInputModeCode = new StructureMapInputModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StructureMapModelModeCode$Type extends MessageType<StructureMapModelModeCode> {
    constructor() {
        super("google.fhir.r4.core.StructureMapModelModeCode", []);
    }
    create(value?: PartialMessage<StructureMapModelModeCode>): StructureMapModelModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StructureMapModelModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StructureMapModelModeCode): StructureMapModelModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StructureMapModelModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StructureMapModelModeCode
 */
export const StructureMapModelModeCode = new StructureMapModelModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StructureMapSourceListModeCode$Type extends MessageType<StructureMapSourceListModeCode> {
    constructor() {
        super("google.fhir.r4.core.StructureMapSourceListModeCode", []);
    }
    create(value?: PartialMessage<StructureMapSourceListModeCode>): StructureMapSourceListModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StructureMapSourceListModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StructureMapSourceListModeCode): StructureMapSourceListModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StructureMapSourceListModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StructureMapSourceListModeCode
 */
export const StructureMapSourceListModeCode = new StructureMapSourceListModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StructureMapTargetListModeCode$Type extends MessageType<StructureMapTargetListModeCode> {
    constructor() {
        super("google.fhir.r4.core.StructureMapTargetListModeCode", []);
    }
    create(value?: PartialMessage<StructureMapTargetListModeCode>): StructureMapTargetListModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StructureMapTargetListModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StructureMapTargetListModeCode): StructureMapTargetListModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StructureMapTargetListModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StructureMapTargetListModeCode
 */
export const StructureMapTargetListModeCode = new StructureMapTargetListModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StructureMapTransformCode$Type extends MessageType<StructureMapTransformCode> {
    constructor() {
        super("google.fhir.r4.core.StructureMapTransformCode", []);
    }
    create(value?: PartialMessage<StructureMapTransformCode>): StructureMapTransformCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StructureMapTransformCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StructureMapTransformCode): StructureMapTransformCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StructureMapTransformCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.StructureMapTransformCode
 */
export const StructureMapTransformCode = new StructureMapTransformCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionChannelTypeCode$Type extends MessageType<SubscriptionChannelTypeCode> {
    constructor() {
        super("google.fhir.r4.core.SubscriptionChannelTypeCode", []);
    }
    create(value?: PartialMessage<SubscriptionChannelTypeCode>): SubscriptionChannelTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubscriptionChannelTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscriptionChannelTypeCode): SubscriptionChannelTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SubscriptionChannelTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SubscriptionChannelTypeCode
 */
export const SubscriptionChannelTypeCode = new SubscriptionChannelTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionStatusCode$Type extends MessageType<SubscriptionStatusCode> {
    constructor() {
        super("google.fhir.r4.core.SubscriptionStatusCode", []);
    }
    create(value?: PartialMessage<SubscriptionStatusCode>): SubscriptionStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubscriptionStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscriptionStatusCode): SubscriptionStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SubscriptionStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SubscriptionStatusCode
 */
export const SubscriptionStatusCode = new SubscriptionStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SupplyDeliveryStatusCode$Type extends MessageType<SupplyDeliveryStatusCode> {
    constructor() {
        super("google.fhir.r4.core.SupplyDeliveryStatusCode", []);
    }
    create(value?: PartialMessage<SupplyDeliveryStatusCode>): SupplyDeliveryStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SupplyDeliveryStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SupplyDeliveryStatusCode): SupplyDeliveryStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SupplyDeliveryStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SupplyDeliveryStatusCode
 */
export const SupplyDeliveryStatusCode = new SupplyDeliveryStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SupplyItemTypeCode$Type extends MessageType<SupplyItemTypeCode> {
    constructor() {
        super("google.fhir.r4.core.SupplyItemTypeCode", []);
    }
    create(value?: PartialMessage<SupplyItemTypeCode>): SupplyItemTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SupplyItemTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SupplyItemTypeCode): SupplyItemTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SupplyItemTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SupplyItemTypeCode
 */
export const SupplyItemTypeCode = new SupplyItemTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SupplyRequestStatusCode$Type extends MessageType<SupplyRequestStatusCode> {
    constructor() {
        super("google.fhir.r4.core.SupplyRequestStatusCode", []);
    }
    create(value?: PartialMessage<SupplyRequestStatusCode>): SupplyRequestStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SupplyRequestStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SupplyRequestStatusCode): SupplyRequestStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SupplyRequestStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SupplyRequestStatusCode
 */
export const SupplyRequestStatusCode = new SupplyRequestStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskIntentCode$Type extends MessageType<TaskIntentCode> {
    constructor() {
        super("google.fhir.r4.core.TaskIntentCode", []);
    }
    create(value?: PartialMessage<TaskIntentCode>): TaskIntentCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaskIntentCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskIntentCode): TaskIntentCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TaskIntentCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TaskIntentCode
 */
export const TaskIntentCode = new TaskIntentCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskStatusCode$Type extends MessageType<TaskStatusCode> {
    constructor() {
        super("google.fhir.r4.core.TaskStatusCode", []);
    }
    create(value?: PartialMessage<TaskStatusCode>): TaskStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaskStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskStatusCode): TaskStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TaskStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TaskStatusCode
 */
export const TaskStatusCode = new TaskStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TemplateStatusCodeLifeCycleCode$Type extends MessageType<TemplateStatusCodeLifeCycleCode> {
    constructor() {
        super("google.fhir.r4.core.TemplateStatusCodeLifeCycleCode", []);
    }
    create(value?: PartialMessage<TemplateStatusCodeLifeCycleCode>): TemplateStatusCodeLifeCycleCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TemplateStatusCodeLifeCycleCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TemplateStatusCodeLifeCycleCode): TemplateStatusCodeLifeCycleCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TemplateStatusCodeLifeCycleCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TemplateStatusCodeLifeCycleCode
 */
export const TemplateStatusCodeLifeCycleCode = new TemplateStatusCodeLifeCycleCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestReportActionResultCode$Type extends MessageType<TestReportActionResultCode> {
    constructor() {
        super("google.fhir.r4.core.TestReportActionResultCode", []);
    }
    create(value?: PartialMessage<TestReportActionResultCode>): TestReportActionResultCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TestReportActionResultCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestReportActionResultCode): TestReportActionResultCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TestReportActionResultCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TestReportActionResultCode
 */
export const TestReportActionResultCode = new TestReportActionResultCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestReportParticipantTypeCode$Type extends MessageType<TestReportParticipantTypeCode> {
    constructor() {
        super("google.fhir.r4.core.TestReportParticipantTypeCode", []);
    }
    create(value?: PartialMessage<TestReportParticipantTypeCode>): TestReportParticipantTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TestReportParticipantTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestReportParticipantTypeCode): TestReportParticipantTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TestReportParticipantTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TestReportParticipantTypeCode
 */
export const TestReportParticipantTypeCode = new TestReportParticipantTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestReportResultCode$Type extends MessageType<TestReportResultCode> {
    constructor() {
        super("google.fhir.r4.core.TestReportResultCode", []);
    }
    create(value?: PartialMessage<TestReportResultCode>): TestReportResultCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TestReportResultCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestReportResultCode): TestReportResultCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TestReportResultCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TestReportResultCode
 */
export const TestReportResultCode = new TestReportResultCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestReportStatusCode$Type extends MessageType<TestReportStatusCode> {
    constructor() {
        super("google.fhir.r4.core.TestReportStatusCode", []);
    }
    create(value?: PartialMessage<TestReportStatusCode>): TestReportStatusCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TestReportStatusCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestReportStatusCode): TestReportStatusCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TestReportStatusCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TestReportStatusCode
 */
export const TestReportStatusCode = new TestReportStatusCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestScriptRequestMethodCode$Type extends MessageType<TestScriptRequestMethodCode> {
    constructor() {
        super("google.fhir.r4.core.TestScriptRequestMethodCode", []);
    }
    create(value?: PartialMessage<TestScriptRequestMethodCode>): TestScriptRequestMethodCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TestScriptRequestMethodCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestScriptRequestMethodCode): TestScriptRequestMethodCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TestScriptRequestMethodCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TestScriptRequestMethodCode
 */
export const TestScriptRequestMethodCode = new TestScriptRequestMethodCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TriggerTypeCode$Type extends MessageType<TriggerTypeCode> {
    constructor() {
        super("google.fhir.r4.core.TriggerTypeCode", []);
    }
    create(value?: PartialMessage<TriggerTypeCode>): TriggerTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TriggerTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TriggerTypeCode): TriggerTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TriggerTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TriggerTypeCode
 */
export const TriggerTypeCode = new TriggerTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TypeDerivationRuleCode$Type extends MessageType<TypeDerivationRuleCode> {
    constructor() {
        super("google.fhir.r4.core.TypeDerivationRuleCode", []);
    }
    create(value?: PartialMessage<TypeDerivationRuleCode>): TypeDerivationRuleCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TypeDerivationRuleCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TypeDerivationRuleCode): TypeDerivationRuleCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TypeDerivationRuleCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TypeDerivationRuleCode
 */
export const TypeDerivationRuleCode = new TypeDerivationRuleCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UDIEntryTypeCode$Type extends MessageType<UDIEntryTypeCode> {
    constructor() {
        super("google.fhir.r4.core.UDIEntryTypeCode", []);
    }
    create(value?: PartialMessage<UDIEntryTypeCode>): UDIEntryTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UDIEntryTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UDIEntryTypeCode): UDIEntryTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UDIEntryTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.UDIEntryTypeCode
 */
export const UDIEntryTypeCode = new UDIEntryTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UseCode$Type extends MessageType<UseCode> {
    constructor() {
        super("google.fhir.r4.core.UseCode", []);
    }
    create(value?: PartialMessage<UseCode>): UseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UseCode): UseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.UseCode
 */
export const UseCode = new UseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V20444Code$Type extends MessageType<V20444Code> {
    constructor() {
        super("google.fhir.r4.core.V20444Code", []);
    }
    create(value?: PartialMessage<V20444Code>): V20444Code {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V20444Code>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V20444Code): V20444Code {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V20444Code, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V20444Code
 */
export const V20444Code = new V20444Code$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3AddressUseCode$Type extends MessageType<V3AddressUseCode> {
    constructor() {
        super("google.fhir.r4.core.V3AddressUseCode", []);
    }
    create(value?: PartialMessage<V3AddressUseCode>): V3AddressUseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3AddressUseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3AddressUseCode): V3AddressUseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3AddressUseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3AddressUseCode
 */
export const V3AddressUseCode = new V3AddressUseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3ConfidentialityCode$Type extends MessageType<V3ConfidentialityCode> {
    constructor() {
        super("google.fhir.r4.core.V3ConfidentialityCode", []);
    }
    create(value?: PartialMessage<V3ConfidentialityCode>): V3ConfidentialityCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3ConfidentialityCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3ConfidentialityCode): V3ConfidentialityCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3ConfidentialityCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3ConfidentialityCode
 */
export const V3ConfidentialityCode = new V3ConfidentialityCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3EntityNamePartQualifierCode$Type extends MessageType<V3EntityNamePartQualifierCode> {
    constructor() {
        super("google.fhir.r4.core.V3EntityNamePartQualifierCode", []);
    }
    create(value?: PartialMessage<V3EntityNamePartQualifierCode>): V3EntityNamePartQualifierCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3EntityNamePartQualifierCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3EntityNamePartQualifierCode): V3EntityNamePartQualifierCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3EntityNamePartQualifierCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3EntityNamePartQualifierCode
 */
export const V3EntityNamePartQualifierCode = new V3EntityNamePartQualifierCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3EntityNamePartQualifierR2Code$Type extends MessageType<V3EntityNamePartQualifierR2Code> {
    constructor() {
        super("google.fhir.r4.core.V3EntityNamePartQualifierR2Code", []);
    }
    create(value?: PartialMessage<V3EntityNamePartQualifierR2Code>): V3EntityNamePartQualifierR2Code {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3EntityNamePartQualifierR2Code>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3EntityNamePartQualifierR2Code): V3EntityNamePartQualifierR2Code {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3EntityNamePartQualifierR2Code, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3EntityNamePartQualifierR2Code
 */
export const V3EntityNamePartQualifierR2Code = new V3EntityNamePartQualifierR2Code$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3EntityNameUseCode$Type extends MessageType<V3EntityNameUseCode> {
    constructor() {
        super("google.fhir.r4.core.V3EntityNameUseCode", []);
    }
    create(value?: PartialMessage<V3EntityNameUseCode>): V3EntityNameUseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3EntityNameUseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3EntityNameUseCode): V3EntityNameUseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3EntityNameUseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3EntityNameUseCode
 */
export const V3EntityNameUseCode = new V3EntityNameUseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3EntityNameUseR2Code$Type extends MessageType<V3EntityNameUseR2Code> {
    constructor() {
        super("google.fhir.r4.core.V3EntityNameUseR2Code", []);
    }
    create(value?: PartialMessage<V3EntityNameUseR2Code>): V3EntityNameUseR2Code {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3EntityNameUseR2Code>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3EntityNameUseR2Code): V3EntityNameUseR2Code {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3EntityNameUseR2Code, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3EntityNameUseR2Code
 */
export const V3EntityNameUseR2Code = new V3EntityNameUseR2Code$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3NullFlavorCode$Type extends MessageType<V3NullFlavorCode> {
    constructor() {
        super("google.fhir.r4.core.V3NullFlavorCode", []);
    }
    create(value?: PartialMessage<V3NullFlavorCode>): V3NullFlavorCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3NullFlavorCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3NullFlavorCode): V3NullFlavorCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3NullFlavorCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3NullFlavorCode
 */
export const V3NullFlavorCode = new V3NullFlavorCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3ParticipationModeCode$Type extends MessageType<V3ParticipationModeCode> {
    constructor() {
        super("google.fhir.r4.core.V3ParticipationModeCode", []);
    }
    create(value?: PartialMessage<V3ParticipationModeCode>): V3ParticipationModeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3ParticipationModeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3ParticipationModeCode): V3ParticipationModeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3ParticipationModeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3ParticipationModeCode
 */
export const V3ParticipationModeCode = new V3ParticipationModeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3ProbabilityDistributionTypeCode$Type extends MessageType<V3ProbabilityDistributionTypeCode> {
    constructor() {
        super("google.fhir.r4.core.V3ProbabilityDistributionTypeCode", []);
    }
    create(value?: PartialMessage<V3ProbabilityDistributionTypeCode>): V3ProbabilityDistributionTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3ProbabilityDistributionTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3ProbabilityDistributionTypeCode): V3ProbabilityDistributionTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3ProbabilityDistributionTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3ProbabilityDistributionTypeCode
 */
export const V3ProbabilityDistributionTypeCode = new V3ProbabilityDistributionTypeCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3RoleCode$Type extends MessageType<V3RoleCode> {
    constructor() {
        super("google.fhir.r4.core.V3RoleCode", []);
    }
    create(value?: PartialMessage<V3RoleCode>): V3RoleCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3RoleCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3RoleCode): V3RoleCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3RoleCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3RoleCode
 */
export const V3RoleCode = new V3RoleCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3TimingEventCode$Type extends MessageType<V3TimingEventCode> {
    constructor() {
        super("google.fhir.r4.core.V3TimingEventCode", []);
    }
    create(value?: PartialMessage<V3TimingEventCode>): V3TimingEventCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3TimingEventCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3TimingEventCode): V3TimingEventCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3TimingEventCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3TimingEventCode
 */
export const V3TimingEventCode = new V3TimingEventCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VisionBaseCode$Type extends MessageType<VisionBaseCode> {
    constructor() {
        super("google.fhir.r4.core.VisionBaseCode", []);
    }
    create(value?: PartialMessage<VisionBaseCode>): VisionBaseCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VisionBaseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VisionBaseCode): VisionBaseCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: VisionBaseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.VisionBaseCode
 */
export const VisionBaseCode = new VisionBaseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VisionEyesCode$Type extends MessageType<VisionEyesCode> {
    constructor() {
        super("google.fhir.r4.core.VisionEyesCode", []);
    }
    create(value?: PartialMessage<VisionEyesCode>): VisionEyesCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VisionEyesCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VisionEyesCode): VisionEyesCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: VisionEyesCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.VisionEyesCode
 */
export const VisionEyesCode = new VisionEyesCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class XPathUsageTypeCode$Type extends MessageType<XPathUsageTypeCode> {
    constructor() {
        super("google.fhir.r4.core.XPathUsageTypeCode", []);
    }
    create(value?: PartialMessage<XPathUsageTypeCode>): XPathUsageTypeCode {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<XPathUsageTypeCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: XPathUsageTypeCode): XPathUsageTypeCode {
        return target ?? this.create();
    }
    internalBinaryWrite(message: XPathUsageTypeCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.XPathUsageTypeCode
 */
export const XPathUsageTypeCode = new XPathUsageTypeCode$Type();
