import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useProfile} from '../../hooks/useProfile';
import {enrollFlowPath, profilePath} from '../../types/route';
import Loading from '../loading';

/**
 * This component simply redirects to the enroll path.
 *
 * It exists to pass any query params that exist when the user arrives
 * at the profile base path /profile/:profileId. This is required
 * because Sharecare profiles use URL params to determine the enrollment
 * profile.
 */
export default function ProfileRoot() {
  const navigate = useNavigate();
  const {profileName} = useProfile();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // We can't use relative paths to get to ./enroll because in strict dev
    // mode this will result in a duplicate path like ./enroll/enroll (see PHP-9808)
    navigate({
      pathname: enrollFlowPath(profilePath(profileName)),
      search: `?${searchParams.toString()}`,
    });
  });

  return <Loading />;
}
