import i18next from 'i18next';
import type {AppConfig} from '../lib/types/appConfig';

// Tells webpack that all asset files are build dependencies so
// they're included in build and publicPaths are updated via webpack
// TODO(PHP-10760): Figure out a better solution for mocking require.context in testing.
// This is a hack to allow jest tests to pass since they don't use webpack
// and we couldn't get any of the suggested 3rd party macros/libraries to work.
export const BUILD_ASSETS =
  process.env.NODE_ENV === 'test'
    ? (s: string) => {
        return s;
      }
    : require.context('../assets', true, /^\.\/.*$/);

export enum OperatingSystem {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  OTHER = 'OTHER',
}

export function getOperatingSystem(): OperatingSystem {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return OperatingSystem.ANDROID;
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return OperatingSystem.IOS;
  }
  return OperatingSystem.OTHER;
}

export function setFavicon(uri: string) {
  const favicon = document.getElementById('favicon');
  favicon?.setAttribute('href', toProfileAssetsPath(uri));
}

export function getPageTitle(config: AppConfig) {
  if (config.pageTitle) {
    return config.pageTitle;
  }
  if (config.productName) {
    return i18next.t('page-title.product-specific', {
      productName: config.productName,
    });
  }
  return i18next.t('page-title.default');
}

// TODO(PHP-10719): remove this once we've migrated off Angular build and can update all config
// paths to consistently reference the filename if in build assets.
/**
 * Shims asset paths in profile configs to webpack asset key if required.
 *
 * Profile assets can be part of the build or loaded through an external URI.
 *
 * Prior to building as an MFE, our React vite and Angular deployments could reference
 * paths relative to URL root with or without a leading backslash.
 * Additionally, the Angular deployed configs always references profile assets
 * relative to the assets subpath, whereas in the mfe webpack build
 * this relative path is not needed and it's based on build asset keys.
 *
 * Rather than updating all configs, we simply transform the config-specified paths through
 * this helper.
 */
export function toProfileAssetsPath(configValue: string): string {
  try {
    return new URL(configValue).toString();
  } catch (_) {
    let assetPath = configValue.startsWith('/')
      ? configValue
      : '/' + configValue;
    assetPath = assetPath.startsWith('/assets')
      ? assetPath.substring(7)
      : assetPath;
    return BUILD_ASSETS('.' + assetPath);
  }
}

//TODO(https://verily.atlassian.net/browse/PHP-26265): we need have a way to map the localized assets
// with same filename
// Converts asset filename to appropriate webpack asset key
export function toCommonAssetsPath(
  fileName: string,
  localizedAsset = false
): string {
  if (localizedAsset) {
    return BUILD_ASSETS(
      './common/' + i18next.resolvedLanguage + '_' + fileName
    );
  }
  return BUILD_ASSETS('./common/' + fileName);
}
