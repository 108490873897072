import {sanitizeUrl} from '@braintree/sanitize-url';
import {useParams, useSearchParams} from 'react-router-dom';
import {profileIdFromSponsorParam} from './utils/sharecare_utils';

// TODO(PHP-3785): error states should lead to a relevant "profile not found"
// error page rather than generic error page
export const useProfileException =
  'useProfile Exception: No Profile name provided';
export const urlInjectionException =
  'useProfile Exception: profile name contained HTML and/or control characters';
export const sharecareSponsorParamProfileException =
  'useProfile Exception: Unable to parse sharecare sponsor from URL params';

// Mappings of sharecare profile URLs to profile prefixes we prepend to the
// sponsor code to route to actual profile (PHP-729).
// Prefixes here should omit the trailing underscore.
const SHARECARE_DYNAMIC_PROFILE_URL_TO_PREFIX: Record<string, string> = {
  sharecare: 'sharecare',
  'sharecare-staging': 'sharecare-staging',
};

export function useProfile() {
  const {profileName} = useParams();
  const [searchParams] = useSearchParams();

  if (!profileName) {
    throw new Error(useProfileException);
  }

  if (sanitizeUrl(profileName) !== profileName) {
    throw new Error(urlInjectionException);
  }

  // We lowercase this to perform a case insensitive match against the possible
  // Sharecare landing profiles.
  let finalProfile = profileName?.toLowerCase();
  // User entering any of the sharecare "profile" subpath URLs require presence of a sponsor
  // query param that is used to determine the actual enrollment profile.
  if (SHARECARE_DYNAMIC_PROFILE_URL_TO_PREFIX[finalProfile]) {
    try {
      const profilePrefix: string =
        SHARECARE_DYNAMIC_PROFILE_URL_TO_PREFIX[finalProfile] + '_';
      finalProfile = profileIdFromSponsorParam(profilePrefix, searchParams);
    } catch (e) {
      throw new Error(sharecareSponsorParamProfileException);
    }
  }
  return {profileName: finalProfile};
}
