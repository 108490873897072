import {EnrolleeEvent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {useEffect} from 'react';
import {useConfig} from '../../../hooks/useConfig';
import {useRecordEvent} from '../../../hooks/useRecordEvent';
import HandoffLayout from './layout';

export default function HandoffPage() {
  const {config} = useConfig();
  const {recordEvent} = useRecordEvent();

  useEffect(() => {
    recordEvent(EnrolleeEvent.VISIT_PRODUCT_HANDOFF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HandoffLayout
      logo1={config.logo1}
      logo2={config.logo2}
      disclaimerFooter={config.disclaimerFooter}
      productName={config.productName}
      webHandoffLink={config.webHandoffLink}
      appHandoffLink={config.appHandoffLink}
      androidHandoffLink={config.androidHandoffLink}
      iosHandoffLink={config.iosHandoffLink}
    />
  );
}
