import {
  GetUserStateRequest,
  GetUserStateResponse,
  Step,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callGetUserState} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {protoToState} from '../proto/userState';
import {getRpcOptions} from './getRpcOptions';

export const getUserStateServiceExceptionMsg =
  'Service Exception: User State could not be fetched';

type GetUserStateProps = {
  profileName: string;
  currentStep: Step;
};

export const getUserState = async ({
  profileName,
  currentStep,
}: GetUserStateProps) => {
  let res: GetUserStateResponse;
  try {
    const options = await getRpcOptions();
    res = await callGetUserState(
      {
        profile: toProfileProto(profileName, window.location.hostname),
        currentStep: currentStep,
      } as GetUserStateRequest,
      options
    );
  } catch (error) {
    throw new Error(getUserStateServiceExceptionMsg);
  }

  return protoToState(res);
};
