import {useSnackbar} from '@verily-src/react-design-system';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BlockerFunction, useBlocker} from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import {useConfig} from '../../hooks/useConfig';
import {useProfile} from '../../hooks/useProfile';
import {useUserState} from '../../hooks/useUserState';
import {api} from '../../lib/api';
import {EnrollmentError, EnrollmentErrorType} from '../../lib/api/error';
import {EnrollmentStep} from '../../types/flow';
import {useProgress} from '../enrollment-flow/progress';
import ErrorElement from '../global-error/global-error';
import Loading from '../loading';
import {TerminationReason} from '../termination-page/states';
import HandoffPage from './handoff';
import RedirectUser from './redirect-user';

export enum CompletionDestination {
  PRODUCT_HANDOFF_PAGE,
  REDIRECT_USER,
}

export type CompleteEnrollmentProps = {
  destination: CompletionDestination;
};

/**
 * Component that handles completion of enrollment.
 *
 * This component is responsible for marking the user as complete in the backend
 * and routing the user to the appropriate terminal location (handoff page or external redirect).
 *
 * @param destination - The destination to route the user to after enrollment is complete
 */
export default function CompleteEnrollment(props: CompleteEnrollmentProps) {
  const {config} = useConfig();
  const currentEnrollmentStep =
    props.destination === CompletionDestination.PRODUCT_HANDOFF_PAGE
      ? EnrollmentStep.HANDOFF
      : EnrollmentStep.REDIRECT_USER;
  const currentStep = config.flow.enrollmentStepToConfigStep.get(
    currentEnrollmentStep
  );
  const {
    userState,
    isFetchingUpdatedState,
    updateUserStateComplete,
    error: updateUserStateError,
  } = useUserState(currentStep);
  const [allowNav, setAllowNav] = useState(true);
  const {t} = useTranslation();
  const snackbar = useSnackbar();
  // The specific step set here isn't important since this is only used
  // for routing the "eligibility user already enrolled error", which has its own
  // failure handling logic
  const {failStep} = useProgress(currentEnrollmentStep);

  const {profileName} = useProfile();
  const [runSetEnrollmentComplete, setRunSetEnrollmentComplete] =
    useState(false);

  // we use SWRImmutable here to prevent the RPC from being called multiple times
  // otherwise, the RPC is called again in the background to revalidate the cache
  // see https://swr.vercel.app/docs/revalidation
  const {
    error: completeEnrollError,
    isValidating: isRunningCompleteEnrollRequest,
    isLoading: isLoadingCompleteEnrollRequest,
  } = useSWRImmutable(
    runSetEnrollmentComplete
      ? {
          userState: {enrollmentComplete: true},
          profileName,
          currentStep,
        }
      : null,
    api.enroll
  );

  // TODO: add cypress e2e test that verifies user can't click back
  const blockerFn: BlockerFunction = ({historyAction}) => {
    // We need to allow navigation to a terminal page for some error states
    // so only block navigation if user has completed enrollment
    if (allowNav) {
      return false;
    }
    if (historyAction === 'POP') {
      snackbar.show(t('navigation.no-back-after-completion-error'), 'error');
      return true;
    }
    return false;
  };
  // Note that you'll get a console warning about running 2 blockers in
  // react dev strict mode. This is expected.
  // See: https://github.com/remix-run/react-router/issues/10073
  useBlocker(blockerFn);

  // TODO(PHP-25872): set completion at final user action step, which then avoids
  // having the request state tracking logic below
  useEffect(() => {
    if (userState?.enrollmentComplete) {
      // Prevent user from navigating back if enrollment is complete
      setAllowNav(false);
      return;
    }
    if (completeEnrollError) {
      if (
        completeEnrollError instanceof EnrollmentError &&
        completeEnrollError.errorType ===
          EnrollmentErrorType.ELIGIBILITY_USER_ALREADY_ENROLLED
      ) {
        // This navigates the user to the appropriate terminal page
        failStep(TerminationReason.ELIGIBILITY_USER_ALREADY_ENROLLED);
        return;
      }
      return;
    }
    // Don't attempt to run grpc request to set user's completion status
    // if there's an ongoing get user state or enroll request. (I believe there could
    // be an ongoing user state update after this component sets completion status to complete and
    // we're waiting for the user state update.) This is required to prevent race conditions
    // in prod as well as duplicate enrollment completion requests
    // in react dev strict mode when it mounts the component twice
    if (isFetchingUpdatedState || isLoadingCompleteEnrollRequest) {
      return;
    }
    if (!userState?.enrollmentComplete) {
      if (!runSetEnrollmentComplete) {
        setRunSetEnrollmentComplete(true);
      } else {
        // At this point, we've attempted to set completion status through
        // an enroll request and it succeeded, so update cached + rehydrate user state.
        updateUserStateComplete!();
      }
    }
  }, [
    completeEnrollError,
    isRunningCompleteEnrollRequest,
    isFetchingUpdatedState,
    userState?.enrollmentComplete,
    runSetEnrollmentComplete,
    failStep,
    updateUserStateComplete,
    allowNav,
    isLoadingCompleteEnrollRequest,
  ]);

  if (userState?.enrollmentComplete) {
    // TODO(PHP-18585): route to URL redirect component
    return props.destination === CompletionDestination.PRODUCT_HANDOFF_PAGE ? (
      <HandoffPage />
    ) : (
      <RedirectUser />
    );
  }
  if (updateUserStateError || completeEnrollError) {
    // Since enroll request failed to mark user as complete, we hard error
    // here so user knows they need to retry enrollment.
    return <ErrorElement />;
  }
  return <Loading />;
}
