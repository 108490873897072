import {GetDomainConfigResponse} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {DomainConfig} from '../../hooks/useDomainConfig';
import {callGetDomainConfig} from '../../utils/clientCaller';

const domainServiceExceptionMsg =
  'Service Exception: Auth configuration could not be fetched';

export const getDomainConfig = async (
  domain: string,
  metadata?: {[s: string]: string}
): Promise<DomainConfig> => {
  let res: GetDomainConfigResponse;
  try {
    res = await callGetDomainConfig({domain}, metadata);
  } catch (error) {
    throw new Error(domainServiceExceptionMsg);
  }
  return {
    eConsentBaseURL: res['econsentBaseUrl'],
  };
};
