import {useEffect, useState} from 'react';
import {useRouteError} from 'react-router-dom';
import {api} from '../../lib/api';
import {EnrollmentError, EnrollmentErrorType} from '../../lib/api/error';
import Loading from '../loading';
import {ErrorElementLayout} from './layout';

type ErrorElementProps = {
  error?: EnrollmentError;
};

function ErrorElement(props: ErrorElementProps) {
  // Because this component relies on useRouteError(), it will not work as
  // intended if rendered by something other than the router. See
  // https://verily.atlassian.net/browse/PHP-2358 for more information.
  const routeError = useRouteError();
  // if error element rendered from route, use route error -- otherwise attempt to use props error
  const error = routeError ?? props.error;
  const [enrollmentErrorType, setEnrollmentErrorType] =
    useState<EnrollmentErrorType>(EnrollmentErrorType.UNKNOWN);

  useEffect(() => {
    // we don't need to report unauthenticated errors
    if (
      error instanceof EnrollmentError &&
      error.errorType === EnrollmentErrorType.UNAUTHENTICATED
    ) {
      return;
    }
    if (error instanceof Error) {
      api.reportError(error);
      if (error instanceof EnrollmentError) {
        setEnrollmentErrorType(error.errorType);
      }
    }
  }, [error]);

  // simply return a loading page for unauthenticated errors since
  // the Auth MFE will handle the redirect
  if (
    error instanceof EnrollmentError &&
    error.errorType === EnrollmentErrorType.UNAUTHENTICATED
  ) {
    return <Loading />;
  }

  return <ErrorElementLayout errorType={enrollmentErrorType} />;
}

export default ErrorElement;
