import {Auth} from '@verily-src/auth';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {clearStoredSession} from '../../../session/sessionUtil';
import {
  passwordResetRedirectProfilePath,
  passwordResetRedirectProfileUrl,
} from '../../../types/route';
import Loading from '../../loading';

// TODO(PHP-16059) remove this component in favor of calling `getPasswordResetReturnProfile` with forceLogin=true
export default function PasswordResetRedirect() {
  const navigate = useNavigate();

  // clear any stored session metadata
  // so that we don't end up with a session mismatch
  // TODO(PHP-18562) link an existing pre-auth session to the new post-auth session
  clearStoredSession();

  useEffect(() => {
    // Because we aren't at this point sure that the user has reentered the same
    // profile from which they initiated the password reset flow, we push them back to the page which gets the truth of it from FHIR.
    Auth.logout(passwordResetRedirectProfileUrl()).then(() => {
      // Logout simply returns an empty promise if the user is not
      // authenticated, but we want to navigate to this URL unconditionally.
      navigate(passwordResetRedirectProfilePath());
    });
  }, [navigate]);

  return <Loading />;
}
