import {createContext} from 'react';
import {reloadOTBanner} from './oneTrustScripts';

class OneTrustCookieConsent {
  private isInitialized = false;

  // TODO(PHP-19893): support initialization with user ID via authenticated consent
  // https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046?language=en_US#UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046_section-idm4622881725907233939339745639
  public initialize() {
    if (this.isInitialized) {
      return;
    }
    // The below script is simply the one provided via the OneTrust console
    const script = document.createElement('script');
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.setAttribute(
      'data-domain-script',
      // TODO(PHP-19893): parameterize test vs prod script
      '018e85d1-127e-76c0-bfdb-704cc8b17fac-test'
    );
    document.head.appendChild(script);
    // end script from OneTrust console
    this.isInitialized = true;
  }

  // TODO(PHP-19893): SPAs may need to call reloadOtBanner per https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046?language=en_US#UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046_section-idm46212287146848
  public reloadOTBanner() {
    reloadOTBanner();
  }
}

export const OneTrustContext = createContext(new OneTrustCookieConsent());
