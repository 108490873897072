import {EnrollmentProfile} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import i18n from 'i18next';

export function toProfileProto(
  profile: string,
  domain: string
): EnrollmentProfile {
  const enrollmentProfile: EnrollmentProfile = {
    profile: profile,
    domain: domain,
    language: i18n.language,
    isPreview: false,
  };
  return enrollmentProfile;
}
