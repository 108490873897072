import {CardMedia, Grid, useTheme} from '@mui/material';
import * as React from 'react';
import {toProfileAssetsPath} from '../../utils';

export interface ResponsiveSplashProps {
  logo1?: AppImageConfig;
  logo2?: AppImageConfig;
  centerImage?: string;
}

// TODO(mestopinal): Move to config service and import once config service has been created
export interface AppImageConfig {
  uri: string;
  altText: string;
}

const ResponsiveSplash: React.FC<ResponsiveSplashProps> = ({
  logo1,
  logo2,
  centerImage,
}: ResponsiveSplashProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="flex-start"
      sx={{
        display: 'flex',
        height: {xs: 'auto', md: '100vh'},
        minHeight: {xs: theme.spacing(27.5)},
      }}
    >
      <Grid
        item
        xs={10}
        container
        justifyContent="space-evenly"
        alignItems="center"
        direction="row"
        sx={{
          mt: {xs: 4, md: 8.75},
        }}
      >
        {logo1 && (
          <Grid item>
            <CardMedia
              component="img"
              src={toProfileAssetsPath(logo1.uri)}
              className="logo-img"
              alt={logo1.altText}
              data-testid="logo1"
              sx={{
                maxWidth: {xs: theme.spacing(12), md: theme.spacing(18)},
              }}
            />
          </Grid>
        )}
        {logo2 && (
          <Grid item>
            <CardMedia
              component="img"
              src={toProfileAssetsPath(logo2.uri)}
              className="logo-img"
              alt={logo2.altText}
              data-testid="logo2"
              sx={{
                maxWidth: {xs: theme.spacing(12), md: theme.spacing(18)},
              }}
            />
          </Grid>
        )}
      </Grid>
      {centerImage && (
        <Grid item xs={9} container justifyContent="center">
          <CardMedia
            component="img"
            image={centerImage}
            alt=""
            data-testid="centerImage"
            sx={{
              height: 'auto',
              width: 'auto',
              maxHeight: {
                xs: theme.spacing(24),
                md: theme.spacing(42),
              },
              my: {xs: 2.5, md: 12.5},
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ResponsiveSplash;
