import {Stack, Typography} from '@mui/material';
import {Button} from '@verily-src/react-design-system';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';

type WebHandoffLayoutProps = {
  productName?: string;
  webHandoffLink?: string;
  appHandoffLink?: string;
};

const WebHandoffLayout = memo((props: WebHandoffLayoutProps) => {
  const webOnly = props.webHandoffLink && !props.appHandoffLink;
  const {t} = useTranslation();
  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        {webOnly
          ? t('handoff.web-only-handoff', {productName: props.productName})
          : t('handoff.app+web-web-handoff', {
              productName: props.productName,
            })}
      </Typography>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          data-cy="handoff-link"
          href={props.webHandoffLink}
          variant={webOnly ? 'filled' : 'outlined'}
          label={webOnly ? t('handoff.continue') : t('handoff.stay-on-web')}
          role="button"
        />
      </div>
    </Stack>
  );
});

export default WebHandoffLayout;
