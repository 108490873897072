/* eslint-disable node/no-unpublished-import */

import {ArrowForward} from '@mui/icons-material';
import {
  Card,
  CardContent,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {Button} from '@verily-src/react-design-system';

type ContentItemProp = {
  icon: JSX.Element;
  body: String;
};

type ContentCardLayoutProps = {
  title: String;
  listItems: ContentItemProp[];
};

export default function ContentCardLayout({
  title,
  listItems,
}: ContentCardLayoutProps) {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.canvas,
      }}
    >
      <CardContent>
        <Stack spacing={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{fontWeight: 500, fontSize: '1.25rem', fontFamily: 'Roboto'}}
            >
              {title}
            </Typography>

            <Button
              variant="outlined"
              sx={{height: '32px', width: '32px'}}
              aria-label="expand arrow button"
            >
              <ArrowForward />
            </Button>
          </Stack>
          {listItems.map((item, idx) => {
            return (
              <Stack key={idx} direction="row" spacing={1}>
                {item.icon}
                <Typography variant="body1">{item.body}</Typography>
              </Stack>
            );
          })}
          <Link
            sx={{
              color: theme.palette.highlight[1],
            }}
            href="#"
          >
            Commonly asked questions
          </Link>
        </Stack>
      </CardContent>
    </Card>
  );
}
