import {Box, Typography, useTheme} from '@mui/material';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import ResponsiveContainer from '../responsive/responsive-container/ResponsiveContainer';
import ResponsiveContent from '../responsive/responsive-content/ResponsiveContent';
import ResponsiveSplash from '../responsive/responsive-splash/ResponsiveSplash';

type LogoutLayoutProps = {
  centerImage?: string;
};

const LogoutLayout = memo(({centerImage}: LogoutLayoutProps) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const splash = <ResponsiveSplash centerImage={centerImage} />;
  const content = (
    <ResponsiveContent>
      <Box sx={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}>
        <Typography variant="display5" component="h1">
          {t('logout.you-have-been-logged-out', 'You have been logged out')}
        </Typography>
      </Box>
      <Typography variant="body1">
        {t('logout.thank-you', 'Thank you for using our service!')}
      </Typography>
    </ResponsiveContent>
  );

  return <ResponsiveContainer splash={splash} content={content} />;
});

export default LogoutLayout;
