import {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import useSWR from 'swr';
import {useConfig} from '../../hooks/useConfig';
import {useDomain} from '../../hooks/useDomain';
import {useProfile} from '../../hooks/useProfile';
import {api} from '../../lib/api';
import {EnrollmentError, EnrollmentErrorType} from '../../lib/api/error';
import {setStoredSession} from '../../session/sessionUtil';
import {EnrollmentStep} from '../../types/flow';
import {enrollFlowPath, profilePath, ROUTE_STUBS} from '../../types/route';
import Loading from '../loading';

function Login(): JSX.Element {
  const {config} = useConfig();
  const {profileName} = useProfile();
  const {domainName} = useDomain();
  // Use the _next_ step in the enrollment flow to ensure that an authentication check with CIAM is triggered
  // we want to perform this call in the /login route since in the event a user has already completed enrollment,
  // we can redirect them properly in /enroll
  const nextStep = config.flow.enrollmentStepToConfigStep.get(
    config.flow.successMap.get(EnrollmentStep.ACCOUNT_CREATION)
  );
  const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(false);
  const [runCheckAuth, setRunCheckAuth] = useState(false);

  const {
    data: checkAuthenticationResponse,
    error: checkAuthenticationError,
    isLoading: isLoadingCheckAuthentication,
  } = useSWR(
    runCheckAuth ? [domainName, profileName, nextStep] : null,
    ([d, p, cs]) => api.checkAuthentication(d, p, cs)
  );

  // this hook performs an authentication check and overrides session, if required
  // once isAuthenticated is set to true, the user is redirected back to the /enroll route
  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    if (!runCheckAuth) {
      setRunCheckAuth(true);
      return;
    }
    if (checkAuthenticationError) {
      // Leaves component in loading state if unauthenticated error
      // Although the router errorElement handles the unauthenticated error status appropriately,
      // if we don't explicitly prevent the error from propagating in development environments,
      // the user will see a flash of the error overlay before being redirected
      // to the auth login page. (There should be no UX consequences in environments where the error
      // overlay is disabled, however).
      if (
        checkAuthenticationError instanceof EnrollmentError &&
        checkAuthenticationError.errorType ===
          EnrollmentErrorType.UNAUTHENTICATED
      ) {
        return;
      } else {
        throw checkAuthenticationError;
      }
    }
    if (isLoadingCheckAuthentication) {
      // remain in loading state
      return;
    }
    if (checkAuthenticationResponse) {
      if (checkAuthenticationResponse.sessionToken) {
        // TODO(PHP-23910): remove this
        setStoredSession(checkAuthenticationResponse.sessionToken);
      }
      setIsAuthenticated(true);
      return;
    }
  }, [
    checkAuthenticationError,
    checkAuthenticationResponse,
    domainName,
    isAuthenticated,
    isLoadingCheckAuthentication,
    nextStep,
    profileName,
    runCheckAuth,
  ]);

  if (isAuthenticated) {
    // the /enroll route will handle redirecting the user to either the subsequent step in the flow or handoff
    return (
      <Navigate
        to={enrollFlowPath(profilePath(profileName))}
        state={{from: ROUTE_STUBS.login}}
      />
    );
  } else {
    return <Loading />;
  }
}

export default Login;
