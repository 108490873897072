import {GrpcStatusCode} from '@protobuf-ts/grpcweb-transport';
import {RpcError} from '@protobuf-ts/runtime-rpc';
import {
  CreateAccountRequest,
  CreateAccountResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callCreateAccount} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {EnrollmentError, EnrollmentErrorType} from './error';
import {getRpcOptions} from './getRpcOptions';

const createAccountServiceException =
  'There was an error creating your account. Please try again later.';

export const createAccount = async (
  email: string,
  profileName: string,
  domain: string,
  sendPasswordResetEmail: boolean
): Promise<CreateAccountResponse> => {
  try {
    const options = await getRpcOptions();
    const request: CreateAccountRequest = {
      profile: toProfileProto(profileName, domain),
      emailAddress: email,
      sendPasswordResetEmail: sendPasswordResetEmail,
    };
    const res = await callCreateAccount(request, options);
    return res;
  } catch (error) {
    if (error instanceof RpcError) {
      let serverErrorType = EnrollmentErrorType.UNKNOWN;
      if (error.code === GrpcStatusCode[GrpcStatusCode.ALREADY_EXISTS]) {
        serverErrorType = EnrollmentErrorType.ACCOUNT_ALREADY_EXISTS;
      }
      throw new EnrollmentError(serverErrorType, error.message, error);
    }
    throw new Error(createAccountServiceException);
  }
};
