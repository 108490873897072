import {Grid, useTheme} from '@mui/material';
import * as React from 'react';

export interface ResponsiveContainerProps {
  splash: React.ReactNode;
  content: React.ReactNode;
}
const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({
  splash,
  content,
}: ResponsiveContainerProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      data-testid="grid"
      sx={{
        display: 'flex',
        height: {xs: 'auto', md: '100vh'},
        overflow: {md: 'hidden'},
        minWidth: {xs: theme.spacing(40)},
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          bgcolor: 'background.canvas',
        }}
      >
        {splash}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          height: '100%',
          overflow: 'hidden',
          overflowY: 'auto',
          bgcolor: 'background.paper',
        }}
      >
        {content}
      </Grid>
    </Grid>
  );
};

export default ResponsiveContainer;
