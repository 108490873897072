import {SnackbarProvider} from '@verily-src/react-design-system';
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import Loading from './components/loading';
import {setFavicon, toCommonAssetsPath} from './components/utils';
import {EnrollmentThemeProvider} from './hooks/useEnrollmentTheme';
import './i18n';
import {AppRouteProvider} from './routing';

const root = document.getElementById('root');

export default function App() {
  // Sets the default favicon in the link element set by the root config.
  // A separate hook within Root overrides this by profile as necessary.
  setFavicon(toCommonAssetsPath('favicon.ico'));

  return (
    <React.StrictMode>
      <EnrollmentThemeProvider>
        <Suspense fallback={<Loading />}>
          <SnackbarProvider>
            <AppRouteProvider />
          </SnackbarProvider>
        </Suspense>
      </EnrollmentThemeProvider>
    </React.StrictMode>
  );
}

if (root) {
  createRoot(root).render(<App />);
}
