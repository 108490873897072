/**
 * Uses the SystemJS resolution context to find the url the MFE is mapped to, and constructs the appropriate path.
 * e.g., @verily-src/text-mfe: 'https://verily.com/mfe/test/1.0.0/file=main.js' provides protocol, and host 'https://verily.com', while relativeAssetPath is injected
 * as a parameter to accommodate build changes.
 */
function setPublicPath(relativeAssetPath: string) {
  if (!__system_context__?.meta?.url)
    throw Error(
      'Missing SystemJs context meta url. Verify webpack target is system.'
    );
  const mfeUrl = __system_context__.meta.url;

  __webpack_public_path__ = `${getLocalizedPath(mfeUrl)}${relativeAssetPath}`;
}

/**
 * @param mfeUrl - the url the MFE is rendered on, e.g., https://verily.com/home
 * @returns the protocol and host of the MFE url, e.g., https://verily.com
 */
function getLocalizedPath(mfeUrl: string): string {
  // By creating a temporary "a" tag, we can reliably parse the url into PORT and HOST.
  const temp = document.createElement('a');
  temp.href = mfeUrl;

  return `${temp.protocol}//${temp.host}`;
}

export {setPublicPath, getLocalizedPath};
