import {EnrolleeEvent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {useEffect} from 'react';
import {useConfig} from '../../hooks/useConfig';
import {useRecordEvent} from '../../hooks/useRecordEvent';
import {TerminationPageLayout} from './layout';
import {TerminationReason} from './states';

type TerminationPageProps = {
  reason: TerminationReason;
};

/**
 * Termination page for a user who should not be allowed to continue enrollment
 */
export default function TerminationPage(props: TerminationPageProps) {
  const {config} = useConfig();
  const {recordEvent} = useRecordEvent();

  const terminationReasonToEventType = new Map<
    TerminationReason,
    EnrolleeEvent
  >([
    [TerminationReason.INELIGIBLE, EnrolleeEvent.VISIT_INELIGIBLE],
    [TerminationReason.SCREENED_OUT, EnrolleeEvent.VISIT_SCREENED_OUT],
    [
      TerminationReason.ELIGIBILITY_USER_ALREADY_ENROLLED,
      EnrolleeEvent.VISIT_ELIGIBLE_USER_ALREADY_ENROLLED,
    ],
  ]);

  useEffect(() => {
    recordEvent(terminationReasonToEventType.get(props.reason));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TerminationPageLayout
      reason={props.reason}
      logo1={config.logo1}
      logo2={config.logo2}
      disclaimerFooter={config.disclaimerFooter}
      productName={config.productName}
    />
  );
}
