import {GrpcStatusCode} from '@protobuf-ts/grpcweb-transport';
import {RpcError} from '@protobuf-ts/runtime-rpc';

export enum EnrollmentErrorType {
  // Defined as strings for storybook human readability
  UNKNOWN = 'unknown',
  PROFILE_NOT_FOUND = 'profile_not_found',
  INTERNAL_SERVER_ERROR = 'internal_server_error',
  PAGE_NOT_FOUND = 'page_not_found',
  ACCOUNT_ALREADY_EXISTS = 'account_already_exists',
  PASSWORD_RESET_REDIRECT_ERROR = 'password_reset_redirect_error',
  UNAUTHENTICATED = 'unauthenticated',
  MISCONFIGURED_ELIGIBILITY_GROUP = 'misconfigured_eligibility_group',
  ELIGIBILITY_USER_ALREADY_ENROLLED = 'eligibility_user_already_enrolled',
}

/**
 * this error class wraps any grpc errors from the server to make them interpretable by the frontend.
 * grpc errors can be identified by `isGrpcWebTransportError` in a `try`/`catch`;
 * we then differentiate errors by usage, code, and methodName
 */
export class EnrollmentError extends Error {
  errorType: EnrollmentErrorType;
  constructor(errorType: EnrollmentErrorType, message?: string, cause?: Error) {
    super(message, {cause: cause});
    this.errorType = errorType;
  }
}

// Two status codes can represent the unauthenticated case
// as defined in verily1/ciam/mfe/auth/src/api/interceptors.ts
export const isCiamUnauthenticatedError = (error: Error): boolean => {
  if (error instanceof RpcError) {
    return (
      GrpcStatusCode[error.code] === GrpcStatusCode.UNAUTHENTICATED ||
      GrpcStatusCode[error.code] === GrpcStatusCode.PERMISSION_DENIED
    );
  }
};
