import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {useEffect, useState} from 'react';
import useSWRImmutable from 'swr/immutable';
import {client} from '../lib/apiv2';
import {getConfig} from '../lib/apiv2/getConfig';
import Loading from './loading';

export default function RootVerilyMeWebEnrollment() {
  // TODO(PHP-34807): Determine if favicon, page title, etc need to be set from config and implement
  // TODO(PHP-34810): Determine if session initialization is needed and implement
  const [stepId, setStepId] = useState<string>(undefined);

  const {data: getCurrentStepData, error: getCurrentStepError} =
    useSWRImmutable(
      'bundle/getCurrentStep',
      (api as typeof VerilyMeApi).bundle.getCurrentStep
    );
  const {data: getConfigData, error: getConfigError} = useSWRImmutable(
    stepId ? [client, stepId] : null, // Using a null key means the fetcher will not run.
    () => getConfig(client, stepId)
  );

  useEffect(() => {
    if (getCurrentStepData) {
      setStepId(getCurrentStepData.id);
    }
  }, [getCurrentStepData]);

  if (getCurrentStepError || getConfigError) {
    // TODO(PHP-35486): Render error page
    return <div>Error fetching config</div>;
  }
  if (getConfigData) {
    if (getConfigData.accountCreation) {
      // TODO(PHP-32180): Render appropriate page
      return <div>Render account creation</div>;
    } else if (getConfigData.participantData) {
      // TODO(PHP-32179): Render appropriate page
      return <div>Render participant data</div>;
    } else {
      // TODO: Render appropriate enrollment page based on config
      return <div>Render appropriate enrollment page</div>;
    }
  }
  return <Loading />;
}
