import Alert from '@mui/material/Alert';
import {ReactNode} from 'react';
import type {FieldError} from 'react-hook-form';

export type WithErrorTextProps = {
  children: ReactNode;
  error: FieldError | undefined | null;
};

// Wrapper component which ensures that any error text is presented in a div
// with the role="alert" attribute, so that it's properly announced to screen
// readers when it appears.
// TODO(PHP-4977) Replace this with a react-design-system alternative.
// Ideally this would become a feature of the react-design-system alternatives
// to MUI's base TextField and DatePicker components, rather than a wrapper at
// all.
export const WithErrorText = ({children, error}: WithErrorTextProps) => {
  return (
    <>
      {children}
      {/* The "alert" role must be on an element which is consistently present,
       * as it triggers activity only when updated, not when created. That's
       * why we have to unconditionally render a div with that role and
       * conditionally render the error text within that div, rather than just
       * conditionally rendering the element with the "alert" role. */}
      <div role="alert">
        {error && <Alert severity="error">{error?.message}</Alert>}
      </div>
    </>
  );
};

export const listOfStateAbbreviationsAsDropdownOptions = [
  {label: 'AL', value: 'AL'},
  {label: 'AK', value: 'AK'},
  {label: 'AZ', value: 'AZ'},
  {label: 'AR', value: 'AR'},
  {label: 'CA', value: 'CA'},
  {label: 'CO', value: 'CO'},
  {label: 'CT', value: 'CT'},
  {label: 'DE', value: 'DE'},
  {label: 'DC', value: 'DC'},
  {label: 'FL', value: 'FL'},
  {label: 'GA', value: 'GA'},
  {label: 'HI', value: 'HI'},
  {label: 'ID', value: 'ID'},
  {label: 'IL', value: 'IL'},
  {label: 'IN', value: 'IN'},
  {label: 'IA', value: 'IA'},
  {label: 'KS', value: 'KS'},
  {label: 'KY', value: 'KY'},
  {label: 'LA', value: 'LA'},
  {label: 'ME', value: 'ME'},
  {label: 'MD', value: 'MD'},
  {label: 'MA', value: 'MA'},
  {label: 'MI', value: 'MI'},
  {label: 'MN', value: 'MN'},
  {label: 'MS', value: 'MS'},
  {label: 'MO', value: 'MO'},
  {label: 'MT', value: 'MT'},
  {label: 'NE', value: 'NE'},
  {label: 'NV', value: 'NV'},
  {label: 'NH', value: 'NH'},
  {label: 'NJ', value: 'NJ'},
  {label: 'NM', value: 'NM'},
  {label: 'NY', value: 'NY'},
  {label: 'NC', value: 'NC'},
  {label: 'ND', value: 'ND'},
  {label: 'OH', value: 'OH'},
  {label: 'OK', value: 'OK'},
  {label: 'OR', value: 'OR'},
  {label: 'PA', value: 'PA'},
  {label: 'RI', value: 'RI'},
  {label: 'SC', value: 'SC'},
  {label: 'SD', value: 'SD'},
  {label: 'TN', value: 'TN'},
  {label: 'TX', value: 'TX'},
  {label: 'UT', value: 'UT'},
  {label: 'VT', value: 'VT'},
  {label: 'VA', value: 'VA'},
  {label: 'WA', value: 'WA'},
  {label: 'WV', value: 'WV'},
  {label: 'WI', value: 'WI'},
  {label: 'WY', value: 'WY'},
  {label: 'AS', value: 'AS'},
  {label: 'GU', value: 'GU'},
  {label: 'MP', value: 'MP'},
  {label: 'PR', value: 'PR'},
  {label: 'VI', value: 'VI'},
  {label: 'TT', value: 'TT'},
];

export const BetweenSectionSpacer = () => {
  return (
    <>
      <div style={{marginTop: '28px'}} />
    </>
  );
};
