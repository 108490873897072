import {
  EnrolleeEvent,
  RecordEnrolleeEventRequest,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callRecordEnrolleeEvent} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {getRpcOptions} from './getRpcOptions';

export const recordEnrolleeEventServiceExceptionMsg =
  'Service Exception: Unable to record enrollee event';

type RecordEnrolleeEventProps = {
  event: EnrolleeEvent;
  profileName: string;
  metadata?: {[s: string]: string};
};

export const recordEnrolleeEvent = async ({
  event,
  profileName,
}: RecordEnrolleeEventProps) => {
  try {
    const options = await getRpcOptions();
    const recordEnrolleeEventRequest: RecordEnrolleeEventRequest = {
      observedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      observedAt: {
        seconds: BigInt(Math.round(new Date().getTime() / 1000)),
        nanos: 0,
      },
      event,
      profile: toProfileProto(profileName, window.location.hostname),
    };
    await callRecordEnrolleeEvent(recordEnrolleeEventRequest, options);
  } catch (error) {
    throw new Error(recordEnrolleeEventServiceExceptionMsg);
  }
};
