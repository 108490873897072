/**
 * Returns Sharecare sponsor enrollment profile based on URL params.
 *
 * The profile is determined based on appending a prefix to the extracted sponsor code
 * to avoid namespace clashes with other customers who may have the same subcustomers.
 *
 * This extracts Sharecare sponsor code from sponsor url param based on
 * the expected format as described in
 * https://partner.sharecare.com/docs/integration-flows/usingoauth/#obtaining-offering-and-sponsor
 *
 * Ref: PHP-729
 */
export function profileIdFromSponsorParam(
  prefix: string,
  params: URLSearchParams
): string {
  const sponsorParam = params.get('sponsor');
  if (!sponsorParam) {
    throw new Error('Sponsor URL param not found');
  }
  const sponsorParamDecoded = decodeURI(sponsorParam);
  const segments = new URL(sponsorParamDecoded).pathname.split('/');
  const sponsorCode = segments.at(-1);
  if (!sponsorCode || segments.at(-2) !== 'sponsor') {
    throw new Error('Unexpected sponsor url param format');
  }
  // Sharecare sponsor codes seem to always come over uppercased in the URL param.
  // We lowercase them since it's preferable to use lowercase in URLs.
  return prefix + sponsorCode.toLowerCase();
}
