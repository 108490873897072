import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import CompleteEnrollment, {
  CompletionDestination,
} from './components/complete-enrollment';
import EnrollmentFlowPage from './components/enrollment-flow';
import ExclusionPage from './components/exclusion';
import InclusionPage from './components/inclusion';
import LogoutPage from './components/logout';
import ParticipantDataPage from './components/participant-data';
import TerminationPage from './components/termination-page';
import {TerminationReason} from './components/termination-page/states';

import AccountCreation from './components/accountCreation/create-account';
import VerifyEmail from './components/accountCreation/verify-email';
import AuthenticationGuard from './components/auth-route-guard/authRouteGuard';
import Auth0Callback from './components/auth0callback/auth0callback';
import ErrorElement from './components/global-error/global-error';
import Login from './components/login';
import ParticipantDataNUXPage from './components/participant-data-nux';
import PasswordResetRedirect from './components/password-reset/login';
import PasswordResetRedirectProfile from './components/password-reset/profile';
import ProfileRoot from './components/profile-root/profile-root';
import Root from './components/Root';
import {EnrollmentError, EnrollmentErrorType} from './lib/api/error';
import {EnrollmentStep} from './types/flow';
import {ROUTE_STUBS} from './types/route';

const ProfileLayout = () => (
  <Root>
    <Outlet />
  </Root>
);

const pageNotFoundError = new EnrollmentError(
  EnrollmentErrorType.PAGE_NOT_FOUND
);

const withinProfileRoutes = [
  // This element simply serves to replicate the behavior that existed
  // in the Angular app, where we redirect to the /enroll path
  // and pass any query params that exist
  {path: '', element: <ProfileRoot />},
  {
    path: ROUTE_STUBS.accountCreation,
    element: <AccountCreation />,
  },
  {
    path: ROUTE_STUBS.verifyEmail,
    element: <VerifyEmail />,
  },
  {
    path: ROUTE_STUBS.enroll,
    children: [
      {
        index: true,
        element: <EnrollmentFlowPage />,
      },
      {
        path: ROUTE_STUBS.accountCreation,
        element: <AccountCreation />,
      },
      {
        path: ROUTE_STUBS.login,
        element: <Login />,
      },
      {
        path: ROUTE_STUBS.participantData,
        element: (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.PARTICIPANT_DATA}>
            <ParticipantDataPage />
          </AuthenticationGuard>
        ),
      },
      {
        path: ROUTE_STUBS.participantDataNux,
        element: phafConfig.getBoolean(
          'FEATURE_NEW_USER_EXPERIENCE_ENABLED'
        ) ? (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.PARTICIPANT_DATA}>
            <ParticipantDataNUXPage />
          </AuthenticationGuard>
        ) : (
          <ErrorElement error={pageNotFoundError} />
        ),
      },
      {
        path: ROUTE_STUBS.ineligible,
        element: (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.INELIGIBLE}>
            <TerminationPage reason={TerminationReason.INELIGIBLE} />
          </AuthenticationGuard>
        ),
      },
      {
        path: ROUTE_STUBS.inclusion,
        element: (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.INCLUSION}>
            <InclusionPage />
          </AuthenticationGuard>
        ),
      },
      {
        path: ROUTE_STUBS.exclusion,
        element: (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.EXCLUSION}>
            <ExclusionPage />
          </AuthenticationGuard>
        ),
      },
      {
        path: ROUTE_STUBS.handoff,
        element: (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.HANDOFF}>
            <CompleteEnrollment
              destination={CompletionDestination.PRODUCT_HANDOFF_PAGE}
            />
          </AuthenticationGuard>
        ),
      },
      {
        path: ROUTE_STUBS.completionRedirect,
        element: (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.REDIRECT_USER}>
            <CompleteEnrollment
              destination={CompletionDestination.REDIRECT_USER}
            />
          </AuthenticationGuard>
        ),
      },
      {
        path: ROUTE_STUBS.screenedOut,
        element: (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.SCREENED_OUT}>
            <TerminationPage reason={TerminationReason.SCREENED_OUT} />
          </AuthenticationGuard>
        ),
      },
      {
        path: ROUTE_STUBS.eligUserAlreadyEnrolled,
        element: (
          <AuthenticationGuard enrollmentStep={EnrollmentStep.ALREADY_ENROLLED}>
            <TerminationPage
              reason={TerminationReason.ELIGIBILITY_USER_ALREADY_ENROLLED}
            />
          </AuthenticationGuard>
        ),
      },
    ],
  },
];

const ROUTES: RouteObject[] = [
  {
    children: [
      {
        path: '*',
        element: <ErrorElement error={pageNotFoundError} />,
      },
      {
        path: ROUTE_STUBS.error,
        element: <ErrorElement />,
      },
      {
        path: ROUTE_STUBS.authCallback,
        element: <Auth0Callback />,
      },
      {
        path: ROUTE_STUBS.profile + ROUTE_STUBS.profileDynamicSegment,
        element: <ProfileLayout />,
        children: withinProfileRoutes,
      },
      {
        path: ROUTE_STUBS.logout,
        element: <LogoutPage />,
      },
      {
        path: ROUTE_STUBS.passwordResetRedirect,
        element: <PasswordResetRedirect />,
      },
      {
        path: ROUTE_STUBS.profileRedirect,
        element: <PasswordResetRedirectProfile />,
      },
    ],
    errorElement: <ErrorElement />,
  },
];

export const AppRouteProvider = () => {
  return (
    <RouterProvider
      router={createBrowserRouter(ROUTES, {
        basename: `/${ROUTE_STUBS.enrollmentMfePath}`,
      })}
    />
  );
};
