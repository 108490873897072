/* eslint-disable react-hooks/rules-of-hooks */
import {default as _useSWR, SWRHook} from 'swr';
import {default as _useSWRImmutable} from 'swr/immutable';
/**
 * useSWR wraps the default useSWR hook so params and method name can be used together as the SWR Key
 * and callers do not have to worry about maintaining globally unique keys for each api method.
 */

export const useSWRImmutable = useSWRPicker(_useSWRImmutable);
export const useSWR = useSWRPicker(_useSWR);

function useSWRPicker(swr: SWRHook) {
  return <Req, Res>(req: Req, fetcher: (req: Req) => Promise<Res>) => {
    const methodName = fetcher.name;
    const results = swr({req, methodName}, () => fetcher(req), {
      suspense: true,
    });
    return results;
  };
}
