import {Divider, Typography} from '@mui/material';
import Stack from '@mui/material/Stack';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {AppImageConfig} from '../../../lib/types/appConfig';
import ResponsiveContainer from '../../responsive/responsive-container/ResponsiveContainer';
import ResponsiveContent from '../../responsive/responsive-content/ResponsiveContent';
import ResponsiveSplash from '../../responsive/responsive-splash/ResponsiveSplash';
import {toCommonAssetsPath} from '../../utils';
import AppHandoffLayout from './app-handoff/layout';
import WebHandoffLayout from './web-handoff/layout';

type HandoffLayoutProps = {
  logo1?: AppImageConfig;
  logo2?: AppImageConfig;
  disclaimerFooter?: string;
  productName?: string;
  webHandoffLink?: string;
  appHandoffLink?: string;
  androidHandoffLink?: string;
  iosHandoffLink?: string;
};

export const HandoffLayout = memo((props: HandoffLayoutProps) => {
  const centerImage = toCommonAssetsPath('illy_product_handoff.png');
  const splash = (
    <ResponsiveSplash
      logo1={props.logo1}
      logo2={props.logo2}
      centerImage={centerImage}
    />
  );
  const {t} = useTranslation();
  const content = (
    <ResponsiveContent disclaimerFooter={props.disclaimerFooter}>
      <Stack
        spacing={2}
        display="inline-flex"
        sx={{width: {sm: '75%'}, minWidth: '75%'}}
      >
        <Typography
          variant="display5"
          display="flex"
          component="h1"
          gutterBottom
        >
          {t('handoff.header')}
        </Typography>

        <Divider />
        <br />
        {props.appHandoffLink && (
          <>
            <AppHandoffLayout
              productName={props.productName}
              appHandoffLink={props.appHandoffLink}
              androidHandoffLink={props.androidHandoffLink}
              iosHandoffLink={props.iosHandoffLink}
            />
            <Divider />
          </>
        )}
        {props.webHandoffLink && (
          <WebHandoffLayout
            productName={props.productName}
            webHandoffLink={props.webHandoffLink}
            appHandoffLink={props.appHandoffLink}
          />
        )}
      </Stack>
    </ResponsiveContent>
  );

  return <ResponsiveContainer splash={splash} content={content} />;
});

export default HandoffLayout;
