import {FC} from 'react';
import Loading from '../loading';

/**
 * Component the user is redirected to after Auth0 login.
 *
 * It serves no purpose other than as a landing location; Auth0ProviderWithNavigate
 * contains logic to navigate the user back to where they were before
 * the Auth0 login redirect
 */
const Auth0Callback: FC = () => <Loading />;

export default Auth0Callback;
