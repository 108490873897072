import {CardMedia, Stack, Typography} from '@mui/material';
import {Button, useSnackbar} from '@verily-src/react-design-system';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Navigate, useLocation} from 'react-router-dom';
import {useConfig} from '../../../hooks/useConfig';
import {useDomain} from '../../../hooks/useDomain';
import {useProfile} from '../../../hooks/useProfile';
import {api} from '../../../lib/api';
import {createAccountPath, profilePath} from '../../../types/route';
import {toCommonAssetsPath} from '../../utils';
import AccountCreationBox from '../account-creation-box';

type VerifyEmailLocationState = {
  email?: string;
  auth0Id?: string;
};

export default function VerifyEmail() {
  const logo = toCommonAssetsPath('verily_logo.png');
  const {t} = useTranslation();
  const {profileName} = useProfile();
  const {domainName} = useDomain();
  const snackbar = useSnackbar();
  const location = useLocation();
  const {config} = useConfig();
  const {email, auth0Id} = (location.state as VerifyEmailLocationState) ?? {};
  const [resendPasswordEmailIsLoading, setResendPasswordEmailIsLoading] =
    useState(false);

  const resendPasswordResetEmail = async () => {
    setResendPasswordEmailIsLoading(true);
    try {
      await api
        .resendPasswordResetEmail(email, profileName, domainName, auth0Id)
        .then(() => snackbar.show(t('verify-email.resend-email-success')));
    } catch (error) {
      // If we get here, we have an unexpected error
      snackbar.show(t('verify-email.resend-email-failure', 'error'));
    }
    setResendPasswordEmailIsLoading(false);
  };

  // if no email is received, assume the user has not completed
  // the previous step and redirect them back to create account page
  if (!email) {
    return <Navigate to={createAccountPath(profilePath(profileName))} />;
  }

  return (
    <AccountCreationBox>
      <Stack justifyContent="center" alignItems="center" spacing={3}>
        <CardMedia
          component="img"
          src={logo}
          alt="Verily logo"
          sx={{
            maxWidth: '100px',
          }}
        />
        <Typography
          width="90%"
          variant="body1em"
          component="h2"
          textAlign="center"
        >
          {t('verify-email.please-verify-email')}
        </Typography>
        <Typography
          width="90%"
          variant="body2"
          component="h2"
          textAlign="center"
        >
          <Trans
            i18nKey="verify-email.we-sent-email"
            values={{userEmail: email}}
            components={{b: <strong />}}
          />
        </Typography>
        <Typography
          width="90%"
          variant="body2"
          component="h2"
          textAlign="center"
        >
          {t('verify-email.please-check-inbox')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          label={t('verify-email.resend-email')}
          onClick={() => resendPasswordResetEmail()}
          style={{width: '100%'}}
          loading={resendPasswordEmailIsLoading}
        ></Button>
      </Stack>
    </AccountCreationBox>
  );
}
