import {Step} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {api} from '../lib/api';
import {toProfileProto} from '../lib/proto/enrollmentProfile';
import {
  EligibilityStatus,
  UserEligibilityInfo,
} from '../lib/types/userEligibility';
import {useProfile} from './useProfile';

type UseCheckEligibilityReturn = {
  checkEligibility: (
    args: Omit<UserEligibilityInfo, 'profile'>
  ) => Promise<EligibilityStatus>;
};

export function useCheckEligibility(
  currentStep: Step
): UseCheckEligibilityReturn {
  const {profileName} = useProfile();
  const enrollmentProfile = toProfileProto(
    profileName,
    window.location.hostname
  );
  return {
    checkEligibility: async (args): Promise<EligibilityStatus> => {
      return api.checkEligibility(
        {
          ...args,
          profile: enrollmentProfile,
        },
        currentStep
      );
    },
  };
}
