import {Typography} from '@mui/material';
import * as React from 'react';

export interface StyledInjectedHtmlProps {
  content: string;
}

// Injects sanitized HTML into typography to make use of themed elements
// NOTE: This uses pre-sanitized HTML, it does not sanitize it here
const StyledInjectedHtml: React.FC<StyledInjectedHtmlProps> = ({
  content,
}: StyledInjectedHtmlProps) => {
  return (
    <div data-testid="innerHTML">
      <Typography
        variant="caption"
        dangerouslySetInnerHTML={{__html: content}}
      />
    </div>
  );
};

export default StyledInjectedHtml;
