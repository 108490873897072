import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  GetConfigRequest,
  GetConfigResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {EnrollmentClient as Client} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server.client';
import {
  bundleAndStepToRequest,
  Configs,
  protoToConfigs,
} from '../protov2/config';

export const makeGetConfigCall = async (
  client: Client,
  req: GetConfigRequest,
  opts: RpcOptions
): Promise<GetConfigResponse> => {
  const call = await client.getConfig(req, opts);
  return call.response;
};
export const getConfig = async (
  client: Client,
  stepId: string
): Promise<Configs> => {
  const req = bundleAndStepToRequest(stepId);
  const res = await makeGetConfigCall(client, req, {});
  return protoToConfigs(res);
};
