import {Auth} from '@verily-src/auth';
import {useSnackbar} from '@verily-src/react-design-system';
import {useEffect, useState} from 'react';
import {FieldValues, UseFormSetError} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom';
import {useConfig} from '../../../hooks/useConfig';
import {useDomain} from '../../../hooks/useDomain';
import {useProfile} from '../../../hooks/useProfile';
import {api} from '../../../lib/api';
import {EnrollmentError, EnrollmentErrorType} from '../../../lib/api/error';
import {EnrollmentStep, stepToRoute} from '../../../types/flow';
import {
  enrollFlowPath,
  loginUrl,
  profilePath,
  verifyEmailPath,
} from '../../../types/route';
import {
  logPasswordResetUrlParameter,
  passwordResetUrlLogPrefix,
} from '../../../utils/constants-cypress-needs-access-to';
import Loading from '../../loading';
import {AccountCreationLayout} from './layout';

export default function AccountCreation(): JSX.Element {
  const {profileName} = useProfile();
  const {domainName} = useDomain();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const snackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const {config} = useConfig();
  const [hasCheckedUserLoginStatus, setHasCheckedUserLoginStatus] =
    useState(false);
  const [userAlreadyLoggedIn, setUserAlreadyLoggedIn] = useState(false);

  // This only needs to be checked once upon component mount and not on every render
  useEffect(() => {
    Auth.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        setUserAlreadyLoggedIn(true);
      }
      setHasCheckedUserLoginStatus(true);
    });
  }, []);

  const onSubmit = async (
    email: string,
    setError: UseFormSetError<FieldValues>
  ) => {
    setSubmitLoading(true);

    const sendPasswordResetEmail =
      searchParams.get(logPasswordResetUrlParameter) !== 'true';
    try {
      const response = await api.createAccount(
        email,
        profileName,
        domainName,
        sendPasswordResetEmail
      );
      if (!response.passwordResetUrl) {
        // need to send back email in navigation state to pass into localization content and auth0Id for resend reset password email
        navigate(verifyEmailPath(profilePath(profileName)), {
          state: {email: email, auth0Id: response.auth0Id},
        });
      } else {
        console.log(passwordResetUrlLogPrefix + response.passwordResetUrl); // Reading this console.log for cypress tests
      }
    } catch (error) {
      if (error instanceof EnrollmentError) {
        if (error.errorType === EnrollmentErrorType.ACCOUNT_ALREADY_EXISTS) {
          setError('email', {
            message: t('account-creation-ciam.account-already-exists'),
          });
          setSubmitLoading(false);
          return;
        }
      }
      // If we get here, we have an unexpected error
      snackbar.show(t('snackbar.create-account-error'), 'error');
      setSubmitLoading(false);
    }
  };

  if (!hasCheckedUserLoginStatus) {
    return <Loading />;
  }

  if (userAlreadyLoggedIn) {
    return (
      <Navigate
        to={`${enrollFlowPath(profilePath(profileName))}/${stepToRoute.get(
          config.flow.successMap.get(EnrollmentStep.ACCOUNT_CREATION)
        )}`}
      />
    );
  }

  return (
    <AccountCreationLayout
      onSubmit={onSubmit}
      submitLoading={submitLoading}
      signinUrl={loginUrl(profileName)}
    />
  );
}
