import {GrpcStatusCode} from '@protobuf-ts/grpcweb-transport';
import {RpcError} from '@protobuf-ts/runtime-rpc';
import {
  CheckEligibilityRequest,
  CheckEligibilityResponse,
  Step,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callCheckEligibility} from '../../utils/clientCaller';
import {EligibilityStatus, UserEligibilityInfo} from '../types/userEligibility';
import {EnrollmentError, EnrollmentErrorType} from './error';
import {getRpcOptions} from './getRpcOptions';

export const checkEligibilityServiceExceptionKey =
  'eligibility check request failed';

export const checkEligibility = async (
  info: UserEligibilityInfo,
  currentStep: Step
): Promise<EligibilityStatus> => {
  let response: CheckEligibilityResponse;
  try {
    const options = await getRpcOptions();
    const request: CheckEligibilityRequest = {
      name: info.name,
      dateOfBirth: info.dob,
      profile: info.profile,
      enrollmentVerificationKey: info.enrollmentVerificationKey,
      currentStep: currentStep,
    };

    response = await callCheckEligibility(request, options);
  } catch (error) {
    if (error instanceof RpcError) {
      switch (error.code) {
        case GrpcStatusCode[GrpcStatusCode.FAILED_PRECONDITION]:
          throw new EnrollmentError(
            EnrollmentErrorType.MISCONFIGURED_ELIGIBILITY_GROUP,
            error.message,
            error
          );
        case GrpcStatusCode[GrpcStatusCode.ALREADY_EXISTS]:
          throw new EnrollmentError(
            EnrollmentErrorType.ELIGIBILITY_USER_ALREADY_ENROLLED,
            error.message,
            error
          );
        default:
          break;
      }
    }
    throw new Error(checkEligibilityServiceExceptionKey);
  }
  return {
    isEligible: response['isEligible'],
    sponsor: response['sponsor'],
  };
};
