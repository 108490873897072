import {CardMedia, Grid, Typography, useTheme} from '@mui/material';
import * as React from 'react';

export interface ResponsiveErrorPageProps {
  header: string;
  message?: string;
  imageUri?: string;
}

const ResponsiveErrorPage: React.FC<ResponsiveErrorPageProps> = ({
  header: header,
  message: message,
  imageUri: centerImage,
}: ResponsiveErrorPageProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="start"
      sx={{
        height: {xs: 'auto', md: '100vh'},
        minHeight: {xs: theme.spacing(27.5)},
      }}
    >
      {centerImage && (
        <Grid item xs={9} container justifyContent="center">
          <CardMedia
            component="img"
            image={centerImage}
            data-testid="centerImage"
            sx={{
              maxWidth: {
                xs: theme.spacing(24),
                md: '50%',
              },
              my: {xs: 2.5, md: 6.5},
            }}
            alt="Error Image"
          />
        </Grid>
      )}
      <Grid
        item
        xs={9}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Typography
          variant="display5"
          component="h1"
          textAlign="center"
          gutterBottom
        >
          {header}
        </Typography>
        {message && (
          <Typography variant="body1" textAlign="center">
            {message}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ResponsiveErrorPage;
