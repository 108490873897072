import {ArrowBackIos} from '@mui/icons-material';
import {Typography} from '@mui/material';
import Stack from '@mui/material/Stack';
import {Button} from '@verily-src/react-design-system';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {AppImageConfig} from '../../lib/types/appConfig';
import ResponsiveContainer from '../responsive/responsive-container/ResponsiveContainer';
import ResponsiveContent from '../responsive/responsive-content/ResponsiveContent';
import ResponsiveSplash from '../responsive/responsive-splash/ResponsiveSplash';

type ExclusionLayoutProps = {
  submit: () => void;
  logo1?: AppImageConfig;
  logo2?: AppImageConfig;
  centerImage?: string;
  disclaimerFooter?: string;
  questionForm: JSX.Element;
  isValid: boolean;
  isLoading?: boolean;
};

const ExclusionLayout = memo(
  ({
    submit,
    logo1,
    logo2,
    centerImage,
    disclaimerFooter,
    questionForm,
    isValid,
    isLoading,
  }: ExclusionLayoutProps) => {
    const splash = (
      <ResponsiveSplash logo1={logo1} logo2={logo2} centerImage={centerImage} />
    );

    const navigate = useNavigate();
    const {t} = useTranslation();

    const content = (
      <ResponsiveContent disclaimerFooter={disclaimerFooter}>
        <Stack display="inline-flex" style={{minWidth: '75%'}}>
          <Button
            onClick={() => navigate(-1)}
            role="button"
            variant="text"
            sx={{
              mb: 2,
              width: 'unset',
              paddingInline: '0.5rem',
              alignSelf: 'start',
              display: 'flex',
            }}
          >
            <ArrowBackIos />
            {t('navigation.back')}
          </Button>
          <Typography variant="display5" component="h1" gutterBottom>
            {t('exclusion.tell-us-more-about-your-health')}
          </Typography>
          {questionForm}
          <Button
            disabled={!isValid}
            loading={isLoading}
            label={t('navigation.continue')}
            variant="filled"
            sx={{
              alignSelf: 'end',
              paddingInline: '1.5rem',
            }}
            onClick={submit}
          />
        </Stack>
      </ResponsiveContent>
    );

    return <ResponsiveContainer splash={splash} content={content} />;
  }
);

export default ExclusionLayout;
