import {GrpcStatusCode} from '@protobuf-ts/grpcweb-transport';
import {RpcError} from '@protobuf-ts/runtime-rpc';
import {callGetProfileConfig} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {protoToAppConfig} from '../proto/profileConfig';
import {AppConfig} from '../types/appConfig';
import {EnrollmentError, EnrollmentErrorType} from './error';

type GetProfileConfigProps = {
  profileName: string;
};

export const getProfileConfig = async (
  props: GetProfileConfigProps
): Promise<AppConfig> => {
  const {profileName} = props;
  try {
    const res = await callGetProfileConfig({
      profile: toProfileProto(profileName, window.location.hostname),
    });
    return protoToAppConfig(res);
  } catch (e) {
    if (e instanceof RpcError) {
      let serverErrorType = EnrollmentErrorType.UNKNOWN;
      if (e.code === GrpcStatusCode[GrpcStatusCode.INVALID_ARGUMENT]) {
        serverErrorType = EnrollmentErrorType.PROFILE_NOT_FOUND;
      }
      throw new EnrollmentError(serverErrorType, e.message, e);
    }
    throw e;
  }
};
