import {RpcError} from '@protobuf-ts/runtime-rpc';
import {ResendPasswordResetEmailRequest} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callResendPasswordResetEmail} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {EnrollmentError, EnrollmentErrorType} from './error';
import {getRpcOptions} from './getRpcOptions';

async function resendPasswordResetEmail(
  email: string,
  profileName: string,
  domain: string,
  auth0Id: string
) {
  try {
    const options = await getRpcOptions();
    const request: ResendPasswordResetEmailRequest = {
      emailAddress: email,
      profile: toProfileProto(profileName, domain),
      auth0Id: auth0Id,
    };

    await callResendPasswordResetEmail(request, options);
  } catch (error) {
    if (error instanceof RpcError) {
      throw new EnrollmentError(
        EnrollmentErrorType.INTERNAL_SERVER_ERROR,
        error.message,
        error
      );
    }
  }
}

export default resendPasswordResetEmail;
