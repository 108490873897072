import {ROUTE_STUBS} from './route';

/** Concrete Steps in Enrollment Flow */
export enum EnrollmentStep {
  ACCOUNT_CREATION = 'ACCOUNT_CREATION',
  PARTICIPANT_DATA = 'PARTICIPANT_DATA',
  INELIGIBLE = 'INELIGIBLE',
  INCLUSION = 'INCLUSION',
  EXCLUSION = 'EXCLUSION',
  HANDOFF = 'HANDOFF',
  SCREENED_OUT = 'SCREENED_OUT',
  ALREADY_ENROLLED = 'ALREADY_ENROLLED',
  LOGIN = 'LOGIN',
  REDIRECT_USER = 'REDIRECT_USER',
}

/** Map of Enrollment Steps to Route Stubs */
export const stepToRoute = new Map<EnrollmentStep, string>([
  // TODO(PHP-3377): Merge Participant data routes. The only reason they are differentiated
  // is because they go to different failure states but the hard coded state machine here
  // probably won't be needed once reorderable flow is supported
  [EnrollmentStep.PARTICIPANT_DATA, ROUTE_STUBS.participantData],
  [EnrollmentStep.INELIGIBLE, ROUTE_STUBS.ineligible],
  [EnrollmentStep.INCLUSION, ROUTE_STUBS.inclusion],
  [EnrollmentStep.EXCLUSION, ROUTE_STUBS.exclusion],
  [EnrollmentStep.HANDOFF, ROUTE_STUBS.handoff],
  [EnrollmentStep.SCREENED_OUT, ROUTE_STUBS.screenedOut],
  [EnrollmentStep.ACCOUNT_CREATION, ROUTE_STUBS.accountCreation],
  [EnrollmentStep.ALREADY_ENROLLED, ROUTE_STUBS.eligUserAlreadyEnrolled],
  [EnrollmentStep.LOGIN, ROUTE_STUBS.login],
  [EnrollmentStep.REDIRECT_USER, ROUTE_STUBS.completionRedirect],
]);
