import {EnrolleeEvent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {api} from '../lib/api';
import {useProfile} from './useProfile';

type UseRecordEventReturn = {
  recordEvent: Function;
};

export function useRecordEvent(): UseRecordEventReturn {
  const {profileName} = useProfile();

  return {
    recordEvent: async (event: EnrolleeEvent) => {
      await api.recordEnrolleeEvent({
        event: event,
        profileName: profileName!,
      });
    },
  };
}
