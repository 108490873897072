import {Box, CircularProgress} from '@mui/material';

export const LoadingElementTestId = 'loading-component';
const Loading = () => (
  <Box
    data-testid={LoadingElementTestId}
    sx={{
      position: 'absolute',
      inset: '0',
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',
    }}
  >
    <CircularProgress />
  </Box>
);

export default Loading;
