import {EnrolleeEvent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {useEffect} from 'react';
import {useConfig} from '../../../hooks/useConfig';
import {useRecordEvent} from '../../../hooks/useRecordEvent';
import {EnrollmentStep} from '../../../types/flow';
import Loading from '../../loading';
import {externalRedirect} from './utils';

/**
 * Step in enrollment flow that redirects the user to a URL external to enrollment.
 *
 * This represents an alternative to the `Handoff` page when the user completes enrollment
 * */
export default function RedirectUser() {
  const {config} = useConfig();
  const {recordEvent} = useRecordEvent();

  const redirectUrl = config.flow?.enrollmentStepToConfigStep?.get(
    EnrollmentStep.REDIRECT_USER
  )?.step?.redirectUser?.url;

  useEffect(() => {
    // These should be validated by config service, but run some basic sanity checks
    // in case user gets here unexpectedly
    if (!redirectUrl) {
      throw new Error('Missing redirect URL in profile config');
    }
    const url = new URL(redirectUrl);
    recordEvent(EnrolleeEvent.VISIT_USER_REDIRECT).then(() => {
      // Pass string to function instead of URL object; otherwise tests do not verify passed value correctly
      externalRedirect(url.href);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
}
