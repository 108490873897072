import {useSnackbar} from '@verily-src/react-design-system';
import {EnrolleeEvent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useConfig} from '../../hooks/useConfig';
import {useRecordEvent} from '../../hooks/useRecordEvent';
import {useUserState} from '../../hooks/useUserState';
import {EnrollmentStep} from '../../types/flow';
import {compareBoolean} from '../../utils';
import useBoolQuestionForm from '../bool-question-form';
import {useProgress} from '../enrollment-flow/progress';
import {toCommonAssetsPath} from '../utils';
import InclusionLayout from './layout';

export default function InclusionPage() {
  const {userState, setInclusionData} = useUserState();
  const {config} = useConfig();
  const {recordEvent} = useRecordEvent();
  const snackbar = useSnackbar();
  const {t} = useTranslation();

  const centerImage = toCommonAssetsPath('illy_inclusion_exclusion.png');

  const [currentlyLoading, setCurrentlyLoading] = useState(false);

  useEffect(() => {
    recordEvent(EnrolleeEvent.VISIT_INCLUSION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {layout, handleSubmit, isValid, getValues} = useBoolQuestionForm({
    questionSections: config.inclusion.questionSections,
    responses: userState?.inclusion?.responses,
    disableForm: currentlyLoading,
  });

  const {completeStep, failStep} = useProgress(EnrollmentStep.INCLUSION);
  const submit = async () => {
    setCurrentlyLoading(true);
    const responses = getValues();
    try {
      await setInclusionData!({responses: responses});
    } catch {
      // For simplicity just naively assume all errors are transient submit errors
      // Worth improving specificity in the future.
      snackbar.show(t('snackbar.enroll-submit-error'), 'error');
      setCurrentlyLoading(false);
      return;
    }
    const success = Object.values(responses).some(compareBoolean);
    success ? completeStep() : failStep();
    setCurrentlyLoading(false);
  };

  return (
    <InclusionLayout
      submit={handleSubmit(submit)}
      logo1={config.logo1}
      logo2={config.logo2}
      centerImage={centerImage}
      disclaimerFooter={config.disclaimerFooter}
      questionForm={layout}
      isValid={isValid}
      isLoading={currentlyLoading}
    />
  );
}
