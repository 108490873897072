import {toCommonAssetsPath} from '../utils';
import LogoutLayout from './layout';

/**
 * Users will be redirected to this page from auth0 after log out
 */
const LogoutPage = () => {
  const centerImage = toCommonAssetsPath('illy_ineligible.png');

  return <LogoutLayout centerImage={centerImage} />;
};

export default LogoutPage;
