import {useEffect} from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import {useConfig} from '../../hooks/useConfig';
import {useUserState} from '../../hooks/useUserState';
import {EnrollmentStep, stepToRoute} from '../../types/flow';
import {ROUTE_STUBS} from '../../types/route';
import Loading from '../loading';
import {useFirstStep} from './progress';

// The EnrollFlowLocationState type is used to pass the `from` property
// when a user is redirected from the password reset flow;
// this value with either be ROUTE_STUBS.profileRedirect, ROUTE_STUBS.profileRedirect, or undefined.
type EnrollFlowLocationState = {
  from?: string;
};

export default function EnrollmentFlowPage() {
  const navigate = useNavigate();
  const {config} = useConfig();
  // call getUserState with the first step of the enrollment flow since
  // we need to know if the user has already completed enrollment
  const {isLoadingInitialState, userState} = useUserState(
    config.flow.enrollmentStepToConfigStep.get(config.flow.firstStep)
  );
  const firstStep = useFirstStep(userState);
  const location = useLocation();
  const {from} = (location.state as EnrollFlowLocationState) ?? {};
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (firstStep && !isLoadingInitialState) {
      let route = stepToRoute.get(firstStep) ?? '/';
      if (returnFromLogin(from) && !userState.enrollmentComplete) {
        route =
          stepToRoute.get(
            config.flow.successMap.get(EnrollmentStep.ACCOUNT_CREATION)
          ) ?? '/';
      }
      if (Array.from(searchParams).length === 0) {
        navigate(route);
      } else {
        navigate({
          pathname: route,
          search: searchParams.toString(),
        });
      }
    }
  }, [
    config.flow.successMap,
    firstStep,
    from,
    isLoadingInitialState,
    navigate,
    userState.enrollmentComplete,
  ]);

  // Don't navigate to an enrollment form page until user state is
  // loaded since those components expect it to already be available.
  if (!firstStep || isLoadingInitialState) {
    return <Loading />;
  }

  return <Outlet />;
}

const returnFromLogin = (from: string) => {
  return from === ROUTE_STUBS.profileRedirect || from === ROUTE_STUBS.login;
};
