import {yupResolver} from '@hookform/resolvers/yup';
import {Divider, Stack, useTheme} from '@mui/material';
import * as React from 'react';
import {Control, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {AppQuestionSectionConfig} from '../../lib/types/appConfig';
import {BoolQuestionFormSection} from './bool-question-section/index';

export interface useBoolQuestionFormProps {
  questionSections?: AppQuestionSectionConfig[];
  responses?: Record<string, boolean | null>;
  disableForm?: boolean;
}

export type FormType = {
  [x: string]: boolean | null;
};

// Build the yup object
export default function useBoolQuestionForm({
  questionSections,
  responses,
  disableForm,
}: useBoolQuestionFormProps): {
  layout: JSX.Element;
  handleSubmit: Function;
  getValues: Function;
  isValid: boolean;
} {
  let schema = yup.object();

  if (questionSections) {
    const initialValue = {};
    // Flatten the questions into one yup form
    schema = yup.object(
      questionSections.reduce(
        (obj, item) => ({...obj, ...questionSectionToObject(item)}),
        initialValue
      )
    );
  }

  const {control, getValues, handleSubmit, formState} = useForm<FormType>({
    resolver: yupResolver(schema), // requires @hookform/resolver 3.0.0
    values: {
      ...schema.getDefault(),
      ...(responses as FormType),
    },
  });

  const layout = BoolQuestionLayout({
    control: control,
    questionSections: questionSections,
    disableForm: disableForm,
  });

  return {layout, getValues, handleSubmit, isValid: formState.isValid};
}

interface BoolQuestionLayoutProps {
  questionSections?: AppQuestionSectionConfig[];
  control: Control<FormType>;
  disableForm?: boolean;
}

const BoolQuestionLayout = ({
  control,
  questionSections,
  disableForm,
}: BoolQuestionLayoutProps) => {
  const theme = useTheme();

  const sectionDivider = () => (
    <Divider
      sx={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}
    />
  );

  return (
    <form role="form">
      {sectionDivider()}
      <Stack divider={sectionDivider()}>
        {questionSections?.map(section => (
          <React.Fragment key={keyFromIds(section)}>
            <BoolQuestionFormSection
              control={control}
              section={section}
              disableForm={disableForm}
            />
          </React.Fragment>
        ))}
      </Stack>
      {sectionDivider()}
    </form>
  );
};

// Build the yup object for the section
const questionSectionToObject = ({questions}: AppQuestionSectionConfig) => {
  const initialValue = {};
  return questions.reduce((obj, question) => {
    return {
      ...obj,
      [question.id]: yup
        .boolean()
        .oneOf([true, false])
        .required()
        .default(null),
    };
  }, initialValue);
};

// React wants every fragment to have a unique key assigned to it
// Use ids for each question, and just join the question ids for the section
function keyFromIds(section: AppQuestionSectionConfig) {
  let key = '';
  section.questions.map(question => {
    key = key + question.id;
  });
  return key;
}
