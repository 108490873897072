import {configuration} from '@verily-src/configuration';

/**
 * This is a wrapper on the configuration MFE package. It allows MFEs to call .get
 * directly from the configuration package but with the MFE name already set.
 * An error will be throw if MFE_NAME is not set.
 */
class ConfigurationWrapper {
  get(key: string): unknown {
    if (!MFE_NAME) {
      throw new Error(
        'MFE_NAME is not set. Verify you call setVars in your webpack config.'
      );
    }
    return Reflect.apply(configuration.get, configuration, [MFE_NAME, key]);
  }

  getBoolean(key: string): boolean {
    // Boolean("false") == true, so we need to check for that case.
    return this.get(key) === 'false' ? false : Boolean(this.get(key));
  }

  /**
   * Retrieves the value of a specified configuration key as a string.
   * If the key does not exist or its value is `undefined` or `null`, an empty string is returned.
   * This method ensures that all configuration values are treated as strings, providing a consistent
   * interface for accessing configuration values.
   *
   * @param {string} key - The name of the configuration key whose value is to be retrieved.
   * @returns {string} The value of the configuration key as a string, or an empty string if the key
   *          does not exist or its value is `undefined` or `null`.
   */
  getString(key: string): string {
    if (this.get(key) === undefined || this.get(key) === null) {
      return '';
    }
    return String(this.get(key));
  }

  getNumber(key: string): number {
    return Number(this.get(key));
  }
}

export const config = new ConfigurationWrapper();
