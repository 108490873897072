import {
  AccountCreationStep,
  GetConfigRequest,
  GetConfigResponse,
  ParticipantDataStep,
  ParticipantDataStep_ConstraintOperator as Operator,
  ParticipantDataStep_Field as Field,
  ParticipantDataStep_FormFieldConstraint as Constraint,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';

export type CommonConfig = {};
export type ParticipantDataConfig = {
  collectGender: boolean;
  collectAddress: boolean;
  formHeader: string;
  formSubHeader: string;
  addressFormHeader: string;
  addressFormSubHeader: string;
  basicInfoSubFormSubHeader: string;
  contactInfoSubFormSubHeader: string;
  constraints: ConstraintConfig[];
};
type ConstraintConfig = {
  field: 'First Name' | 'Last Name';
  predicate: (value: string) => boolean;
  errorMessage: string;
};
export type AccountCreationConfig = {};

export type Configs = {
  common: CommonConfig;
  participantData?: ParticipantDataConfig;
  accountCreation?: AccountCreationConfig;
};

export const bundleAndStepToRequest = (stepId: string): GetConfigRequest => {
  return {stepId: stepId};
};

export const protoToConfigs = (res: GetConfigResponse): Configs => {
  const configs: Configs = {common: {}};
  switch (res.step?.step.oneofKind) {
    case 'accountCreation': {
      configs.accountCreation = accountCreationStepToConfig(
        (
          res.step.step as {
            oneofKind: string;
            accountCreation: AccountCreationStep;
          }
        ).accountCreation
      );
      break;
    }
    case 'participantData': {
      configs.participantData = participantDataStepToConfig(
        (
          res.step.step as {
            oneofKind: string;
            participantData: ParticipantDataStep;
          }
        ).participantData
      );
      break;
    }
    case 'handoff': {
      // TODO(PHP-25286): Implement handoff.
      break;
    }
    case 'redirectUser': {
      // TODO(PHP-25286): Implement redirection.
      break;
    }
  }
  return configs;
};

const accountCreationStepToConfig = (
  _step: AccountCreationStep
): AccountCreationConfig => {
  return {};
};

const participantDataStepToConfig = (
  step: ParticipantDataStep
): ParticipantDataConfig => {
  return {
    collectGender: step.collectGenderIdentity,
    collectAddress: step.collectAddress,
    formHeader: step.participantDataFormHeader,
    formSubHeader: step.participantDataFormSubheader,
    addressFormHeader: step.addressCollectionSubformHeader,
    addressFormSubHeader: step.addressCollectionSubformSubheader,
    basicInfoSubFormSubHeader: step.basicInfoSubformSubheader,
    contactInfoSubFormSubHeader: step.contactInfoSubformSubheader,
    constraints: step.constraints.map(constraintToConfig),
  };
};

const constraintToConfig = (constraint: Constraint): ConstraintConfig => {
  return <ConstraintConfig>{
    field: protoFieldToConfigField.get(constraint.field)!,
    predicate: operatorAndValueToPredicate(
      constraint.operator,
      constraint.value
    ),
    errorMessage: constraint.errorMessage,
  };
};

const protoFieldToConfigField = new Map([
  [Field.FIRST_NAME, 'First Name'],
  [Field.LAST_NAME, 'Last Name'],
]);

const operatorAndValueToPredicate = (
  op: Operator,
  value: string
): ((value: string) => boolean) => {
  switch (op) {
    case Operator.LENGTH_LESS_THAN: {
      return (input: string) => input.length < Number(value);
    }
  }
};
