import {FormControl, Typography} from '@mui/material';
import {SingleSelectDropdown, TextField} from '@verily-src/react-design-system';
import {UserType} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import type {FieldValues, Path} from 'react-hook-form';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {userTypeSpecificI18Namespace} from '../../../i18n_helpers';
import {
  listOfStateAbbreviationsAsDropdownOptions,
  WithErrorText,
} from './utils';

type AddressFormProps = {
  userType: UserType;
  header: string;
  subheader: string;
};

/**
 * Subform layout for collecting user's address
 */
export default function AddressFormLayout<T extends FieldValues>({
  userType,
  header,
  subheader,
}: AddressFormProps) {
  const {control, formState} = useFormContext<T>();
  const {t} = useTranslation();

  const userTypeSpecificContentNs = userTypeSpecificI18Namespace(userType);

  // TODO(PHP-24668): address header can be in common resource once bff i18n serving is deprecated
  return (
    <>
      <header>
        <Typography
          variant="body1em"
          component="h2"
          gutterBottom={
            !(
              subheader ||
              t('user-info-form.address-subheader', {
                ns: userTypeSpecificContentNs,
              })
            )
          }
        >
          {header ??
            t('user-info-form.address-header', 'Residential Address', {
              ns: userTypeSpecificContentNs,
            })}
        </Typography>
      </header>

      {(subheader ||
        t('user-info-form.address-subheader', '', {
          ns: userTypeSpecificContentNs,
        })) && (
        <Typography variant="caption" gutterBottom>
          {subheader ||
            t('user-info-form.address-subheader', {
              ns: userTypeSpecificContentNs,
            })}
        </Typography>
      )}

      <Controller
        control={control}
        name={'addressLine1' as Path<T>}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <>
            <WithErrorText error={error}>
              <TextField
                required
                data-cy="addressLine1"
                {...field}
                label={t('address.address-line-1')}
                placeholder={t('address.address-line-1')}
                disabled={formState.isSubmitting}
                error={!!error}
                role="textbox"
                style={{width: '70%'}}
                multiline={false}
              />
            </WithErrorText>
          </>
        )}
      />
      <Controller
        control={control}
        name={'addressLine2' as Path<T>}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <>
            <TextField
              data-cy="addressLine2"
              {...field}
              label={t('address.address-line-2')}
              placeholder={t('address.address-line-2')}
              disabled={formState.isSubmitting}
              error={!!error}
              role="textbox"
              style={{width: '70%', marginBottom: '16px'}} // TODO (PHP-7281) remove (https://verily.atlassian.net/browse/PHP-7281)
              multiline={false}
              value={field.value || ''}
            />
          </>
        )}
      />
      <Controller
        control={control}
        name={'city' as Path<T>}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <>
            <WithErrorText error={error}>
              <TextField
                required
                data-cy="city"
                {...field}
                label={t('address.city')}
                placeholder={t('address.city')}
                disabled={formState.isSubmitting}
                error={!!error}
                role="textbox"
                style={{width: '70%'}}
                multiline={false}
              />
            </WithErrorText>
          </>
        )}
      />
      <Controller
        control={control}
        name={'state' as Path<T>}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <>
            <FormControl fullWidth required>
              <SingleSelectDropdown
                required
                options={listOfStateAbbreviationsAsDropdownOptions}
                data-cy="state"
                {...field}
                label={t('address.state')}
                labelId="something"
                placeholder={t('address.state')}
                disabled={formState.isSubmitting}
                error={!!error}
                role="textbox"
                style={{
                  width: '70%',
                  borderRadius: '16px',
                  marginBottom: '16px', // TODO (PHP-7281) remove (https://verily.atlassian.net/browse/PHP-7281)
                }}
                multiline={false}
                defaultValue=""
              ></SingleSelectDropdown>
            </FormControl>
          </>
        )}
      />
      <Controller
        control={control}
        name={'zipCode' as Path<T>}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <>
            <WithErrorText error={error}>
              <TextField
                required
                data-cy="zipCode"
                {...field}
                label={t('address.zip-code')}
                placeholder={t('address.zip-code-placeholder')}
                disabled={formState.isSubmitting}
                error={!!error}
                role="textbox"
                style={{width: '70%'}}
                multiline={false}
              />
            </WithErrorText>
          </>
        )}
      />
    </>
  );
}
