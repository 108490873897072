import {Params} from 'react-router-dom';

export const SESSION_HEADER_KEY = 'x-enrollment-session-id';

export type Session = {
  sessionId: string;
  queryParams?: Params;
};

export type SessionMetadata = {
  [SESSION_HEADER_KEY]: string;
};

export function getStoredSession(): string | null {
  return sessionStorage.getItem(SESSION_HEADER_KEY);
}

export function setStoredSession(s: string): string {
  sessionStorage.setItem(SESSION_HEADER_KEY, s);
  return s;
}

export function clearStoredSession(): void {
  sessionStorage.removeItem(SESSION_HEADER_KEY);
}
