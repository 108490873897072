import {PropsWithChildren, Suspense, useEffect, useState} from 'react';
import {ConfigContext, useFetchConfig} from '../hooks/useConfig';
import {useEnrollmentTheme} from '../hooks/useEnrollmentTheme';
import useSessionInitializer from '../hooks/useSessionInitializer';
import Loading from './loading';
import {getPageTitle, setFavicon} from './utils';

interface RootProps {
  children: React.ReactNode;
}

export default function Root({children}: PropsWithChildren<RootProps>) {
  return (
    <Suspense fallback={<Loading />}>
      <RootElement children={children} />
    </Suspense>
  );
}

function RootElement({children}: PropsWithChildren<RootProps>) {
  const [sessionInitializationComplete, setSessionInitializationComplete] =
    useState<boolean>(false);
  const {config} = useFetchConfig();
  const {updateTheme} = useEnrollmentTheme();

  useSessionInitializer(setSessionInitializationComplete);

  useEffect(() => {
    if (config.favicon?.uri) {
      setFavicon(config.favicon.uri);
    }
    document.title = getPageTitle(config);
    if (config.primaryColorMain) {
      updateTheme({primaryMainColor: config.primaryColorMain});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);
  return (
    <ConfigContext.Provider value={config}>
      {sessionInitializationComplete ? <>{children}</> : <Loading />}
    </ConfigContext.Provider>
  );
}
