import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {Auth} from '@verily-src/auth';
import {
  CheckAuthenticationRequest,
  CheckEligibilityRequest,
  CheckEligibilityResponse,
  CreateAccountRequest,
  CreateAccountResponse,
  EnrollPatientRequest,
  EnrollPatientResponse,
  GetAgreementConsentsRequest,
  GetAgreementConsentsResponse,
  GetDomainConfigRequest,
  GetDomainConfigResponse,
  GetPasswordResetReturnProfileRequest,
  GetPasswordResetReturnProfileResponse,
  GetProfileConfigRequest,
  GetProfileConfigResponse,
  GetUserStateRequest,
  GetUserStateResponse,
  RecordEnrolleeEventRequest,
  RecordEnrolleeEventResponse,
  ReportErrorRequest,
  ResendPasswordResetEmailRequest,
  ResendPasswordResetEmailResponse,
  StartEnrollmentRequest,
  StartEnrollmentResponse,
  StartSessionRequest,
  StartSessionResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {client} from '../lib/api';

// The mock CIAM client relies on the test access token attached to the request.
// Even though this BFF call does not require authentication, we need to attach
// the test access token to the request so that the mock CIAM client can use it.
async function getRpcOptionsMaybeMockingCiam(): Promise<RpcOptions> {
  let options: RpcOptions = {};
  if (process.env.REACT_APP_MOCK_CIAM) {
    console.log('Mocking CIAM to send test access token to BFF.');
    const interceptors = [await Auth.getInterceptor()];
    options = {interceptors};
  }
  return options;
}

/**
 * This file exists purely for mocking functionality because protobuf-ts uses unmockable client functions.
 * Here we have mockable wrapper functions that call the enrollment client and retrieve the response.
 * This way we can call
 * const spy = jest.spyOn(...).mockImplementation(...) and use that.
 *
 * Ideally we get a much better mocking alternative.
 *
 * https://verily.atlassian.net/browse/PHP-8371; fix TODO (PHP-8371)
 */
export const callResendPasswordResetEmail = async (
  request: ResendPasswordResetEmailRequest,
  options: RpcOptions
): Promise<ResendPasswordResetEmailResponse> => {
  const call = await client.resendPasswordResetEmail(request, options);
  return call.response;
};

export const callCreateAccount = async (
  request: CreateAccountRequest,
  options: RpcOptions
): Promise<CreateAccountResponse> => {
  const call = await client.createAccount(request, options);
  return call.response;
};

export const callGetAgreementConsents = async (
  request: GetAgreementConsentsRequest,
  options: RpcOptions
): Promise<GetAgreementConsentsResponse> => {
  const call = await client.getAgreementConsents(request, options);
  return call.response;
};

export const callGetDomainConfig = async (
  request: GetDomainConfigRequest,
  options: RpcOptions
): Promise<GetDomainConfigResponse> => {
  const call = await client.getDomainConfig(request, options);
  return call.response;
};

export const callStartSession = async (
  request: StartSessionRequest,
  options: RpcOptions
): Promise<StartSessionResponse> => {
  const call = await client.startSession(request, options);
  return call.response;
};

export const callGetProfileConfig = async (
  request: GetProfileConfigRequest
): Promise<GetProfileConfigResponse> => {
  const call = await client.getProfileConfig(
    request,
    await getRpcOptionsMaybeMockingCiam()
  );
  return call.response;
};

export const callCheckEligibility = async (
  request: CheckEligibilityRequest,
  options: RpcOptions
): Promise<CheckEligibilityResponse> => {
  const call = await client.checkEligibility(request, options);
  return call.response;
};

export const callStartEnrollment = async (
  request: StartEnrollmentRequest,
  options: RpcOptions
): Promise<StartEnrollmentResponse> => {
  const call = await client.startEnrollment(request, options);
  return call.response;
};

export const callEnroll = async (
  request: EnrollPatientRequest,
  options: RpcOptions
): Promise<EnrollPatientResponse> => {
  const call = await client.enroll(request, options);
  return call.response;
};

export const callRecordEnrolleeEvent = async (
  request: RecordEnrolleeEventRequest,
  options: RpcOptions
): Promise<RecordEnrolleeEventResponse> => {
  const call = await client.recordEnrolleeEvent(request, options);
  return call.response;
};

export const callGetUserState = async (
  request: GetUserStateRequest,
  options: RpcOptions
): Promise<GetUserStateResponse> => {
  const call = await client.getUserState(request, options);
  return call.response;
};

export const callGetPasswordResetReturnProfile = async (
  request: GetPasswordResetReturnProfileRequest,
  options: RpcOptions
): Promise<GetPasswordResetReturnProfileResponse> => {
  const call = await client.getPasswordResetReturnProfile(request, options);
  return call.response;
};

export const callReportError = async (request: ReportErrorRequest) => {
  client.reportError(request, await getRpcOptionsMaybeMockingCiam());
};

export const callCheckAuthentication = async (
  request: CheckAuthenticationRequest,
  options: RpcOptions
) => {
  const call = client.checkAuthentication(request, options);
  return call.response;
};
