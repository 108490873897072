import {
  BooleanQuestionAnswer,
  ConsentData,
  ConsentData_PolicySetConsent,
  EnrollPatientRequest,
  ExclusionScreenerData,
  GenderIdentity,
  GetUserStateResponse,
  InclusionScreenerData,
  PatientData,
  Step,
  Telecom,
  Telecom_ContactPointSystem,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
import {compareBoolean} from '../../utils';
import {UserState, UserStateConsents} from '../types/userState';
import {parseDateStringToProto, parseProtoToDateString} from './conversion';
import {toProfileProto} from './enrollmentProfile';

export function protoToState(proto: GetUserStateResponse): UserState {
  return {
    participantData: protoToParticipantData(proto['patientData']),
    consent: {
      policySetConsents: protoToPolicySetConsents(proto.consentData),
    },
    inclusion: {
      responses: protoToResponses(proto.inclusionData),
    },
    exclusion: {
      responses: protoToResponses(proto.exclusionData),
    },
    enrollmentComplete: proto.isComplete,
    participantId: proto['patientId'],
  };
}

function protoToParticipantData(proto: PatientData | undefined): {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phone: string;
  genderIdentity: GenderIdentity;
  insurance: string;
} {
  if (!proto) {
    return {
      firstName: '',
      lastName: '',
      dob: '',
      email: '',
      phone: '',
      genderIdentity: GenderIdentity.GENDER_IDENTITY_UNSPECIFIED,
      insurance: '',
    };
  }
  const dob = proto['dateOfBirth'];
  const {email, phone} = protoToContact(proto['telecom']);
  return {
    firstName: proto['name']?.['given'] || '',
    lastName: proto['name']?.['family'] || '',
    dob: dob ? parseProtoToDateString(dob) : '',
    email,
    phone,
    genderIdentity: proto['genderIdentity'],
    insurance: proto['enrollmentVerificationKey'],
  };
}

function protoToContact(proto: Telecom[] | undefined): {
  email: string;
  phone: string;
} {
  if (!proto) {
    return {email: '', phone: ''};
  }
  const email = proto?.find(
    t => t['system'] === Telecom_ContactPointSystem.EMAIL
  );
  const phone = proto?.find(
    t => t['system'] === Telecom_ContactPointSystem.PHONE
  );
  return {
    email: email?.['value'] || '',
    phone: phone?.['value'] || '',
  };
}

function protoToPolicySetConsents(
  proto: ConsentData | undefined
): (Date | null)[] {
  return (
    proto?.['consents'].map(policySetConsent =>
      policySetConsent['consentedAt']
        ? Timestamp.toDate(policySetConsent['consentedAt']) || null
        : null
    ) || []
  );
}

function protoToResponses(
  proto: InclusionScreenerData | ExclusionScreenerData | undefined
): Record<string, boolean | null> {
  return (
    proto?.['questions'].reduce((acc, cur) => {
      return {...acc, [cur['id']]: cur['answer']};
    }, {}) || {}
  );
}

export function stateToProto(
  s: Partial<UserState>,
  profile: string,
  step: Step
): EnrollPatientRequest {
  const req: EnrollPatientRequest = {
    isComplete: false,
    currentStep: step,
  };
  req.profile = toProfileProto(profile, window.location.hostname);
  if (s.enrollmentComplete) {
    req.isComplete = true;
  }

  if (s.participantData) {
    req['patientData'] = participantDataToProto(s['participantData']);
  }
  if (s.consent) {
    req.consentData = consentDataToProto(s.consent);
  }
  if (s.inclusion) {
    req.inclusionData = {questions: screenerDataToProto(s.inclusion)};
  }
  if (s.exclusion) {
    req.exclusionData = {questions: screenerDataToProto(s.exclusion)};
  }
  return req;
}

function timestampFromDate(d = new Date()): Timestamp {
  const timestamp: Timestamp = {
    seconds: BigInt(Math.round(d.getTime() / 1000)),
    nanos: 0,
  };
  return timestamp;
}

function participantDataToProto(d: UserState['participantData']): PatientData {
  const out: PatientData = {
    dateOfBirth: parseDateStringToProto(d!.dob),
    name: {given: d!.firstName, family: d!.lastName},
    telecom: [
      {system: Telecom_ContactPointSystem.EMAIL, value: d!.email},
      {system: Telecom_ContactPointSystem.PHONE, value: d!.phone},
    ],
    genderIdentity: d!.genderIdentity!,
    enrollmentVerificationKey: d!.insurance!,
    address: d.address,
  };
  return out;
}

function consentDataToProto(d: UserStateConsents): ConsentData {
  const {policySetConsents} = d;

  const consents: ConsentData_PolicySetConsent[] = policySetConsents.map(c => {
    const pol: ConsentData_PolicySetConsent = {
      consentedAt: c ? timestampFromDate(c) : undefined,
    };
    return pol;
  });
  return {consents};
}

function screenerDataToProto(
  d: UserState['inclusion'] | UserState['exclusion']
): Array<BooleanQuestionAnswer> {
  if (!d?.responses) {
    return [];
  }

  return Object.entries(d.responses).map(entry => {
    return {id: entry[0], answer: compareBoolean(entry[1])};
  });
}
