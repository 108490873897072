import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {createContext} from 'react';

export const AnalyticsModuleContext = createContext<Promise<System.Module>>(
  importAnalyticsModule()
);

async function importAnalyticsModule(): Promise<System.Module> {
  if (phafConfig.getBoolean('FEATURE_ANALYTICS_MFE_ENABLED')) {
    return System.import('@verily-src/analytics');
  }
  return Promise.resolve(undefined as System.Module);
}
