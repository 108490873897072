import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {EnrollmentClient} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server.client';
import {checkAuthentication} from './checkAuthentication';
import {checkEligibility} from './checkEligibility';
import {createAccount} from './createAccount';
import {enroll} from './enroll';
import {getAgreementConsents} from './getAgreementConsents';
import {getDomainConfig} from './getDomainConfig';
import {getPasswordResetReturnProfile} from './getPasswordResetReturnProfile';
import {getProfileConfig} from './getProfileConfig';
import {getUserState} from './getUserState';
import {recordEnrolleeEvent} from './recordEnrolleeEvent';
import reportError from './reportError';
import resendPasswordResetEmail from './resendPasswordResetEmail';
import {startEnrollment} from './startEnrollment';
import {startSession} from './startSession';

export const client = new EnrollmentClient(
  new GrpcWebFetchTransport({
    // This env variable is only used by velvet deployed storybook
    // which is a static site using the same translation backend as the mfe
    baseUrl: process.env.STORYBOOK_BFF_URL || '/api',
  })
);

export const api = {
  checkEligibility,
  checkAuthentication,
  createAccount,
  enroll,
  getAgreementConsents,
  getDomainConfig,
  getProfileConfig,
  getUserState,
  recordEnrolleeEvent,
  reportError,
  startEnrollment,
  startSession,
  getPasswordResetReturnProfile,
  resendPasswordResetEmail,
};
