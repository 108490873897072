import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {Auth} from '@verily-src/auth';
import {getStoredSession, SESSION_HEADER_KEY} from '../../session/sessionUtil';

export const getRpcOptions = async (): Promise<RpcOptions> => {
  let options: RpcOptions = {};
  const storedSession = getStoredSession();
  const sessionMetadata = {[SESSION_HEADER_KEY]: storedSession ?? ''};
  options = {
    ...options,
    meta: {
      ...sessionMetadata,
    },
  };
  const interceptors = [await Auth.getInterceptor()];
  options = {...options, interceptors};
  return options;
};
