import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import React, {useContext, useEffect} from 'react';
import useSWR from 'swr';
import {OneTrustContext} from '../../contexts/oneTrustContext';
import {useConfig} from '../../hooks/useConfig';
import {useDomain} from '../../hooks/useDomain';
import {useProfile} from '../../hooks/useProfile';
import {api} from '../../lib/api';
import {EnrollmentError, EnrollmentErrorType} from '../../lib/api/error';
import {setStoredSession} from '../../session/sessionUtil';
import {EnrollmentStep} from '../../types/flow';
import Loading from '../loading';

export interface AuthenticationGuardProps {
  children: React.ReactNode;
  enrollmentStep: EnrollmentStep;
}

const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  children,
  enrollmentStep,
}) => {
  const {profileName} = useProfile();
  const {domainName} = useDomain();
  const {config} = useConfig();
  const oneTrust = useContext(OneTrustContext);
  // TODO(PHP-26054): Steps like screened out will not be found in the map since they
  // aren't actual reorderable FlowConfig steps, so we use PARTICIPANT_DATA as a proxy given
  // those pages if shown would happen after that step
  const currentStep =
    config.flow.enrollmentStepToConfigStep.get(enrollmentStep) ||
    config.flow.enrollmentStepToConfigStep.get(EnrollmentStep.PARTICIPANT_DATA);

  const {
    data: checkAuthenticationResponse,
    error: checkAuthenticationError,
    isLoading: isLoadingCheckAuthentication,
  } = useSWR([domainName, profileName, currentStep], ([d, p, cs]) =>
    api.checkAuthentication(d, p, cs)
  );

  useEffect(() => {
    if (
      checkAuthenticationResponse &&
      phafConfig.getBoolean('FEATURE_ONETRUST_ENABLED')
    ) {
      // TODO(PHP-19902): initialize oneTrust with user ID
      oneTrust.initialize();
    }
  }, [checkAuthenticationResponse, oneTrust]);

  if (checkAuthenticationError) {
    // Although the router errorElement handles the unauthenticated error status appropriately,
    // if we don't explicitly prevent the error from propagating in development environments,
    // the user will see a flash of the error overlay before being redirected
    // to the auth login page. (There should be no UX consequences in environments where the error
    // overlay is disabled, however).
    if (
      checkAuthenticationError instanceof EnrollmentError &&
      checkAuthenticationError.errorType === EnrollmentErrorType.UNAUTHENTICATED
    ) {
      return <Loading />;
    }
    throw checkAuthenticationError;
  }
  if (isLoadingCheckAuthentication) {
    return <Loading />;
  }
  if (checkAuthenticationResponse) {
    if (checkAuthenticationResponse.sessionToken) {
      // TODO(PHP-23910): remove this
      setStoredSession(checkAuthenticationResponse.sessionToken);
    }
    return <>{children}</>;
  }
  return <Loading />;
};

export default AuthenticationGuard;
