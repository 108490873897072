import {RpcError} from '@protobuf-ts/runtime-rpc';
import {GetPasswordResetReturnProfileResponse} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callGetPasswordResetReturnProfile} from '../../utils/clientCaller';
import {
  EnrollmentError,
  EnrollmentErrorType,
  isCiamUnauthenticatedError,
} from './error';
import {getRpcOptions} from './getRpcOptions';

type GetPasswordResetReturnProfileProps = {
  domain: string;
};

export const getPasswordResetReturnProfile = async ({
  domain,
}: GetPasswordResetReturnProfileProps): Promise<GetPasswordResetReturnProfileResponse> => {
  try {
    const options = await getRpcOptions();
    return await callGetPasswordResetReturnProfile({domain}, options);
  } catch (e) {
    if (e instanceof RpcError) {
      // unauthenticated errors will be handled by the Auth MFE
      if (isCiamUnauthenticatedError(e)) {
        throw new EnrollmentError(
          EnrollmentErrorType.UNAUTHENTICATED,
          e.message,
          e
        );
      } else {
        throw new EnrollmentError(
          EnrollmentErrorType.PASSWORD_RESET_REDIRECT_ERROR,
          e.message,
          e
        );
      }
    }
    throw new EnrollmentError(EnrollmentErrorType.UNKNOWN, e.message, e);
  }
};
