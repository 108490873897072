import {Box, Grid, Stack, Typography} from '@mui/material';
import QRCode from 'qrcode.react';
import {memo, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {
  getOperatingSystem,
  OperatingSystem,
  toCommonAssetsPath,
} from '../../../utils';

type AppHandoffLayoutProps = {
  productName?: string;
  appHandoffLink?: string;
  androidHandoffLink?: string;
  iosHandoffLink?: string;
};

const AppHandoffLayout = memo((props: AppHandoffLayoutProps) => {
  const {t} = useTranslation();
  const OS: OperatingSystem = getOperatingSystem();
  const isMobile = OS === OperatingSystem.IOS || OS === OperatingSystem.ANDROID;
  return (
    <>
      <Typography variant="body1">
        {t('handoff.app+web-app-handoff', {
          productName: props.productName,
        })}
      </Typography>
      <Box
        style={{
          display: 'flex',
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Stack spacing={2} direction={{xs: 'column', sm: 'row'}}>
          {!isMobile && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <QRCode
                data-testid="qrcode"
                value={props.appHandoffLink}
                aria-label={t('handoff.qr-code-alt-text')}
                role={t('handoff.qr-code-role')}
                renderAs="svg"
              />
            </div>
          )}
          <Grid container spacing={2} width="100%" display="flex">
            <Typography variant="display6" gutterBottom align="left">
              {t('handoff.qr-code-header', {productName: props.productName})}
            </Typography>
            {!isMobile && (
              <Typography variant="body1" gutterBottom>
                {t('handoff.qr-code-description', {
                  productName: props.productName,
                })}
              </Typography>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {getDownloadButtonsForOS(
                props.iosHandoffLink,
                props.androidHandoffLink,
                OS,
                t
              )}
            </div>
          </Grid>
        </Stack>
      </Box>
    </>
  );
});

function getDownloadButtonsForOS(
  iosHandoffLink: string,
  androidHandoffLink: string,
  OS: OperatingSystem,
  t?: Function
): ReactNode {
  const iosImgComponent = (
    <a href={iosHandoffLink}>
      <img
        data-testid="ios-image"
        src={toCommonAssetsPath('download_badge_apple@1.5x.png', true)}
        alt={t('handoff.qr-code-alt-text')}
        role={t('handoff.qr-code-role')}
        style={{
          width: 'auto',
          maxWidth: '100%',
        }}
      />
    </a>
  );
  const androidImgComponent = (
    <a href={androidHandoffLink}>
      <img
        data-testid="android-image"
        src={toCommonAssetsPath('download_badge_google@1.5x.png', true)}
        alt={t('handoff.qr-code-alt-text')}
        role={t('handoff.qr-code-role')}
        style={{
          width: 'auto',
          maxWidth: '100%',
        }}
      />
    </a>
  );
  switch (OS) {
    case OperatingSystem.IOS:
      return iosImgComponent;
    case OperatingSystem.ANDROID:
      return androidImgComponent;
    default:
      return (
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          sx={{width: '100%'}}
          spacing={2}
        >
          <Grid item>{iosImgComponent}</Grid>
          <Grid item>{androidImgComponent}</Grid>
        </Stack>
      );
  }
}

export default AppHandoffLayout;
