import {UserType} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import i18n, {Resource, TFunction} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

// Used to denote the type of user enrolling in the program
// (i.e., for themselves or as a pediatric caregiver).
export const INDIVIDUAL_USERTYPE_I18_NAMESPACE = 'individual';
export const PEDIATRIC_USERTYPE_I18_NAMESPACE = 'pediatric';

// Returns the i18n namespace for the usertype-specific content
export const userTypeSpecificI18Namespace = (userType: UserType) => {
  switch (userType) {
    case UserType.PEDIATRIC:
      return PEDIATRIC_USERTYPE_I18_NAMESPACE;
    default:
      return INDIVIDUAL_USERTYPE_I18_NAMESPACE;
  }
};

// MFE localized content settings
const PSEUDO_LOCALE = 'en-XA';
const DEFAULT_LOCALES = ['en', 'es'];
const DEFAULT_COMMON_NAMESPACE = 'common';
const DEFAULT_NAMESPACES = [
  DEFAULT_COMMON_NAMESPACE,
  INDIVIDUAL_USERTYPE_I18_NAMESPACE,
  PEDIATRIC_USERTYPE_I18_NAMESPACE,
];

// TODO(PHP-20534): Modify that common function to be called by Enrollment
/**
 * Reads the resource JSONS and organizes them into language-namespace
 * resources for i18next to use
 *
 * This is a copied but slightly modifed version of the helper function
 * in verily1/common/l10n/web. We can't use that function directly
 * because it assumes the JSONs are formatted in a flattened format
 * matching what translation service outputs, instead of generic
 * i18n nested content JSONs.
 */
export async function initI18nMfeResources(
  requireFn: (string) => unknown,
  locales: string[] = DEFAULT_LOCALES,
  namespaces: string[] = DEFAULT_NAMESPACES,
  langOverride: string | undefined = undefined
): Promise<TFunction> {
  const resources: {
    locale: string;
    ns: string;
    resource: unknown;
  }[] = [];

  const realLocales = locales.filter(l => l !== PSEUDO_LOCALE);
  for (const locale of realLocales) {
    for (const ns of namespaces) {
      const resource = requireFn(`${locale}/${ns}.json`);
      resources.push({locale, ns, resource});
    }
  }

  const bundle: Resource = {};
  for (const {locale, ns, resource} of resources) {
    bundle[locale] ??= {};
    bundle[locale][ns] ??= resource;
  }

  const postProcessModules: string[] = [];
  const mod = i18n.use(LanguageDetector).use(initReactI18next);

  return mod.init({
    lng: langOverride,
    detection: {
      order: [
        'querystring',
        'navigator',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    ns: namespaces,
    defaultNS: DEFAULT_COMMON_NAMESPACE,
    fallbackLng: {
      default: ['en'],
    },
    // This ensures variants are supported when main language is here.
    // E.g., en-US gets mapped to en
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false, // react already performs sanitization
    },
    resources: bundle,
    postProcess: postProcessModules,
  });
}
