import {Stack, Typography} from '@mui/material';
import {Radio, RadioGroup} from '@verily-src/react-design-system';
import * as React from 'react';
import {Control, Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {AppQuestionSectionConfig} from '../../../lib/types/appConfig';
import {FormType} from '../index';

export interface BoolQuestionSectionLayoutProps {
  section: AppQuestionSectionConfig;
  control: Control<FormType>;
  disableForm?: boolean;
}

interface SingleQuestionSectionProps {
  question: {
    id: string;
    title?: string;
    description?: string;
  };
  control: Control<FormType>;
  disableForm?: boolean;
}

interface MultiQuestionSectionProps {
  questions: {
    id: string;
    title?: string;
    description?: string;
  }[];
  control: Control<FormType>;
  disableForm?: boolean;
}

export const BoolQuestionFormSection = ({
  control,
  section,
  disableForm,
}: BoolQuestionSectionLayoutProps) => {
  return (
    <>
      {section.header && (
        <Typography variant="body1" gutterBottom>
          <strong>{section.header}</strong>
        </Typography>
      )}
      {section.subheader && (
        <Typography variant="body2">{section.subheader}</Typography>
      )}
      {section.questions.length === 1 ? (
        <SingleQuestionSection
          control={control}
          question={section.questions[0]}
          disableForm={disableForm}
        />
      ) : (
        <MultiQuestionSection
          control={control}
          questions={section.questions}
          disableForm={disableForm}
        />
      )}
    </>
  );
};

const SingleQuestionSection = ({
  control,
  question,
  disableForm,
}: SingleQuestionSectionProps) => {
  const {t} = useTranslation();
  return (
    <>
      <React.Fragment key={question.id}>
        <Controller
          control={control}
          name={question.id}
          render={({field, fieldState: {error}}) => (
            <RadioGroup
              label={
                <Typography variant="body1" gutterBottom>
                  <strong>{question.title}</strong>
                </Typography>
              }
              disabled={disableForm}
              {...field}
              ref={null}
              orientation="horizontal"
              horizontalPlacement={true}
              helperText={question.description}
              error={!!error}
              errorText={error && t('navigation.response-required')}
            >
              <Radio
                role="radio"
                value={true}
                label={t('navigation.yes')}
                aria-label={t('navigation.yes')}
              />
              <Radio
                role="radio"
                value={false}
                label={t('navigation.no')}
                aria-label={t('navigation.no')}
              />
            </RadioGroup>
          )}
        />
      </React.Fragment>
    </>
  );
};

const MultiQuestionSection = ({
  control,
  questions,
  disableForm,
}: MultiQuestionSectionProps) => {
  const {t} = useTranslation();
  return (
    <>
      {questions.map(question => (
        <React.Fragment key={question.id}>
          <Stack sx={{ml: 3.75, spacing: 3, display: 'inline-flex'}}>
            <Controller
              control={control}
              name={question.id}
              render={({field, fieldState: {error}}) => (
                <RadioGroup
                  label={question.title}
                  disabled={disableForm}
                  {...field}
                  ref={null}
                  orientation="horizontal"
                  horizontalPlacement={true}
                  helperText={question.description}
                  error={!!error}
                  errorText={error && t('navigation.response-required')}
                >
                  <Radio
                    role="radio"
                    value={true}
                    label={t('navigation.yes')}
                    aria-label={t('navigation.yes')}
                  />
                  <Radio
                    role="radio"
                    value={false}
                    label={t('navigation.no')}
                    aria-label={t('navigation.no')}
                  />
                </RadioGroup>
              )}
            />
          </Stack>
        </React.Fragment>
      ))}
    </>
  );
};
