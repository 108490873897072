import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import {api} from '../lib/api';
import {EnrollmentError, EnrollmentErrorType} from '../lib/api/error';
import {getStoredSession, setStoredSession} from '../session/sessionUtil';
import {useProfile} from './useProfile';

/**
 * Hook for getting enrollment session token
 *
 * This retrieves the existing token from session storage if it exists;
 * otherwise, it calls the bff to initialize one and stores it in session storage.
 */
export default function useSessionInitializer(
  setSessionInitializationComplete: (_: boolean) => void
) {
  const {profileName: profile} = useProfile();
  const [params] = useSearchParams();
  const [runStartSession, setRunStartSession] = useState<boolean>(false);
  const {
    error,
    isLoading: startSessionIsLoading,
    data: startSessionData,
  } = useSWRImmutable(runStartSession ? profile : null, p =>
    api.startSession(p, params)
  );

  useEffect(() => {
    if (error) {
      throw new EnrollmentError(
        EnrollmentErrorType.INTERNAL_SERVER_ERROR,
        `Error in ${api.startSession.name}`,
        error
      );
    }
    if (startSessionIsLoading) {
      return;
    }
    const storedSession = getStoredSession();
    if (!storedSession) {
      setRunStartSession(true);
    }
    const sessionToken = storedSession || startSessionData?.sessionToken;
    if (sessionToken) {
      // TODO(PHP-23910): remove this
      setStoredSession(sessionToken);
      setSessionInitializationComplete(!!sessionToken);
    }
  }, [
    error,
    setSessionInitializationComplete,
    startSessionData,
    startSessionIsLoading,
  ]);
  return;
}
