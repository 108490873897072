import {StartEnrollmentRequest} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callStartEnrollment} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {getRpcOptions} from './getRpcOptions';

const startEnrollmentServiceException =
  'There was an error logging in, please try again later.';

export const startEnrollment = async (
  profileName: string | undefined,
  domain: string
): Promise<Boolean> => {
  try {
    const options = await getRpcOptions();
    const request: StartEnrollmentRequest = {
      profile: profileName ? toProfileProto(profileName, domain) : undefined,
    };
    const res = await callStartEnrollment(request, options);
    return !!res;
  } catch (error) {
    throw new Error(startEnrollmentServiceException);
  }
};
