import {
  Collections,
  ContentPaste,
  InsertChart,
  RoomService,
  VisibilityOff,
} from '@mui/icons-material';
import {Grid, Typography, useTheme} from '@mui/material';
import Stack from '@mui/material/Stack';
import {
  Button,
  Checkbox,
  HowToRegIcon,
  TextField,
} from '@verily-src/react-design-system';
import {
  AgreementConsentContent,
  UserType,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {t} from 'i18next';
import {Controller, useFormContext} from 'react-hook-form';
import {WithErrorText} from '../participant-data/subforms/utils';
import ContentCardLayout from './subforms/content-card-layout';

type ParticipantDataLayoutProps = {
  submit: () => void;
  consents: AgreementConsentContent[];
  isLoading?: boolean;
  policiesAgreedTo: boolean;
  userType: UserType;
};

const HeaderLayout = () => {
  return (
    <header>
      <Typography variant="display5" component="h1" display="flex" gutterBottom>
        You’re almost done!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Review and agree to the terms & conditions to continue.
      </Typography>
    </header>
  );
};

const ConsentCardsLayout = () => {
  return (
    <>
      <ContentCardLayout
        title="Consent to Care"
        listItems={[
          {
            body: 'Verily Me requires your consent to access and use your health data. This consent outlines how your data will be used.',
            icon: <ContentPaste />,
          },
        ]}
      />
      <ContentCardLayout
        title="Our Privacy Policy"
        listItems={[
          {
            body: 'We don’t share your data outside of Verily.',
            icon: <VisibilityOff />,
          },
          {
            body: 'This is a different document than what you saw during enrollment.',
            icon: <Collections />,
          },
          {
            body: 'You must be 18 years or above to use the application.',
            icon: <HowToRegIcon />,
          },
          {
            body: 'By using Verily services, you consent to the use of your health data for personalized insights, service improvements, and research, with strict privacy safeguards.',
            icon: <RoomService />,
          },
        ]}
      />
      <ContentCardLayout
        title="Consent to promotional text"
        listItems={[
          {
            body: 'Verily Me prioritizes your control over your health data and communication preferences. You provide explicit consent for data access and promotional texts, with the ability to revoke or opt-out anytime.',
            icon: <InsertChart />,
          },
        ]}
      />
    </>
  );
};

const ParticipantDataFormLayout = () => {
  const {control, formState} = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name={'firstName'}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <WithErrorText error={error}>
            <TextField
              required
              data-cy="first-name"
              {...field}
              label={t('patientData.first-name')}
              placeholder={t('patientData.first-name')}
              disabled={formState.isSubmitting}
              error={!!error}
              fullWidth={true}
              multiline={false}
            />
          </WithErrorText>
        )}
      />
      <Controller
        control={control}
        name={'lastName'}
        render={({field: {ref: _, ...field}, fieldState: {error}}) => (
          <WithErrorText error={error}>
            <TextField
              required
              data-cy="last-name"
              {...field}
              label={t('patientData.last-name')}
              placeholder={t('patientData.last-name')}
              disabled={formState.isSubmitting}
              error={!!error}
              fullWidth={true}
              multiline={false}
            />
          </WithErrorText>
        )}
      />
    </>
  );
};

type FooterProps = {
  submit: () => void;
};
const ParticipantDataFooterLayout = (props: FooterProps) => {
  const {control, formState} = useFormContext();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.background.canvas,
        padding: '16px',
        borderTop: '1px solid',
        borderColor: 'neutral.divider',
      }}
      spacing={1}
    >
      <Controller
        name="checkboxReasons.0"
        control={control}
        render={({field: {ref: _, ...field}}) => (
          <Checkbox
            data-cy="policy-checkbox"
            data-testid="policy-checkbox"
            {...field}
            checked={field.value || false}
            label="By checking this box, you agree that you have reviewed and accept all terms and conditions."
            sx={{
              paddingInline: 2,
              paddingBlock: 1,
              margin: 0,
              borderRadius: 2,
            }}
          />
        )}
      />
      <Button
        data-cy="submit-button"
        data-testid="submit-button"
        label="Agree & continue"
        variant="filled"
        size="large"
        loading={formState.isSubmitting}
        disabled={!formState.isValid}
        onClick={props.submit}
      />
    </Stack>
  );
};

const ParticipantDataLayout = ({submit}: ParticipantDataLayoutProps) => {
  return (
    <Grid
      container
      data-testid="grid"
      sx={{
        display: 'flex',
        height: '100vh',
        overflow: {md: 'hidden'},
      }}
    >
      <Grid
        item
        key="main-area"
        sx={{
          height: '100vh',
          overflow: 'hidden',
          bgcolor: 'background.paper',
        }}
      >
        <form style={{height: '100%'}} onSubmit={submit}>
          <Stack sx={{overflow: 'hidden', height: '100%'}}>
            <Stack
              sx={{
                overflow: 'hidden',
                overflowY: 'auto',
                height: '100%',
                display: 'inline',
                padding: '16px',
              }}
              spacing={2}
            >
              <HeaderLayout />
              <ConsentCardsLayout />
              <ParticipantDataFormLayout />
            </Stack>
            <ParticipantDataFooterLayout submit={submit} />
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
};

export default ParticipantDataLayout;
