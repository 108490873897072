import {Typography} from '@mui/material';
import Stack from '@mui/material/Stack';
import {Button} from '@verily-src/react-design-system';
import {
  AgreementConsentContent,
  UserType,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {Dispatch, SetStateAction} from 'react';
import {useFormContext, type FieldValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {userTypeSpecificI18Namespace} from '../../i18n_helpers';
import {AppConfig} from '../../lib/types/appConfig';
import {PolicyElement} from '../policy';
import ResponsiveContainer from '../responsive/responsive-container/ResponsiveContainer';
import ResponsiveContent from '../responsive/responsive-content/ResponsiveContent';
import ResponsiveSplash from '../responsive/responsive-splash/ResponsiveSplash';
import {toCommonAssetsPath} from '../utils';
import {
  ConfirmParticipantDataModal,
  ParticipantDataInfo,
} from './confirm-data-modal';
import AddressFormLayout from './subforms/address-info-layout';
import BasicInfoFormLayout from './subforms/basic-info-layout';
import ContactInfoFormLayout from './subforms/contact-info-layout';
import EligibilityInfoFormLayout from './subforms/eligibility-info-layout';
import {BetweenSectionSpacer} from './subforms/utils';

type ParticipantDataLayoutProps = {
  econsentHandoff: boolean;
  submit: () => void;
  config: AppConfig;
  consents: AgreementConsentContent[];
  isLoading?: boolean;
  policiesAgreedTo: boolean;
  memberIdFieldLabel: string; // only used for eligibility check
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  // Data that appears in modal for user to confirm, if ineligible
  modalConfirmationInfo: {
    firstName: string;
    lastName: string;
    dob: string;
    eligibilityKey?: string;
  };
  onConfirmModalInfoHandler: () => void;
  userType: UserType;
};

const ParticipantDataLayout = <T extends FieldValues>({
  econsentHandoff,
  submit,
  config,
  consents,
  isLoading,
  policiesAgreedTo,
  memberIdFieldLabel,
  openModal,
  setOpenModal,
  modalConfirmationInfo,
  onConfirmModalInfoHandler,
  userType,
}: ParticipantDataLayoutProps) => {
  const {t} = useTranslation();
  const centerImage = toCommonAssetsPath('illy_participant_data.png');
  const {control, formState} = useFormContext<T>();
  const splash = (
    <ResponsiveSplash
      logo1={config.logo1}
      logo2={config.logo2}
      centerImage={centerImage}
    />
  );
  // Chosen because text is only unique property, should create separate buttons if there become more.
  const buttonText = econsentHandoff
    ? t('navigation.econsentContinue')
    : t('navigation.continue');

  const firstNameFieldLabel = t('patientData.first-name');
  const lastNameFieldLabel = t('patientData.last-name');
  const dobFieldLabel = t('patientData.date-of-birth');

  function toModalTableRows(): ParticipantDataInfo[] {
    return [
      {label: firstNameFieldLabel, value: modalConfirmationInfo.firstName},
      {label: lastNameFieldLabel, value: modalConfirmationInfo.lastName},
      {label: dobFieldLabel, value: modalConfirmationInfo.dob},
      {label: memberIdFieldLabel, value: modalConfirmationInfo.eligibilityKey},
    ];
  }

  const userTypeSpecificContentNs = userTypeSpecificI18Namespace(userType);

  const content = (
    <ResponsiveContent disclaimerFooter={config.disclaimerFooter}>
      <form onSubmit={submit}>
        <Stack
          spacing={2}
          display="inline-flex"
          sx={{width: {sm: '75%'}, minWidth: '75%'}}
        >
          <header>
            <Typography
              variant="display5"
              component="h1"
              display="flex"
              gutterBottom
            >
              {config.participantDataHeader ??
                t('welcome.welcome2', {ns: userTypeSpecificContentNs})}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {config.participantDataSubheader ??
                t('welcome.welcome-subheader', {
                  ns: userTypeSpecificContentNs,
                })}
            </Typography>
          </header>
          <BetweenSectionSpacer />

          <BasicInfoFormLayout
            collectGenderIdentity={config.collectGenderIdentity}
            userType={userType}
            subheader={config.basicInfoSubheader}
          />
          <BetweenSectionSpacer />

          <ContactInfoFormLayout
            userType={userType}
            subheader={config.contactInfoSubheader}
          />
          <BetweenSectionSpacer />

          {config.collectAddress && (
            <>
              <AddressFormLayout
                userType={userType}
                header={config.addressHeader}
                subheader={config.addressSubheader}
              />
              <BetweenSectionSpacer />
            </>
          )}

          {config.eligibility.checkEligibility && (
            <>
              <EligibilityInfoFormLayout
                memberIdFieldLabel={memberIdFieldLabel}
                userType={userType}
              />
              <BetweenSectionSpacer />
            </>
          )}

          {config.hasAgreementConsents && (
            <>
              <PolicyElement<T>
                control={control}
                consents={consents}
                disabled={policiesAgreedTo || formState.isSubmitting}
              />
              <BetweenSectionSpacer />
            </>
          )}

          <Button
            data-cy="submit-button"
            data-testid="submit-button"
            label={buttonText}
            variant="filled"
            sx={{
              alignSelf: 'end',
              paddingInline: '1.5rem',
            }}
            loading={formState.isSubmitting || isLoading}
            disabled={!formState.isValid}
            onClick={submit}
          />
        </Stack>
      </form>
    </ResponsiveContent>
  );

  return (
    <>
      <ConfirmParticipantDataModal
        data-testid="confirm-modal"
        open={openModal}
        setOpen={setOpenModal}
        participantInfo={toModalTableRows()}
        handleContinue={onConfirmModalInfoHandler}
      />
      <ResponsiveContainer splash={splash} content={content} />
    </>
  );
};

export default ParticipantDataLayout;
