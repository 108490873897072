import {CssBaseline, Theme, ThemeProvider} from '@mui/material';
import {ConsumerLightTheme, extendTheme} from '@verily-src/react-design-system';
import React from 'react';
import i18n from '../i18n';

export type UpdateThemeProps = {
  primaryMainColor: string;
};

export const EnrollmentThemeProviderContext = React.createContext<{
  updateTheme: (props: UpdateThemeProps) => void;
}>(null as never);

export const EnrollmentThemeProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [theme, setTheme] = React.useState<Theme>(ConsumerLightTheme);

  const updateTheme = React.useCallback(
    (props: UpdateThemeProps) => {
      // Can't just set main color; need to copy over all other palette
      // color options (see PHP-2866)
      const primary = {
        ...ConsumerLightTheme.palette.primary,
        // profile color override should change: all InputField borders, Spinner, Checkbox, Button, Radio,
        ...(props.primaryMainColor && {
          main: props.primaryMainColor, // Updates everything except for TextField
          textOnBackground: props.primaryMainColor, // required to update border around RDS TextField (see PHP-6429)
        }),
      };
      if (props.primaryMainColor) {
        setTheme(
          extendTheme(ConsumerLightTheme, {
            palette: {primary},
          })
        );
      }
    },
    [setTheme]
  );

  return (
    <EnrollmentThemeProviderContext.Provider value={{updateTheme}}>
      <ThemeProvider
        theme={{
          ...theme,
          locale: i18n.languages?.length > 0 ? i18n.languages[0] : 'en',
        }}
      >
        <CssBaseline />
        {children}
      </ThemeProvider>
    </EnrollmentThemeProviderContext.Provider>
  );
};

export function useEnrollmentTheme() {
  return React.useContext(EnrollmentThemeProviderContext);
}
