import {Step} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {api} from '../lib/api';

import {UserState, UserStateConsents} from '../lib/types/userState';
import {useSWR} from '../swr';
import {useProfile} from './useProfile';

type UseUserStateReturn = {
  userState?: UserState;
  setParticipantData?: Function;
  setInclusionData?: Function;
  setExclusionData?: Function;
  updateUserStateComplete?: Function;
  // True when there is an ongoing request and **data is not loaded yet**
  // See https://swr.vercel.app/docs/advanced/understanding
  isLoadingInitialState: boolean;
  // True when there's an active request ongoing to update user state
  isFetchingUpdatedState?: boolean;
  error?: Error;
};

// TODO(PHP-23652): require currentStep; we allow null values since
// inclusion/exclusion pages do not map to a Step defined in the proto
// but we should enforce this requirement once we remove these pages
export function useUserState(currentStep?: Step): UseUserStateReturn {
  const {profileName} = useProfile();

  const {
    data,
    mutate,
    isLoading: isLoadingInitialState,
    isValidating: isFetchingUpdatedState,
    error,
  } = useSWR({profileName, currentStep}, api.getUserState);

  return {
    userState: data,
    setParticipantData: async (
      participantData: UserState['participantData'],
      consentData: UserStateConsents | undefined
    ) => {
      // Send only the participant+consent data since the server expects
      // "partial enrollment" payloads at each enrollment step and not
      // the entire user state
      const newParticipantDataUserState: UserState = {
        participantData: participantData,
        ...(consentData && {consent: consentData}),
      };
      await api.enroll({
        userState: newParticipantDataUserState,
        profileName,
        currentStep,
      });
      // ...then update just the relevant sections of the cached user state
      const newEntireUserState: UserState = {
        ...data,
        participantData: participantData,
        ...(consentData && {consent: consentData}),
      };
      await mutate(newEntireUserState);
    },
    setInclusionData: async (responses: UserState['inclusion']) => {
      const partialUserState: UserState = {
        inclusion: responses,
      };
      // Send only partial enrollment data similar to above
      await api.enroll({
        userState: partialUserState,
        profileName,
        currentStep,
      });
      const newEntireUserState: UserState = {
        ...data,
        inclusion: responses,
      };
      await mutate(newEntireUserState);
    },
    setExclusionData: async (responses: UserState['exclusion']) => {
      const partialUserState: UserState = {
        exclusion: responses,
      };
      // Send only partial enrollment data similar to above
      await api.enroll({
        userState: partialUserState,
        profileName,
        currentStep,
      });
      const newEntireUserState: UserState = {
        ...data,
        exclusion: responses,
      };
      await mutate(newEntireUserState);
    },
    updateUserStateComplete: async () => {
      // Updates the cached user state and refreshes from server
      // The complete enrollment grpc enroll request is called separately
      // within the completion component since it's not triggered by a user action
      // and needs to handle various async states
      const newEntireUserState: UserState = {
        ...data,
        enrollmentComplete: true,
      };
      await mutate(newEntireUserState);
    },
    isLoadingInitialState,
    isFetchingUpdatedState,
    error,
  };
}
