import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDomain} from '../../../hooks/useDomain';
import {api} from '../../../lib/api';
import {EnrollmentError} from '../../../lib/api/error';
import {enrollFlowPath, profilePath, ROUTE_STUBS} from '../../../types/route';
import ErrorElement from '../../global-error/global-error';
import Loading from '../../loading';

export default function PasswordResetRedirectProfile() {
  const navigate = useNavigate();
  const {domainName} = useDomain();
  const [error, setError] = useState<EnrollmentError>(null);

  useEffect(() => {
    api
      .getPasswordResetReturnProfile({domain: domainName})
      .then(res => {
        navigate(enrollFlowPath(profilePath(res.profile)), {
          state: {
            from: ROUTE_STUBS.profileRedirect,
          },
        });
      })
      .catch(err => setError(err));
  }, [domainName, navigate]);

  return error ? <ErrorElement error={error} /> : <Loading />;
}
