import {yupResolver} from '@hookform/resolvers/yup';
import {useSnackbar} from '@verily-src/react-design-system';
import {
  AgreementConsentContent,
  GetAgreementConsentsResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {TFunction} from 'i18next';
import {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {useConfig} from '../../hooks/useConfig';
import {useDomain} from '../../hooks/useDomain';
import {useProfile} from '../../hooks/useProfile';
import {useUserState} from '../../hooks/useUserState';
import {getAgreementConsents} from '../../lib/api/getAgreementConsents';
import {UserState} from '../../lib/types/userState';
import {EnrollmentStep} from '../../types/flow';
import {errorPath} from '../../types/route';
import {
  getAddressFromFormValues,
  getGenderIdentity,
  numCheckboxesInConsents,
  policiesAgreedTo,
} from '../../utils';
import {useProgress} from '../enrollment-flow/progress';
import Loading from '../loading';
import {getSchema} from '../policy';
import ParticipantDataLayout from './layout';

export const createSchema = (t: TFunction) => {
  return yup
    .object({
      firstName: yup
        .string()
        .required(t('eligibility.please-enter-your-first-name'))
        .default(''),
      lastName: yup
        .string()
        .required(t('eligibility.please-enter-your-last-name'))
        .default(''),
      ...getSchema().fields,
    })
    .required();
};

export default function ParticipantDataNUXPage() {
  const {config} = useConfig();
  const participantDataStep = config.flow.enrollmentStepToConfigStep.get(
    EnrollmentStep.PARTICIPANT_DATA
  );
  const {userState, setParticipantData} = useUserState(participantDataStep);
  const {t} = useTranslation();
  const {completeStep} = useProgress(EnrollmentStep.PARTICIPANT_DATA);
  const {profileName} = useProfile();
  const {domainName} = useDomain();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [consentsAreLoaded, setConsentsAreLoaded] = useState(
    !config.hasAgreementConsents
  );
  const [consents, setConsents] = useState<AgreementConsentContent[]>([]);

  useEffect(() => {
    if (!config.hasAgreementConsents) {
      return;
    }
    getAgreementConsents(profileName, domainName, participantDataStep)
      .then((res: GetAgreementConsentsResponse) => {
        setConsents(res.consents);
        setConsentsAreLoaded(true);
      })
      .catch(() => {
        navigate(errorPath());
      });
  }, [
    profileName,
    domainName,
    config.hasAgreementConsents,
    navigate,
    participantDataStep,
  ]);

  const schema = createSchema(t);

  type SchemaType = yup.InferType<typeof schema>;

  const useFormMethods = useForm<SchemaType>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    values: {
      ...schema.getDefault(),
      ...userState?.['participantData'],
    },
  });

  const [currentlyLoading, setCurrentlyLoading] = useState(false);

  const submit = async () => {
    setCurrentlyLoading(true);

    // Fetch all form values excluding checkboxReasons.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {checkboxReasons, ...formValues} = useFormMethods.getValues();

    const participantData: UserState['participantData'] = {
      ...formValues,
      genderIdentity: getGenderIdentity(undefined),
      // Placeholder data for NUX prototype.
      dob: '1970-01-01',
      address: getAddressFromFormValues(
        '269 E Grand Ave., South San Francisco, CA 94080'
      ),
      phone: '+16175753142',
      email: 'nuxtest@verily.com',
    };

    try {
      // Agree to all consents with a single checkbox.
      const consentData = config.hasAgreementConsents
        ? {
            policySetConsents: [
              ...Array(numCheckboxesInConsents(consents)).keys(),
            ].map(_ => new Date()),
          }
        : undefined;
      await setParticipantData!(participantData, consentData);
    } catch (error) {
      snackbar.show(t('snackbar.enroll-submit-error'), 'error');
      setCurrentlyLoading(false);
      return;
    }

    setCurrentlyLoading(false);
    completeStep();
  };

  if (consentsAreLoaded && !currentlyLoading) {
    return (
      <FormProvider {...useFormMethods}>
        <ParticipantDataLayout
          submit={useFormMethods.handleSubmit(submit)}
          consents={consents}
          policiesAgreedTo={policiesAgreedTo(config, userState)}
          userType={config.userType}
        />
      </FormProvider>
    );
  } else {
    return <Loading />;
  }
}
